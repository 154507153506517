import { useCallback, useLayoutEffect, useState } from 'react'
import { debounce } from 'ts-debounce'

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
}
interface UseInfiniteScrollProps<T> {
  lastListElementRef: React.RefObject<HTMLDivElement>
  loadCount: number
  listData: T[]
  debounceTime?: number
}
export const useInfiniteScroll = <T>({
  lastListElementRef,
  loadCount = 10,
  listData,
  debounceTime = 500,
}: UseInfiniteScrollProps<T>) => {
  const [count, setCount] = useState(loadCount)
  const list = listData?.slice(0, count)
  const isFullListLoaded = listData.length <= count

  const loadMore = useCallback(
    debounce((entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setCount(count => count + loadCount)
        }
      }
    }, debounceTime),
    []
  )

  const observer = new IntersectionObserver(loadMore, intersectionOptions)
  useLayoutEffect(() => {
    if (lastListElementRef.current && listData)
      observer.observe(lastListElementRef.current)
  }, [lastListElementRef.current])

  return { list, isFullListLoaded }
}
