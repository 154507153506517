import { Box, styled, Typography } from '@material-ui/core'
import {
  DRAWER_MENU_SCALE,
  DRAWER_MENU_WIDTH,
  DRAWER_WIDTH,
} from 'shared/config'

export const RequiredActionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  color: 'white',
  width: `calc(100% - ${DRAWER_WIDTH}px)`,
  [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
    width: `calc(100% - ${DRAWER_WIDTH}px + ${
      DRAWER_MENU_WIDTH - DRAWER_MENU_WIDTH / DRAWER_MENU_SCALE
    }px)`,
  },
}))

export const HeaderText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 600,
  fontSize: '36px',
}))

export const SubtitleText = styled(Typography)(({ theme }) => ({
  color: '#a0a7b0',
  fontWeight: 400,
  fontSize: '20px',
}))
