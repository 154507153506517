import { CSSProperties, memo, PropsWithChildren } from 'react'
import { Button, ButtonProps, Tooltip } from '@material-ui/core'
import DisabledElement from '../DisabledElement'

interface LabeledButtonProps extends ButtonProps {
  disabledLabel: string
  label?: string | null
  notAllowedCursor?: boolean
  containerStyles?: CSSProperties
}

const LabeledButton = ({
  disabledLabel,
  label,
  containerStyles,
  children,
  notAllowedCursor = false,
  ...props
}: PropsWithChildren<LabeledButtonProps>) => (
  <Tooltip title={props.disabled ? disabledLabel : label ?? ''}>
    {notAllowedCursor ? (
      <DisabledElement
        disabled={!!props.disabled}
        style={containerStyles}
        fitContent
      >
        <Button {...props}>{children}</Button>
      </DisabledElement>
    ) : (
      <div style={containerStyles}>
        <Button {...props}>{children}</Button>
      </div>
    )}
  </Tooltip>
)

export default memo(LabeledButton)
