import { Button, Grid, Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import { ComponentType, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NoResult } from 'shared/icons'
import { Devices, GetDevicesParameters } from 'shared/services'
import { DisplayState, GetDevicesResponse, LoadingStatus } from 'shared/types'
import { CenterBox } from '../CenterBox'
import DisplayItem from '../DisplayItem'
import {
  ExpressiveDialog,
  ExpressiveDialogActions,
  ExpressiveDialogScrollbars,
  ExpressiveDialogTitle,
} from '../ExpressiveDialogComponents'
import Loader from '../Loader'
import { Link } from './DisplayModal.style'

const devices = new Devices()

interface LinkType {
  path: (...args: any[]) => string
  args?: (string | number | null | undefined)[]
}

interface DisplaysModalProps<T extends {}> {
  requestedDisplays: GetDevicesParameters | null
  onClose: () => void
  DisplayDetailsComponent?: ComponentType<GetDevicesResponse & Partial<T>>
  additionalDataFetcher?: () => Promise<T>
  additionalFiltering?: (state: DisplayState) => DisplayState
  subtitle: string
  templateId: number | null
  linkTo?: LinkType
}

const DisplaysModal = <T extends {} = {}>({
  requestedDisplays,
  onClose,
  DisplayDetailsComponent,
  additionalDataFetcher,
  additionalFiltering,
  subtitle,
  linkTo,
}: DisplaysModalProps<T>) => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [additionalData, setAdditionalData] = useState<Partial<T>>({}) // use it to load any additional data for display tiles, like playlists, descriptions
  const [displays, setDisplays] = useState<
    GetDevicesResponse[] | null | undefined
  >(null)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchDisplays = async () => {
      try {
        if (requestedDisplays) {
          setDisplays([])
          setLoading(LoadingStatus.Pending)
          setDisplays(await devices.getDisplays(requestedDisplays))
          additionalFiltering &&
            setDisplays(state => additionalFiltering(state))
          additionalDataFetcher &&
            setAdditionalData(await additionalDataFetcher())
          setLoading(LoadingStatus.Succeeded)
        }
      } catch (e) {
        const error = e as AxiosError
        setLoading(
          error.response?.status !== 404
            ? LoadingStatus.Failed
            : LoadingStatus.Succeeded
        )
      }
    }

    fetchDisplays()
  }, [additionalDataFetcher, additionalFiltering, requestedDisplays])

  return (
    <ExpressiveDialog
      onClose={onClose}
      open={!!requestedDisplays}
      maxWidth={false}
    >
      <ExpressiveDialogTitle
        title={t('displaysModal.title', {
          displayModel: requestedDisplays?.displayModel,
          inches: requestedDisplays?.inches,
        })}
        subtitle={subtitle}
      />
      <ExpressiveDialogScrollbars perRow={2} length={displays?.length}>
        {loading === LoadingStatus.Pending ? (
          <Loader isFullHeight />
        ) : loading === LoadingStatus.Failed || displays?.length === 0 ? (
          <CenterBox flexDirection="column">
            <NoResult isBig />
            <Typography component="div" variant="h6" align="center">
              {t(
                loading === LoadingStatus.Failed
                  ? 'displaysModal.error'
                  : 'displaysModal.noDisplays'
              )}
            </Typography>
          </CenterBox>
        ) : (
          <Grid container spacing={5}>
            {displays?.map(display => (
              <Grid item xs={6}>
                <Link
                  target="_blank"
                  to={{
                    pathname: linkTo?.args
                      ? linkTo.path(display.id, ...linkTo.args)
                      : linkTo?.path(display.id),
                  }}
                >
                  <DisplayItem shouldDisplayAddress {...display}>
                    {DisplayDetailsComponent && (
                      <DisplayDetailsComponent
                        {...display}
                        {...additionalData}
                      />
                    )}
                  </DisplayItem>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </ExpressiveDialogScrollbars>
      <ExpressiveDialogActions>
        <Button onClick={onClose}>{t('buttons.close')}</Button>
      </ExpressiveDialogActions>
    </ExpressiveDialog>
  )
}

export default DisplaysModal
