import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { actions, selectors } from 'shared/store'
import ConfirmModal from '../ConfirmModal'

const LeaveModal = () => {
  const { t } = useTranslation()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const dispatch = useDispatch()
  const { isModalOpen, to, customMessage } = useSelector(
    selectors.utils.getRedirectionOptions
  )
  const onClose = () => {
    dispatch(actions.utils.setOpenPreventRedirectionModal(false))
  }
  const onLeave = () => {
    dispatch(actions.utils.setOpenPreventRedirectionModal('leave'))
    setShouldRedirect(true)
  }

  return (
    <>
      {shouldRedirect && <Redirect to={to as any} />}
      <ConfirmModal
        title={t('leaveModal.title')}
        open={isModalOpen}
        onClose={onClose}
        onSubmit={onLeave}
        question={customMessage ?? t('leaveModal.question')}
        primaryButtonLabel={t('leaveModal.leave')}
        secondaryButtonLabel={t('leaveModal.stay')}
      />
    </>
  )
}

export default LeaveModal
