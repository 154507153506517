import { Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { memo } from 'react'
import { MIN_STATUS_BAR_ITEM_WIDTH } from 'shared/config'
import { StatusBarItem } from './StatusBar.style'

interface StatusBarProps {
  label: string
  values: string[]
  loading: boolean
  preloderItems: number
  withYMargin?: boolean
}

const StatusBar = ({
  label,
  values,
  loading,
  preloderItems,
  withYMargin = false,
}: StatusBarProps) => (
  <>
    <Typography variant="subtitle1" color="primary">
      {label}
    </Typography>
    {loading
      ? Array.from({ length: preloderItems }, (_, i) => (
          <StatusBarItem key={`status-${i}`}>
            <Typography>
              <Skeleton width={MIN_STATUS_BAR_ITEM_WIDTH} />
            </Typography>
          </StatusBarItem>
        ))
      : values.map(value => (
          <StatusBarItem key={`status-${value}`} withYMargin={withYMargin}>
            {value}
          </StatusBarItem>
        ))}
  </>
)

export default memo(StatusBar)
