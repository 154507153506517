import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'

export const getSelectedMediaType = (state: RootState) =>
  state.mediaLibrary.selectedMediaType

export const getAllMediaFiles = (state: RootState) =>
  state.mediaLibrary.getAllMediaFiles

export const getMediaFiles = (state: RootState) =>
  state.mediaLibrary.getMediaFiles

export const getCreateCategory = (state: RootState) =>
  state.mediaLibrary.addNewCategory

export const getCreateMediaFile = (state: RootState) =>
  state.mediaLibrary.addNewFile

export const getEditCategory = (state: RootState) =>
  state.mediaLibrary.editCategory

export const getDeleteCategory = (state: RootState) =>
  state.mediaLibrary.deleteCategory

export const getAllMediaCategories = (state: RootState) =>
  state.mediaLibrary.getAllMediaCategories

export const getDeleteMedia = (state: RootState) =>
  state.mediaLibrary.deleteMedia

export const getDeleteMediaStatus = (state: RootState) =>
  state.mediaLibrary.deleteMediaStatus

export const getSaveMediaFile = (state: RootState) =>
  state.mediaLibrary.saveMediaFile

export const getMediaCategory = (state: RootState) =>
  state.mediaLibrary.getMediaCategory

export const getAffectedAssets = (state: RootState) =>
  state.mediaLibrary.affectedAssets

export const getCategorySideEffects = createSelector(
  getCreateCategory,
  getEditCategory,
  (createData, editData) => ({
    createData,
    editData,
  })
)

export const getFonts = (state: RootState) => state.mediaLibrary.getFonts
