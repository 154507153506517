import { Position } from 'react-rnd'
import DOMtoImg from 'dom-to-image'
import {
  AbsoluteSize,
  DeviceAddressFields,
  DisplayDetailsProperties,
} from 'shared/types'
import { ERRORS } from './config'

interface TextSizeProps {
  text: string
  fontFamily?: string
  fontSize?: number | string
  fontWeight?: number
}

export const checkRectCollision = (
  firstSize: AbsoluteSize,
  firstPosition: Position,
  secondSize: AbsoluteSize,
  secondPosition: Position
) =>
  firstPosition.x < secondPosition.x + secondSize.width &&
  firstPosition.x + firstSize.width > secondPosition.x &&
  firstPosition.y < secondPosition.y + secondSize.height &&
  firstPosition.y + firstSize.height > secondPosition.y

export const generateBase64Image = (target: Node | null) => {
  const base64 = target && DOMtoImg.toPng(target)
  if (!base64) throw new Error(ERRORS.failedToGenerateImg)
  return base64
}

export const getTextWidth = ({
  text,
  fontFamily = 'Nunito Sans',
  fontSize = '1.125rem',
  fontWeight = 700,
}: TextSizeProps) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (!context) return 0
  const fontProperties = `${fontWeight} ${fontSize} 
  ${fontFamily}`

  context.font = fontProperties || getComputedStyle(document.body).font

  return context.measureText(text).width
}

export const createAddressField = (device: DeviceAddressFields) => {
  const address = device.buildingAddress ? `${device.buildingAddress} ` : ''
  const buildingCity = device.buildingCity ? `${device.buildingCity} ` : ''
  const buildingCountry = device.buildingCountry
    ? `${device.buildingCountry} `
    : ''
  const buildingDistrict = device.buildingDistrict
    ? `${device.buildingDistrict} `
    : ''
  const buildingName = device.buildingName ? `${device.buildingName} ` : ''
  return `${buildingCountry}${buildingCity}${buildingDistrict}${address}${buildingName}`
}
