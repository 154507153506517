import { styled } from '@material-ui/core'

interface ImagePreviewProps {
  assetUrl: string | null
  height?: string | number
}

const ImagePreview = styled('div')(
  ({ assetUrl, height = '100%' }: ImagePreviewProps) => ({
    height,
    backgroundImage: `url(${assetUrl})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })
)

export default ImagePreview
