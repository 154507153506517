export enum LayoutVariant {
  Fullscreen = 1,
  DividedVertically = 2,
  TripleLeft = 3,
  TripleRight = 4,
  DoubleLeft = 5,
  DoubleRight = 6,
}

export enum ProgressStep {
  LayoutSelection,
  ContentAddition,
  ConfigAndDeployment,
}

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum FilterType {
  Boolean,
  Enum,
  String,
}

export enum PromiseStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Fulfilled = 'fulfilled',
}

export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum BooleanStringType {
  False = 'False',
  True = 'True',
}

export enum DeleteStatusCode {
  SuccessSingle = 200,
  SuccessPartial = 201,
  Error = 400,
}

export enum AppEnv {
  develop = 'develop',
  stage = 'stage',
  prod = 'prod',
}

export enum DayOfWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

//It has to start with sunday, because Data.day() works like that
export enum Days {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

export enum UserScopes {
  UI_DisplayDetails = 'frontend_get_display_details', //UI - Edit Device
  UI_FloorAudios = 'frontend_get_floor_audios',
  UI_FloorLegends = 'frontend_get_floor_legends',
  UI_StatusMessages = 'frontend_get_status_messages',

  API_GetPlaylists = 'api_get_display_playlists',
  API_DeviceAudioFile = 'api_get_audio_file',
  API_DeviceSetParams = 'api_post_devices_set_params',
  API_DevicesFiles = 'api_get_devices_files',
}
