import { SvgIcon, SvgIconProps } from '@material-ui/core'

const TopAlignment = (props: SvgIconProps) => (
  <SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46973 7.59074L6.74998 5.87099V15.3105H5.24998V5.87099L3.53023 7.59074L2.46973 6.53024L5.99998 2.99999L9.53023 6.53024L8.46973 7.59074Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H0V1.5H12V0Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default TopAlignment
