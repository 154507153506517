import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Crop = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4001 0H3.6001V1.8H5.4001V0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0998 0H6.2998V1.8H8.0998V0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4001 2.70001H3.6001V4.50001H5.4001V2.70001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4001 5.39999H3.6001V7.19999H5.4001V5.39999Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7998 14.4H12.5998V12.6H10.7998V14.4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 14.4H15.3V12.6H13.5V14.4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2002 14.4H18.0002V12.6H16.2002V14.4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2002 11.7H18.0002V9.89999H16.2002V11.7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3 16.2H1.8V11.7H6.3V16.2ZM8.1 9.90001H5.4V8.10001H3.6V9.90001H0V18H8.1V14.4H9.9V12.6H8.1V9.90001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6998 0V1.8H14.9272L11.0635 5.6637L12.3361 6.9363L16.1998 3.0726V6.3H17.9998V0H11.6998Z"
    />
  </SvgIcon>
)

export default Crop
