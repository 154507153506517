declare module '@material-ui/core/styles/zIndex' {
  export interface ZIndex {
    negative: number
    neutral: number
    backgroundWidget: number
    stackableWidget: number
    editOverlay: number
    icon: number
    zoomControl: number
    croppedWidget: number
    cropUI: {
      button: number
      grid: number
    }
    calendarSelectedItem: number
    appOverlay: {
      default: number
      mediaLibrary: number
      uploadOverlay: number
      WidgetEditionOverlay: number
    }
    dragLayer: number
    dialogFooter: number
    appMenuDrawer: {
      mediaLibrary: number
      default: number
    }
  }
}

const zIndex = {
  negative: -999,
  neutral: 0,
  backgroundWidget: 400,
  stackableWidget: 500,
  croppedWidget: 660,
  editOverlay: 700,
  cropUI: {
    grid: 650,
    button: 651,
  },
  calendarSelectedItem: 50,
  zoomControl: 800,
  icon: 100,
  appOverlay: {
    default: 2500,
    mediaLibrary: 1000,
    uploadOverlay: 1270,
    WidgetEditionOverlay: 1000,
  },
  dragLayer: 1500,
  dialogFooter: 100,
  appMenuDrawer: {
    mediaLibrary: 1260,
    default: 1302,
  },
}

export default zIndex
