import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'tagsModule'

export const SNACKBARS: SnackbarDescriptorTable = {
  addTag: {
    successMessage: true,
    errorMessage: true,
  },
  deleteTag: {
    successMessage: true,
  },
}
