import {
  Typography,
  TypographyProps,
  TypographyVariant,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'

type Variant = 'caption2'

interface EnhancedTypographyProps extends Omit<TypographyProps, 'variant'> {
  variant: Variant | TypographyVariant
}

const Caption2 = styled(Typography)({
  fontSize: '0.8125rem',
  opacity: '55%',
  lineHeight: '1.2rem',
  fontWeight: 700,
})

export const EnhancedTypography = ({
  variant,
  ...props
}: EnhancedTypographyProps) => {
  switch (variant) {
    case 'caption2':
      return <Caption2 {...props} />
    default:
      return <Typography variant={variant} {...props} />
  }
}
