import { Checkbox, MenuItem } from '@material-ui/core'
import { Wrapper } from './CheckBoxItem.style'

interface CheckBoxItemProps {
  onClick: React.MouseEventHandler<HTMLLIElement>
  label?: string
  isActive?: boolean
  placeholder?: string
}

const CheckBoxItem = ({
  label,
  onClick,
  isActive,
  placeholder,
}: CheckBoxItemProps) => (
  <MenuItem onClick={onClick}>
    <Wrapper>
      {!placeholder && <Checkbox checked={isActive} />}
      <p>{placeholder || label}</p>
    </Wrapper>
  </MenuItem>
)

export default CheckBoxItem
