import { Select, SelectProps } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CollapsibleSharpArrowSmall } from 'shared/icons'
import { IconSelectOption } from 'shared/types'
import { FormControl, useSelectStyles } from '../Select.style'
import CheckBoxItem from './CheckBoxItem'

interface MaterialSelectProps extends SelectProps {
  options: IconSelectOption[]
  height?: string | number
  width?: string | number
  isDefaultCase?: boolean
  setValue: (args: string[]) => void
  value: string[]
}

const CheckBoxSelect = ({
  options,
  value,
  disabled,
  name,
  placeholder,
  height,
  width,
  isDefaultCase,
  setValue,
  ...props
}: MaterialSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const classes = useSelectStyles({ disabled, isDefaultCase })
  const isActive = (option: string) =>
    Array.isArray(value) && value.includes(option)
  const onClick = (option: string) =>
    value.includes(option)
      ? setValue(value.filter(item => item !== option))
      : setValue([...value, option])
  const printedValues =
    value.length > 0
      ? value
          ?.map(
            option => options.filter(({ value }) => value === option)?.[0].label
          )
          ?.join(', ')
      : t('listFilter.extensions')

  return (
    <FormControl fullWidth height={height} width={width} open={isOpen}>
      <Select
        multiple
        displayEmpty
        disableUnderline
        value={value}
        name={name}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={() => <CollapsibleSharpArrowSmall collapsed={!isOpen} />}
        disabled={disabled}
        className={classes.select}
        renderValue={() => printedValues}
        {...props}
      >
        {placeholder && (
          <CheckBoxItem
            key="placeholder"
            onClick={() => setValue([])}
            placeholder={placeholder}
          />
        )}
        {options.map(({ value, label }, i) => (
          <CheckBoxItem
            key={`${label}-${i}`}
            label={label}
            onClick={() => onClick(`${value}`)}
            isActive={isActive(`${value}`)}
          />
        ))}
      </Select>
    </FormControl>
  )
}

export default CheckBoxSelect
