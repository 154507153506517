import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'shared/hooks'
import { actions, selectors } from 'shared/store'
import { LoadingStatus } from 'shared/types'

interface UseSaveVideoTrimParams {
  start: number
  end: number
  mediaUuid: string | null
  onClose: () => void
}

export const useSaveVideoTrim = ({
  start,
  end,
  mediaUuid,
  onClose,
}: UseSaveVideoTrimParams) => {
  const dispatch = useDispatch()
  const successAlert = useAlert('success')
  const { t } = useTranslation()
  const { loading, data: fullyProcessed } = useSelector(
    selectors.utils.getTransformVideo
  )

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      successAlert(
        t(
          fullyProcessed
            ? 'snackbars.transformAsset'
            : 'snackbars.transformAssetPartial'
        )
      )
      dispatch(actions.utils.resetTransformVideo())
      onClose()
    }
    if (loading === LoadingStatus.Failed) {
      dispatch(actions.utils.resetTransformVideo())
      onClose()
    }
  }, [dispatch, fullyProcessed, loading, onClose, successAlert, t])

  const onSave = (keepOriginal: boolean) =>
    mediaUuid &&
    dispatch(
      actions.utils.transformVideo({
        trim: `{"start": "${start.toFixed(0)}", "end": "${end.toFixed(0)}"}`,
        keepOriginal,
        mediaUuid,
      })
    )
  return { onSave, loading: loading === LoadingStatus.Pending }
}
