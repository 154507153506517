import {
  Button,
  styled,
  makeStyles,
  InputLabel,
  Input,
} from '@material-ui/core'

export const UploadButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  position: 'relative',
  height: '43px',
}))

export const useStyles = makeStyles({
  upload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      padding: 0,
      width: '100%',
      height: '100%',
    },
  },
})

export const StyledInputLabel = styled(InputLabel)({
  '&:hover': {
    cursor: 'pointer',
  },
})

export const StyledInput = styled(Input)({
  display: 'none',
})

export const UploadLabel = styled('div')({
  position: 'absolute',
  top: 'calc(100% + 4px)',
  fontSize: '0.8em',
  left: 0,
  right: 0,
})
