import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Config = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 20" {...props}>
    <g
      id="CALENDAR"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-155.000000, -103.000000)"
        fill="currentColor"
      >
        <g
          id="Config-&amp;-Deployment"
          transform="translate(155.000000, 103.000000)"
        >
          <path
            d="M14,7 C14,4.209 12.363,1.807 10,0.683 L10,6 C10,7.657 8.657,9 7,9 C5.343,9 4,7.657 4,6 L4,0.683 C1.637,1.807 0,4.209 0,7 C0,9.378 1.188,11.475 3,12.74 L3,20 L6,20 L6,15 L8,15 L8,20 L11,20 L11,12.74 C12.812,11.475 14,9.378 14,7"
            id="Fill-2114"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Config
