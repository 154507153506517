import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Upload = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 20H2C0.897 20 0 19.103 0 18V16H2V18H14V16H16V18C16 19.103 15.103 20 14 20Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00019 12H9.00019V6.00001C9.00019 5.44801 9.44819 5.00001 10.0002 5.00001H10.5862L8.00019 2.41401L5.41419 5.00001H6.00019C6.55219 5.00001 7.00019 5.44801 7.00019 6.00001V12ZM10.0002 14H6.00019C5.44819 14 5.00019 13.552 5.00019 13V7.00001H3.00019C2.59619 7.00001 2.23119 6.75701 2.07619 6.38301C1.92119 6.00901 2.00719 5.57901 2.29319 5.29301L7.29319 0.293006C7.68419 -0.0979941 8.31619 -0.0979941 8.70719 0.293006L13.7072 5.29301C13.9932 5.57901 14.0792 6.00901 13.9242 6.38301C13.7692 6.75701 13.4042 7.00001 13.0002 7.00001H11.0002V13C11.0002 13.552 10.5522 14 10.0002 14Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Upload
