import { Grid, Box, TypographyProps } from '@material-ui/core'
import { PropsWithChildren } from 'react'
import { FormLabel } from 'shared/components'

interface LabeledItemGridProps
  extends TypographyProps,
    PropsWithChildren<{
      translatedLabel: string
    }> {}

const LabeledItemGrid = ({
  children,
  translatedLabel,
  ...props
}: LabeledItemGridProps) => {
  return (
    <Grid item xs={12}>
      <Box mb={1}>
        <FormLabel {...props}>{translatedLabel}</FormLabel>
      </Box>
      {children}
    </Grid>
  )
}

export default LabeledItemGrid
