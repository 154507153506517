import { Box, styled } from '@material-ui/core'
import { usePlayPause, useVideoDuration } from 'shared/hooks'
import { formatDuration } from 'shared/utils'
import { Duration, DurationSkeleton } from '../Duration'
import ItemOverlay from '../ItemOverlay'
import { DurationContainer } from './ThumbnailStyles'

export interface VideoThumbnailProps {
  withDurationLabel?: boolean
  withPlayOnHover?: boolean
  thumbnail: string
  isBlocked?: boolean
  controls?: boolean
}

export const VideoContent = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

/**
 * VideoThumbnail is used to fetch video from the URL and display it as a video player. VideoThumbnail can operate as a vidoe with controls, or video which plays while you are hovering over it
 * @param withDurationLabel will show duration label at the bottom. Do not use in conjuction with controls
 * @param controls will display controls for the video
 * @param withPlayOnHover will only play the video while you are hovering over it. Do not use with conjunction with controls.
 * @param isBlocked will display a blocked symbol on top of the video
 * @param thumbnail URL of video
 */
const VideoThumbnail = ({
  withDurationLabel = false,
  controls = false,
  withPlayOnHover = false,
  isBlocked = false,
  thumbnail,
}: VideoThumbnailProps) => {
  const { play, pause } = usePlayPause()
  const { duration, setVideoDuration } = useVideoDuration()

  return (
    <Box position="relative">
      <VideoContent
        onMouseEnter={withPlayOnHover ? play : undefined}
        onMouseLeave={withPlayOnHover ? pause : undefined}
        onLoadedMetadata={setVideoDuration}
        onMouseDown={pause}
        key={thumbnail}
        controls={controls}
      >
        <source src={thumbnail} type="video/mp4" />
      </VideoContent>
      <ItemOverlay isBlocked={isBlocked} />
      <DurationContainer>
        {withDurationLabel &&
          (duration === null ? (
            <DurationSkeleton />
          ) : (
            <Duration label={formatDuration(duration || 0)} />
          ))}
      </DurationContainer>
    </Box>
  )
}

export default VideoThumbnail
