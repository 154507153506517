import {
  Box,
  Container,
  ContainerProps,
  Divider,
  Tooltip,
} from '@material-ui/core'
import DoubleArrow from '@material-ui/icons/DoubleArrow'
import { memo, PropsWithChildren, ReactNode } from 'react'
import { Close } from 'shared/icons'
import { useToggle, useWidgetDragOverStatus } from '../../hooks'
import {
  CloseButton,
  ContentScrollableContainer,
  ContentWrapper,
  DrawerFooter,
  DrawerMenuContainer,
  DrawerMenuContent,
  FooterChildrenBox,
} from './DrawerMenu.style'

interface DrawerProps extends ContainerProps {
  header: ReactNode
  footer?: ReactNode
  headerDivider?: boolean
  headerPadding?: boolean
}

const Drawer = ({
  header,
  children,
  footer,
  headerDivider = true,
  headerPadding = true,
}: PropsWithChildren<DrawerProps>) => {
  const [isDrawerToggled, toggleDrawer] = useToggle(true)
  const setIsDragOverStatus = useWidgetDragOverStatus()

  return (
    <DrawerMenuContent
      elevation={10}
      isOpen={isDrawerToggled}
      variant="permanent"
      anchor="right"
      onDragEnterCapture={() => setIsDragOverStatus(false)}
    >
      <DrawerMenuContainer id="drawer-portal">
        <Tooltip title="Show/Hide the drawer">
          <CloseButton
            isDrawerOpen={isDrawerToggled}
            size="small"
            onClick={toggleDrawer}
          >
            {isDrawerToggled ? <Close /> : <DoubleArrow />}
          </CloseButton>
        </Tooltip>
        <ContentWrapper show={isDrawerToggled}>
          <header>
            <Box
              paddingTop={headerPadding ? 8 : 0}
              paddingBottom={headerPadding ? 3 : 0}
            >
              {header}
            </Box>
          </header>
          {headerDivider && <Divider />}
          <ContentScrollableContainer autoHide hideTracksWhenNotNeeded>
            <Box height="100%" mr={2}>
              {children}
            </Box>
          </ContentScrollableContainer>
          {footer && (
            <DrawerFooter>
              <Container>
                <Divider />
                <FooterChildrenBox>{footer}</FooterChildrenBox>
              </Container>
            </DrawerFooter>
          )}
        </ContentWrapper>
      </DrawerMenuContainer>
    </DrawerMenuContent>
  )
}

export default memo(Drawer)
