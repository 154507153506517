import { TransitionsOptions } from '@material-ui/core/styles/transitions'

const transitions: TransitionsOptions = {
  duration: {
    short: 250,
    standard: 500,
    leavingScreen: 1000,
  },
  easing: {
    easeInOut: 'ease-in-out',
  },
}
export default transitions
