import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'

export const getTemplates = (state: RootState) =>
  state.common.templates.templates

export const getDeleteTemplate = (state: RootState) =>
  state.common.templates.deleteTemplate

export const getDeleteTemplateError = (state: RootState) =>
  state.common.templates.deleteTemplate.error

export const getCategoryList = (state: RootState) =>
  state.common.templates.categoryList

export const getTemplateStatuses = (state: RootState) =>
  state.common.templates.templateStatuses

export const getUploadTemplateThumbnail = (state: RootState) =>
  state.common.templates.uploadTemplateThumbnail

export const getLayoutSuggestions = createSelector(getTemplates, templates =>
  templates.data.map(template => ({
    id: template.id,
    name: template.name,
    categoryName: template.categoryName,
    templateThumbnail: template.templateThumbnail,
    projectGenerated: template.projectGenerated,
    lastUpdate: template.lastUpdate,
  }))
)

export const getGenerateProject = (state: RootState) =>
  state.common.templates.generateProject

export const getZipProject = (state: RootState) =>
  state.common.templates.getZipProject

export const getAddTemplateCategory = (state: RootState) =>
  state.common.templates.addTemplateCategory
