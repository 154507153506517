export enum WidgetType {
  Background = 'wbg',
  PositionIndicatorText = 'wfm',
  DirectionArrows = 'was',
  Image = 'wig',
  Text = 'wtx',
  LiftStatus = 'wlm',
  Video = 'wvi',
  DateTime = 'wdt',
  LiveVideo = 'wvl',
  FlippingImages = 'wsl',
  ImagesByFloor = 'wfi',
  Weather = 'wwt',
  RssFeed = 'wbn',
  Pictogram = 'wpe',
  Playlist = 'wpl',
}

export enum SlideshowPropertyName {
  imageList = 'imageList',
  eventsList = 'eventsList',
  backgroundColor = 'backgroundColor',
  backgroundTransparency = 'backgroundTransparency',
  font = 'font',
  color = 'color',
  fontSize = 'fontSize',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  uppercase = 'uppercase',
}

export enum ExtendedSlideshowPropertyName {
  transitionTime = 'transitionTime',
}

export type FlippingImagesWidgetPropertyName =
  | SlideshowPropertyName
  | ExtendedSlideshowPropertyName

export type ImagesByFloorWidgetPropertyName = SlideshowPropertyName

export type PictogramWidgetPropertyName = SlideshowPropertyName

export enum VideoWidgetPropertyName {
  video = 'video',
}

export enum LiveVideoWidgetPropertyName {
  streamingIP = 'streamingIP',
  ooHMediaIP = 'ooHMediaIP',
}

export enum ImageWidgetPropertyName {
  img = 'img',
  original = 'original',
}

export enum BackgroundWidgetPropertyName {
  color = 'backgroundColor',
  category = 'category',
  gradient = 'gradient',
  img = 'img',
}

export enum TextWidgetPropertyName {
  font = 'font',
  color = 'color',
  content = 'content',
  fontSize = 'fontSize',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  uppercase = 'uppercase',
  alignment = 'alignment',
  scrollingText = 'scrollingText',
  scrollingTextDirection = 'scrollingTextDirection',
  backgroundColor = 'backgroundColor',
}

export enum DateTimeWidgetPropertyName {
  bold = 'bold',
  italic = 'italic',
  fontSize = 'fontSize',
  underline = 'underline',
  color = 'color',
  backgroundColor = 'backgroundColor',
  font = 'font',
  dateTimeFormat = 'dateTimeFormat',
  content = 'content',
}

export enum PositionIndicatorTextPropertyName {
  font = 'font',
  color = 'color',
  fontSize = 'fontSize',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  uppercase = 'uppercase',
  transition = 'transition',
  transitionType = 'transitionType',
  backgroundColor = 'backgroundColor',
}

export enum LiftStatusPropertyName {
  font = 'font',
  color = 'color',
  fontSize = 'fontSize',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  uppercase = 'uppercase',
  backgroundColor = 'backgroundColor',
}

export enum DirectionArrowWidgetPropertyName {
  upImg = 'upImg',
  downImg = 'downImg',
  upDownImg = 'upDownImg',
  downUpImg = 'downUpImg',
  position = 'position',
  color = 'color',
}

export enum WeatherWidgetPropertyName {
  PictureX = 'PictureX',
  PictureY = 'PictureY',
  degreeFontSize = 'degreeFontSize',
  degreeColor = 'degreeColor',
  degreeFont = 'degreeFont',
  degreeX = 'degreeX',
  degreeY = 'degreeY',
  locationFont = 'locationFont',
  locationFontSize = 'locationFontSize',
  locationFontColor = 'locationFontColor',
  img = 'img',
  CityXlocation = 'CityXlocation',
  CityYlocation = 'CityYlocation',
  TextXimage = 'TextXimage',
  TextYimage = 'TextYimage',
}

export enum RssFeedWidgetPropertyName {
  font = 'font',
  color = 'color',
  fontSize = 'fontSize',
  backgroundColor = 'backgroundColor',
  url = 'url',
}

export enum PlaylistWidgetPropertyName {
  aspectRatio = 'aspectRatio',
}

export enum Styles {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Uppercase = 'uppercase',
}

export enum TextAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}

export enum MediaTransformationKind {
  Resize = 'resize',
  Crop = 'crop',
  Scale = 'scale',
}

export enum TextBehaviour {
  Fixed = 'fixed',
  Scrolling = 'scrolling',
}

export enum PositionIndicatorTextBehaviour {
  StaticText = 'StaticText',
  Transition = 'Transition',
}

export enum ArrowSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ArrowPositionVariant {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  custom = 'custom',
}

export enum PositionIndicatorTransitionType {
  FadeInOut = 'FadeInOut',
  SwipeTopBottom = 'SwipeTopBottom',
  SwipeBottomTop = 'SwipeBottomTop',
  SwipeLeftRight = 'SwipeLeftRight',
  SwipeRightLeft = 'SwipeRightLeft',
  Blink = 'Blink',
}

export enum PictogramEventType {
  alarmActivated = 'alarmActivated',
  alarmReceived = 'alarmReceived',
  doorsClosing = 'doorsClosing',
  doorsOpening = 'doorsOpening',
  fire = 'fire',
  inspection = 'inspection',
  overload = 'overload',
  parking = 'parking',
  pitPump = 'pitPump',
  preference = 'preference',
}

export enum PictogramEventContentType {
  Text = 'text',
  Image = 'image',
}
