import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AddWidgetCategoryBody,
  Widgets,
  Playlist,
  addPlaylistCategoryBody,
  Templates,
} from 'shared/services'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import { Categories } from '../types'
import { AddCategoryParams } from './actions.types'

const widgets = new Widgets()
const playlist = new Playlist()
const templates = new Templates()

export const getWidgetsCategories = createAsyncThunk(
  `${MODULE_NAME}/getWidgetsCategories`,
  () => widgets.getWidgetsCategories()
)

export const resetAddCategory = createAction(`${MODULE_NAME}/resetAddCategory`)

export const getCategories = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getCategories`,
  payloadCreator: async (category: Categories) => {
    switch (category) {
      case Categories.Widgets: {
        return widgets.getWidgetsCategories()
      }
      case Categories.Playlists: {
        return playlist.getPlaylistCategories()
      }
      case Categories.Templates: {
        return templates.getCategoryList()
      }
    }
  },
})

export const addCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/addCategory`,
  payloadCreator: async ({ name, category }: AddCategoryParams) => {
    switch (category) {
      case Categories.Widgets: {
        return widgets.addWidgetCategory({ widgetCategoryName: name })
      }
      case Categories.Playlists: {
        return playlist.addPlaylistCategory({ name })
      }
      case Categories.Templates: {
        return templates.addCategory({ name })
      }
    }
  },
  ...SNACKBARS.addCategory,
})
