import { actions, selectors } from 'modules/layoutDesigner/store'
import { useDispatch, useSelector } from 'react-redux'
import { MediaTransformationKind } from 'shared/types'

export const useTabButton = (widgetId: string | number) => {
  const dispatch = useDispatch()
  const { transformation } = useSelector(selectors.getEditMode)

  const createSetMediaTransformation = (kind: MediaTransformationKind) => () =>
    dispatch(
      actions.setMediaTransformation(
        transformation?.widgetId === widgetId && transformation?.kind === kind
          ? null
          : {
              widgetId,
              kind,
            }
      )
    )

  const isWidgetSelected = widgetId === transformation?.widgetId
  const isResizeActive =
    isWidgetSelected && transformation?.kind === MediaTransformationKind.Resize
  const isCropActive =
    isWidgetSelected && transformation?.kind === MediaTransformationKind.Crop

  return {
    createSetMediaTransformation,
    isCropActive,
    isResizeActive,
  }
}
