import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Playlist = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H13V2H15C15.551 2 16 2.448 16 3V4ZM12 12H10V14H8V12H6V10H8V8H10V10H12V12ZM5 4H2V3C2 2.448 2.449 2 3 2H5V4ZM7 2H11V4H7V2ZM15 0H3C1.346 0 0 1.346 0 3V16C0 17.103 0.897 18 2 18H16C17.103 18 18 17.103 18 16V3C18 1.346 16.654 0 15 0Z"
    />
  </SvgIcon>
)

export default Playlist
