import { Helmet } from 'react-helmet'

const DefaultSEO = () => {
  return (
    <Helmet
      titleTemplate={`%s | Avire Layout Designer`}
      defaultTitle="Avire Layout Designer"
    >
      <meta
        name="description"
        content="A tool for elevator display content edition"
      />
      <meta name="theme-color" content="#05a19f" />
      <meta name="background-color" content="#f2f2f2" />
      <meta
        name="keywords"
        content="Avire Layout Designer Elevators Elevator"
      />
    </Helmet>
  )
}

export default DefaultSEO
