import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Resize = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8H2C0.897 8 0 8.897 0 10V16C0 17.103 0.897 18 2 18H8C9.103 18 10 17.103 10 16V10C10 8.897 9.103 8 8 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H7V0H2C0.897 0 0 0.897 0 2V6H2V2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0H11V2H16V7H18V2C18 0.897 17.103 0 16 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16H12V18H16C17.103 18 18 17.103 18 16V11H16V16Z"
    />
  </SvgIcon>
)

export default Resize
