import { ListItem, ListItemText } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavigationItem } from 'shared/types'
import { DrawerIcon, NaviElementWrapper } from './SidebarItem.style'
import { RouterLink } from 'shared/components'

interface LinkItemProps extends Omit<NavigationItem, 'subItems'> {
  collapsible?: boolean
  discrete?: boolean
  subItems?: NavigationItem['subItems']
}

const LinkItem = ({
  path,
  exact,
  icon: Icon,
  label = '',
  collapsible = false,
  discrete,
  subItems,
}: LinkItemProps) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const to = discrete ? `${pathname}${search}` : path
  const isActive = subItems?.length
    ? false
    : path !== '/' && pathname.includes(path)

  return (
    <ListItem
      button
      {...(collapsible
        ? { component: RouterLink, to }
        : {
            exact,
            component: RouterLink,
            to,
          })}
    >
      <NaviElementWrapper isActive={isActive}>
        <DrawerIcon>{Icon && <Icon color="inherit" />}</DrawerIcon>
        <ListItemText
          primary={t(label)}
          primaryTypographyProps={{ variant: 'button', color: 'inherit' }}
        />
      </NaviElementWrapper>
    </ListItem>
  )
}

export default LinkItem
