import { createServiceURL } from 'shared/utils'

export interface LoginPayload {
  username: string
  password: string
}
export interface LoginResponse {
  accessToken: string
  tokenType: string
}

const loginModule = 'login'
const loginEndpoint = 'access-token'

export const loginURL = createServiceURL(loginModule, loginEndpoint)
