import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'

interface DocumentTitleProps {
  children: string
}

const DocumentTitle = ({ children }: PropsWithChildren<DocumentTitleProps>) => {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  )
}

export default DocumentTitle
