import {
  CircularProgress,
  CircularProgressProps,
  styled,
} from '@material-ui/core'
import { PropsWithChildren } from 'react'

interface LoadingSpinnerProps extends CircularProgressProps {
  isVisible?: boolean
}

const ChildrenWrapper = styled('div')(({ isVisible }: LoadingSpinnerProps) => ({
  visibility: isVisible ? 'hidden' : 'visible',
}))

const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
}))

const LoadingSpinner = ({
  isVisible,
  children,
  ...props
}: PropsWithChildren<LoadingSpinnerProps>) => {
  return (
    <>
      {isVisible && <StyledCircularProgress size={30} {...props} />}
      <ChildrenWrapper isVisible={isVisible}>{children}</ChildrenWrapper>
    </>
  )
}

export default LoadingSpinner
