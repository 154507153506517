import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { WidgetType } from 'shared/types'

export const getDisplays = ({ displayManagement }: RootState) =>
  displayManagement.displays

export const getDisplayDetails = ({ displayManagement }: RootState) =>
  displayManagement.displayDetails

export const getRefreshStatusesSideEffects = (state: RootState) =>
  state.displayManagement.refreshStatusesSideEffects

export const getUpdateDisplaySideEffects = ({ displayManagement }: RootState) =>
  displayManagement.updateDisplaySideEffects

export const getDisplayIds = createSelector(getDisplays, displays =>
  displays.data.map(display => display.id)
)

export const getAddAudioFile = ({ displayManagement }: RootState) =>
  displayManagement.addAudioFile

export const addAudioLanguage = ({ displayManagement }: RootState) =>
  displayManagement.addAudioLanguage

export const getAudioLanguages = ({ displayManagement }: RootState) =>
  displayManagement.getAudioLanguages.data

export const getEcosystemEvents = ({ displayManagement }: RootState) =>
  displayManagement.ecosystemEvents

export const getDeviceAudioFilesFloors = ({ displayManagement }: RootState) =>
  displayManagement.getDeviceAudioFilesFloors

export const getDeviceAudioFilesStatusMessages = (state: RootState) =>
  state.displayManagement.getDeviceAudioFilesStatusMessages

export const getDeviceAudioFloors = ({ displayManagement }: RootState) =>
  displayManagement.getDeviceAudioFloors

export const getAudioEvents = ({ displayManagement }: RootState) =>
  displayManagement.getAudioEvents

export const getRssFeedSources = ({ displayManagement }: RootState) =>
  displayManagement.getDeviceRssFeeds

export const getDisplayDetailsWithFloorsAndEvents = createSelector(
  getDisplayDetails,
  getDeviceAudioFloors,
  getAudioEvents,
  (displayDetails, deviceAudioFloors, audioEvents) => {
    const {
      data: displayDetailsData,
      loading: displayDetailsLoading,
    } = displayDetails
    const { data: deviceAudioFloorsData } = deviceAudioFloors
    const { data: audioEventsData } = audioEvents

    const data = displayDetailsData?.id
      ? {
          ...displayDetailsData,
          floorAudios: deviceAudioFloorsData,
          audioEvents: audioEventsData,
        }
      : displayDetailsData

    return { data, loading: displayDetailsLoading }
  }
)

export const getSelectOptionsForDeviceSettings = createSelector(
  getDeviceAudioFilesFloors,
  getDeviceAudioFilesStatusMessages,
  getRssFeedSources,
  (audioFilesFloors, audioFilesStatusMessages, rssFeedSources) => ({
    audioFilesFloors: audioFilesFloors?.data.map(({ filename, fileUuid }) => ({
      value: fileUuid,
      label: filename,
    })),
    audioFilesStatusMessages: audioFilesStatusMessages?.data.map(
      ({ filename, fileUuid }) => ({
        value: fileUuid,
        label: filename,
      })
    ),
    rssFeedSources: rssFeedSources?.data.map(({ rssNewsId, rsssource }) => ({
      value: rssNewsId,
      label: rsssource,
    })),
  })
)

export const getUpdateFirmwareSideEffects = (state: RootState) =>
  state.displayManagement.updateFirmwareSideEffects

export const getSelectedRssSourceFromDevice = (state: RootState) =>
  state.displayManagement.getDeviceRssFeeds

export const getAssignRssResourceToDevice = (state: RootState) =>
  state.displayManagement.assignRssResourceToDevice

export const getDeviceScreenshot = (state: RootState) =>
  state.displayManagement.getDeviceScreenshot

export const getCurrentTemplateIdOfDevice = createSelector(
  getDisplayDetails,
  displayDetails => displayDetails.data?.currentTemplateId
)

export const getLastTemplateSentIdOfDevice = createSelector(
  getDisplayDetails,
  displayDetails => displayDetails.data?.lastSentTemplateId
)

export const getPlaylistWidgetFromCurrentTemplate = (state: RootState) =>
  state.displayManagement.displayDetails.data?.lastSentTemplate?.widgets.find(
    widget => widget.widgetType === WidgetType.Playlist
  ) // TODO: change to current in the future
