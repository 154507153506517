import { Tooltip } from '@material-ui/core'
import { Lock } from 'shared/icons'
import { StyledBox } from './ItemOverlay.style'

interface PlaylistOverlayProps {
  isBlocked: boolean
  tooltipText?: string
}

const ItemOverlay = ({ isBlocked, tooltipText }: PlaylistOverlayProps) => (
  <Tooltip title={tooltipText || ''}>
    <StyledBox isBlocked={isBlocked}>
      <Lock />
    </StyledBox>
  </Tooltip>
)

export default ItemOverlay
