import {
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { actions } from 'shared/store/snackbars'
import {
  ApiWidget,
  BulkSideEffects,
  ErrorType,
  LiveEditHelperValuesMap,
  LoadingStatus,
  MultifileSideEffects,
  PaginationResource,
  Resource,
  SideEffects,
  SnackbarDescriptor,
  WidgetType,
} from 'shared/types'
import { convertAxiosErrorToSnackbarData } from './helpers'

const defaultSuccessMessage: SnackbarDescriptor = {
  text: 'snackbars.success',
  type: 'success',
}

const defaultErrorMessages = [
  'snackbars.error',
  'snackbars.errorMsg',
  'snackbars.errorMsgCode',
]

type Message = SnackbarDescriptor | true

interface AsyncThunkParams<Returned, ThunkArg> {
  typePrefix: string
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>
  withCode?: boolean
  successMessage?: Message | null // Used to make snackbars for all dispatches of action
  errorMessage?: Message | null // Passing true = default snackbar, passing null = force no snackbar
}

type SnackbarsOverride = {
  successMessage?: Message | null // Used to make a different snackbar for a specific dispatch of action
  errorMessage?: Message | null
}

const createSnackbarAction = (
  message: Message,
  errorMessage?: string,
  errorCode?: number,
  errorIndex?: number
) => {
  const isDefault = typeof message === 'boolean'
  const defaultError = errorCode
    ? defaultErrorMessages[2]
    : errorMessage
    ? defaultErrorMessages[1]
    : defaultErrorMessages[0]

  const correctText = isDefault
    ? defaultError
    : errorIndex && typeof message.text === 'object'
    ? message.text[errorIndex]
    : message.text

  return actions.add({
    text: correctText,
    type: isDefault ? 'error' : message.type,
    namespace: isDefault ? undefined : message.namespace,
    error: errorMessage,
    code: errorCode ? `${errorCode}` : undefined,
  })
}

export class SnackbarError extends Error {
  constructor(
    message: string,
    public code: number | undefined,
    public errorIndex: number
  ) {
    super(message)
  }
}

// When payload creator has 0 arguments and you still want to pass snackbars to dispatched action override ThunkArg to be {} in expression.
export const createAsyncThunkWithErrorHandling = <Returned, ThunkArg = void>({
  typePrefix,
  payloadCreator,
  withCode = true,
  successMessage = null,
  errorMessage = null,
}: AsyncThunkParams<
  Returned,
  ThunkArg extends void ? undefined : ThunkArg & SnackbarsOverride
>) =>
  createAsyncThunk<
    Returned,
    ThunkArg extends void ? undefined : ThunkArg & SnackbarsOverride,
    { rejectValue?: string | ErrorType }
  >(typePrefix, async (payload, thunkAPI) => {
    try {
      const response = await payloadCreator(payload, thunkAPI)
      if (payload?.successMessage !== undefined) {
        if (payload.successMessage)
          thunkAPI.dispatch(
            actions.add(
              typeof payload.successMessage === 'boolean'
                ? defaultSuccessMessage
                : payload.successMessage
            )
          )
      } else if (successMessage)
        thunkAPI.dispatch(
          actions.add(
            typeof successMessage === 'boolean'
              ? defaultSuccessMessage
              : successMessage
          )
        )
      return response as Returned
    } catch (error: unknown) {
      const axiosError = convertAxiosErrorToSnackbarData(error)

      if (payload?.errorMessage !== undefined) {
        if (payload.errorMessage)
          thunkAPI.dispatch(
            createSnackbarAction(
              payload.errorMessage,
              axiosError.message,
              withCode ? axiosError.code : undefined
            )
          )
      } else if (errorMessage) {
        if (
          error &&
          typeof error === 'object' &&
          'message' in error &&
          'errorIndex' in error &&
          typeof error.message === 'string' &&
          typeof error.errorIndex === 'number'
        ) {
          thunkAPI.dispatch(
            thunkAPI.dispatch(
              createSnackbarAction(
                errorMessage,
                error.message,
                withCode && 'code' in error && typeof error.code === 'number'
                  ? error.code
                  : undefined,
                error.errorIndex
              )
            )
          )
        } else {
          thunkAPI.dispatch(
            thunkAPI.dispatch(
              createSnackbarAction(
                errorMessage,
                axiosError.message,
                withCode ? axiosError.code : undefined
              )
            )
          )
        }
      }
      return thunkAPI.rejectWithValue(
        withCode ? axiosError : { message: axiosError.message }
      )
    }
  })

export const defaultSideEffects: SideEffects = {
  loading: LoadingStatus.Idle,
  error: null,
}

export const defaultMultifileSideEffects: MultifileSideEffects = {
  ...defaultSideEffects,
  badFilesCount: 0,
  numberOfFiles: 0,
}

export const getDefaultResourceState = <T>(initialData: T): Resource<T> => ({
  ...defaultSideEffects,
  data: initialData,
})

export const getDefaultPaginationResourceState = <T>(
  initialData: T,
  limit: number
): PaginationResource<T> => ({
  ...defaultSideEffects,
  data: initialData,
  limit,
  skip: 0,
  page: 0,
})

export const setResourcePending = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Pending
  resource.error = null
}

export const setResourceIdle = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Idle
  resource.error = null
}

export const setResourceRejected = <P extends unknown, T extends string, M>(
  resource: SideEffects,
  action: PayloadAction<P, T, M, SerializedError | ErrorType>
) => {
  const error = action.payload as ErrorType

  resource.loading = LoadingStatus.Failed
  resource.error = error
}

export const setResourceFulfilled = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Succeeded
  resource.error = null
}

const liveEditDefaultValues = {
  isValid: true,
  properties: [],
}

export const defaultLiveEditValuesMap = Object.entries(WidgetType).reduce(
  (obj, [, type]) => ({ ...obj, [type]: liveEditDefaultValues }),
  {}
) as LiveEditHelperValuesMap

export const defaultWidgetLists = Object.entries(WidgetType).reduce(
  (obj, [, type]) => ({ ...obj, [type]: [] }),
  {}
) as Record<WidgetType, ApiWidget<WidgetType>[]>

export const combineLoadingStatuses = (statuses: LoadingStatus[]) =>
  statuses.includes(LoadingStatus.Pending)
    ? LoadingStatus.Pending
    : statuses.includes(LoadingStatus.Failed)
    ? LoadingStatus.Failed
    : !statuses
        .map(status => status === LoadingStatus.Succeeded && status)
        .includes(false)
    ? LoadingStatus.Succeeded
    : LoadingStatus.Idle

export const setBulkSideEffects = (
  data: BulkSideEffects,
  id: number,
  value: SideEffects
) => {
  const found = data.find(element => element.id === id)
  if (found) found.effects = value
  else data.push({ id, effects: value })
}
