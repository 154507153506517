import { Chip, styled, Theme } from '@material-ui/core'

interface StatusProps {
  backgroundColor: string
}

const StatusChip = styled(Chip)<Theme, StatusProps>(
  ({ theme, backgroundColor }) => ({
    backgroundColor,
    borderRadius: 19,
    fontSize: '1rem',
  })
)

export default StatusChip
