import { Box, Button, Grid, TextField } from '@material-ui/core'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextFieldMagnifier } from 'shared/icons'
import { MediaAssetType, WidgetType } from 'shared/types'
import { LoadingSpinner, CheckBoxSelect } from '../index'
import { FilterTextField } from './SimpleSearch.style'
import { useAssetFormats } from './SimpleSearch.utils'

export interface SimpleSearchProps {
  onSearch: (query: string, extensions?: string[]) => void
  width?: string | number
  isBold?: boolean
  paddingTop?: number
  loading: boolean
  query: string
  setQuery: (v: string) => void
  mediaType?: MediaAssetType
  widgetType?: WidgetType
}

const SimpleSearch = ({
  paddingTop = 5,
  onSearch,
  loading,
  query,
  setQuery,
  isBold = true,
  width = '100%',
  mediaType,
  widgetType,
}: SimpleSearchProps) => {
  const { t } = useTranslation()
  const TextFieldComponent = isBold ? FilterTextField : TextField
  const [extensions, setExtensions] = useState<string[]>([])
  const options = useAssetFormats(mediaType, widgetType)

  return (
    <Box paddingTop={paddingTop} paddingBottom={2} width={width}>
      <Grid
        item
        container
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <TextFieldComponent
            onChange={(e: ChangeEvent<{ value: string }>) =>
              setQuery(e.target.value)
            }
            value={query}
            InputProps={{
              startAdornment: <TextFieldMagnifier />,
            }}
            placeholder={t('buttons.enterNamePlaceholder')}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckBoxSelect
            options={options}
            setValue={setExtensions}
            value={extensions}
            placeholder={t('listFilter.none')}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            onClick={() => onSearch(query, extensions)}
            color="secondary"
            size="small"
          >
            <LoadingSpinner isVisible={loading}>
              {t(`buttons.search`)}
            </LoadingSpinner>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SimpleSearch
