import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardImage,
  CardTypography,
  ErrorTile,
  StyledCard,
} from 'shared/components'
import {
  CARD_HEIGHT_WITH_SPACING,
  DISPLAY_CARD_INNER_MARGIN,
} from 'shared/config'
import { useAssetUrl } from 'shared/hooks'
import { DisplayProperties, LoadingStatus } from 'shared/types'
import ActiveChip from '../ActiveChip'
import DisplayLongValue from './DisplayLongValue'
import { Label } from './DisplayItem.style'
import { createAddressField } from 'shared/utils'

export interface DisplayItemProps extends DisplayProperties {
  minHeight?: number
  shouldDisplayAddress?: boolean
  onClick?: (id: number) => void
}

const DisplayItem = ({
  description,
  id,
  aspectRatio,
  connectionStatus,
  displayModel,
  lastSentTemplateThumbnail,
  minHeight: externalMinHeight,
  children,
  onClick,
  shouldDisplayAddress = false,
  ...props
}: PropsWithChildren<DisplayItemProps>) => {
  const { assetUrl, loading } = useAssetUrl(lastSentTemplateThumbnail)
  const { t } = useTranslation()
  const minHeight =
    externalMinHeight ?? (children ? CARD_HEIGHT_WITH_SPACING : undefined)

  const calcLoading =
    loading === LoadingStatus.Pending ||
    (loading === LoadingStatus.Idle && lastSentTemplateThumbnail)

  return calcLoading ? (
    <Skeleton variant="rect" height={CARD_HEIGHT_WITH_SPACING} />
  ) : (
    <StyledCard onClick={() => onClick?.(id)} minHeight={minHeight}>
      <Box m={`${DISPLAY_CARD_INNER_MARGIN}px`}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            {assetUrl ? <CardImage image={assetUrl} /> : <ErrorTile />}
          </Grid>
          <Grid item xs={7}>
            <Label>{t('displayList.model')}</Label>
            <CardTypography gutterBottom color="primary">
              {displayModel}
            </CardTypography>
            <Label>{t('displayList.aspectRatio')}</Label>
            <CardTypography gutterBottom color="primary">
              {aspectRatio}
            </CardTypography>
            <Box mt={1}>
              <ActiveChip connectionStatus={connectionStatus} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Label>{t('displayList.description')}</Label>
          <DisplayLongValue value={description} testId="displayDescription" />
          {shouldDisplayAddress && (
            <>
              <Label>{t('displayList.address')}</Label>
              <DisplayLongValue
                value={createAddressField(props)}
                testId="displayAddress"
              />
            </>
          )}
        </Box>
        {children && (
          <Box mt={3}>
            <Grid container spacing={3}>
              {children}
            </Grid>
          </Box>
        )}
      </Box>
    </StyledCard>
  )
}

export default DisplayItem
