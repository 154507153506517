import { styled } from '@material-ui/core'

const CHECKBOX_SIZE = 19
const MAX_LISTITEM_HEIGHT = 36
const size = { width: CHECKBOX_SIZE, height: CHECKBOX_SIZE }

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: MAX_LISTITEM_HEIGHT,

  '& .MuiCheckbox-root': {
    padding: 0,
    margin: '0 8px 0 0',
    ...size,
  },

  '& .MuiSvgIcon-root': {
    ...size,
  },
})
