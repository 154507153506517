import {
  AbsoluteSize,
  CategoryItem,
  LayoutSlot,
  LayoutVariant,
  Orientation,
  ProgressStep,
  ResponseStatus,
  SlotSizes,
  SlotWidgets,
} from 'shared/types'

export type GetCategoryListResponse = CategoryItem[]
export type DeleteTemplateResponse = ResponseStatus
export interface CalculationProperties {
  slotSizes: SlotSizes
  slotName: LayoutSlot
  orientation: Orientation
  activeStep: ProgressStep
  deviceSize: number[]
  selectedLayout: LayoutVariant
  widgets: SlotWidgets
  layoutPreviewSize: AbsoluteSize
}

export interface TemplateStatus {
  id: number
  templateStatus: boolean
}

export interface UploadTemplateThumbnailParams {
  templateId: number
  thumbnailUuid: string
}

export enum GetTemplateParamsNames {
  displayModel = 'displayModel',
  resolution = 'resolution',
  orientation = 'orientation',
  inches = 'inches',
  templateName = 'templateName',
  templateId = 'templateId',
  formatId = 'formatId',
  formatName = 'formatName',
}

export interface GetTemplatesParams {
  [GetTemplateParamsNames.displayModel]: string
  [GetTemplateParamsNames.resolution]: string
  [GetTemplateParamsNames.orientation]: Orientation
  [GetTemplateParamsNames.inches]: string
  [GetTemplateParamsNames.templateName]: string
  [GetTemplateParamsNames.templateId]: number
}

export type GetTemplatesQueryProps = Partial<GetTemplatesParams>

export interface AddTemplateThumbnailResponse {
  id: number
  templateThumbnail: string
}

export interface AddCategoryBody {
  name: string
}

export interface AddCategoryResponse {
  groupId: number
  id: number
  name: string
}

export interface AddTagRequest {
  tag: string
}
