import { Redirect } from 'react-router'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { LayoutDesigner } from 'shared/icons'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/layout-designer'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    label: 'navigation.layoutDesigner.label',
    icon: LayoutDesigner,
    path: '/',
    exact: true,
    component: () => <Redirect to={`${BASE_PATH}/edit`} />,
    subItems: addBasePath(BASE_PATH, [
      {
        label: 'navigation.layoutDesigner.new',
        path: '/new',
      },
      {
        label: 'navigation.layoutDesigner.edit',
        path: '/edit',
      },
      //TODO: temporarily hidden
      // {
      //   label: 'navigation.layoutDesigner.recommended',
      //   path: '/recommended',
      // },
    ]),
  },
  {
    path: '/new',
    exact: true,
    component: Loadable({
      component: () => import('./containers/LayoutDesigner'),
    }),
  },
  {
    path: '/edit',
    exact: true,
    component: Loadable({
      component: () => import('./containers/TemplateList'),
    }),
  },
  {
    path: '/edit/:id',
    exact: true,
    component: Loadable({
      component: () => import('./containers/LayoutDesigner'),
    }),
  },
])

export default routes
