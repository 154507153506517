import { SvgIcon, SvgIconProps } from '@material-ui/core'

const MediaLibrary = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 19 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2 19H0.95C0.4256 19 0 18.5744 0 18.05V3.79999H1.9V17.1H15.2V19Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6498 12.35L9.4998 8.55L10.8412 10.3379L13.2998 6.65L16.1498 12.35H6.6498ZM8.5498 2.85C9.5986 2.85 10.4498 3.70025 10.4498 4.75C10.4498 5.8007 9.5986 6.65 8.5498 6.65C7.50005 6.65 6.6498 5.8007 6.6498 4.75C6.6498 3.70025 7.50005 2.85 8.5498 2.85ZM18.0498 0H4.7498C4.2254 0 3.7998 0.4256 3.7998 0.95V14.25C3.7998 14.7744 4.2254 15.2 4.7498 15.2H18.0498C18.5742 15.2 18.9998 14.7744 18.9998 14.25V0.95C18.9998 0.4256 18.5742 0 18.0498 0Z"
      fill="white"
    />
  </SvgIcon>
)

export default MediaLibrary
