import { Box, SvgIconProps, Typography, Theme, styled } from '@material-ui/core'
import { Error } from '../icons'

interface ErrorInfoBoxProps {
  size?: string | number
}

const ErrorInfoBox = styled(Box)<Theme, ErrorInfoBoxProps>(({ size }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& .MuiSvgIcon-root': {
    fontSize: size ?? '7rem',
  },
}))

interface ErrorInfoProps extends SvgIconProps {
  text?: string
  size?: string | number
}

const ErrorInfo = ({ text, size, ...props }: ErrorInfoProps) => (
  <ErrorInfoBox size={size} textAlign="center">
    <Error {...props} />
    {text && <Typography variant="subtitle2">{text}</Typography>}
  </ErrorInfoBox>
)

export default ErrorInfo
