import { SvgIcon, SvgIconProps } from '@material-ui/core'

const ArrowUp = (props: SvgIconProps) => (
  <SvgIcon width="24px" height="15px" viewBox="0 0 24 15" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-196.000000, -69.000000)"
        fill="currentColor"
      >
        <polygon
          id="Arrow_Up"
          transform="translate(207.707000, 76.087561) scale(1, -1) translate(-207.707000, -76.087561) "
          points="207.707 78.2388774 198.468123 69 196 71.4681226 207.707 83.1751226 219.414 71.4681226 216.945877 69"
        />
      </g>
    </g>
  </SvgIcon>
)

export default ArrowUp
