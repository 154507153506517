import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SelectedMediaLibraryItem } from 'shared/types'
import { actions } from '../../../store'

const useSelectingAssets = (numberOfItems: number) => {
  const [selected, setSelected] = useState<SelectedMediaLibraryItem[]>([])

  return {
    selected,
    setSelected,
    onSelect: (MLItem: SelectedMediaLibraryItem | null) => {
      if (MLItem === null) setSelected([])
      else
        setSelected(pastSelection => {
          const foundIndex = selected.findIndex(
            item => item.uuid === MLItem.uuid
          )
          if (foundIndex !== -1) {
            return [
              ...pastSelection.slice(0, foundIndex),
              ...pastSelection.slice(foundIndex + 1),
            ]
          } else if (pastSelection.length < numberOfItems)
            return [...pastSelection, MLItem]
          return pastSelection
        })
    },
  }
}

export const useMediaLibraryPicker = (
  numberOfItems: number,
  onGoBack: () => void
) => {
  const dispatch = useDispatch()
  const { setSelected, ...useSelectingAssetsProps } = useSelectingAssets(
    numberOfItems
  )
  const onAdd = (selected: SelectedMediaLibraryItem[]) => {
    dispatch(actions.mediaPicker.saveMediaLibraryPicker(selected))
    onGoBack()
    setSelected([])
  }

  return { ...useSelectingAssetsProps, setSelected, onAdd }
}
