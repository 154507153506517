import { createServiceURL } from 'shared/utils'

const audioLibraryModule = 'audio_library'
const getAudioFilesEndpoint = 'device_audio_file'

export const getAudioFiles = createServiceURL(
  audioLibraryModule,
  getAudioFilesEndpoint
)

export const FLOOR_AUDIOS = [
  {
    fileUuid: '04d85c239946484d80b6e5662108b75b',
    filename: 'floor_11',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/floor',
    dateposted: '2021-10-05T11:37:01',
  },
  {
    fileUuid: '1781b17f08764b5681bf0629163add49',
    filename: 'reception',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/floor',
    dateposted: '2021-10-05T11:37:01',
  },
  {
    fileUuid: '2442dbeb4feb4a9c9b524017d6a31cec',
    filename: 'lg_floor',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/floor',
    dateposted: '2021-10-05T11:37:01',
  },
]

export const EVENT_AUDIOS = [
  {
    fileUuid: '35728900240e48e0b74f3d305f893ab5',
    filename: 'doors_closing',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/event',
    dateposted: '2021-10-05T14:50:42',
  },
  {
    fileUuid: '46f008d1bba44cef9f80739c1ed9a634',
    filename: 'doors_opening',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/event',
    dateposted: '2021-10-05T14:50:42',
  },
  {
    fileUuid: '4b0357b4fa754d938a21c73b73a4a2e6',
    filename: 'out_of_service',
    fileExtension: 'wav',
    folder: '/audio_library/atom/english/event',
    dateposted: '2021-10-05T14:50:42',
  },
]
