import { WidgetManager } from 'shared/services'
import {
  ImageWidgetPropertyName,
  VideoWidgetPropertyName,
  WidgetProperty,
} from 'shared/types'

type PropertyName = ImageWidgetPropertyName.img | VideoWidgetPropertyName.video

interface UseLoadUploadedWidgetProps {
  mediaUuid?: string | null
  isVisible: boolean
  propertyName: PropertyName
  widgetProperties?: WidgetProperty[]
}
export const useLoadUploadedWidget = ({
  mediaUuid,
  isVisible,
  propertyName,
  widgetProperties,
}: UseLoadUploadedWidgetProps) => {
  const uploadedWidgetImageUuid =
    widgetProperties &&
    WidgetManager.getProperty<WidgetProperty>(propertyName, widgetProperties)

  return uploadedWidgetImageUuid === mediaUuid || isVisible
}
