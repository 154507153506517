import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Close = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 11 11" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Step-18"
        transform="translate(-1599.000000, -643.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Group-15" transform="translate(1191.000000, 590.000000)">
          <g id="Group-6" transform="translate(0.000000, 36.000000)">
            <polygon
              id="close-icon"
              points="417.237 17.707 418.707 19.177 415.176774 22.707 418.707 26.237 417.237 27.707 413.707 24.1767743 410.177 27.707 408.707 26.237 412.237226 22.707 408.707 19.177 410.177 17.707 413.707 21.2372257"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Close
