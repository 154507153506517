import { RootState } from 'app'

export const getMediaLibraryPickerState = (state: RootState) => ({
  isOpen: state.common.mediaPicker.isOpen,
  items: state.common.mediaPicker.assets.data.items.filter(
    ({ fileUuid, thumbnail, duration }) =>
      fileUuid && thumbnail && duration !== 0 // in images duration is always null
  ),
  categories: state.common.mediaPicker.assets.data.categories,
  assetsType: state.common.mediaPicker.assetsType,
  numberOfItems: state.common.mediaPicker.numberOfItems,
  loading: state.common.mediaPicker.assets.loading,
  assetIdentifier: state.common.mediaPicker.assetIdentifier,
  totalNumberOfItems: state.common.mediaPicker.assets.total,
  widgetType: state.common.mediaPicker.widgetType,
})

export const getMediaLibraryPickerSelection = (state: RootState) => ({
  selected: state.common.mediaPicker.selected,
  isRequestHandled: state.common.mediaPicker.isRequestHandled,
  suggestedName: state.common.mediaPicker.suggestedName,
  assetIdentifier: state.common.mediaPicker.assetIdentifier,
})

export const getMediaLibraryPickerSideEffects = (state: RootState) =>
  state.common.mediaPicker.uploadSideEffects

export const getUploadDrawerMediaLibraryFile = (state: RootState) =>
  state.common.mediaPicker.uploadDrawerMediaLibraryFile
