import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Edit = (props: SvgIconProps) => (
  <SvgIcon width="19px" height="19px" viewBox="0 0 19 19" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-127.000000, -64.000000)"
        fill="currentColor"
      >
        <g id="Edit" transform="translate(127.002278, 64.000045)">
          <polygon
            id="Fill-1449"
            points="9.94747961 2.99516905 2.19547961 10.7489786 7.34538437 15.8988833 15.0982891 8.14597857"
          />
          <path
            d="M16.3770796,6.86691667 L17.0285082,6.21639286 C18.4480796,4.79591667 18.4480796,2.48605952 17.0276034,1.06467857 C15.608032,-0.354892857 13.2972701,-0.354892857 11.8767939,1.06467857 L11.2271748,1.71610714 L16.3770796,6.86691667 Z"
            id="Fill-1450"
          />
          <path
            d="M5.78856056,16.9010881 L1.12360818,18.0673262 C0.815084367,18.1433262 0.489370081,18.05285 0.264989129,17.828469 C0.0397034148,17.6040881 -0.0498680138,17.2783738 0.0270367481,16.96985 L1.19237008,12.3048976 L5.78856056,16.9010881 Z"
            id="Fill-1451"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Edit
