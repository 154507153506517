import { AppBar as BaseAppBar, styled, Theme, Toolbar } from '@material-ui/core'
import { TOOLBAR_HEIGHT, DRAWER_WIDTH } from 'shared/config'

const MAX_SECTION_WIDTH = 800
const CATEGORY_NAME_SIZE = '35%'

export const StyledAppBar = styled(BaseAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.info.main,
  height: TOOLBAR_HEIGHT,
  marginLeft: DRAWER_WIDTH,
  width: `calc(100% - ${DRAWER_WIDTH}px)`,
}))

export const StyledToolbar = styled(Toolbar)({
  height: 'inherit',
})

interface SectionProps {
  flexValue?: string | null
  fixNameSizeSection?: boolean
}

export const Section = styled('div')<Theme, SectionProps>(
  ({ theme, flexValue, fixNameSizeSection }) => ({
    '&:not(:last-of-type)': {
      borderRight: `3px solid ${theme.palette.info.dark}`,
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
    },
    height: 'inherit',
    padding: theme.spacing(0, 4),
    display: 'flex',
    alignItems: 'center',
    ...(flexValue && {
      flex: flexValue,
      maxWidth: MAX_SECTION_WIDTH,
    }),
    ...(fixNameSizeSection && {
      [theme.breakpoints.down(theme.breakpoints.values.mediaCategoryName)]: {
        maxWidth: CATEGORY_NAME_SIZE,
      },
      [theme.breakpoints.down(theme.breakpoints.values.templateListSm)]: {
        maxWidth: '30%',
      },
    }),
  })
)

export const ToolbarFix = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: TOOLBAR_HEIGHT,
})
