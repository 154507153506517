import { useTranslation } from 'react-i18next'
import { Typography, Grid, Box } from '@material-ui/core'
import { DraggingIcon } from 'shared/icons'

const DraggingExplanationItem = () => {
  const { t } = useTranslation()

  return (
    <Box my={4}>
      <Grid container wrap="nowrap" alignItems="center">
        <Box mr={1}>
          <DraggingIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          {t('draggingExplanationText')}
        </Typography>
      </Grid>
    </Box>
  )
}

export default DraggingExplanationItem
