import { Box, capitalize } from '@material-ui/core'
import { getIn } from 'formik'
import {
  Select as FormikSelect,
  SelectProps as BaseSelectProps,
} from 'formik-material-ui'
import { useState } from 'react'
import { CollapsibleSharpArrowSmall } from 'shared/icons'
import { SelectOption } from 'shared/types'
import Loader from '../Loader'
import {
  ErrorWrapper,
  FormControl,
  MenuItem,
  useSelectStyles,
} from './Select.style'

export interface SelectProps extends BaseSelectProps {
  loading?: boolean
  options: SelectOption[]
  placeholder: string
  isGray?: boolean
  isDefaultCase?: boolean
  isHigher?: boolean
  onBeforeSelected?: (newValue: unknown) => void
  showErrors?: boolean
}
export const Select = ({
  placeholder,
  label,
  field,
  options,
  loading = false,
  required = true,
  disabled,
  isGray,
  isDefaultCase,
  isHigher = false,
  onBeforeSelected,
  showErrors,
  readOnly,
  ...props
}: SelectProps) => {
  const error = getIn(props.form.errors, field.name)
  const [isOpen, setIsOpen] = useState(false)
  const classes = useSelectStyles({ disabled, isDefaultCase, isGray })
  const getLabel = (value: SelectOption['value']) =>
    options.find(option => option.value === value)?.label ?? ''
  const showError = showErrors && !!props.form.submitCount && !!error
  const renderValue = (value: SelectOption['value']) =>
    label ? (
      <>
        {label}
        {value !== '' && ': '}
        <b>{getLabel(value)}</b>
      </>
    ) : (
      getLabel(value) ||
      (loading && (
        <Box width={40}>
          <Loader size={20} />
        </Box>
      )) ||
      placeholder
    )

  return (
    <>
      <FormControl
        fullWidth
        readonly={readOnly}
        open={isOpen}
        isDefaultCase={isDefaultCase}
        isGray={isGray}
        isHigher={isHigher}
        showError={showError}
      >
        <FormikSelect
          {...props}
          field={field}
          onChange={e => {
            onBeforeSelected?.(e.target.value)
            field.onChange(e)
          }}
          disableUnderline
          displayEmpty
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={renderValue as (value: unknown) => React.ReactNode}
          IconComponent={() => (
            <CollapsibleSharpArrowSmall collapsed={!isOpen} />
          )}
          required={required}
          disabled={disabled}
          className={classes.select}
        >
          {(placeholder || (!placeholder && !required)) && (
            <MenuItem value="" disabled>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map(({ value, label }) => (
            <MenuItem key={`option-${value}`} value={value}>
              {isDefaultCase ? label : capitalize(label)}
            </MenuItem>
          ))}
        </FormikSelect>
      </FormControl>
      {showError && <ErrorWrapper>{error}</ErrorWrapper>}
    </>
  )
}
export default Select
