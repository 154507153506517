import { LayoutDesignerUtils } from 'shared/services'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'

const layoutDesignerUtils = new LayoutDesignerUtils()
export const MODULE_NAME = 'shared'

export const getDisplayModels = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDisplays`,
  payloadCreator: () => layoutDesignerUtils.getDisplayModels(),
})
