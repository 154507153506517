import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Calendar = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M16.2 7.2H1.8V4.5H16.2V7.2ZM11.7 12.6H8.1V9H11.7V12.6ZM6.3 12.6H2.7V9H6.3V12.6ZM16.2 2.7H14.4V0H12.6V2.7H5.4V0H3.6V2.7H1.8C0.8073 2.7 0 3.5082 0 4.5V16.2C0 17.1927 0.8073 18 1.8 18H16.2C17.1927 18 18 17.1927 18 16.2V4.5C18 3.5082 17.1927 2.7 16.2 2.7Z"
    />
  </SvgIcon>
)

export default Calendar
