import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Multiselect = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <g
      id="CALENDAR"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-188.000000, -105.000000)"
        fill="currentColor"
      >
        <g id="Multiselect" transform="translate(188.000000, 105.000000)">
          <path
            d="M9,16 L9,11 C9,9.896 9.896,9 11,9 L16,9 L9,16 Z M16,0 L2,0 C0.897,0 0,0.898 0,2 L0,16 C0,17.103 0.897,18 2,18 L9,18 C9.82,18 10.835,17.579 11.414,17 L17,11.414 C17.58,10.834 18,9.819 18,9 L18,2 C18,0.898 17.103,0 16,0 L16,0 Z"
            id="Fill-2286"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Multiselect
