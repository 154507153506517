import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import { Redirect } from 'react-router-dom'
import { Tags } from 'shared/icons'

const BASE_PATH = '/tags'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    path: '/',
    label: 'navigation.tags.label',
    icon: () => <Tags fontSize="large" />,
    exact: true,
    component: () => <Redirect to={`${BASE_PATH}/template`} />,
    subItems: addBasePath(BASE_PATH, [
      {
        label: 'navigation.tags.template',
        path: '/template',
      },
      {
        label: 'navigation.tags.display',
        path: '/display',
      },
    ]),
  },
  {
    path: '/template',
    exact: true,
    component: Loadable({
      component: () => import('./containers/TemplateTags'),
    }),
  },
  {
    path: '/display',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayTags'),
    }),
  },
])

export default routes
