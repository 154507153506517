import { Api } from '../api'
import { GetMeResponse } from './Users.types'
import { aboutEndpoint } from './Users.utils'

class Users extends Api {
  me = async () => {
    const { data } = await this.api.get<GetMeResponse>(aboutEndpoint)

    return data
  }

  edit = async (props: Partial<GetMeResponse>) => {
    const { data } = await this.api.put(aboutEndpoint, props)

    return data
  }
}

export default Users
