import { createReducer } from '@reduxjs/toolkit'
import { GetRssFeedFromUrlResponse } from 'shared/services'
import {
  BulkSideEffects,
  ErrorType,
  LoadingStatus,
  RedirectsState,
  Resource,
} from 'shared/types'
import {
  setResourcePending,
  setResourceRejected,
  getDefaultResourceState,
  setResourceFulfilled,
  setBulkSideEffects,
} from 'shared/utils'
import {
  getRssFeedFromUrl,
  resetTransformVideo,
  resetUpdateOTA,
  setOpenPreventRedirectionModal,
  setPreventFromRedirection,
  setPreventFromRedirectionOptions,
  transformVideo,
  updateOTA,
} from './actions'

interface State {
  getRssFeedFromUrl: Resource<GetRssFeedFromUrlResponse | null>
  updateOTA: BulkSideEffects
  transformVideo: Resource<boolean | null>
  redirectionGuard: RedirectsState
}

const initialState: State = {
  redirectionGuard: {
    prevent: false,
    to: '',
    isModalOpen: false,
    customMessage: undefined,
  },
  updateOTA: [],
  getRssFeedFromUrl: getDefaultResourceState(null),
  transformVideo: getDefaultResourceState(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getRssFeedFromUrl.pending, state => {
      setResourcePending(state.getRssFeedFromUrl)
    })
    .addCase(getRssFeedFromUrl.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getRssFeedFromUrl)
      state.getRssFeedFromUrl.data = payload
    })
    .addCase(getRssFeedFromUrl.rejected, (state, action) => {
      setResourceRejected(state.getRssFeedFromUrl, action)
    })
    .addCase(setPreventFromRedirection, (state, { payload }) => {
      state.redirectionGuard.prevent = payload.prevent
      state.redirectionGuard.customMessage = payload.customMessage
    })
    .addCase(setOpenPreventRedirectionModal, (state, { payload }) => {
      if (payload === 'leave') {
        state.redirectionGuard.isModalOpen = false
        state.redirectionGuard.customMessage = undefined
        state.redirectionGuard.prevent = false
      } else state.redirectionGuard.isModalOpen = payload
    })
    .addCase(setPreventFromRedirectionOptions, (state, { payload }) => {
      state.redirectionGuard.to = payload
    })
    .addCase(updateOTA.pending, (state, { meta }) => {
      setBulkSideEffects(state.updateOTA, meta.arg.deviceId, {
        loading: LoadingStatus.Pending,
      })
    })
    .addCase(updateOTA.fulfilled, (state, { meta }) => {
      setBulkSideEffects(state.updateOTA, meta.arg.deviceId, {
        loading: LoadingStatus.Succeeded,
      })
    })
    .addCase(updateOTA.rejected, (state, { payload, meta }) => {
      setBulkSideEffects(state.updateOTA, meta.arg.deviceId, {
        loading: LoadingStatus.Failed,
        error: payload as ErrorType,
      })
    })
    .addCase(resetUpdateOTA, state => {
      state.updateOTA = []
    })
    .addCase(transformVideo.pending, state => {
      setResourcePending(state.transformVideo)
    })
    .addCase(transformVideo.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.transformVideo)
      state.transformVideo.data = payload
    })
    .addCase(transformVideo.rejected, (state, action) => {
      setResourceRejected(state.transformVideo, action)
    })
    .addCase(resetTransformVideo, state => {
      state.transformVideo = initialState.transformVideo
    })
)
