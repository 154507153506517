import { Api } from '../api'
import { AudioEvent, LiftStopAudio } from './FloorAndEventsContent.types'

const SUBPATH = 'floors'

class FloorAndEventsContent extends Api {
  setAudioFloors = async (
    deviceId: number,
    stops: (LiftStopAudio | null)[]
  ) => {
    const { data } = await this.api.post<LiftStopAudio[]>(
      `${SUBPATH}/audios`,
      stops,
      { params: { deviceId } }
    )

    return data
  }

  getDeviceAudioFloors = async (deviceId: number) => {
    const { data } = await this.api.get(`${SUBPATH}/audios`, {
      params: { deviceId },
    })

    return data
  }

  setAudioEvents = async (deviceId: number, audioEvents: AudioEvent[]) => {
    const { data } = await this.api.post<AudioEvent[]>(
      `${SUBPATH}/audios/events`,
      audioEvents,
      { params: { deviceId } }
    )

    return data
  }

  getAudioEvents = async (deviceId: number) => {
    const { data } = await this.api.get<AudioEvent[]>(
      `${SUBPATH}/audios/events`,
      {
        params: { deviceId },
      }
    )

    return data
  }
}

export default FloorAndEventsContent
