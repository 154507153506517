import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Delete = (props: SvgIconProps) => (
  <SvgIcon width="14" height="19" viewBox="0 0 14 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33333 2V0H4.66667V2H0V4H14V2H9.33333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33333 15H7.77778V9H9.33333V15ZM6.22222 15H4.66667V9H6.22222V15ZM1.55556 5V17C1.55556 18.103 2.25322 19 3.11111 19H10.8889C11.7468 19 12.4444 18.103 12.4444 17V5H1.55556Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Delete
