const scheduleColors = [
  { main: ' #022C3C', light: ' #BFD2D9' },
  { main: ' #0E8380', light: ' #C7E3E2' },
  { main: ' #02173C', light: ' #B7C5DC' },
  { main: ' #168657', light: ' #BFD9CE' },
  { main: ' #240C65', light: ' #BFB8D2' },
  { main: ' #7E279C', light: ' #CFBFD4' },
]

export default scheduleColors
