import { useEffect } from 'react'
import { useField } from 'formik'

export const useShouldClearField = (name: string, shouldClear: boolean) => {
  const [, , { setValue }] = useField(name)

  useEffect(() => {
    if (!shouldClear) return
    setValue('')
  }, [shouldClear])
}
