import { Box, Typography } from '@material-ui/core'
import { Label } from './Label'

interface WidgetCalloutProps {
  title: string
  description: string
}

const WidgetCallout = ({ title, description }: WidgetCalloutProps) => (
  <>
    <Label>{title}</Label>
    <Box mb={5}>
      <Typography variant="body2" color="textPrimary">
        {description}
      </Typography>
    </Box>
  </>
)

export default WidgetCallout
