import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'mediaLibrary'

export const SNACKBARS: SnackbarDescriptorTable = {
  saveMediaFile: {
    successMessage: {
      text: 'mediaLibraryCategory.drawer.savedSuccessfully',
      type: 'success',
      namespace: MODULE_NAME,
    },
    errorMessage: true,
  },
  deleteCategory: {
    successMessage: {
      text: 'manageCategoryModal.successDeleteText',
      type: 'success',
      namespace: MODULE_NAME,
    },
    errorMessage: true,
  },
  createCategory: {
    successMessage: {
      text: 'newCategoryModal.successText',
      type: 'success',
      namespace: MODULE_NAME,
    },
    errorMessage: true,
  },
  editCategory: {
    successMessage: {
      text: 'manageCategoryModal.successEditText',
      type: 'success',
      namespace: MODULE_NAME,
    },
    errorMessage: true,
  },
  getCategories: {
    errorMessage: true,
  },
  getMediaFiles: {
    errorMessage: true,
  },
  getFonts: {
    errorMessage: {
      text: 'mediaLibraryCategory.couldNotFetchFonts',
      type: 'error',
      namespace: MODULE_NAME,
    },
  },
  uploadFont: {
    errorMessage: true,
    successMessage: {
      text: 'assetAdd.successCreateMediaFile',
      type: 'success',
      namespace: MODULE_NAME,
    },
  },
}
