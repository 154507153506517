import { useState } from 'react'

export const useToggle = (
  initialState: boolean = false
): [boolean, () => void] => {
  const [isOpen, setToggle] = useState(initialState)
  const toggleValue = () => setToggle(toggle => !toggle)

  return [isOpen, toggleValue]
}
