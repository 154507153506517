import type { RootState } from 'app'

export const getAuthState = (state: RootState) => state.common.auth

export const getIsAuthenticated = (state: RootState) =>
  state.common.auth.isAuthenticated

export const getLoadingState = (state: RootState) => state.common.auth.loading

export const getError = (state: RootState) => state.common.auth.error

export const getCurrentUser = (state: RootState) => state.common.auth.user

export const getUserScopes = (state: RootState) =>
  state.common.auth.user?.companies[0].scopes

export const getUserRole = (state: RootState) =>
  state.common.auth.user?.companies[0].role
