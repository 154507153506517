import { formatISO } from 'date-fns'
import { rest } from 'msw'
import {
  GetDevicesResponse,
  Orientation,
  OTAStatus,
  OTAStatusInfo,
} from 'shared/types'
import { createHandlerURL } from 'shared/utils'
import { GetDevicesParameters } from './Devices.types'
import {
  createParamsEndpoint,
  displaysEndpoint,
  otaStatusEndpoint,
} from './Devices.utils'

let progressCounter: number | null = null
let requestCount = 0
let status = OTAStatus.Pending

export const devicesHandlers = [
  rest.get<GetDevicesParameters | undefined, GetDevicesResponse[]>(
    createHandlerURL(displaysEndpoint),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            id: 31,
            thumbnail: 'string',
            connectionStatus: true,
            displayModel: 'Atom',
            aspectRatio: '3:4',
            description: 'string',
            tags: [],
            firmware: null,
            buildingAddress: 'asd',
            buildingCity: 'asd2',
            buildingCountry: 'asd3',
            buildingDistrict: 'asd33',
            buildingName: 'name',
            ccid: 'asdfg',
            displayOrientation: Orientation.Landscape,
            gatewayId: 22,
            idBuilding: 34,
            inches: 'asx',
            installationType: '',
            macAddress: null,
            resolution: 'string',
            audioLanguage: 'english',
            numberStops: 3,
            floorLabels: [],
            floorAudios: [],
            floorImages: [],
            navigationBox: true,
            currentTemplateId: null,
            lastSentTemplateId: null,
            lastSentTemplateThumbnail: null,
            schedule: [],
          },
        ])
      )
    }
  ),
  rest.get<{}, OTAStatusInfo>(
    createHandlerURL(otaStatusEndpoint),
    (req, res, ctx) => {
      requestCount++
      if (requestCount < 3) return res(ctx.status(404))
      if (status === OTAStatus.Pending) {
        if (requestCount >= 5) {
          status = OTAStatus.Progress
          progressCounter = 0
        } else {
          return res(
            ctx.status(200),
            ctx.json({
              status,
              dateposted: formatISO(new Date()),
              layoutId: 1,
              progress: null,
            })
          )
        }
      }
      if (progressCounter !== null && progressCounter < 100)
        progressCounter += 10
      else status = OTAStatus.Success
      if (requestCount > 18) status = OTAStatus.Failed
      return res(
        ctx.status(200),
        ctx.json({
          status,
          progress: status === OTAStatus.Progress ? progressCounter : null,
          dateposted: formatISO(new Date()),
          layoutId: null,
        })
      )
    }
  ),
  rest.post(createHandlerURL(createParamsEndpoint(31)), (req, res, ctx) => {
    return res(ctx.status(200))
  }),
  rest.put(createHandlerURL(displaysEndpoint), (req, res, ctx) => {
    return res(ctx.status(200))
  }),
]
