import { Box } from '@material-ui/core'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkProps, Redirect } from 'react-router-dom'
import { actions, selectors } from 'shared/store'

type RouterLinkProps = Pick<LinkProps, 'to'>

const RouterLink = ({ to, children }: PropsWithChildren<RouterLinkProps>) => {
  const dispatch = useDispatch()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { prevent } = useSelector(selectors.utils.getRedirectionOptions)

  const onClick = () => {
    if (prevent) {
      dispatch(actions.utils.setPreventFromRedirectionOptions(to))
      dispatch(actions.utils.setOpenPreventRedirectionModal(true))
    } else setShouldRedirect(true)
  }

  useEffect(() => {
    if (shouldRedirect) setShouldRedirect(false)
  }, [shouldRedirect])

  return (
    <>
      {!prevent && shouldRedirect && <Redirect to={to as any} />}
      <Box width="100%" display="flex" onClick={onClick}>
        {children}
      </Box>
    </>
  )
}

export default RouterLink
