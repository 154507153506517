import { createAction } from '@reduxjs/toolkit'
import {
  GetPlaylistParams,
  Playlist,
  GetScheduledPlaylistsInDeviceParams,
} from 'shared/services'
import { Playlist as PlaylistType } from 'shared/types'
import { ScheduleComplexId } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import {
  DeletePlaylistPayload,
  UpdateOrCreateScheduleProps,
  UpdatePlaylistPayload,
} from './action.types'

const playlist = new Playlist()

export const getPlaylists = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getPlaylists`,
  payloadCreator: (params: GetPlaylistParams) => playlist.getPlaylists(params),
  withCode: true,
})

export const resetGetPlaylist = createAction(
  `${MODULE_NAME}/resetGetPlaylistErrors`
)

export const deletePlaylist = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deletePlaylist`,
  payloadCreator: ({ playlistId }: DeletePlaylistPayload) =>
    playlist.deletePlaylist(playlistId),
  ...SNACKBARS.deletePlaylist,
})

export const resetDeletePlaylist = createAction(
  `${MODULE_NAME}/resetDeletePlaylist`
)

export const resetUpdatePlaylist = createAction(
  `${MODULE_NAME}/resetUpdatePlaylist`
)

export const resetUpdateOrCreateSchedule = createAction(
  `${MODULE_NAME}/resetUpdateOrCreateSchedule`
)

export const clearPlaylistCreator = createAction(
  `${MODULE_NAME}/clearPlaylistCreator`
)

export const clearScheduler = createAction(`${MODULE_NAME}/clearScheduler`)

export const updatePlaylist = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updatePlaylist`,
  payloadCreator: ({ dto, playlistId }: UpdatePlaylistPayload) =>
    playlist.updateOrCreatePlaylist(dto, playlistId),
})

export const getPlaylistCategories = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getPlaylistCategories`,
  payloadCreator: () => playlist.getPlaylistCategories(),
})

export const increasePlaylistCounter = createAction(
  `${MODULE_NAME}/increasePlaylistCounter`
)

export const generateScheduleCompleted = createAction<number>(
  `${MODULE_NAME}/generateScheduleCompleted`
)

export const addPlaylistToSchedule = createAction<PlaylistType>(
  `${MODULE_NAME}/addPlaylistToSchedule`
)

export const initializeSelectingPlaylistForScheduling = createAction<
  string | null
>(`${MODULE_NAME}/initializeSelectingPlaylistForScheduling`)

export const removePlaylistToSchedule = createAction<number>(
  `${MODULE_NAME}/removePlaylistToSchedule`
)

export const generateScheduleFailed = createAction<[number, string]>(
  `${MODULE_NAME}/generateScheduleFailed`
)

export const getDeviceSchedules = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDeviceSchedules`,
  payloadCreator: async (params: GetScheduledPlaylistsInDeviceParams) => {
    const baseData = await playlist.getScheduledPlaylistsInDevice(params)

    return await Promise.all(
      baseData.map(async object => {
        const playlistData = await playlist.getPlaylists({
          playlistId: object.playlistId,
        })
        const scheduleData = await playlist.getSchedule({
          scheduleId: object.scheduleId,
        })
        return {
          ...object,
          playlist: playlistData[0],
          schedule: scheduleData[0],
        }
      })
    )
  },
})

export const updateOrCreateSchedule = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updateOrCreateSchedule`,
  payloadCreator: async ({
    complexId,
    schedule,
    playlistData,
  }: UpdateOrCreateScheduleProps) => {
    const created = complexId.scheduleId
      ? await playlist.updateSchedule(complexId.scheduleId, schedule)
      : await playlist.addSchedule(schedule)

    const taskId = complexId.scheduleId
      ? null
      : (
          await playlist.addPlaylistScheduleToDevice({
            ...complexId,
            scheduleId: created.idSchedule,
          })
        ).data

    return {
      schedule: created,
      templateId: complexId.templateId,
      displayId: complexId.displayId,
      playlist: playlistData,
      isCreating: !complexId.scheduleId,
      taskId,
    }
  },
  ...SNACKBARS.updateOrCreateSchedule,
})

export const deleteSchedule = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteSchedule`,
  payloadCreator: async (complexId: ScheduleComplexId) => {
    await playlist.removePlaylistScheduleFromDevice(complexId)
    await playlist.removeSchedule(complexId.scheduleId)
    return complexId
  },
})
