import { SvgIcon, SvgIconProps } from '@material-ui/core'

const ContentAddition = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 26" {...props}>
    <g
      id="CALENDAR"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-108.000000, -42.000000)"
        fill="currentColor"
      >
        <g id="Content-Additio" transform="translate(108.000000, 42.000000)">
          <path
            d="M14.6803808,16.4650286 L5.74987898,18.2511289 L7.53597934,9.31936393 L14.3292239,2.52611939 L2.52630886,2.52611939 C1.13304953,2.52611939 0,3.66043207 0,5.05242826 L0,22.7365903 C0,24.1285865 1.13304953,25.2628992 2.52630886,25.2628992 L20.2104709,25.2628992 C21.6037303,25.2628992 22.7367798,24.1285865 22.7367798,22.7365903 L22.7367798,8.40736643 L14.6803808,16.4650286 Z"
            id="Fill-1443"
          />
          <polygon
            id="Fill-1444"
            points="9.86270981 10.5654658 13.4349105 14.1376665 8.96965962 15.0307167"
          />
          <path
            d="M23.2603573,4.31209344 C24.2468809,3.32556983 24.2468809,1.72641632 23.2603573,0.739892709 C22.2738337,-0.246630903 20.6746802,-0.246630903 19.6881566,0.739892709 L11.6494418,8.77860752 L15.2216425,12.3508082 L23.2603573,4.31209344 Z"
            id="Fill-1445"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default ContentAddition
