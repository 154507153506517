import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Info = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4.001C7 4.554 6.553 5.001 6 5.001C5.447 5.001 5 4.554 5 4.001C5 3.448 5.447 3.001 6 3.001C6.553 3.001 7 3.448 7 4.001ZM7 10.001H5V6.001H7V10.001ZM6 0C2.691 0 0 2.693 0 6C0 9.309 2.691 12 6 12C9.308 12 12 9.309 12 6C12 2.693 9.308 0 6 0Z"
      fill="#869EAA"
    />
  </SvgIcon>
)

export default Info
