import {
  styled,
  Avatar,
  Grid,
  Theme,
  Collapse as BaseCollapse,
  CollapseProps,
  Typography,
} from '@material-ui/core'
import { DropdownArrow } from 'shared/icons'

const AVATAR_SIZE = 48

interface UserProfileContainerProps {
  open: boolean
}
export const UserProfileContainer = styled('div')<
  Theme,
  UserProfileContainerProps
>(({ theme, open }) => ({
  margin: theme.spacing(5, 'auto'),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: open
      ? theme.palette.primary.main
      : theme.palette.background.overlay.dark,
    transition: theme.transitions.create('background', {
      duration: theme.transitions.duration.standard,
    }),
  },
}))

export const UserProfileCardGrid = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(2, 6),
}))

export const UserProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderRadius: 3,
}))

interface DropdownIconProps {
  flipped?: boolean
}

export const DropdownIcon = styled(DropdownArrow)<Theme, DropdownIconProps>(
  ({ flipped }) => ({
    width: 15,
    height: 'auto',
    ...(flipped && {
      transform: 'rotate(180deg)',
    }),
  })
)

export const Collapse = styled(BaseCollapse)<Theme, CollapseProps>(
  ({ theme }) => ({
    '& .MuiList-root': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiListItem-root': {
      padding: theme.spacing(1.5, 5),
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.background.overlay.dark,
        transition: theme.transitions.create('background', {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
    '& .MuiListItemText-root': {
      paddingLeft: theme.spacing(6),
      '& .MuiTypography-button': {
        ...theme.typography.subtitle2,
        lineHeight: '1.125rem',
      },
    },
  })
)

export const StyledTypography = styled(Typography)({
  textTransform: 'capitalize',
  lineHeight: '1.125rem',
})
