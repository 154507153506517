import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Weather = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 20" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icons"
        transform="translate(-240.000000, -2018.000000)"
        fill="currentColor"
      >
        <g id="Group" transform="translate(240.000000, 2018.000000)">
          <polygon
            id="Fill-3745"
            points="8.051 0.005 6.051 0.005 6.051 2.005 8.051 2.005"
          ></polygon>
          <polygon
            id="Fill-3746"
            points="4.2075 2.7275 2.7935 1.3125 1.3785 2.7265 2.7925 4.1415"
          ></polygon>
          <polygon
            id="Fill-3747"
            points="2 6.005 0 6.005 0 8.005 2 8.005"
          ></polygon>
          <path
            d="M2.9999,7.0049 C2.9999,7.3959 3.0599,7.7839 3.1699,8.1459 C4.2689,7.4249 5.5899,7.0049 6.9999,7.0049 C7.1699,7.0049 7.3299,7.0149 7.4999,7.0249 C8.1799,5.9949 9.0599,5.1249 10.0799,4.4549 C9.3399,3.5659 8.2399,3.0049 6.9999,3.0049 C4.7899,3.0049 2.9999,4.7949 2.9999,7.0049"
            id="Fill-3748"
          ></path>
          <polygon
            id="Fill-3749"
            points="12.6923 2.7988 11.2783 1.3828 9.8633 2.7978 11.2773 4.2128"
          ></polygon>
          <path
            d="M14.9999,5.0049 C12.4909,5.0049 10.2599,6.3149 9.0209,8.3619 C9.9869,8.8189 11.0179,9.6249 11.9919,11.0079 L11.1749,11.5849 C9.6779,9.4589 8.3929,9.0049 6.9999,9.0049 C4.2429,9.0049 1.9999,11.2479 1.9999,14.0049 C1.9999,16.7619 4.2429,19.0049 6.9999,19.0049 L14.9999,19.0049 C18.8599,19.0049 21.9999,15.8649 21.9999,12.0049 C21.9999,8.1449 18.8599,5.0049 14.9999,5.0049"
            id="Fill-3750"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Weather
