import { styled, Typography, Theme } from '@material-ui/core'

export enum MarginType {
  NoMargin,
  SmallMargin,
  BigMargin,
  Custom,
}

interface LabelPropsRegular {
  marginType?: Exclude<MarginType, MarginType.Custom>
  margin?: undefined
}
interface LabelPropsCustom {
  marginType: MarginType.Custom
  margin: number | string
}

type LabelProps = LabelPropsRegular | LabelPropsCustom

export const Label = styled(Typography)<Theme, LabelProps>(
  ({ theme, marginType = MarginType.BigMargin, margin }) => ({
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 0.21,
    color: theme.palette.primary.main,
    margin:
      marginType === MarginType.Custom
        ? margin
        : marginType === MarginType.SmallMargin
        ? theme.spacing(0, 0, 2, 0)
        : marginType === MarginType.BigMargin
        ? theme.spacing(5, 0, 3)
        : 0,
  })
)
