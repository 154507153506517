import { SvgIcon, SvgIconProps } from '@material-ui/core'

const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="12" fill="#05A19F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6759 17.9031L5 12.2273L7.16501 10.0622L10.6759 13.5731L17.249 7L19.414 9.16501L10.6759 17.9031Z"
      fill="white"
    />
  </SvgIcon>
)

export default CheckIcon
