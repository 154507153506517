import {
  GetWidgetCategoriesResponse,
  PlaylistCategory,
  AddWidgetCategoryResponse,
} from 'shared/services'
import { CategoryItem } from 'shared/types'

export enum Categories {
  Widgets = 'widgets',
  Templates = 'templates',
  Playlists = 'playlists',
}

export type CategoriesState = {
  widgets: GetWidgetCategoriesResponse[]
  templates: CategoryItem[]
  playlists: PlaylistCategory[]
}

export type AddCategoryState =
  | AddWidgetCategoryResponse
  | PlaylistCategory
  | CategoryItem
  | null
