import { LayoutWidget, WidgetType } from 'shared/types'

export const layoutWidgets: LayoutWidget[] = [
  {
    widgetType: WidgetType.Image,
    size: {
      width: 493,
      height: 262,
    },
    position: {
      x: 1427,
      y: 548,
    },
    thumbnail: '',
    id: 'wig-widget-0',
    isInCollisionWith: [],
    layoutProperties: {},
    actions: [],
    metadata: [],
    properties: [],
  },
  {
    widgetType: WidgetType.Image,
    size: {
      width: 300,
      height: 221,
    },
    position: {
      x: 1200,
      y: 400,
    },
    thumbnail: '',
    id: 'wig-widget-1',
    isInCollisionWith: [],
    layoutProperties: {},
    actions: [],
    metadata: [],
    properties: [],
  },
]
