import { extractFileName } from 'shared/utils'
import { Api } from '../api'
import {
  GetDeviceAudioFilesParams,
  GetDeviceAudioFilesResponse,
} from './AudioLibrary.types'
import { AddAudioFile, AudioLanguage } from './index'

const SUBPATH = 'audio_library'

class AudioLibrary extends Api {
  getDeviceAudioFiles = async (params: GetDeviceAudioFilesParams) => {
    const { data } = await this.api.get<GetDeviceAudioFilesResponse>(
      `${SUBPATH}/device_audio_file`,
      {
        params,
      }
    )
    const fileNames = data.map(({ filename }) => filename.toLowerCase()).sort()
    const sortedData = fileNames.map(
      name => data.find(({ filename }) => filename.toLowerCase() === name)!
    )

    return sortedData
  }
  getAudioLanguages = async (params: AudioLanguage) => {
    const { data } = await this.api.get<AudioLanguage[]>(
      `${SUBPATH}/audio_languages`,
      { params }
    )

    return data
  }
  addAudioFile = async ({ file, filename, ...props }: AddAudioFile) => {
    const formData = new FormData()
    const { fileName, fileExtension } = extractFileName(file.name) || {}
    formData.append('file', file, `${fileName}.${fileExtension}`)
    const { data } = await this.api.post<AddAudioFile>(
      `${SUBPATH}/device_audio_file`,
      formData,
      { params: { filename, ...props } }
    )

    return data
  }
  addAudioLanguage = async (languageLabel: string) => {
    const { data } = await this.api.post<string>(
      `${SUBPATH}/audio_languages`,
      null,
      {
        params: { languageLabel },
      }
    )
    return data
  }
}

export default AudioLibrary
