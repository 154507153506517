import { Box, ButtonProps, styled } from '@material-ui/core'
import { PropsWithChildren } from 'react'
import { CheckIcon, Upload } from 'shared/icons'
import { UploadButton } from './UploadFileButton.style'

type EmptyUploadButtonProps = PropsWithChildren<
  {
    label: string
    isFullWidth?: boolean
    showSuccessIcon?: boolean
  } & ButtonProps
>

const SuccessIcon = styled(CheckIcon)({
  position: 'absolute',
  right: 10,
  top: '50%',
  transform: 'translateY(-50%)',
})

const EmptyUploadButton = ({
  children,
  label,
  color = 'primary',
  fullWidth = true,
  showSuccessIcon = false,
  ...props
}: EmptyUploadButtonProps) => (
  <UploadButton
    color={color || 'primary'}
    size="small"
    fullWidth={fullWidth}
    {...props}
  >
    {children}
    <Upload />
    <Box pl={2}>{label}</Box>
    {showSuccessIcon && <SuccessIcon />}
  </UploadButton>
)

export default EmptyUploadButton
