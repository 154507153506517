import { useTranslation } from 'react-i18next'
import { BooleanStringType } from 'shared/types'
import { string, mixed } from 'yup'
import { MODULE_NAME } from '../strings'
import { useColorValidation, useOptionalColorValidation } from './common'

export const useTextValidation = () => {
  const { t } = useTranslation(MODULE_NAME)
  return {
    font: string().required(t('validation.required')),
    fontSize: string().required(t('validation.required')),
    color: useColorValidation(),
    backgroundColor: useOptionalColorValidation(),
    bold: mixed<BooleanStringType>(),
    italic: mixed<BooleanStringType>(),
    uppercase: mixed<BooleanStringType>(),
    underline: mixed<BooleanStringType>(),
  }
}
