import { ReactNode } from 'react'
import { FieldProps, useField } from 'formik'
import { ButtonBase, styled, Theme } from '@material-ui/core'
import { BooleanStringType } from 'shared/types'
import {
  DENSE_ICON_BUTTON_WIDTH,
  NORMAL_ICON_BUTTON_WIDTH,
} from 'shared/config'

interface SwitchButtonProps extends FieldProps {
  icon: ReactNode
}

interface SwitchProps {
  active?: BooleanStringType
}

const Switch = styled(ButtonBase)<Theme, SwitchProps>(({ theme, active }) => ({
  backgroundColor: theme.palette.declined.main,
  color: theme.palette.accent.main,
  width: DENSE_ICON_BUTTON_WIDTH,
  height: 38,
  borderRadius: 3,
  '&:hover': {
    backgroundColor: theme.palette.declined.dark,
  },
  ...(active === BooleanStringType.True && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
    width: NORMAL_ICON_BUTTON_WIDTH,
  },
}))

const SwitchButton = ({ icon, field }: SwitchButtonProps) => {
  const [{ value }, , { setValue }] = useField(field)
  return (
    <Switch
      active={value}
      onClick={() =>
        setValue(
          value === BooleanStringType.False
            ? BooleanStringType.True
            : BooleanStringType.False
        )
      }
      {...field}
    >
      {icon}
    </Switch>
  )
}

export default SwitchButton
