import { Grid, Typography } from '@material-ui/core'
import { Empty } from 'shared/icons'

interface NoRowsOverlayProps {
  text: string
}

const NoRowsOverlay = ({ text }: NoRowsOverlayProps) => (
  <Grid
    container
    spacing={2}
    direction="column"
    className="MuiDataGrid-overlay"
  >
    <Grid item>
      <Empty fontSize="large" />
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">{text}</Typography>
    </Grid>
  </Grid>
)

export default NoRowsOverlay
