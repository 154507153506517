import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Right = (props: SvgIconProps) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      transform="translate(-359.000000, -1732.000000)"
      fillRule="evenodd"
    >
      <g transform="translate(367.000000, 1740.500000) scale(-1, 1) translate(-367.000000, -1740.500000) translate(359.000000, 1732.000000)">
        <polygon id="Fill-413" points="16 2 0 2 0 0 16 0"></polygon>
        <polygon
          id="Fill-414"
          points="10 6.999 0 6.999 0 4.999 10 4.999"
        ></polygon>
        <polygon id="Fill-415" points="16 12 0 12 0 10 16 10"></polygon>
        <polygon id="Fill-416" points="13 17 0 17 0 15 13 15"></polygon>
      </g>
    </g>
  </SvgIcon>
)

export default Right
