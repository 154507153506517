import { useState } from 'react'
import { Select, SelectProps } from '@material-ui/core'
import { IconSelectOption } from 'shared/types'
import { CollapsibleSharpArrowSmall } from 'shared/icons'
import {
  FormControl,
  useSelectStyles,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
} from './Select.style'

interface MaterialSelectProps extends SelectProps {
  options: IconSelectOption[]
  height?: string | number
  width?: string | number
  isDefaultCase?: boolean
  withFormControl?: boolean
}

interface FormControlWrapperProps {
  children: JSX.Element
}

export const MaterialSelect = ({
  options,
  value,
  disabled,
  name,
  placeholder,
  height,
  width,
  isDefaultCase,
  withFormControl = true,
  ...props
}: MaterialSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useSelectStyles({ disabled, isDefaultCase })

  const FormControlWrapper = ({ children }: FormControlWrapperProps) =>
    withFormControl ? (
      <FormControl fullWidth height={height} width={width} open={isOpen}>
        {children}
      </FormControl>
    ) : (
      children
    )

  return (
    <FormControlWrapper>
      <Select
        value={value}
        {...(placeholder && { defaultValue: placeholder })}
        name={name}
        disableUnderline
        displayEmpty
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={() => <CollapsibleSharpArrowSmall collapsed={!isOpen} />}
        disabled={disabled}
        className={classes.select}
        {...props}
      >
        {placeholder && (
          <MenuItem key="placeholder" value={placeholder} disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ value, label, icon }) => (
          <MenuItem key={`option-${value}`} value={value}>
            <ListItem button>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{label}</ListItemText>
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControlWrapper>
  )
}

export default MaterialSelect
