import { ButtonProps, PropTypes } from '@material-ui/core'
import { FieldProps } from 'formik'
import { useState } from 'react'
import { Ellipsis } from '../Ellipsis'
import EmptyUploadButton from './EmptyUploadButton'
import {
  StyledInput,
  StyledInputLabel,
  UploadLabel,
  useStyles,
} from './UploadFileButton.style'

interface UploadFileButtonProps extends Partial<FieldProps> {
  accept: string[]
  disabled?: boolean
  name: string
  label: string
  size?: ButtonProps['size']
  setUploaded?: (files: FileList) => void
  withFileName?: boolean
  withSameUploadsInRow?: boolean
  color?: PropTypes.Color
  isFullWidth?: boolean
  multiple?: boolean
}

const UploadFileButton = ({
  field,
  accept,
  disabled = false,
  name,
  form,
  label,
  setUploaded,
  withFileName = false,
  withSameUploadsInRow = false,
  multiple = false,
  isFullWidth = true,
  size,
  color = 'inherit',
  ...props
}: UploadFileButtonProps) => {
  const classes = useStyles()
  const [fileName, setFileName] = useState('')
  const inputId = `uploadButton-${name}`
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (multiple && event.currentTarget.files) {
      setUploaded?.(event.currentTarget.files)
      if (withSameUploadsInRow) event.currentTarget.value = ''
      return
    }
    const file = event.currentTarget.files?.[0]
    if (!event.currentTarget.files || !file) return
    setFileName(file.name)
    if (form && field) form.setFieldValue(field.name, file)
    setUploaded?.(event.currentTarget.files)
    if (withSameUploadsInRow) event.currentTarget.value = ''
  }
  return (
    <EmptyUploadButton
      disabled={disabled}
      label={label}
      color={color}
      fullWidth={isFullWidth}
      size={size}
    >
      <StyledInputLabel htmlFor={inputId} classes={{ root: classes.upload }} />
      <StyledInput
        id={inputId}
        inputProps={{
          accept,
          name,
          disabled,
          type: 'file',
          onChange,
          multiple,
        }}
        {...props}
      />
      {withFileName && fileName && (
        <UploadLabel>
          <Ellipsis variant="horizontal">{fileName}</Ellipsis>
        </UploadLabel>
      )}
    </EmptyUploadButton>
  )
}

export default UploadFileButton
