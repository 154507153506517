import {
  Card,
  CardMedia,
  CardProps,
  styled,
  Theme,
  Typography,
} from '@material-ui/core'

export const CardImage = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: 3,
  backgroundSize: 'contain',
  backgroundColor: theme.palette.background.neutral.divider,
}))

const CATEGORY_TITLE_LINE_HEIGHT = 1.4

interface CardTypographyProps {
  gutterBottom?: boolean
}

export const CardTypography = styled(Typography)<Theme, CardTypographyProps>(
  ({ theme, gutterBottom = false }) => ({
    lineHeight: `${CATEGORY_TITLE_LINE_HEIGHT}rem`,
    fontWeight: 700,
    fontSize: '1.25rem',
    marginBottom: gutterBottom ? theme.spacing(2) : 0,
    overflow: 'hidden',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    maxHeight: `${CATEGORY_TITLE_LINE_HEIGHT * 3}rem`,
    wordWrap: 'break-word',
  })
)

interface StyledCardProps extends CardProps {
  minHeight?: number
  isSelected?: boolean
  cursorOnHover?: string
}

export const StyledCard = styled(Card)<Theme, StyledCardProps>(
  ({ theme, minHeight, isSelected = false, cursorOnHover = 'pointer' }) => ({
    minHeight,

    '&:hover': {
      cursor: cursorOnHover,
      boxShadow: '0px 2px 3px 0px #0000000D, 0px 1px 20px 0px #0000000D',
    },
    ...(isSelected && {
      outline: `3px solid ${theme.palette.accent.main}`,
      borderRadius: 3,
    }),
  })
)
