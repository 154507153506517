export enum TemplateType {
  MediaOnly = 'MediaOnly',
  LiftInformationAndMedia = 'LiftInformationAndMedia',
}

export enum BackgroundType {
  configGradient,
  configColor,
  addedColor,
  image,
}

export enum Alignment {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum MediaAssetType {
  Image = 'image',
  Video = 'video',
}
/**
 * @Desc Type used only for filtering purposes.
 */
export type MediaAssetFilterType = MediaAssetType | 'all'

export enum MetadataName {
  slotName = 'slotName',
  liveVideoType = 'liveVideoType',
}

export enum AssetCategory {
  General = 'General',
  Category1 = 'Category1',
  Category2 = 'Category2',
}

export enum DeviceQueries {
  BuildingName = 'Building name',
  BuildingAddress = 'Building address',
  BuildingCity = 'Building city',
  DisplayModel = 'Display model',
  ConnectionStatusActive = 'Connection status active',
  ConnectionStatusInactive = 'Connection status inactive',
  MacAddress = 'MAC address',
  Tags = 'Tags',
}

export enum ConnectionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum OTAStatus {
  Idle = 'idle', // used in Frontend to indicate no status present
  Await = 'await', // used in Frontend to indicate waiting for OTA Status to be created
  Pending = 'pending',
  Progress = 'progress',
  Success = 'success',
  Failed = 'failed',
}

export enum ProgrammableParam {
  Firmware = 'firmware',
  Template = 'template',
  DisplayScreenshot = 'displayScreenshot',
}

export enum TagType {
  Template = 'template',
  Display = 'display',
}

export enum PlaylistItemType {
  Dragged = 'Dragged',
  Loaded = 'Loaded',
}

export enum PlaylistPriority {
  Low,
  Medium,
  High,
}

export enum PlaylistResizeMode {
  Fit = 'contain',
  Fill = 'fill',
}
