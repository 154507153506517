import { SvgIcon, SvgIconProps } from '@material-ui/core'

const PositionIndicatorText = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 72.28 72.28" {...props}>
    <polygon points="27.06 21.64 21.42 36.29 32.7 36.29 27.06 21.64" />
    <path d="M64.47,0H7.8A7.8,7.8,0,0,0,0,7.8V64.47a7.8,7.8,0,0,0,7.8,7.81H64.47a7.8,7.8,0,0,0,7.81-7.81V7.8A7.8,7.8,0,0,0,64.47,0ZM13.1,41.45l2-5.16,8.8-22.88.34-.88h5.65l.34.88L39,36.29l2,5.16,2.22,5.76H36.9l-2.22-5.76H19.43l-2.21,5.76H10.88Zm48.17,18.4H11V53.2H61.27Zm0-12.64H53.41V19.66H53.3l-6.63,3.16-1.34-6.1,9.2-4.28h6.74Z" />
  </SvgIcon>
)

export default PositionIndicatorText
