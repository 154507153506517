import {
  ApiWidget,
  ImageWidgetPropertyName,
  TextWidgetPropertyName,
  WidgetType,
} from 'shared/types'

export const widgets: ApiWidget<WidgetType>[] = [
  {
    id: 0,
    name: 'test',
    widgetType: WidgetType.Background,
    idWidgetCategory: 1,
    universal: false,
    created: '2020-12-12T20:20:50',
    positionX: 0,
    positionY: 0,
    width: 800,
    height: 480,
    thumbnail: 'test',
    properties: [
      {
        name: ImageWidgetPropertyName.img,
        value: '2ed8fe51899631c402246f0dc464b5823391d1d2',
      },
    ],
  },
  {
    id: 1,
    name: 'test3',
    universal: false,
    created: '2020-12-12T20:20:50',
    idWidgetCategory: 1,
    widgetType: WidgetType.Text,
    positionX: 10,
    positionY: 10,
    width: 60,
    height: 100,
    thumbnail: 'test',
    properties: [
      {
        name: TextWidgetPropertyName.color,
        value: '#COCOCO',
      },
      {
        name: TextWidgetPropertyName.font,
        value: 'Arial',
      },
      {
        name: TextWidgetPropertyName.font,
        value: '12',
      },
    ],
  },
  {
    id: 2,
    name: 'test2',
    universal: false,
    created: '2020-12-12T20:20:50',
    idWidgetCategory: 1,
    widgetType: WidgetType.Image,
    positionX: 10,
    positionY: 10,
    width: 80,
    height: 100,
    thumbnail: 'test',
    properties: [
      {
        name: ImageWidgetPropertyName.img,
        value: '2ed8fe51899631c402246f0dc464b5823391d1d2',
      },
    ],
  },
]
