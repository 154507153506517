import { styled, Theme } from '@material-ui/core'
import { MIN_STATUS_BAR_ITEM_WIDTH } from 'shared/config'
interface StatusBarItemProps {
  withYMargin?: boolean
}

export const StatusBarItem = styled('div')<Theme, StatusBarItemProps>(
  ({ theme, withYMargin }) => ({
    background: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    minWidth: MIN_STATUS_BAR_ITEM_WIDTH,
    fontWeight: 800,
    fontHeight: '1rem',
    lineHeight: 1.75,
    padding: theme.spacing(1.5, withYMargin ? 1.5 : 0),
    textAlign: 'center',
    borderRadius: 4,
    margin: theme.spacing(0, 1),
  })
)
