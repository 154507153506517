import { formatDuration } from 'shared/utils'
import { Duration } from '../Duration'
import ImageThumbnail, { ImageThumbnailProps } from './ImageThumbnail'
import { DurationContainer } from './ThumbnailStyles'

export interface ImageThumbnailWithDurationProps extends ImageThumbnailProps {
  duration?: number
}

const ImageThumbnailWithDuration = (props: ImageThumbnailWithDurationProps) => (
  <>
    <ImageThumbnail {...props}>
      <DurationContainer>
        <Duration label={formatDuration(props.duration || 0)} />
      </DurationContainer>
    </ImageThumbnail>
  </>
)

export default ImageThumbnailWithDuration
