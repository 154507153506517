import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DisplayManagement = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 19 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1136 18C13.5366 18 13.902 17.7057 13.9929 17.2935C14.0829 16.8804 13.875 16.4592 13.4907 16.2828C12.1236 15.6528 10.6044 15.3 9.00061 15.3C7.39591 15.3 5.8767 15.6528 4.5096 16.2828C4.1262 16.4592 3.9165 16.8804 4.0074 17.2926C4.09741 17.7057 4.4646 18 4.8867 18H13.1136Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9185 12.6L10.0998 11.3265L11.3724 10.0539L8.1909 6.8724L9.4635 5.5998L12.0087 8.145L14.5548 6.8724L17.1 9.4176L13.9185 12.6ZM16.2 0H1.8C0.8073 0 0 0.8073 0 1.8V12.6C0 13.5927 0.8073 14.4 1.8 14.4H16.2C17.1927 14.4 18 13.5927 18 12.6V1.8C18 0.8073 17.1927 0 16.2 0Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default DisplayManagement
