import { SvgIcon, SvgIconProps } from '@material-ui/core'

const ImagesByFloor = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-671.000000, -1372.000000)" fill="#033E54">
        <g transform="translate(197.000000, 314.000000)">
          <g transform="translate(474.000000, 1058.000000)">
            <polygon points="18 11 16 11 16 2 4 2 4 11 2 11 2 0 18 0"></polygon>
            <polygon points="11.2739 4 8.3379 8.708 7.0649 6.666 4.9999 9.979 14.9999 9.979"></polygon>
            <path d="M13.8584,13 C13.4124,14.722 11.8614,16 10.0004,16 C8.1384,16 6.5874,14.722 6.1414,13 L0.0004,13 L0.0004,20 L20.0004,20 L20.0004,13 L13.8584,13 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default ImagesByFloor
