import { Box, ButtonProps } from '@material-ui/core'
import { ReactNode } from 'react'
import { LayoutWidget, MediaTransformationKind } from 'shared/types'
import { BaseTabButton } from './TabButton.style'
import { useTabButton } from './TabButton.utils'

interface TabButtonProps extends ButtonProps {
  mediaTransformationKind?: MediaTransformationKind
  text?: string
  icon?: ReactNode
  size?: 'small' | 'medium' | 'large'
  widgetId: LayoutWidget['id']
  toggle?: () => void
  active?: boolean
}

const TabButton = ({
  mediaTransformationKind,
  text,
  icon,
  size,
  widgetId,
  toggle,
  active = false,
}: TabButtonProps) => {
  const {
    createSetMediaTransformation,
    isCropActive,
    isResizeActive,
  } = useTabButton(widgetId)
  const isActive =
    active ||
    (mediaTransformationKind === MediaTransformationKind.Resize &&
      isResizeActive) ||
    (mediaTransformationKind === MediaTransformationKind.Crop && isCropActive)
  const handleOnClick =
    toggle ||
    createSetMediaTransformation(
      mediaTransformationKind || MediaTransformationKind.Resize
    )

  return (
    <BaseTabButton
      onClick={handleOnClick}
      active={isActive}
      size={size || 'medium'}
    >
      <span>{icon}</span>
      {text && <Box ml={3}>{text}</Box>}
    </BaseTabButton>
  )
}

export default TabButton
