import { useCallback } from 'react'

const findDuplicateTag = (tag: string, tags: string[]) =>
  tags.find(
    savedTag => savedTag.toLowerCase().trim() === tag.toLowerCase().trim()
  )

type EventType = React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>

export const useOnKeyDown = (
  newTag: string,
  tags: string[],
  setNewTag: (tag: string) => void,
  setValue: (tags: string[], v: boolean) => void
) =>
  useCallback(
    (e: EventType) => {
      if (e.key !== 'Enter') return
      e.preventDefault()

      if (newTag && !findDuplicateTag(newTag, tags))
        setValue([...tags, newTag.trim()], false)

      setNewTag('')
    },
    [newTag, setNewTag, setValue, tags]
  )

export const useOnDeleteTag = (
  tags: string[],
  setValue: (tags: string[], v: boolean) => void
) =>
  useCallback(
    (tag: string) => {
      const index = tags.findIndex(savedTag => savedTag === tag)
      if (index !== -1)
        setValue([...tags.slice(0, index), ...tags.slice(index + 1)], false)
    },
    [setValue, tags]
  )
