import { createReducer } from '@reduxjs/toolkit'
import {
  CategoryItem,
  Resource,
  SideEffects,
  EditTemplateDetailsAPI,
} from 'shared/types'
import {
  AddCategoryResponse,
  AddTemplateThumbnailResponse,
  TemplateStatus,
} from 'shared/services'
import {
  setResourcePending,
  setResourceRejected,
  defaultSideEffects,
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
} from 'shared/utils'
import {
  clearTemplate,
  deleteTemplate,
  getCategoryList,
  getTemplateStatuses,
  getTemplates,
  uploadTemplateThumbnail,
  clearSaveTemplateError,
  generateProject,
  getZipProject,
  clearGetZipProject,
  clearGenerateProject,
  addTemplateCategory,
  resetAddCategory,
} from './actions'

interface State {
  categoryList: Resource<CategoryItem[]>
  uploadTemplateThumbnail: Resource<AddTemplateThumbnailResponse | null>
  templates: Resource<EditTemplateDetailsAPI[]>
  templateStatuses: Resource<TemplateStatus[] | null>
  saveTemplate: SideEffects
  deleteTemplate: SideEffects
  generateProject: SideEffects
  getZipProject: SideEffects
  addTemplateCategory: Resource<AddCategoryResponse | null>
}

const initialState: State = {
  categoryList: getDefaultResourceState([]),
  uploadTemplateThumbnail: getDefaultResourceState(null),
  templates: getDefaultResourceState([]),
  templateStatuses: getDefaultResourceState(null),
  deleteTemplate: defaultSideEffects,
  saveTemplate: defaultSideEffects,
  generateProject: defaultSideEffects,
  getZipProject: defaultSideEffects,
  addTemplateCategory: getDefaultResourceState(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(clearSaveTemplateError, state => {
      state.uploadTemplateThumbnail.error =
        initialState.uploadTemplateThumbnail.error
    })
    .addCase(clearTemplate, state => {
      state.deleteTemplate = initialState.deleteTemplate
      state.saveTemplate = initialState.saveTemplate
    })
    .addCase(deleteTemplate.pending, state =>
      setResourcePending(state.deleteTemplate)
    )
    .addCase(deleteTemplate.fulfilled, (state, { meta }) => {
      if (state.templates.data) {
        state.templates.data = state.templates.data?.filter(
          template => template.id !== meta.arg.templateId
        )
        setResourceFulfilled(state.deleteTemplate)
      }
    })
    .addCase(deleteTemplate.rejected, (state, action) =>
      setResourceRejected(state.deleteTemplate, action)
    )
    .addCase(getCategoryList.pending, state =>
      setResourcePending(state.categoryList)
    )
    .addCase(getCategoryList.fulfilled, (state, action) => {
      state.categoryList.data = action.payload
      setResourceFulfilled(state.categoryList)
    })
    .addCase(getCategoryList.rejected, (state, action) =>
      setResourceRejected(state.categoryList, action)
    )
    .addCase(getTemplates.pending, state => setResourcePending(state.templates))
    .addCase(getTemplates.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.templates)
      state.templates.data = payload
    })
    .addCase(getTemplates.rejected, (state, action) => {
      setResourceRejected(state.templates, action)
      state.templates.data = []
    })
    .addCase(getTemplateStatuses.pending, state =>
      setResourcePending(state.templateStatuses)
    )
    .addCase(getTemplateStatuses.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.templateStatuses)
      state.templateStatuses.data = payload
    })
    .addCase(getTemplateStatuses.rejected, (state, action) =>
      setResourceRejected(state.templateStatuses, action)
    )
    .addCase(uploadTemplateThumbnail.pending, state =>
      setResourcePending(state.uploadTemplateThumbnail)
    )
    .addCase(uploadTemplateThumbnail.fulfilled, (state, { payload }) => {
      if (payload?.templateThumbnail) {
        setResourceFulfilled(state.uploadTemplateThumbnail)
        state.uploadTemplateThumbnail.data = payload
      }
    })
    .addCase(uploadTemplateThumbnail.rejected, (state, action) =>
      setResourceRejected(state.uploadTemplateThumbnail, action)
    )
    .addCase(generateProject.pending, state =>
      setResourcePending(state.generateProject)
    )
    .addCase(generateProject.fulfilled, state => {
      setResourceFulfilled(state.generateProject)
    })
    .addCase(generateProject.rejected, (state, action) =>
      setResourceRejected(state.generateProject, action)
    )
    .addCase(getZipProject.pending, state =>
      setResourcePending(state.getZipProject)
    )
    .addCase(getZipProject.fulfilled, state => {
      setResourceFulfilled(state.getZipProject)
    })
    .addCase(getZipProject.rejected, (state, action) =>
      setResourceRejected(state.getZipProject, action)
    )
    .addCase(clearGetZipProject, state => {
      setResourceIdle(state.getZipProject)
    })
    .addCase(clearGenerateProject, state => {
      setResourceIdle(state.generateProject)
    })
    .addCase(addTemplateCategory.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.addTemplateCategory)
      state.addTemplateCategory.data = payload
    })
    .addCase(addTemplateCategory.pending, state =>
      setResourcePending(state.addTemplateCategory)
    )
    .addCase(addTemplateCategory.rejected, (state, action) => {
      setResourceRejected(state.addTemplateCategory, action)
    })
    .addCase(resetAddCategory, state => {
      setResourceIdle(state.addTemplateCategory)
    })
)
