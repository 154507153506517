import { Overrides } from '@material-ui/core/styles/overrides'
import { DRAWER_WIDTH } from 'shared/config'
import palette from './palette'
import spacing from './spacing'

const overrides: Overrides = {
  MuiChip: {
    root: {
      cursor: 'default',
    },
  },
  MuiCssBaseline: {
    '@global': {
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiButton: {
    root: {
      '&:hover': {
        transition: '0.5s',
      },
    },
    sizeSmall: {
      '& $label': {
        fontSize: '1rem',
      },
      height: 38,
      padding: spacing(0, 4),
    },
    contained: {
      backgroundColor: palette.declined.main,
      '&$disabled': {
        backgroundColor: palette.background.overlay.primary,
      },
      '&:hover': {
        backgroundColor: palette.declined.dark,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: palette.background.neutral.divider,
      height: 3,
    },
  },
  MuiDrawer: {
    paper: {
      boxShadow:
        '0px 2px 3px rgba(0, 0, 0, 0.05), 0px 1px 20px rgba(0, 0, 0, 0.05)',
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow:
        '0px 2px 3px rgba(0, 0, 0, 0.05), 0px 1px 20px rgba(0, 0, 0, 0.05)',
    },
    rounded: { borderRadius: 3 },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: palette.background.neutral.light,
      border: `3px solid ${palette.declined.main}`,
      borderRadius: 3,
      color: palette.accent.main,

      '&$focused ': {
        backgroundColor: palette.background.neutral.light,
        borderColor: palette.primary.main,
      },
      '&$disabled ': {
        backgroundColor: palette.background.neutral.light,
      },
      '&:hover': {
        backgroundColor: palette.background.neutral.light,
      },
      '&$error': {
        borderColor: palette.error.main,
      },
    },
    input: {
      padding: spacing(1.5, 3),
      '&::placeholder': {
        color: palette.declined.contrastText,
        fontWeight: 700,
        fontSize: '0.75rem',
      },
      '&:-webkit-autofill': {
        transition: 'all 5000s ease-in-out 0s',
      },
      '&:autofill': {
        transition: 'all 5000s ease-in-out 0s',
      },
    },
  },
  MuiInputBase: {
    input: {
      padding: spacing(1.2, 0),
    },
  },
  MuiListItem: {
    root: {
      textTransform: 'capitalize',
    },
  },
  MuiDialog: {
    paperWidthXs: { maxWidth: 500 },
    container: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      position: 'absolute',
      right: 0,
    },
    paper: {
      padding: spacing(12, 10),
    },
  },
  MuiFormHelperText: {
    root: {
      position: 'absolute',
      bottom: -20,
      '&$error': {
        color: palette.error.main,
      },
    },
  },
  MuiDialogTitle: {
    root: {
      padding: spacing(0, 0, 7, 0),
    },
  },
  MuiDialogContent: {
    root: {
      padding: spacing(0, 0, 8, 0),
      overflowY: 'unset',
    },
  },
  MuiDialogActions: {
    root: {
      margin: 0,
      justifyContent: 'center',
      padding: 0,
    },
  },
  MuiCardMedia: {
    root: {
      width: '100%',
      height: '100%',
      borderRadius: 3,
    },
  },
}

export default overrides
