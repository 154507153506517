import { createReducer } from '@reduxjs/toolkit'
import {
  openMediaLibraryPicker,
  closeMediaLibraryPicker,
  saveMediaLibraryPicker,
  getMediaLibraryPickerFiles,
  uploadMediaLibraryPickerFiles,
  clearMediaLibraryPicker,
  uploadDrawerMediaLibraryFile,
  resetUploadDrawerMediaLibraryFile,
} from './actions'
import {
  MediaAssetType,
  MultifileSideEffects,
  Resource,
  SelectedMediaLibraryItem,
  WidgetType,
} from 'shared/types'
import {
  defaultMultifileSideEffects,
  getDefaultPaginationResourceState,
  getDefaultResourceState,
  removeDuplicates,
  setResourceFulfilled,
  setResourceIdle,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import { MediaAsset } from 'shared/services'
import { PAGINATION_LIMITS } from 'shared/config'
import { Assets } from './types'

export interface State {
  isOpen: boolean
  assets: Assets
  selected: SelectedMediaLibraryItem[]
  suggestedName?: string
  assetsType?: MediaAssetType
  uploadSideEffects: MultifileSideEffects
  numberOfItems: number
  isRequestHandled: boolean
  assetIdentifier?: string | number | null
  widgetType?: WidgetType
  uploadDrawerMediaLibraryFile: Resource<MediaAsset | null | undefined>
}

const initialState: State = {
  isOpen: false,
  assets: {
    ...getDefaultPaginationResourceState(
      { items: [], categories: [] },
      PAGINATION_LIMITS.mediaAssets
    ),
    total: 0,
  },

  selected: [],
  uploadSideEffects: defaultMultifileSideEffects,
  numberOfItems: 1,
  isRequestHandled: false,
  assetIdentifier: null,
  widgetType: undefined,
  uploadDrawerMediaLibraryFile: getDefaultResourceState(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(openMediaLibraryPicker, (state, { payload }) => {
      state.assetIdentifier = initialState.assetIdentifier
      state.isOpen = true
      state.assetsType = payload.mediaType
      state.widgetType = payload.widgetType
      state.numberOfItems = payload.numberOfItems
      state.selected = []
      state.isRequestHandled = false
      state.suggestedName = undefined
      state.assetIdentifier = payload.assetIdentifier
      setResourceIdle(state.uploadSideEffects)
    })
    .addCase(closeMediaLibraryPicker, state => {
      state.isOpen = false
      setResourceIdle(state.assets)
      state.isRequestHandled = true
      state.assets.data.items = []
      state.assets.data.categories = []
    })
    .addCase(saveMediaLibraryPicker, (state, { payload }) => {
      state.isOpen = false
      setResourceIdle(state.assets)
      state.assets.data.categories = []
      state.selected = payload
      state.assetsType = undefined
      state.isRequestHandled = true
      state.suggestedName = state.assets.data.items.find(
        asset => asset.fileUuid === payload[0].uuid
      )?.filename
      state.assets.data.items = []
    })
    .addCase(getMediaLibraryPickerFiles.pending, (state, action) => {
      setResourcePending(state.assets)
      if (!action.meta.arg.loadMore) state.assets.data.items = []
    })
    .addCase(getMediaLibraryPickerFiles.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.assets)
      state.assets.data.items = [
        ...(payload.loadMore ? state.assets.data.items : []),
        ...payload.data.mediaFiles,
      ]
      state.assets.data.items = removeDuplicates(
        state.assets.data.items,
        file => file.fileUuid
      )
      state.assets.skip = payload.skip
      state.assets.data.categories =
        payload.categories || state.assets.data.categories
      state.assets.total = payload.data.total
    })
    .addCase(getMediaLibraryPickerFiles.rejected, (state, action) =>
      setResourceRejected(state.assets, action)
    )
    .addCase(
      uploadMediaLibraryPickerFiles.pending,
      (state, { meta: files }) => {
        setResourcePending(state.uploadSideEffects)
        state.uploadSideEffects.badFilesCount = 0
        state.uploadSideEffects.numberOfFiles = files.arg.files.length
        state.isOpen = false
        setResourceIdle(state.assets)
        state.assetsType = undefined
      }
    )
    .addCase(uploadMediaLibraryPickerFiles.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.uploadSideEffects)
      state.uploadSideEffects.badFilesCount = payload.badFilesCount
      state.uploadSideEffects.numberOfFiles = payload.numberOfFiles
      state.selected = payload.selected
      state.suggestedName = payload.suggestedName
      state.isRequestHandled = true
    })
    .addCase(uploadMediaLibraryPickerFiles.rejected, (state, action) => {
      const { meta: files } = action
      setResourceRejected(state.uploadSideEffects, action)
      state.uploadSideEffects.badFilesCount = files.arg.files.length
      state.uploadSideEffects.numberOfFiles = files.arg.files.length
      state.isRequestHandled = true
    })
    .addCase(clearMediaLibraryPicker, state => {
      state.isOpen = false
      setResourceIdle(state.assets)
      state.assets.data.items = []
      state.assets.data.categories = []
    })
    .addCase(uploadDrawerMediaLibraryFile.pending, state =>
      setResourcePending(state.uploadDrawerMediaLibraryFile)
    )
    .addCase(uploadDrawerMediaLibraryFile.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.uploadDrawerMediaLibraryFile)
      state.uploadDrawerMediaLibraryFile.data = payload
    })
    .addCase(uploadDrawerMediaLibraryFile.rejected, (state, action) =>
      setResourceRejected(state.uploadDrawerMediaLibraryFile, action)
    )
    .addCase(resetUploadDrawerMediaLibraryFile, state => {
      state.uploadDrawerMediaLibraryFile =
        initialState.uploadDrawerMediaLibraryFile
    })
)
