import { BASE_PATH } from './routes'

const PLAYLIST_PATH = '/playlist'

const playlistPath = (
  submodule: 'list' | 'playlistSchedule',
  deviceId?: number,
  templateId?: number | null,
  aspectRatio?: string
) =>
  `${PLAYLIST_PATH}/${submodule}?deviceId=${deviceId}&templateId=${templateId}&aspectRatio=${aspectRatio}`

const paths = {
  editList: `${BASE_PATH}/edit`,
  editTemplate: (id: number) => `${BASE_PATH}/edit/${id}`,
  list: (deviceId?: number, templateId?: number | null, aspectRatio?: string) =>
    playlistPath('list', deviceId, templateId, aspectRatio),
  scheduleShortcut: (
    deviceId?: number,
    templateId?: number | null,
    aspectRatio?: string
  ) => playlistPath('playlistSchedule', deviceId, templateId, aspectRatio),
}

export default paths
