import { RefObject, useEffect, useRef, useState } from 'react'
import { Widget } from 'shared/types'
import { useAssetUrl } from './useAssetUrl'

const getOptions = (parent?: Element | null) => ({
  root: parent,
  rootMargin: '0px',
  threshold: 0.25,
})

const useMediaLazyload = (
  ref: RefObject<HTMLElement>,
  uuid?: string | null
) => {
  const [visible, setVisible] = useState(false)
  const observer = useRef(
    new IntersectionObserver(intersection => {
      if (!visible && intersection[intersection.length - 1].isIntersecting) {
        setVisible(true)
      }
    }, getOptions(ref.current?.offsetParent))
  )
  useEffect(() => {
    const tileCurrent = ref.current
    const observerCurrent = observer.current
    if (tileCurrent) observerCurrent.observe(tileCurrent)
    return () => {
      if (tileCurrent) observerCurrent.unobserve(tileCurrent)
    }
  }, [ref])
  const assetData = useAssetUrl(uuid, { isAllowedToLoad: visible })
  return { ...assetData, visible }
}

const useCategoryLazyload = (widgetElements: (HTMLElement | null)[]) => {
  const [visibleWidgets, setVisibleWidgets] = useState<Widget['id'][]>([])
  const observer = useRef(
    new IntersectionObserver(intersections => {
      intersections.forEach(intersection => {
        const idParts = (intersection.target.id || '-0').split('-')
        const decodedId = Number(idParts[idParts.length - 1])
        if (
          !visibleWidgets.find(widgetId => widgetId === decodedId) &&
          intersection.isIntersecting
        ) {
          setVisibleWidgets(savedIds => [...savedIds, decodedId])
        }
      })
    }, getOptions(widgetElements[0] ? widgetElements[0].offsetParent : undefined))
  )
  useEffect(() => {
    const observerCurrent = observer.current
    widgetElements.forEach(element => {
      if (element) observerCurrent.observe(element)
    })
    return () => {
      widgetElements.forEach(element => {
        if (element) observerCurrent.unobserve(element)
      })
    }
  }, [observer, widgetElements, widgetElements.length])

  return visibleWidgets
}

export { useCategoryLazyload, useMediaLazyload }
