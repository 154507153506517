import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'shared/store'
import {
  BooleanStringType,
  LayoutSlot,
  Size,
  TextDirection,
} from 'shared/types'
import { percentageToFraction } from 'shared/utils'

const DEFAULT_SIZE = { width: '100%', height: '100%' }

interface Props {
  underline?: BooleanStringType
  width?: number
  duration: number
  maxTextContainerWidth?: number
  widgetSize?: Size
  slotName?: LayoutSlot
  direction?: TextDirection
}

export const useMarqueeStyles = ({
  underline,
  width = 0,
  duration,
  maxTextContainerWidth,
  widgetSize = DEFAULT_SIZE,
  slotName,
  direction,
}: Props) => {
  const slotSizes = useSelector(selectors.utils.getSlotSizes)
  const [textDirection, setTextDirection] = useState<TextDirection | undefined>(
    TextDirection.RightLeft
  )
  const widgetWidth =
    typeof widgetSize.width === 'string'
      ? percentageToFraction(widgetSize.width) *
        slotSizes[slotName || 'main'].width
      : widgetSize.width
  const translateOffset = maxTextContainerWidth
    ? maxTextContainerWidth > width
      ? maxTextContainerWidth
      : width
    : widgetWidth > width
    ? widgetWidth
    : width

  useEffect(() => {
    setTextDirection(
      direction === TextDirection.Auto
        ? width > widgetWidth
          ? TextDirection.RightLeft
          : TextDirection.LeftRight
        : direction
    )
  }, [widgetWidth, direction, width])

  const classes = makeStyles({
    '@keyframes scrolling':
      textDirection === TextDirection.LeftRight
        ? {
            '0%': {
              transform: 'translateX(-100%)',
            },
            '100%': {
              transform: `translateX(${translateOffset}px)`,
            },
          }
        : {
            '0%': {
              transform: `translateX(${translateOffset}px)`,
            },
            '100%': {
              transform: `translateX(-100%)`,
            },
          },
    marquee: {
      display: 'inline-block',
      animation: `$scrolling linear infinite`,
      animationDuration: `${duration}s`,
      textDecoration:
        underline === BooleanStringType.True ? 'underline' : 'none',
      whiteSpace: 'nowrap',
    },
  })

  return classes()
}
