import { Field, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Select } from 'shared/components'
import { DisplayModel } from 'shared/types'
import { useDisplayModelsAndSizes } from 'shared/hooks'

interface DisplayDataFieldsProps {
  displays: DisplayModel[]
}

const DisplayDataFields = ({ displays }: DisplayDataFieldsProps) => {
  const { t } = useTranslation()
  const { displayModels, displaySizes } = useDisplayModelsAndSizes(displays)
  const displayModel = t('displayData.model')
  const displayInches = t('displayData.size')
  const [{ value }] = useField('displayModel')
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Field
          name="displayModel"
          component={Select}
          label={displayModel}
          placeholder={displayModel}
          options={displayModels}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="displayInches"
          component={Select}
          disabled={!value}
          label={displayInches}
          placeholder={displayInches}
          options={displaySizes}
        />
      </Grid>
    </Grid>
  )
}

export default DisplayDataFields
