import { SyntheticEvent, useState } from 'react'

export const useVideoDuration = () => {
  const [duration, setDuration] = useState<number | null>(null)
  const getDuration = (e: SyntheticEvent<HTMLVideoElement>) =>
    (e.target as HTMLVideoElement).duration
  const setVideoDuration = (e: SyntheticEvent<HTMLVideoElement>) =>
    setDuration(getDuration(e))

  return { duration, setVideoDuration }
}
