import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from 'shared/hooks'
import { actions, selectors } from 'shared/store'
import { ErrorType, LoadingStatus } from 'shared/types'

export const useUploadFileModal = () => {
  const { loading, error } = useSelector(
    selectors.mediaPicker.getUploadDrawerMediaLibraryFile
  )
  const isLoading = loading === LoadingStatus.Pending
  const [file, setFile] = useState<File | null>(null)
  const [filename, setFilename] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    undefined
  )
  const { isOpen, open, close } = useModal()

  return {
    loading,
    isLoading,
    error,
    file,
    filename,
    selectedCategory,
    isOpen,
    setFile,
    setFilename,
    setSelectedCategory,
    open,
    close,
  }
}

export const useOnUploadHandler = (
  filename: string,
  file: File | null,
  selectedCategory?: number
) => {
  const dispatch = useDispatch()

  return () =>
    file &&
    selectedCategory &&
    dispatch(
      actions.mediaPicker.uploadDrawerMediaLibraryFile({
        file,
        filename,
        mediaCategoryId: selectedCategory,
      })
    )
}

interface UseUploadFileSideEffectsProps {
  loading: LoadingStatus
  isOpen: boolean
  error?: ErrorType
  close: () => void
  setFilename: Dispatch<SetStateAction<string>>
  setSelectedCategory: Dispatch<SetStateAction<number | undefined>>
}
export const useUploadFileSideEffects = ({
  loading,
  isOpen,
  error,
  close,
  setFilename,
  setSelectedCategory,
}: UseUploadFileSideEffectsProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (loading !== LoadingStatus.Succeeded) return
    close()
    dispatch(actions.mediaPicker.resetUploadDrawerMediaLibraryFile())
  }, [loading, close, dispatch])

  useEffect(() => {
    if (!isOpen) return
    setFilename('')
    setSelectedCategory(undefined)
    dispatch(actions.mediaPicker.resetUploadDrawerMediaLibraryFile())
  }, [isOpen, setFilename, setSelectedCategory, dispatch])

  useEffect(() => {
    if (!error) return
    dispatch(actions.mediaPicker.resetUploadDrawerMediaLibraryFile())
  }, [error, dispatch])
}
