import { createReducer } from '@reduxjs/toolkit'
import { GetWidgetCategoriesResponse, PlaylistCategory } from 'shared/services'
import { CategoryItem, Resource } from 'shared/types'
import {
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import { AddCategoryState, Categories, CategoriesState } from '../types'
import { resetAddCategory, getCategories, addCategory } from './actions'

const defaultCategories: CategoriesState = {
  playlists: [],
  widgets: [],
  templates: [],
}

interface State {
  getCategories: Resource<CategoriesState>
  addCategory: Resource<AddCategoryState>
}

const initialState: State = {
  getCategories: getDefaultResourceState(defaultCategories),
  addCategory: getDefaultResourceState(null),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(resetAddCategory, state => {
      setResourceIdle(state.addCategory)
    })
    .addCase(getCategories.pending, state => {
      setResourcePending(state.getCategories)
    })
    .addCase(getCategories.rejected, (state, action) => {
      setResourceRejected(state.getCategories, action)
    })
    .addCase(getCategories.fulfilled, (state, { meta, payload }) => {
      setResourceFulfilled(state.getCategories)
      switch (meta.arg) {
        case Categories.Playlists: {
          state.getCategories.data.playlists = payload as PlaylistCategory[]
          break
        }
        case Categories.Templates: {
          state.getCategories.data.templates = payload as CategoryItem[]
          break
        }
        case Categories.Widgets: {
          state.getCategories.data.widgets = payload as GetWidgetCategoriesResponse[]
          break
        }
      }
    })
    .addCase(addCategory.pending, state => {
      setResourcePending(state.addCategory)
    })
    .addCase(addCategory.rejected, (state, action) => {
      setResourceRejected(state.addCategory, action)
    })
    .addCase(addCategory.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.addCategory)
      state.addCategory.data = payload
    })
)
