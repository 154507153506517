import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'shared/store'
import { UserScopes } from 'shared/types'

export const useIsUserAllowed = () => {
  const scopes = useSelector(selectors.auth.getUserScopes)
  const userRole = useSelector(selectors.auth.getUserRole)

  return useCallback(
    (right?: UserScopes) =>
      userRole === 'Administrator' || !!scopes?.find(item => item === right),
    [scopes, userRole]
  )
}
