import { Grid } from '@material-ui/core'
import config from 'config'
import { useState } from 'react'
import { useDrag } from 'react-dnd'
import { ExpressiveDialogCard } from 'shared/components'
import {
  DEFAULT_PLAYLIST_ITEM_DURATION,
  PICKER_ITEM_HEIGHT,
} from 'shared/config'
import { MediaAsset } from 'shared/services'
import { SelectedMediaLibraryItem } from 'shared/types'

interface MediaLibraryItemProps extends MediaAsset {
  isInDrawer?: boolean
  fileUuid: string
  onSelect?: (asset: SelectedMediaLibraryItem | null) => void
  isSelected: boolean
  isBlocked: boolean
}

const MediaLibraryItem = ({
  isInDrawer,
  fileUuid,
  onSelect,
  isSelected,
  thumbnail,
  isBlocked,
  filename,
  duration,
}: MediaLibraryItemProps) => {
  const [canDrag, setCanDrag] = useState(false)
  const [, dragRef] = useDrag({
    item: {
      asset: { fileUuid, duration: duration ?? DEFAULT_PLAYLIST_ITEM_DURATION },
      type: config.REORDER_LISTS.MediaLibrary,
    },
    canDrag,
  })

  return (
    <Grid
      item
      ref={isInDrawer ? dragRef : null}
      lg={isInDrawer ? 4 : 2}
      md={isInDrawer ? 4 : 3}
      xs={isInDrawer ? 6 : 4}
      key={`mediaItem-${fileUuid}`}
    >
      <ExpressiveDialogCard
        withDurationLabel
        imageProps={{ duration }}
        thumbnailUuid={thumbnail ?? fileUuid}
        backupUuid={fileUuid}
        isSelected={!!isSelected}
        thumbnailHeight={PICKER_ITEM_HEIGHT}
        title={filename}
        isBlocked={isBlocked}
        minHeight={PICKER_ITEM_HEIGHT}
        onLoad={() => setCanDrag(true)}
        onClick={() => onSelect?.({ uuid: fileUuid, thumbnail })}
      />
    </Grid>
  )
}

export default MediaLibraryItem
