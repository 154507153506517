import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import palette from './palette'

const typography: TypographyOptions = {
  fontFamily: [
    'Nunito Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: '2.375rem',
    fontWeight: 900,
    color: palette.accent.light,
  },
  h2: {
    fontSize: '2.25rem',
    fontWeight: 600,
    lineHeight: '40px',
    color: palette.accent.light,
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 700,
  },
  h5: {
    fontSize: '1.35rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '0.8rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '1.125rem',
  },
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  body2: {
    fontSize: '0.875rem',
  },
  button: {
    textTransform: 'none',
    fontSize: '1.125rem',
    letterSpacing: 0.25,
    fontWeight: 700,
  },
  caption: {
    fontSize: '0.8125rem',
    opacity: '55%',
    lineHeight: '1.2rem',
  },
  fontWeightMedium: 600,
}

export default typography
