import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Lock = (props: SvgIconProps) => (
  <SvgIcon width="18px" height="18px" viewBox="0 0 18 18" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-261.000000, -67.000000)"
        fill="currentColor"
      >
        <g id="Lock" transform="translate(261.000400, 66.999900)">
          <path
            d="M10.378,9.65 L11,14 L7,14 L7.621,9.65 C6.662,9.149 6,8.157 6,7 C6,5.344 7.343,4 9,4 C10.656,4 12,5.344 12,7 C12,8.157 11.338,9.149 10.378,9.65 M16,0 L2,0 C0.897,0 0,0.898 0,2 L0,16 C0,17.103 0.897,18 2,18 L16,18 C17.103,18 18,17.103 18,16 L18,2 C18,0.898 17.103,0 16,0"
            id="Fill-66"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Lock
