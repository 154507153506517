import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Bold = (props: SvgIconProps) => (
  <SvgIcon width="15" height="18" viewBox="0 0 15 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 14.5H4V8.5H8.5C10.1545 8.5 11.5 9.8455 11.5 11.5C11.5 13.1545 10.1545 14.5 8.5 14.5ZM7.75 2.5C8.9905 2.5 10 3.5095 10 4.75C10 5.9905 8.9905 7 7.75 7H4V2.5H7.75ZM10.3802 7.417C11.0702 6.736 11.5 5.79325 11.5 4.75C11.5 2.68225 9.81775 1 7.75 1H1V2.5H2.5V14.5H1V16H8.5C10.9818 16 13 13.9818 13 11.5C13 9.69025 11.9237 8.131 10.3802 7.417Z"
      fill="currentColor"
    />
    <path
      d="M4 14.5H3.5V15H4V14.5ZM4 8.5V8H3.5V8.5H4ZM4 7H3.5V7.5H4V7ZM4 2.5V2H3.5V2.5H4ZM1 1V0.5H0.5V1H1ZM1 2.5H0.5V3H1V2.5ZM2.5 2.5H3V2H2.5V2.5ZM2.5 14.5V15H3V14.5H2.5ZM1 14.5V14H0.5V14.5H1ZM1 16H0.5V16.5H1V16ZM8.5 14H4V15H8.5V14ZM4.5 14.5V8.5H3.5V14.5H4.5ZM4 9H8.5V8H4V9ZM8.5 9C9.87836 9 11 10.1216 11 11.5H12C12 9.56936 10.4306 8 8.5 8V9ZM11 11.5C11 12.8784 9.87836 14 8.5 14V15C10.4306 15 12 13.4306 12 11.5H11ZM7.75 3C8.71436 3 9.5 3.78564 9.5 4.75H10.5C10.5 3.23336 9.26664 2 7.75 2V3ZM9.5 4.75C9.5 5.71436 8.71436 6.5 7.75 6.5V7.5C9.26664 7.5 10.5 6.26664 10.5 4.75H9.5ZM7.75 6.5H4V7.5H7.75V6.5ZM4.5 7V2.5H3.5V7H4.5ZM4 3H7.75V2H4V3ZM10.7315 7.77287C11.5117 7.00279 12 5.93377 12 4.75H11C11 5.65273 10.6288 6.46921 10.029 7.06113L10.7315 7.77287ZM12 4.75C12 2.40611 10.0939 0.5 7.75 0.5V1.5C9.54161 1.5 11 2.95839 11 4.75H12ZM7.75 0.5H1V1.5H7.75V0.5ZM0.5 1V2.5H1.5V1H0.5ZM1 3H2.5V2H1V3ZM2 2.5V14.5H3V2.5H2ZM2.5 14H1V15H2.5V14ZM0.5 14.5V16H1.5V14.5H0.5ZM1 16.5H8.5V15.5H1V16.5ZM8.5 16.5C11.2579 16.5 13.5 14.2579 13.5 11.5H12.5C12.5 13.7056 10.7056 15.5 8.5 15.5V16.5ZM13.5 11.5C13.5 9.48663 12.3025 7.75528 10.5902 6.9632L10.1703 7.8708C11.545 8.50672 12.5 9.89387 12.5 11.5H13.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Bold
