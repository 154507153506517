import { createReducer } from '@reduxjs/toolkit'
import {
  setResourceFulfilled,
  setResourcePending,
  setResourceRejected,
  getDefaultPaginationResourceState,
  defaultSideEffects,
} from 'shared/utils'
import { ApiFont, MediaAssetList, MediaCategory } from 'shared/services'
import {
  PaginationResource,
  SideEffects,
  Resource,
  MediaAssetFilterType,
} from 'shared/types'
import { getDefaultResourceState } from 'shared/utils'
import { PAGINATION_LIMITS } from 'shared/config'
import { DeleteMediaStatus } from '../types'
import {
  getAllMediaCategories,
  getAllMediaFiles,
  setGetAllMediaFilesPage,
  createMediaCategory,
  createMediaFile,
  saveMediaAsset,
  getMediaFiles,
  deleteMediaFile,
  resetGetMediaFiles,
  resetGetAllMediaCategories,
  resetAddNewCategory,
  editMediaCategory,
  resetDeleteMediaFile,
  resetEditCategory,
  resetAddNewMediaFile,
  deleteMediaCategory,
  resetDeleteMediaCategory,
  resetGetAllMediaFiles,
  resetSaveMediaAsset,
  resetDeleteMediaStatus,
  getMediaCategory,
  setSelectedMediaType,
  getFonts,
  uploadFont,
  setAffectedAssets,
} from './actions'

const DEFAULT_MEDIA_FILES_DATA: MediaAssetList = { mediaFiles: [], total: 0 }
export interface State {
  selectedMediaType?: MediaAssetFilterType
  getAllMediaFiles: PaginationResource<MediaAssetList>
  getMediaFiles: PaginationResource<MediaAssetList>
  addNewCategory: SideEffects
  addNewFile: SideEffects
  editCategory: SideEffects
  deleteCategory: SideEffects
  deleteMedia: SideEffects
  deleteMediaStatus: DeleteMediaStatus
  saveMediaFile: SideEffects
  getAllMediaCategories: Resource<MediaCategory[] | undefined>
  getMediaCategory: Resource<MediaCategory[]>
  getFonts: Resource<ApiFont[]>
  uploadFont: SideEffects
  affectedAssets: number
}

const initialState: State = {
  selectedMediaType: undefined,
  getAllMediaFiles: getDefaultPaginationResourceState(
    DEFAULT_MEDIA_FILES_DATA,
    PAGINATION_LIMITS.mediaAssets
  ),
  getMediaFiles: getDefaultPaginationResourceState(
    DEFAULT_MEDIA_FILES_DATA,
    PAGINATION_LIMITS.mediaAssets
  ),
  addNewCategory: defaultSideEffects,
  addNewFile: defaultSideEffects,
  editCategory: defaultSideEffects,
  deleteCategory: defaultSideEffects,
  deleteMedia: defaultSideEffects,
  deleteMediaStatus: {
    code: null,
    result: { fulfilled: 0, all: 0 },
    errorsIndex: [],
  },
  saveMediaFile: defaultSideEffects,
  getAllMediaCategories: getDefaultResourceState([]),
  getMediaCategory: getDefaultResourceState([]),
  getFonts: getDefaultResourceState([]),
  uploadFont: defaultSideEffects,
  affectedAssets: 0,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(setAffectedAssets, (state, { payload }) => {
      state.affectedAssets += payload
    })
    .addCase(setSelectedMediaType, (state, { payload }) => {
      if (state.selectedMediaType === payload) return
      state.getAllMediaFiles.data.mediaFiles =
        initialState.getAllMediaFiles.data.mediaFiles
      state.selectedMediaType = payload
    })
    .addCase(getAllMediaFiles.pending, state =>
      setResourcePending(state.getAllMediaFiles)
    )
    .addCase(getAllMediaFiles.fulfilled, (state, { payload }) => {
      state.getAllMediaFiles.data.mediaFiles = [
        ...state.getAllMediaFiles.data.mediaFiles,
        ...payload.data.mediaFiles,
      ]
      state.getAllMediaFiles.data.total = payload.data.total
      state.getAllMediaFiles.skip = payload.skip
      setResourceFulfilled(state.getAllMediaFiles)
    })
    .addCase(getAllMediaFiles.rejected, (state, action) =>
      setResourceRejected(state.getAllMediaFiles, action)
    )
    .addCase(getMediaCategory.rejected, (state, action) =>
      setResourceRejected(state.getMediaCategory, action)
    )
    .addCase(getMediaCategory.pending, state =>
      setResourcePending(state.getMediaCategory)
    )
    .addCase(getMediaCategory.fulfilled, (state, { payload }) => {
      state.getMediaCategory.data = payload
      setResourceFulfilled(state.getMediaCategory)
    })
    .addCase(getMediaFiles.pending, state =>
      setResourcePending(state.getMediaFiles)
    )
    .addCase(getMediaFiles.fulfilled, (state, { payload }) => {
      state.getMediaFiles.data.mediaFiles = [
        ...state.getMediaFiles.data.mediaFiles,
        ...payload.data.mediaFiles,
      ]
      state.getMediaFiles.data.total = payload.data.total
      state.getMediaFiles.skip = payload.skip
      setResourceFulfilled(state.getMediaFiles)
    })
    .addCase(getMediaFiles.rejected, (state, action) =>
      setResourceRejected(state.getMediaFiles, action)
    )
    .addCase(resetGetAllMediaFiles, state => {
      state.getAllMediaFiles = initialState.getAllMediaFiles
    })
    .addCase(resetGetMediaFiles, state => {
      state.getMediaFiles = initialState.getMediaFiles
    })
    .addCase(setGetAllMediaFilesPage, (state, { payload }) => {
      state.getAllMediaFiles.page = payload
    })
    .addCase(createMediaCategory.fulfilled, state => {
      setResourceFulfilled(state.addNewCategory)
    })
    .addCase(createMediaCategory.pending, state =>
      setResourcePending(state.addNewCategory)
    )
    .addCase(createMediaCategory.rejected, (state, action) =>
      setResourceRejected(state.addNewCategory, action)
    )
    .addCase(createMediaFile.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.addNewFile)
      if (!payload) return
      state.getAllMediaFiles.data.mediaFiles = [
        payload,
        ...state.getAllMediaFiles.data.mediaFiles,
      ]
      state.getAllMediaFiles.data.total += 1
      state.getMediaFiles.data.mediaFiles = [
        payload,
        ...state.getMediaFiles.data.mediaFiles,
      ]
      state.getMediaFiles.data.total += 1
    })
    .addCase(createMediaFile.pending, state => {
      setResourcePending(state.addNewFile)
    })
    .addCase(createMediaFile.rejected, (state, action) =>
      setResourceRejected(state.addNewFile, action)
    )
    .addCase(deleteMediaFile.fulfilled, (state, { payload, meta }) => {
      setResourceFulfilled(state.deleteMedia)
      state.deleteMediaStatus = payload
      let newMeta = [...meta.arg]
      if (payload.errorsIndex.length !== 0)
        newMeta = meta.arg.filter(
          (_, index) => !payload.errorsIndex.includes(index)
        )
      state.getAllMediaFiles.data.mediaFiles = state.getAllMediaFiles.data.mediaFiles.filter(
        file => !newMeta.includes(file.fileUuid)
      )
      state.getAllMediaFiles.data.total -= 1
      state.getMediaFiles.data.mediaFiles = state.getMediaFiles.data.mediaFiles.filter(
        file => !newMeta.includes(file.fileUuid)
      )
      state.getMediaFiles.data.total -= 1
    })
    .addCase(deleteMediaFile.pending, state =>
      setResourcePending(state.deleteMedia)
    )
    .addCase(deleteMediaFile.rejected, (state, action) =>
      setResourceRejected(state.deleteMedia, action)
    )
    .addCase(resetDeleteMediaStatus, state => {
      state.deleteMediaStatus = initialState.deleteMediaStatus
    })
    .addCase(getAllMediaCategories.pending, state =>
      setResourcePending(state.getAllMediaCategories)
    )
    .addCase(getAllMediaCategories.fulfilled, (state, { payload }) => {
      state.getAllMediaCategories.data = payload
      setResourceFulfilled(state.getAllMediaCategories)
    })
    .addCase(getAllMediaCategories.rejected, (state, action) =>
      setResourceRejected(state.getAllMediaCategories, action)
    )
    .addCase(resetGetAllMediaCategories, state => {
      state.getAllMediaCategories = initialState.getAllMediaCategories
    })
    .addCase(saveMediaAsset.fulfilled, (state, { payload }) => {
      state.getAllMediaFiles.data.mediaFiles = state.getAllMediaFiles.data.mediaFiles.map(
        asset =>
          asset.fileUuid === payload.fileUuid ? { ...asset, ...payload } : asset
      )
      if (
        state.getMediaFiles.data.mediaFiles[0]?.mediaCategoryId !==
        payload.mediaCategoryId
      ) {
        state.getMediaFiles.data.mediaFiles = state.getMediaFiles.data.mediaFiles.filter(
          ({ fileUuid }) => fileUuid !== payload.fileUuid
        )
      }

      setResourceFulfilled(state.saveMediaFile)
    })
    .addCase(saveMediaAsset.pending, state =>
      setResourcePending(state.saveMediaFile)
    )
    .addCase(saveMediaAsset.rejected, (state, action) =>
      setResourceRejected(state.saveMediaFile, action)
    )
    .addCase(resetAddNewCategory, state => {
      state.addNewCategory = initialState.addNewCategory
    })
    .addCase(resetSaveMediaAsset, state => {
      state.saveMediaFile = initialState.saveMediaFile
    })
    .addCase(resetEditCategory, state => {
      state.editCategory = initialState.editCategory
    })
    .addCase(resetAddNewMediaFile, state => {
      state.addNewFile = initialState.addNewFile
    })
    .addCase(editMediaCategory.fulfilled, state =>
      setResourceFulfilled(state.editCategory)
    )
    .addCase(editMediaCategory.pending, state =>
      setResourcePending(state.editCategory)
    )
    .addCase(editMediaCategory.rejected, (state, action) =>
      setResourceRejected(state.editCategory, action)
    )
    .addCase(deleteMediaCategory.pending, state =>
      setResourcePending(state.deleteCategory)
    )
    .addCase(deleteMediaCategory.fulfilled, state => {
      setResourceFulfilled(state.deleteCategory)
    })
    .addCase(deleteMediaCategory.rejected, (state, action) =>
      setResourceRejected(state.deleteCategory, action)
    )
    .addCase(resetDeleteMediaCategory, state => {
      state.deleteCategory = initialState.deleteCategory
    })
    .addCase(resetDeleteMediaFile, state => {
      state.deleteMedia = initialState.deleteMedia
    })
    .addCase(getFonts.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getFonts)
      state.getFonts.data = payload
    })
    .addCase(getFonts.pending, state => setResourcePending(state.getFonts))
    .addCase(getFonts.rejected, (state, action) =>
      setResourceRejected(state.getFonts, action)
    )
    .addCase(uploadFont.pending, state => setResourcePending(state.uploadFont))
    .addCase(uploadFont.rejected, (state, action) =>
      setResourceRejected(state.uploadFont, action)
    )
    .addCase(uploadFont.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.uploadFont)
      state.getFonts.data = [...state.getFonts.data, payload.data]
    })
)
