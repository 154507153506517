import { createServiceURL } from 'shared/utils'

const devicesModule = 'devices'
const getDisplaysEndpoint = 'displays/'
const getOTAStatusEndpoint = 'ota_status/'
const createSetParamsEndpoint = (deviceId: number) => `${deviceId}/set_params`

export const displaysEndpoint = createServiceURL(
  devicesModule,
  getDisplaysEndpoint
)

export const otaStatusEndpoint = createServiceURL(
  devicesModule,
  getOTAStatusEndpoint
)

export const createParamsEndpoint = (deviceId: number) =>
  createServiceURL(devicesModule, createSetParamsEndpoint(deviceId))
