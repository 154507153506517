import { SvgIcon, SvgIconProps } from '@material-ui/core'

const VideoStream = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M1.8818,5.8496 L0.3528,4.5606 C2.2608,2.2966 5.0498,0.9976 8.0068,0.9976 C10.9538,0.9976 13.7388,2.2906 15.6458,4.5446 L14.1198,5.8366 C12.5928,4.0326 10.3648,2.9976 8.0068,2.9976 C5.6408,2.9976 3.4088,4.0376 1.8818,5.8496"></path>
    <path d="M11.209,8.6006 C10.444,7.5816 9.277,6.9976 8.007,6.9976 C6.736,6.9976 5.569,7.5816 4.807,8.5976 L3.207,7.3976 C4.334,5.8946 6.128,4.9976 8.007,4.9976 C9.912,4.9976 11.662,5.8736 12.809,7.3996 L11.209,8.6006 Z"></path>
    <path d="M10,22 L2,22 L2,14 L16,14 L16,12 C16,10.896 15.104,10 14,10 L2,10 C0.896,10 0,10.896 0,12 L0,24 L10,24 L10,22 Z"></path>
    <polygon points="13 24 11 20 13 20 13 15 15 15 15 19 18 20 18 24"></polygon>
  </SvgIcon>
)

export default VideoStream
