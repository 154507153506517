import { ListItemIcon, styled, Theme } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles'

const ICON_SIZE = 18
const DEFAULT_ICON_ITEM_HEIGHT = 25

interface ContainerProps {
  open?: boolean
}

export const Container = styled('div')<Theme, ContainerProps>(
  ({ theme, open }) => ({
    '& .MuiListItem-root': {
      padding: theme.spacing(1.5, 4.6),
      transition: theme.transitions.create('width'),
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.background.overlay.dark,
        transition: theme.transitions.create('background', {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
    '& .MuiTypography-button': {
      ...theme.typography.subtitle2,
      lineHeight: '1.125rem',
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: 30,
      height: DEFAULT_ICON_ITEM_HEIGHT,
      display: 'flex',
      alignItems: 'center',
    },

    ...(open && {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      padding: theme.spacing(2, 0),
      margin: 0,

      '& .MuiListItem-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    }),
  })
)

export const DrawerIcon = styled(ListItemIcon)({
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
})

interface NaviElementWrapperProps {
  isActive: boolean
}

export const NaviElementWrapper = styled('div')<Theme, NaviElementWrapperProps>(
  ({ theme, isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1.5, 4.5),
    width: '100%',
    backgroundColor: isActive ? theme.palette.secondary.main : 'unset',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),

    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
    },
  })
)
