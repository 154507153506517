import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Background = (props: SvgIconProps) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3632 9.54544H11.4541V11.4545H13.3632V9.54544Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5459 13.3637H11.455V11.4546H9.5459V13.3637Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3633 13.3637H15.2724V11.4546H13.3633V13.3637Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0909 0H1.90909C0.856227 0 0 0.857182 0 1.90909V3.81818H21V1.90909C21 0.857182 20.1438 0 19.0909 0Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54581 9.54544H7.63672V11.4545H9.54581V9.54544Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.63663 11.4546H5.72754V13.3637H7.63663V11.4546Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.81818 11.4546H5.72727V9.54546H3.81818V7.63637H5.72727V9.54546H7.63636V7.63637H9.54545V9.54546H11.4545V7.63637H13.3636V9.54546H15.2727V7.63637H17.1818V9.54546H15.2727V11.4546H17.1818V13.3636H15.2727V15.2727H13.3636V13.3636H11.4545V15.2727H9.54545V13.3636H7.63636V15.2727H5.72727V13.3636H3.81818V11.4546ZM0 17.1818C0 18.2347 0.856227 19.0909 1.90909 19.0909H19.0909C20.1438 19.0909 21 18.2347 21 17.1818V4.77274H0V17.1818Z"
      fill="#033E54"
    />
  </SvgIcon>
)

export default Background
