import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Magnifier = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 15 15" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.32222 5.80554C2.32222 3.88507 3.88507 2.32222 5.80554 2.32222C7.72602 2.32222 9.28887 3.88507 9.28887 5.80554C9.28887 7.72602 7.72602 9.28887 5.80554 9.28887C3.88507 9.28887 2.32222 7.72602 2.32222 5.80554ZM14.414 12.7722L10.6439 9.00207C11.2523 8.0848 11.6111 6.98755 11.6111 5.80554C11.6111 2.60437 9.00672 0 5.80554 0C2.60437 0 0 2.60437 0 5.80554C0 9.00672 2.60437 11.6111 5.80554 11.6111C6.98755 11.6111 8.0848 11.2523 9.00207 10.6439L12.7722 14.414L14.414 12.7722Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Magnifier
