import { Button, makeStyles, Slider, styled, Theme } from '@material-ui/core'

interface StyledSliderProps {
  urls: string[]
}

const THUMB_ADDITIONAL_PX = 5
const SLIDER_HEIGHT = 30
const THUMB_WIDTH = 10

const reduceImages = (urls: string[]) =>
  urls.reduce((images, url) => `${images}, url("${url}")`, '').slice(2)

const reducePositions = (urls: string[]) =>
  urls
    .reduce(
      (pos, __, index) => `${pos}, ${(index * 100) / (NUM_FRAMES - 1)}% 0px`,
      ''
    )
    .slice(2)

export const NUM_FRAMES = 10

export const StyledSlider = styled(Slider)<Theme, StyledSliderProps>(
  ({ theme, urls }) => ({
    height: SLIDER_HEIGHT,
    '& > .MuiSlider-track': {
      height: SLIDER_HEIGHT,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
    '& > .MuiSlider-thumb.Mui-disabled': {
      marginLeft: -6,
    },
    '& > .MuiSlider-mark': {
      height: 'calc(100% - 8px)',
      marginTop: `calc(${-THUMB_ADDITIONAL_PX}px * 1.5)`,
      borderRadius: 2,
      width: THUMB_WIDTH / 2,
      backgroundColor: theme.palette.secondary.light,
    },
    '& > .MuiSlider-rail': {
      height: SLIDER_HEIGHT,
      backgroundColor: 'none',
      opacity: 1,
      backgroundImage: reduceImages(urls),
      backgroundSize: '10% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: reducePositions(urls),
    },
    '& > .MuiSlider-thumb': {
      height: 'calc(100% - 16px)',
      marginTop: -THUMB_ADDITIONAL_PX,
      borderRadius: 2,
      width: THUMB_WIDTH,
      backgroundColor: theme.palette.secondary.main,
    },
    '& > .MuiSlider-thumb .MuiSlider-valueLabel': {
      top: -22,
      left: 0,
    },
    '& .MuiSlider-valueLabel > span': {
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      transform: 'none',
      width: 'auto',
      height: 'auto',
    },
    '& .MuiSlider-valueLabel > span > span': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      transform: 'none',
    },
    '& > .MuiSlider-thumb:last-of-type .MuiSlider-valueLabel': {
      top: 'calc(100% + 12px)',
      position: 'relative',
    },
    '& > .MuiSlider-thumb:last-of-type .MuiSlider-valueLabel > span > span': {
      position: 'absolute',
      transform: 'translateX(-100%)',
      marginLeft: 5,
    },
  })
)

export const useVideoStyles = makeStyles<Theme, { loading: boolean }>({
  root: ({ loading }) => ({
    objectFit: 'contain',
    height: 300,
    width: '100%',
    display: loading ? 'none' : 'block',
  }),
})

export const IconButton = styled(Button)({
  minWidth: 180,
  '& .MuiSvgIcon-root': {
    marginRight: 5,
  },
})
