import { Redirect } from 'react-router'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { DisplayManagement } from 'shared/icons'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/display-management'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    label: 'navigation.displayManagement.label',
    icon: DisplayManagement,
    path: '/',
    exact: true,
    component: () => <Redirect to={`${BASE_PATH}/displays`} />,
    subItems: addBasePath(BASE_PATH, [
      {
        label: 'navigation.displayManagement.new',
        path: '/new',
      },
      {
        label: 'navigation.displayManagement.displays',
        path: '/displays',
      },
    ]),
  },
  {
    path: '/displays',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayManagement'),
    }),
  },
  {
    path: '/new',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayManagement'),
    }),
  },
  {
    path: '/displays/:id',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayDetails'),
    }),
  },
])

export default routes
