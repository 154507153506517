import { SvgIcon, SvgIconProps } from '@material-ui/core'

const OohMedia = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13L13 11.857V13C13 14.1 12.1 15 11 15H5C3.9 15 3 14.1 3 13V7C3 5.9 3.9 5 5 5H11C12.1 5 13 5.9 13 7V8.143L17 7V13ZM18 0H2C0.896 0 0 0.895 0 2V18C0 19.105 0.896 20 2 20H18C19.104 20 20 19.105 20 18V2C20 0.895 19.104 0 18 0Z"
    />
  </SvgIcon>
)

export default OohMedia
