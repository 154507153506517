import config from 'config'
import { MediaAssetType, WidgetType } from 'shared/types'
import { convertToSelectOptions } from 'shared/utils'

export const useAssetFormats = (
  mediaType?: MediaAssetType,
  widgetType?: WidgetType
) =>
  convertToSelectOptions({
    options: (!mediaType
      ? config.SUPPORTED_MEDIA_ASSET_TYPES.join(', ')
      : widgetType === WidgetType.DirectionArrows
      ? config.SUPPORTED_ARROW_WIDGET_FORMATS
      : config.SUPPORTED_FILE_TYPES[
          mediaType === MediaAssetType.Video ? 'video' : 'image'
        ]
    )
      .replaceAll('.', '')
      .split(', '),
  })
