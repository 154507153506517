import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Image = (props: SvgIconProps) => (
  <SvgIcon width="20" height="21" viewBox="0 0 20 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 13.0034C15 13.5564 14.553 14.0034 14 14.0034H6C5.447 14.0034 5 13.5564 5 13.0034V8.00339C5 7.45039 5.447 7.00339 6 7.00339H6.382C6.761 7.00339 7.107 6.78939 7.276 6.45039L7.724 5.55639C7.893 5.21739 8.239 5.00339 8.618 5.00339H11.382C11.761 5.00339 12.107 5.21739 12.276 5.55639L12.724 6.45039C12.893 6.78939 13.239 7.00339 13.618 7.00339H14C14.553 7.00339 15 7.45039 15 8.00339V13.0034ZM18 0.00338745H2C0.897 0.00338745 0 0.900387 0 2.00339V18.0034C0 19.1064 0.897 20.0034 2 20.0034H18C19.103 20.0034 20 19.1064 20 18.0034V2.00339C20 0.900387 19.103 0.00338745 18 0.00338745Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.00339C8.896 8.00339 8 8.89939 8 10.0034C8 11.1074 8.896 12.0034 10 12.0034C11.104 12.0034 12 11.1074 12 10.0034C12 8.89939 11.104 8.00339 10 8.00339Z"
      fill="#767676"
    />
  </SvgIcon>
)

export default Image
