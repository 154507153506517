import { Box } from '@material-ui/core'
import { Fragment, memo, useRef } from 'react'
import { useMarqueeStyles, useScrollingTextDuration } from 'shared/hooks'
import { GetRssFeedFromUrlResponse } from 'shared/services'
import { LoadingStatus, SelectedFeed, TextBehaviour } from 'shared/types'
import LabeledItemGrid from '../LabeledItemGrid'
import Loader from '../Loader'
import { PreviewContainer, ScrollableContent } from './RssFeedPreview.style'

interface RssFeedPreviewProps {
  loading: LoadingStatus
  data: GetRssFeedFromUrlResponse | null
  selectedRssFeed: SelectedFeed
  errorMessage: string
  maxTextContainerWidth?: number
  drawer?: boolean
}

const RssFeedPreview = ({
  loading,
  data,
  selectedRssFeed,
  errorMessage,
  maxTextContainerWidth = 950,
  drawer = false,
}: RssFeedPreviewProps) => {
  const feed = data?.news[0].title
  const ref = useRef<HTMLDivElement>(null)
  const textContainerWidth = ref.current?.offsetWidth
  const duration = useScrollingTextDuration({
    textBehavior: TextBehaviour.Scrolling,
    textContainerWidth,
    maxTextContainerWidth,
  })
  const classes = useMarqueeStyles({
    duration,
    width: textContainerWidth,
    maxTextContainerWidth,
  })

  if (!selectedRssFeed) return <Fragment />

  switch (loading) {
    case LoadingStatus.Succeeded:
      return (
        <PreviewContainer maxWidth={maxTextContainerWidth} drawer={drawer}>
          <ScrollableContent ref={ref} className={classes.marquee}>
            {feed}
          </ScrollableContent>
        </PreviewContainer>
      )
    case LoadingStatus.Pending:
      return drawer ? <Loader size={30} /> : <Loader />
    case LoadingStatus.Failed:
      return (
        <Box mt={1}>
          <LabeledItemGrid translatedLabel={errorMessage} color="error" />
        </Box>
      )
    default:
      return <Fragment />
  }
}

export default memo(RssFeedPreview)
