import { createReducer } from '@reduxjs/toolkit'
import { GetPlaylistResponse, PlaylistCategories } from 'shared/services'
import {
  Playlist,
  Resource,
  ScheduleComplexObject,
  ScheduleGenerationObjext,
  SideEffects,
} from 'shared/types'
import {
  defaultSideEffects,
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import {
  addPlaylistToSchedule,
  clearPlaylistCreator,
  clearScheduler,
  deletePlaylist,
  deleteSchedule,
  generateScheduleCompleted,
  generateScheduleFailed,
  getDeviceSchedules,
  getPlaylistCategories,
  getPlaylists,
  increasePlaylistCounter,
  initializeSelectingPlaylistForScheduling,
  removePlaylistToSchedule,
  resetDeletePlaylist,
  resetGetPlaylist,
  resetUpdateOrCreateSchedule,
  resetUpdatePlaylist,
  updateOrCreateSchedule,
  updatePlaylist,
} from './actions'

interface State {
  playlists: Resource<GetPlaylistResponse>
  deletePlaylist: SideEffects
  updatePlaylist: Resource<Playlist | null>
  getPlaylistCategories: Resource<PlaylistCategories>
  playlistCounter: number
  getDeviceSchedules: Resource<ScheduleComplexObject[]>
  schedulesBeingGenerated: ScheduleGenerationObjext[]
  playlistsToSchedule: {
    playlists: Playlist[]
    setupForAspectRatio: string | null
  }
  updateOrCreateSchedule: Resource<ScheduleComplexObject | null>
  deleteSchedule: SideEffects
}

const initialState: State = {
  playlists: getDefaultResourceState([]),
  deletePlaylist: defaultSideEffects,
  updatePlaylist: getDefaultResourceState(null),
  getPlaylistCategories: getDefaultResourceState([]),
  playlistCounter: 0,
  getDeviceSchedules: getDefaultResourceState([]),
  schedulesBeingGenerated: [],
  playlistsToSchedule: { playlists: [], setupForAspectRatio: null },
  updateOrCreateSchedule: getDefaultResourceState(null),
  deleteSchedule: defaultSideEffects,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getPlaylists.pending, state => setResourcePending(state.playlists))
    .addCase(getPlaylists.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.playlists)
      state.playlists.data = payload
    })
    .addCase(getPlaylists.rejected, (state, action) => {
      setResourceRejected(state.playlists, action)
    })
    .addCase(resetGetPlaylist, state => {
      setResourceIdle(state.playlists)
      state.playlists.data = []
    })

    .addCase(deletePlaylist.pending, state =>
      setResourcePending(state.deletePlaylist)
    )
    .addCase(deletePlaylist.fulfilled, (state, { meta }) => {
      setResourceFulfilled(state.deletePlaylist)
      state.playlists.data = state.playlists.data.filter(
        ({ idPlaylist }) => idPlaylist !== meta.arg.playlistId
      )
    })
    .addCase(deletePlaylist.rejected, (state, action) =>
      setResourceRejected(state.deletePlaylist, action)
    )

    .addCase(resetDeletePlaylist, state => {
      state.deletePlaylist = initialState.deletePlaylist
    })
    .addCase(updatePlaylist.pending, state =>
      setResourcePending(state.updatePlaylist)
    )
    .addCase(updatePlaylist.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.updatePlaylist)
      state.updatePlaylist.data = payload
    })
    .addCase(updatePlaylist.rejected, (state, action) => {
      setResourceRejected(state.updatePlaylist, action)
    })
    .addCase(resetUpdatePlaylist, state => {
      setResourceIdle(state.updatePlaylist)
    })
    .addCase(getPlaylistCategories.pending, state => {
      setResourcePending(state.getPlaylistCategories)
      state.getPlaylistCategories.data = []
    })
    .addCase(getPlaylistCategories.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getPlaylistCategories)
      state.getPlaylistCategories.data = payload
    })
    .addCase(getPlaylistCategories.rejected, (state, action) =>
      setResourceRejected(state.getPlaylistCategories, action)
    )
    .addCase(increasePlaylistCounter, state => {
      state.playlistCounter++
    })
    .addCase(clearPlaylistCreator, state => {
      setResourceIdle(state.updatePlaylist)
      setResourceIdle(state.playlists)
      setResourceIdle(state.getPlaylistCategories)
      setResourceIdle(state.deletePlaylist)
      state.playlists.data = []
      state.getPlaylistCategories.data = []
      state.updatePlaylist.data = null
    })
    .addCase(getDeviceSchedules.pending, state =>
      setResourcePending(state.getDeviceSchedules)
    )
    .addCase(getDeviceSchedules.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDeviceSchedules)
      state.getDeviceSchedules.data = payload
    })
    .addCase(getDeviceSchedules.rejected, (state, action) => {
      setResourceRejected(state.getDeviceSchedules, action)
    })
    .addCase(updateOrCreateSchedule.pending, state =>
      setResourcePending(state.updateOrCreateSchedule)
    )
    .addCase(updateOrCreateSchedule.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.updateOrCreateSchedule)
      state.updateOrCreateSchedule.data = payload

      const existing = state.getDeviceSchedules.data.find(
        sch => sch.schedule?.idSchedule === payload.schedule.idSchedule
      )
      if (payload.isCreating && payload.taskId) {
        state.schedulesBeingGenerated.push({
          ...payload,
          generationTaskId: payload.taskId,
        })
        const index = state.playlistsToSchedule.playlists.findIndex(
          p => p.idPlaylist === payload.playlist.idPlaylist
        )
        state.playlistsToSchedule.playlists.splice(index, 1)
      }

      if (existing && !payload.isCreating) {
        existing.schedule = payload.schedule
      }
    })
    .addCase(updateOrCreateSchedule.rejected, (state, action) =>
      setResourceRejected(state.updateOrCreateSchedule, action)
    )
    .addCase(resetUpdateOrCreateSchedule, state => {
      setResourceIdle(state.updateOrCreateSchedule)
    })
    .addCase(deleteSchedule.pending, state =>
      setResourcePending(state.getDeviceSchedules)
    )
    .addCase(deleteSchedule.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDeviceSchedules)
      state.getDeviceSchedules.data = state.getDeviceSchedules.data.filter(
        sc => sc.schedule?.idSchedule !== payload.scheduleId
      )
      state.schedulesBeingGenerated = state.schedulesBeingGenerated.filter(
        sc => sc.schedule?.idSchedule !== payload.scheduleId
      )
    })
    .addCase(deleteSchedule.rejected, (state, action) => {
      setResourceRejected(state.getDeviceSchedules, action)
    })
    .addCase(clearScheduler, state => {
      setResourceIdle(state.getDeviceSchedules)
      setResourceIdle(state.updateOrCreateSchedule)
      state.updateOrCreateSchedule.data = null
      state.schedulesBeingGenerated = []
      setResourceIdle(state.deleteSchedule)
    })
    .addCase(generateScheduleCompleted, (state, { payload: scheduleId }) => {
      const generatedIndex = state.schedulesBeingGenerated.findIndex(
        s => s.schedule?.idSchedule === scheduleId
      )
      const generated = state.schedulesBeingGenerated.splice(generatedIndex, 1)
      state.getDeviceSchedules.data.push(generated[0])
    })
    .addCase(generateScheduleFailed, (state, { payload: data }) => {
      const [scheduleId, reason] = data
      const toChange = state.schedulesBeingGenerated.find(
        s => s.schedule?.idSchedule === scheduleId
      )
      if (toChange) toChange.generationFailedReason = reason
    })
    .addCase(addPlaylistToSchedule, (state, { payload }) => {
      state.playlistsToSchedule.playlists.push(payload)
    })
    .addCase(removePlaylistToSchedule, (state, { payload }) => {
      const index = state.playlistsToSchedule.playlists.findIndex(
        p => p.idPlaylist === payload
      )
      state.playlistsToSchedule.playlists.splice(index, 1)
    })
    .addCase(initializeSelectingPlaylistForScheduling, (state, { payload }) => {
      if (payload !== state.playlistsToSchedule.setupForAspectRatio)
        state.playlistsToSchedule.playlists = []
      state.playlistsToSchedule.setupForAspectRatio = payload
    })
)
