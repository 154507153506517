import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { MediaLibrary } from 'shared/icons'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/media-library'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    path: '/',
    label: 'navigation.mediaLibrary',
    icon: MediaLibrary,
    exact: true,

    component: Loadable({
      component: () => import('./containers/MediaLibrary'),
    }),
  },
  {
    path: '/all-files',
    exact: true,
    component: Loadable({
      component: () => import('./containers/MediaLibraryCategory'),
    }),
  },
  {
    path: '/category/:id',
    exact: true,
    component: Loadable({
      component: () => import('./containers/MediaLibraryCategory'),
    }),
  },
  {
    path: '/category/font',
    exact: true,
    component: Loadable({
      component: () => import('./containers/MediaLibraryCategory'),
    }),
  },
])

export default routes
