import { useTranslation } from 'react-i18next'
import { SelectOption, TextDirection } from 'shared/types'

export const useTextDirection = () => {
  const { t } = useTranslation()
  const directionOfMovement: SelectOption[] = [
    { value: TextDirection.RightLeft, label: t('textDirection.toLeft') },
    { value: TextDirection.LeftRight, label: t('textDirection.toRight') },
    { value: TextDirection.Auto, label: t('textDirection.auto') },
  ]

  return directionOfMovement
}
