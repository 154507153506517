import { Box } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'shared/hooks'
import { Filter, FilterType, ListFilterProps, SelectOption } from 'shared/types'
import ClearableField from '../ClearableField'
import { MaterialSelect } from '../Select'

const FILTER_FILED_WIDTH = 200

const ListFilter = ({
  filters: baseFilters,
  priorityFilter,
  onSearch,
  width,
}: ListFilterProps) => {
  const { t } = useTranslation()
  const defaultValue: SelectOption = { label: t('listFilter.all'), value: '' }
  const filters: Filter[] = useMemo(
    () => [
      {
        label: t('listFilter.noFilter'),
        name: 'noFilter',
        type: FilterType.Boolean,
      },
      ...baseFilters,
    ],
    [baseFilters, t]
  )

  const [currentFilter, setCurrentFilter] = useState<Filter>(
    priorityFilter
      ? filters.find(({ name }) => priorityFilter.name === name) ?? filters[0]
      : filters[0]
  )

  const [searchValue, setSearchValue] = useState<any>(
    priorityFilter ? priorityFilter.value : null
  )

  const handleFilterTypeChange = (e: ChangeEvent<{ value: unknown }>) => {
    setCurrentFilter(() => {
      const nextFilter =
        filters.find(
          ({ name: value }) => value === (e.target.value as string)
        ) ?? filters[0]

      if (nextFilter.type === FilterType.Boolean)
        onSearch(nextFilter.name, null)
      else setSearchValue('')

      return nextFilter
    })
  }
  const setValueAndSearch = (value: string) => {
    setSearchValue(value)
    onSearch(currentFilter.name, value)
  }
  const filterBoxWidth =
    currentFilter.type === FilterType.Boolean ? 0 : FILTER_FILED_WIDTH

  const previousPriorityFilter = usePrevious(priorityFilter)
  useEffect(() => {
    if (!priorityFilter && previousPriorityFilter) {
      setSearchValue(null)
      setCurrentFilter(filters[0])
    }
  }, [filters, previousPriorityFilter, priorityFilter])

  return (
    <>
      <Box width={width ?? 175} mr={2}>
        <MaterialSelect
          isDefaultCase
          options={filters.map(({ name, label }) => ({ label, value: name }))}
          value={currentFilter?.name ?? null}
          onChange={handleFilterTypeChange}
          placeholder={t('listFilter.selectFilter')}
          disabled={!!priorityFilter}
        />
      </Box>
      <Box
        width={filterBoxWidth}
        mr={currentFilter.type === FilterType.Boolean ? 0 : 2}
      >
        {currentFilter.type === FilterType.Enum && currentFilter.options && (
          <MaterialSelect
            isDefaultCase
            options={[defaultValue, ...currentFilter.options]}
            value={searchValue}
            onChange={(e: ChangeEvent<{ value: unknown }>) =>
              setValueAndSearch(e.target.value as string)
            }
            disabled={!!priorityFilter}
          />
        )}
        {currentFilter.type === FilterType.String && (
          <ClearableField
            autoFocus
            height={37.5}
            isFormik={false}
            InputProps={{ startAdornment: <Search /> }}
            setValue={setSearchValue}
            disabled={!!priorityFilter}
            value={searchValue}
            placeholder={t('listFilter.searchTerm')}
            onEnter={() => onSearch(currentFilter.name, searchValue)}
            onClear={() => setValueAndSearch('')}
          />
        )}
      </Box>
    </>
  )
}

export default ListFilter
