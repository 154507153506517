import { rest } from 'msw'
import { createHandlerURL } from 'shared/utils'
import { MediaCategory } from './MediaLibrary.types'
import { categoriesEndpoint } from './MediaLibrary.utils'

export const mediaLibraryHandlers = [
  rest.get<{}, MediaCategory[]>(
    createHandlerURL(categoriesEndpoint),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            mediaCategoryId: 1,
            mediaCategory: 'General',
            items: 8,
            firstItemUuid: '2ed8fe51899631c402246f0dc464b5823391d1d2',
          },
          {
            mediaCategoryId: 2,
            mediaCategory: 'Images',
            items: 10,
            firstItemUuid: '115966d945a540d59d2a0b7b45956d1c',
          },
          {
            mediaCategoryId: 3,
            mediaCategory: 'Videos',
            items: 1,
            firstItemUuid: '',
          },
        ])
      )
    }
  ),
]
