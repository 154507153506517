import { createServiceURL } from 'shared/utils'

const designerModule = 'layout_designer'
const getDisplayModelsEndpoint = 'display_models'
const generateProjectEndpoint = 'generate_project'

export const displayModelsEndpoint = createServiceURL(
  designerModule,
  getDisplayModelsEndpoint
)

export const projectEndpoint = createServiceURL(
  designerModule,
  generateProjectEndpoint
)
