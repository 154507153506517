import { styled } from '@material-ui/core'
import { ToggleButton as BaseToggleButton } from '@material-ui/lab'

export const ToggleButton = styled(BaseToggleButton)(({ theme }) => ({
  marginRight: theme.spacing(4),
  borderRadius: '3px !important',
  padding: theme.spacing(0.7, 6),
  fontSize: theme.typography.button.fontSize,
  border: `2px solid ${theme.palette.secondary.main} !important`,
  color: theme.palette.secondary.main,
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    border: 'none !important',
    '&:hover': { backgroundColor: theme.palette.secondary.dark },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.background.table.grey,
    color: theme.palette.text.disabled,
    border: `2px solid ${theme.palette.divider} !important`,
  },
}))
