import { AxiosError } from 'axios'
import {
  AsyncReturnType,
  DeleteStatusCode,
  MediaAssetType,
  PromiseStatus,
} from 'shared/types'
import { ERRORS } from 'shared/utils'
import { Api } from '../api'
import { Assets, GetAssetParameters } from '../assets'
import { CreateMediaFileParameters } from '../index'
import {
  EditMediaCategoryParams,
  EditMediaFileParams,
  GetMediaFilesParams,
  MediaAsset,
  MediaAssetList,
  MediaCategory,
} from './MediaLibrary.types'

const assets = new Assets()
const SUBPATH = '/media_library'

class MediaLibrary extends Api {
  getMediaFiles = async (props: GetMediaFilesParams) => {
    const { skip: prevSkip, limit, loadMore } = props
    const skip = loadMore ? prevSkip + limit : prevSkip
    const { data } = await this.api.get<MediaAssetList>(
      `${SUBPATH}/media_files`,
      { params: { ...props, skip } }
    )

    return { data, skip }
  }

  getMediaFile = async (params: GetAssetParameters) => {
    const { data: mediaLibraryData } = await this.api.get<MediaAsset>(
      `${SUBPATH}/media_file`,
      { params: { mediaUuid: params.mediaUuid } }
    )
    return mediaLibraryData
  }

  getMediaFileWithMetadata = async (params: GetAssetParameters) => {
    const mediaLibraryData = await this.getMediaFile(params)
    const assetInfo = await assets.getAssetInfo(params.mediaUuid)
    const asset = await assets.getAssetUrl({
      mediaUuid:
        assetInfo.type === MediaAssetType.Video
          ? mediaLibraryData.thumbnail
          : params.mediaUuid,
    })
    return { ...asset, ...assetInfo, ...mediaLibraryData }
  }

  editMediaFile = async (params: EditMediaFileParams) => {
    const { data } = await this.api.put(`${SUBPATH}/media_file`, null, {
      params,
    })
    return data
  }

  createMediaCategory = async (mediaCategory: string) => {
    const data = await this.api.post(`${SUBPATH}/media_categories`, null, {
      params: { mediaCategory },
    })
    return data
  }

  createMediaFile = async ({
    mediaCategoryId,
    filename,
    file,
  }: CreateMediaFileParameters) => {
    if (!file) return

    const formData = new FormData()
    formData.append('file', file, filename)
    const { data } = await this.api.post<MediaAsset>(
      `${SUBPATH}/media_file`,
      formData,
      {
        params: { mediaCategoryId: mediaCategoryId || undefined }, // guard for NaN value
      }
    )

    return data
  }

  editMediaCategory = async (params: EditMediaCategoryParams) => {
    await this.api.put(`${SUBPATH}/media_categories`, null, {
      params,
    })
  }

  deleteMediaCategory = async (mediaCategoryId: number) => {
    await this.api.delete(`${SUBPATH}/media_categories`, {
      params: { mediaCategoryId },
    })
  }

  getAllMediaCategories = async () => {
    try {
      const { data } = await this.api.get<MediaCategory[]>(
        `${SUBPATH}/media_categories`
      )
      return data
    } catch (err) {
      const message = (err as AxiosError).response?.data.detail
      const code = (err as AxiosError).response?.status

      if (message !== 'Media categories not found' && code === 404) throw err
    }
  }

  getMediaCategory = async (mediaCategoryId: number) => {
    const { data } = await this.api.get<MediaCategory[]>(
      `${SUBPATH}/media_categories`,
      { params: { mediaCategoryId } }
    )

    return data
  }

  deleteMediaFile = (mediaUuids: string[]) => {
    const data = Promise.allSettled(
      mediaUuids.map(mediaUuid =>
        this.api.delete<string>(`${SUBPATH}/media_file`, {
          params: { mediaUuid },
        })
      )
    ).then(data => {
      const rejectedPromisesIndex = data
        .map((item, index) => ({ index: index, ...item }))
        .filter(el => el.status === PromiseStatus.Rejected)
        .map(promise => promise.index)
      const fulfilledPromises = data.length - rejectedPromisesIndex.length

      if (fulfilledPromises === 0)
        return {
          code: DeleteStatusCode.Error,
          errorsIndex: rejectedPromisesIndex,
        }
      else if (fulfilledPromises === 1 && data.length === 1) {
        return {
          code: DeleteStatusCode.SuccessSingle,
          errorsIndex: [],
        }
      } else
        return {
          code: DeleteStatusCode.SuccessPartial,
          result: { fulfilled: fulfilledPromises, all: data.length },
          errorsIndex: rejectedPromisesIndex,
        }
    })

    return data
  }

  uploadWidgetAsset = async (file: File, path?: string) => {
    const {
      data: { fileUuid, alreadyExists },
    } = await assets.uploadAsset({ file, fileName: file.name, path })
    const { thumbnail } = await this.getMediaFile({
      mediaUuid: fileUuid,
    })

    return { thumbnail, fileUuid, alreadyExists }
  }

  uploadMultipleWidgetAssets = async (
    files: FileList,
    numberOfItems: number,
    path?: string
  ) => {
    const length = files.length
    const suggestedName = files[0]?.name
    const uploaded = await Promise.allSettled(
      Array.from(files)
        .slice(0, numberOfItems)
        .map(file => this.uploadWidgetAsset(file, path))
    )
    const suceeded = uploaded.filter(
      item => item.status === 'fulfilled'
    ) as PromiseFulfilledResult<
      AsyncReturnType<typeof this.uploadWidgetAsset>
    >[]

    if (suceeded.length === 0) throw new Error(ERRORS.couldNotUploadFiles)
    return {
      suggestedName,
      numberOfFiles: length,
      badFilesCount: length - suceeded.length,
      selected: suceeded.map(item => ({
        uuid: item.value.fileUuid,
        thumbnail: item.value.thumbnail,
      })),
    }
  }
}

export default MediaLibrary
