import { ButtonProps } from '@material-ui/core'
import { Button as BaseButton, styled } from '@material-ui/core'

const Button = styled(BaseButton)(({ theme }) => ({
  padding: theme.spacing(1, 7),
  position: 'relative',
  whiteSpace: 'nowrap',
}))

const DefaultButton = ({ children, ...props }: ButtonProps) => {
  return <Button {...props}>{children}</Button>
}

export default DefaultButton
