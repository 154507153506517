import { List } from '@material-ui/core'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationItem } from 'shared/types'
import SidebarItem from '../SidebarItem'

interface MenuItemsProps {
  items: NavigationItem[]
}
const MenuItems = ({ items }: MenuItemsProps) => {
  const { t } = useTranslation()

  return (
    <List>
      {items.map(item => (
        <SidebarItem key={`key-${item.path}`} {...item}>
          {t(item.label)}
        </SidebarItem>
      ))}
    </List>
  )
}

export default memo(MenuItems)
