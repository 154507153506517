import { rest } from 'msw'
import { createHandlerURL } from 'shared/utils'
import { LoginPayload, LoginResponse, loginURL } from './Api.utils'

export const apiHandlers = [
  rest.post<LoginPayload, LoginResponse>(
    createHandlerURL(loginURL),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          accessToken: 'DEFAULT_TOKEN',
          tokenType: 'Mocked',
        })
      )
    }
  ),
]
