import { Api } from '../index'
import {
  GetPlaylistParams,
  GetPlaylistResponse,
  UpdatePlaylistDto,
  GetScheduledPlaylistsInDeviceParams,
  GetScheduledPlaylistsInDeviceResponse,
  RemovePlaylistScheduleFromDeviceParams,
  PlaylistCategories,
  GetScheduleParams,
  GetScheduleResponse,
  ChangeScheduleDto,
  addPlaylistCategoryBody,
} from './Playlist.types'
import {
  GetDisplayPlaylistsParams,
  Playlist as PlaylistType,
  Schedule,
  ScheduleBackgroundUpdateMessage,
  ScheduleComplexId,
} from 'shared/types'

const SUBPATH = 'layout_designer_playlists'

class Playlist extends Api {
  getPlaylists = async (params: GetPlaylistParams) => {
    const { data } = await this.api.get<GetPlaylistResponse>(
      `${SUBPATH}/playlists`,
      { params }
    )

    return data
  }

  deletePlaylist = async (playlistId: number) => {
    const { data } = await this.api.delete(`${SUBPATH}/playlists`, {
      params: { playlistId },
    })

    return data
  }

  updateOrCreatePlaylist = async (
    dto: UpdatePlaylistDto,
    playlistId?: number
  ) => {
    const { data } = await (playlistId
      ? this.api.put<PlaylistType>(`${SUBPATH}/playlists`, dto, {
          params: { playlistId },
        })
      : this.api.post<PlaylistType>(`${SUBPATH}/playlists`, dto))
    return data
  }

  getPlaylistCategories = async () => {
    const { data } = await this.api.get<PlaylistCategories>(
      `${SUBPATH}/playlist_categories`
    )

    return data
  }

  addPlaylistScheduleToDevice = async (complexId: ScheduleComplexId) =>
    await this.api.post<string>(`${SUBPATH}/display_playlist`, null, {
      params: complexId,
    })

  getPlaylistGenerationStatus = async (taskId: string) => {
    const { data } = await this.api.get<ScheduleBackgroundUpdateMessage>(
      `${SUBPATH}/generation_playlist_status`,
      {
        params: { taskId },
      }
    )
    return data
  }

  removePlaylistScheduleFromDevice = (
    params: RemovePlaylistScheduleFromDeviceParams
  ) =>
    this.api.delete(`${SUBPATH}/display_playlist`, {
      params,
    })

  getScheduledPlaylistsInDevice = async (
    params: GetScheduledPlaylistsInDeviceParams
  ) => {
    const { data } = await this.api.get<GetScheduledPlaylistsInDeviceResponse>(
      `${SUBPATH}/display_playlist`,
      {
        params,
      }
    )

    return data
  }

  getSchedule = async (params: GetScheduleParams) => {
    const { data } = await this.api.get<GetScheduleResponse>(
      `${SUBPATH}/schedule`,
      { params }
    )

    return data
  }

  removeSchedule = async (id: number) =>
    this.api.delete(`${SUBPATH}/schedule`, {
      params: { scheduleId: id },
    })

  addSchedule = async (dto: ChangeScheduleDto) => {
    const { data } = await this.api.post<Schedule>(`${SUBPATH}/schedule`, dto)

    return data
  }

  updateSchedule = async (id: number, dto: ChangeScheduleDto) => {
    const { data } = await this.api.put<Schedule>(`${SUBPATH}/schedule`, dto, {
      params: { scheduleId: id },
    })

    return data
  }

  addPlaylistCategory = async (category: addPlaylistCategoryBody) => {
    const { data } = await this.api.post(
      `${SUBPATH}/playlist_categories`,
      category
    )

    return data
  }

  getDisplayPlaylists = async (params: GetDisplayPlaylistsParams) => {
    const { data } = await this.api.get(`${SUBPATH}/display_playlist`, {
      params,
    })

    return data
  }
}

export default Playlist
