import {
  Box,
  CircularProgress,
  Typography as BaseTypography,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'

interface CircularProgressWithLabelProps {
  value: number
}

const Typography = styled(BaseTypography)({
  fontSize: '0.8rem',
})

const CircularProgressWithLabel = ({
  value,
}: CircularProgressWithLabelProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={50} variant="determinate" value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle2" color="textSecondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
