import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { string } from 'yup'
import {
  ProgressStep,
  ApiWidget,
  LayoutVariant,
  WidgetsCategory,
  Resource,
  Orientation,
  EditTemplateDetails,
  Size,
  AbsoluteSize,
  WidgetType,
  LayoutSlot,
  Widget,
  DirectionArrowWidgetPropertyName,
} from 'shared/types'
import { WidgetManager } from 'shared/services'
import {
  selectors as sharedSelector,
  actions as sharedActions,
} from 'shared/store'
import { COLOR_REGEXP, ERRORS, getSplitPaneSize } from 'shared/utils'
import { actions, selectors } from '../store'
import { MODULE_NAME } from '../strings'
import {
  DEFAULT_CATEGORY,
  DEFAULT_WIDGET_SIZE,
  MAX_COLOR_LENGTH,
} from '../config'

export const useFormStep = () => {
  const dispatch = useDispatch()
  const activeStep = useSelector(selectors.getActiveStep)

  const setActiveStep = (step: ProgressStep) =>
    dispatch(actions.setCurrentStepValue(step))

  return { activeStep, setActiveStep }
}

export const useSelectedLayout = () => {
  const dispatch = useDispatch()
  const variant = useSelector(selectors.getVariant)
  const defaultTemplate = useSelector(selectors.getDefaultTemplate)
  const setSelectedLayout = useCallback(
    (variant: LayoutVariant, defaultTemplate: number | null) =>
      dispatch(actions.setSelectedLayout({ variant, defaultTemplate })),
    [dispatch]
  )

  return useMemo(() => ({ variant, defaultTemplate, setSelectedLayout }), [
    defaultTemplate,
    setSelectedLayout,
    variant,
  ])
}
export interface WidgetCategories<T> {
  [key: string]: T[]
}

export const mapWidgetsToCategories = <T extends ApiWidget<WidgetType>>(
  widgets: T[],
  categoriesResource: Resource<WidgetsCategory[]>
): WidgetCategories<T> => {
  const initialCategories: WidgetCategories<T> = {}

  return widgets.reduce((categories, widget) => {
    const category =
      categoriesResource.data.find(
        category => category.idWidgetCategory === widget.idWidgetCategory
      )?.widgetCategoryName || DEFAULT_CATEGORY

    return {
      ...categories,
      [category]: [...(categories[category] || []), widget],
    }
  }, initialCategories)
}

export const parseAspectRatio = (aspectRatio: string) => {
  const aspectRatioArr = aspectRatio.split(':')
  if (aspectRatioArr.length !== 2)
    throw new Error(ERRORS.wrongAspectRatioFormat)

  return aspectRatioArr.map(Number).reduce((a, b) => a / b)
}

interface GetTooltipProps {
  stepIndex?: ProgressStep
  isNextButton?: boolean
}

export const useGetNavigationTooltips = () => {
  const { t } = useTranslation(MODULE_NAME)
  const isWidgetMenuOpen = useSelector(selectors.isWidgetMenuOpen)
  const { inProgress } = useSelector(selectors.getEditMode)
  const {
    isLayoutEditable,
    isInEditMode,
    template,
    hasAnyCollisions,
    defaultTemplate,
    activeStep,
  } = useSelector(selectors.getUseGetNavigationDisabledTooltip)

  const getDisabledTooltip = ({ stepIndex, isNextButton }: GetTooltipProps) => {
    const goingTo =
      stepIndex ??
      ((isNextButton ? activeStep + 1 : activeStep - 1) as ProgressStep)

    if (isWidgetMenuOpen || inProgress)
      return t('drawer.stepper.cannotSwitchStep')
    if (hasAnyCollisions) return t('drawer.stepper.widgetsCantOverlap')
    if (isInEditMode) return t('drawer.stepper.cannotNavigateDuringEditMode')
    if (
      template &&
      Number(template.editionProgressStep) !== ProgressStep.LayoutSelection &&
      goingTo === ProgressStep.LayoutSelection
    )
      return t('drawer.stepper.cannotEditLayoutAfterSave')
    if (isLayoutEditable && goingTo === ProgressStep.LayoutSelection)
      return t('drawer.stepper.cannotEditLayout')
    return ''
  }

  const getTooltip = ({ stepIndex }: GetTooltipProps) => {
    if (
      defaultTemplate &&
      stepIndex !== ProgressStep.LayoutSelection &&
      activeStep === ProgressStep.LayoutSelection
    )
      return t('defaultLayouts.lockInStep2')
    return ''
  }

  return { getDisabledTooltip, getTooltip }
}

export const parseScreenResolution = ({
  resolution,
  orientation,
}: Pick<EditTemplateDetails, 'resolution' | 'orientation'>) => {
  if (!resolution) return null
  const deviceSize = resolution.split(/[xX]/).map(Number)
  if (deviceSize.length !== 2) throw new Error(ERRORS.wrongResolutionFormat)

  return {
    deviceWidth:
      orientation === Orientation.Landscape ? deviceSize[0] : deviceSize[1],
    deviceHeight:
      orientation === Orientation.Landscape ? deviceSize[1] : deviceSize[0],
  }
}

export const useMediaSize = (mediaSize?: AbsoluteSize): Size => {
  const scale = useSelector(selectors.getLayoutPreviewScale)
  if (!mediaSize) return DEFAULT_WIDGET_SIZE
  else
    return {
      width: mediaSize.width / scale,
      height: mediaSize.height / scale,
    }
}

export const useRemoveWidget = (slot?: LayoutSlot | null) => {
  const dispatch = useDispatch()
  return (widget?: Widget | null) => {
    if (!widget || !slot) return
    dispatch(
      actions.removeWidget({
        id: widget.id,
        slot,
      })
    )
  }
}

export const useScaleWithSplitPane = (slot: LayoutSlot) => {
  const {
    orientation,
    layoutPreviewSize,
    variant,
    deviceSize,
    activeStep,
  } = useSelector(selectors.getUseScaleWithSplitPane)
  const splitPaneSize = getSplitPaneSize(
    variant,
    slot,
    orientation,
    activeStep === ProgressStep.LayoutSelection
  )

  return {
    x:
      (layoutPreviewSize.width - splitPaneSize.x) /
      (orientation === Orientation.Landscape ? deviceSize[0] : deviceSize[1]),
    y:
      (layoutPreviewSize.height - splitPaneSize.y) /
      (orientation === Orientation.Landscape ? deviceSize[1] : deviceSize[0]),
  }
}

export const useOptionalColorValidation = () => {
  const { t } = useTranslation()
  return string()
    .max(MAX_COLOR_LENGTH, t('validation.wrongFormat'))
    .matches(COLOR_REGEXP, t('validation.wrongFormat'))
}

export const useColorValidation = () => {
  const { t } = useTranslation()
  return useOptionalColorValidation().required(t('validation.required'))
}

export const changeSvgColor = (svgCode: string, color: string) =>
  svgCode
    .replace(/fill=".*?"/g, `fill="${color}"`)
    .replace(/fill:.*?;/g, `fill:${color};`)
    .replace(/stroke=".*?"/g, `stroke="${color}"`)

export const useReloadSvgArrow = (
  color: string | null,
  loading: boolean,
  isArrowSelected?: boolean
) => {
  const [reloadArrow, setReloadArrow] = useState<string | number>('')
  const reload = () => {
    if (loading && !isArrowSelected) return
    setReloadArrow(0)
    setReloadArrow(Math.random())
  }

  useEffect(() => {
    if (!isArrowSelected && loading) return
    if (color && color?.length === 7) reload()
    if (!color) reload()
  }, [color, isArrowSelected])

  return reloadArrow
}

export const getArrowUpId = (widget: Widget<WidgetType.DirectionArrows>) =>
  WidgetManager.getProperty(
    DirectionArrowWidgetPropertyName.upImg,
    widget.properties
  )

export const getArrowDownId = (widget: Widget<WidgetType.DirectionArrows>) =>
  WidgetManager.getProperty(
    DirectionArrowWidgetPropertyName.downImg,
    widget.properties
  )

export const useCategoryList = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(sharedSelector.templates.getCategoryList)

  useEffect(() => {
    dispatch(sharedActions.templates.getCategoryList())
  }, [dispatch])
  const categoryOptions = data.map(option => ({
    value: option.id,
    label: option.name,
  }))

  return categoryOptions
}
