import { styled, Theme } from '@material-ui/core'
import Loader from '../Loader'
import RequiredActionInfo from './RequiredActionInfo'

interface OverlayProps {
  zIndex?: number
  fullWindow: boolean
}
const Overlay = styled('div')<Theme, OverlayProps>(
  ({ theme, zIndex, fullWindow }) => ({
    position: 'absolute',
    width: fullWindow ? '100vw' : '100%',
    height: fullWindow ? '100vh' : '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.overlay.loading,
    zIndex: zIndex || theme.zIndex.appOverlay.default,
  })
)

interface AppOverlayProps extends Partial<OverlayProps> {
  hasLoader?: boolean
  isVisible: boolean
  isActionRequiredInDrawer?: boolean
}
const AppOverlay = ({
  hasLoader = true,
  isVisible,
  zIndex,
  isActionRequiredInDrawer,
  fullWindow = true,
}: AppOverlayProps) =>
  isVisible ? (
    <Overlay zIndex={zIndex} fullWindow={fullWindow}>
      {hasLoader && <Loader isFullHeight />}
      {isActionRequiredInDrawer && <RequiredActionInfo />}
    </Overlay>
  ) : null

export default AppOverlay
