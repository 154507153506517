import { Grid, Box, styled, Typography, Theme, Link } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { isSafari } from 'shared/utils'

export const FormWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  padding: theme.spacing(12, 14),
  borderRadius: theme.spacing(1),
  color: theme.palette.primary.light,

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10),
  },
}))

export const FormTextWrapperGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(10),
}))

export const ForgetPasswordLink = styled(Link)(({ theme }) => ({
  width: 'fit-content',
  opacity: 0.8,
  display: 'block',
  marginTop: theme.spacing(3.5),
  '&:hover': {
    opacity: 1,
  },
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
}))

export const FormTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  marginBottom: theme.spacing(3),
}))

export const StyledTextField = styled(TextField)<Theme>(({ theme }) => {
  const isSafariBrowser = isSafari()

  return {
    '& .MuiFilledInput-input': {
      '&::placeholder': {
        color: `${theme.palette.primary.light} !important`,
        opacity: 1,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      fontWeight: 600,

      ...(!isSafariBrowser && {
        '&::placeholder': {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      }),
    },
  }
})
