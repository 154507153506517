import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DragIndicator = (props: SvgIconProps) => (
  <SvgIcon width="15" height="23" viewBox="0 0 15 23" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 5C1.11875 5 0 3.8825 0 2.5C0 1.11875 1.11875 0 2.5 0C3.88 0 5 1.11875 5 2.5C5 3.8825 3.88 5 2.5 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 13.75C1.11875 13.75 0 12.6325 0 11.25C0 9.86875 1.11875 8.75 2.5 8.75C3.88 8.75 5 9.86875 5 11.25C5 12.6325 3.88 13.75 2.5 13.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 22.5C1.11875 22.5 0 21.3825 0 20C0 18.6187 1.11875 17.5 2.5 17.5C3.88 17.5 5 18.6187 5 20C5 21.3825 3.88 22.5 2.5 22.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 5C11.1187 5 10 3.8825 10 2.5C10 1.11875 11.1187 0 12.5 0C13.88 0 15 1.11875 15 2.5C15 3.8825 13.88 5 12.5 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 13.75C11.1187 13.75 10 12.6325 10 11.25C10 9.86875 11.1187 8.75 12.5 8.75C13.88 8.75 15 9.86875 15 11.25C15 12.6325 13.88 13.75 12.5 13.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 22.5C11.1187 22.5 10 21.3825 10 20C10 18.6187 11.1187 17.5 12.5 17.5C13.88 17.5 15 18.6187 15 20C15 21.3825 13.88 22.5 12.5 22.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default DragIndicator
