import { Suspense, lazy } from 'react'
import { Loader as DefaultLoader, LoaderProps } from 'shared/components'
import ErrorBoundary, { ErrorBoundaryProps } from './ErrorBoundary'

interface LoadableOptions extends ErrorBoundaryProps {
  component(): Promise<{ default: React.ComponentType<any> }>
  loader?<T extends LoaderProps>(props: T): JSX.Element | null
}

const Loadable = ({
  component,
  errorHandler,
  loader: Loader,
  ...errorBoundaryProps
}: LoadableOptions) => {
  const Component = lazy(component)
  return <T extends {}>(componentProps: T) => (
    <ErrorBoundary errorHandler={errorHandler} {...errorBoundaryProps}>
      <Suspense fallback={Loader ? <Loader /> : DefaultLoader}>
        <Component {...componentProps} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default Loadable
