import { createReducer } from '@reduxjs/toolkit'
import { getDisplayModels } from './actions'
import { DisplayModel, Resource } from 'shared/types'
import {
  getDefaultResourceState,
  setResourceFulfilled,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'

interface State {
  displayModels: Resource<DisplayModel[]>
}

const initialState: State = {
  displayModels: getDefaultResourceState([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getDisplayModels.pending, state =>
      setResourcePending(state.displayModels)
    )
    .addCase(getDisplayModels.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.displayModels)
      state.displayModels.data = payload
    })
    .addCase(getDisplayModels.rejected, (state, action) =>
      setResourceRejected(state.displayModels, action)
    )
)
