import { Button, DialogActions, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ExpressiveDialog, ExpressiveDialogTitle } from 'shared/components'
import { PICKER_DIALOG_MAX_SIZE } from 'shared/config'
import { useWindowSize } from 'shared/hooks'
import { MediaAssetType, WidgetType } from 'shared/types'
import { useMediaLibraryPicker } from './MediaLibraryPicker.utils'
import MediaLibraryPickerContent from './MediaLibraryPickerContent'

interface MediaLibraryPickerProps {
  onClose: () => void
  onGoBack: () => void
  isOpen: boolean
  numberOfItems: number
  mediaType?: MediaAssetType
  widgetType?: WidgetType
}

const MediaLibraryPicker = (props: MediaLibraryPickerProps) => {
  const { onGoBack, onClose, isOpen, numberOfItems } = props
  const { t } = useTranslation()
  const { height } = useWindowSize()
  const { onAdd, onSelect, selected } = useMediaLibraryPicker(
    numberOfItems,
    onGoBack
  )

  return (
    <ExpressiveDialog
      maxWidth={false}
      width={PICKER_DIALOG_MAX_SIZE}
      maxHeight={height}
      open={isOpen}
      onClose={onClose}
    >
      <ExpressiveDialogTitle
        title={t('mediaLibraryModal.selectionTitle', {
          count: numberOfItems,
        })}
        subtitle={t('mediaLibraryModal.pickerSubtitle', {
          count: numberOfItems,
        })}
      />
      <MediaLibraryPickerContent
        {...props}
        onSelect={onSelect}
        selected={selected}
      />
      <DialogActions>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Button
              color="secondary"
              disabled={selected.length === 0}
              fullWidth
              onClick={() => onAdd(selected)}
            >
              {t('mediaLibraryModal.addSelected')}
            </Button>
          </Grid>

          <Grid item>
            <Button fullWidth onClick={onGoBack}>
              {t(`mediaLibraryModal.cancel`)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </ExpressiveDialog>
  )
}

export default MediaLibraryPicker
