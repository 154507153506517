import {
  Breadcrumbs as BaseBreadcrumbs,
  styled,
  Typography,
} from '@material-ui/core'

const LinkWrapper = styled('span')(({ theme }) => ({
  '& > a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))

interface BreadcrumbsProps {
  breadcrumbs: JSX.Element[]
  separator: string | JSX.Element
}
const Breadcrumbs = ({ breadcrumbs, separator }: BreadcrumbsProps) => (
  <BaseBreadcrumbs separator={separator}>
    {breadcrumbs.map(item => (
      <Typography variant="h5">
        <LinkWrapper>{item}</LinkWrapper>
      </Typography>
    ))}
  </BaseBreadcrumbs>
)

export default Breadcrumbs
