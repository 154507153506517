import { Position } from 'react-rnd'
import { DEFAULT_WIDGET_OFFSET } from 'shared/config'
import {
  LayoutVariant,
  Orientation,
  SlotWidgets,
  SlotSizes,
  LayoutSlot,
  AbsoluteSize,
  WidgetType,
  Size,
  WidgetProperty,
  LayoutWidget,
  MetadataName,
  ApiWidget,
  SlotLayoutScales,
} from 'shared/types'
import {
  addIfNotContained,
  checkRectCollision,
  getRandomString,
  percentageToFraction,
} from 'shared/utils'
import {
  CalcWidgetPositionProperties,
  CalcWidgetSizeProperties,
  MapWidgetsConfigObject,
} from './WidgetManager.types'

class WidgetManager {
  static addWidget = (
    widgets: LayoutWidget[],
    widget: LayoutWidget,
    slot: LayoutSlot
  ): LayoutWidget[] => {
    const metadataSlotName = { name: MetadataName.slotName, value: slot }
    const metadataLiveVideoType = widget.layoutProperties.videoType && {
      name: MetadataName.liveVideoType,
      value: widget.layoutProperties.videoType,
    }
    const metadata = [metadataSlotName, metadataLiveVideoType].filter(
      item => item
    )
    widget.metadata = metadata

    switch (widget.widgetType) {
      case WidgetType.Background:
        return [
          ...widgets.filter(w => w.widgetType !== WidgetType.Background),
          widget,
        ]
      default:
        return [...widgets, widget]
    }
  }

  static getProperty = <T extends WidgetProperty>(
    name: T['name'],
    items: T[]
  ): T['value'] | null => items.find(i => i.name === name)?.value || null

  static createWidgetId = (index: number, prefix?: string): string => {
    return `${prefix || 'unknown'}-widget-${index}-${getRandomString()}`
  }

  static convertSize(slotSize: AbsoluteSize, size: Size): AbsoluteSize {
    return {
      width:
        slotSize.width *
        (typeof size.width === 'string'
          ? percentageToFraction(size.width)
          : size.width),
      height:
        slotSize.height *
        (typeof size.height === 'string'
          ? percentageToFraction(size.height)
          : size.height),
    }
  }

  static handleWidgetsCollisions = (
    transformedIndex: number,
    widgets: LayoutWidget[],
    slotSize: AbsoluteSize
  ) => {
    const transformedWidget = widgets[transformedIndex]
    if (transformedWidget.widgetType === WidgetType.Background) return widgets

    const transformedPosition = transformedWidget.position
    const transformedSize = WidgetManager.convertSize(
      slotSize,
      transformedWidget.size
    )

    const collisionList: LayoutWidget['isInCollisionWith'] = []

    const mappedWidgets = widgets.map((widget, index) => {
      const widgetSize = WidgetManager.convertSize(slotSize, widget.size)
      if (
        transformedSize &&
        transformedPosition &&
        index !== transformedIndex &&
        widget.widgetType !== WidgetType.Background
      ) {
        const isInCollision = checkRectCollision(
          transformedSize,
          transformedPosition,
          widgetSize,
          widget.position
        )
        if (isInCollision) collisionList.push(widget.id)

        return {
          ...widget,
          isInCollisionWith: isInCollision
            ? addIfNotContained(transformedWidget.id, widget.isInCollisionWith)
            : widget.isInCollisionWith.filter(
                collidingId => collidingId !== transformedWidget.id
              ),
        }
      } else return widget
    })

    transformedWidget.isInCollisionWith = collisionList
    return mappedWidgets
  }

  public static getSlotOffset = (
    slotSizes: SlotSizes,
    selectedLayout: LayoutVariant,
    slotName: LayoutSlot,
    orientation: Orientation
  ) => {
    const landscape: Record<LayoutVariant, Record<string, Position>> = {
      [LayoutVariant.Fullscreen]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: DEFAULT_WIDGET_OFFSET,
      },
      [LayoutVariant.DividedVertically]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: { x: 0, y: slotSizes.main.height },
      },
      [LayoutVariant.TripleLeft]: {
        main: { x: slotSizes.sidebar.width, y: 0 },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: { x: 0, y: slotSizes.main.height },
      },
      [LayoutVariant.TripleRight]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: { x: slotSizes.main.width, y: 0 },
        footer: { x: 0, y: slotSizes.main.height },
      },
      [LayoutVariant.DoubleLeft]: {
        main: { x: slotSizes.sidebar.width, y: 0 },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: DEFAULT_WIDGET_OFFSET,
      },
      [LayoutVariant.DoubleRight]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: { x: slotSizes.main.width, y: 0 },
        footer: { x: 0, y: slotSizes.main.height },
      },
    }
    const portrait: Record<LayoutVariant, Record<string, Position>> = {
      [LayoutVariant.Fullscreen]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: DEFAULT_WIDGET_OFFSET,
      },
      [LayoutVariant.DividedVertically]: {
        main: { x: slotSizes.sidebar.width, y: 0 },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: DEFAULT_WIDGET_OFFSET,
      },
      [LayoutVariant.TripleLeft]: {
        main: { x: slotSizes.sidebar.width, y: slotSizes.footer.height },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: { x: slotSizes.sidebar.width, y: 0 },
      },
      [LayoutVariant.TripleRight]: {
        main: { x: slotSizes.sidebar.width, y: 0 },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: { x: slotSizes.sidebar.width, y: slotSizes.main.height },
      },
      [LayoutVariant.DoubleLeft]: {
        main: { x: 0, y: slotSizes.footer.height },
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: DEFAULT_WIDGET_OFFSET,
      },
      [LayoutVariant.DoubleRight]: {
        main: DEFAULT_WIDGET_OFFSET,
        sidebar: DEFAULT_WIDGET_OFFSET,
        footer: { x: 0, y: slotSizes.main.height },
      },
    }

    const widgetOffset = {
      landscape,
      portrait,
    }

    return widgetOffset[orientation][selectedLayout][slotName]
  }

  private calcScaledWidgetPosition = ({
    position = { x: 0, y: 0 },
    scales,
    offset,
  }: CalcWidgetPositionProperties) => ({
    positionX: Math.abs(position.x + offset.x) * scales.width,
    positionY: Math.abs(position.y + offset.y) * scales.height,
  })

  private calcScaledWidgetSize = ({
    widgetSize = { width: '100%', height: '100%' },
    slotSizes,
    slotName,
    scales,
  }: CalcWidgetSizeProperties) => {
    const slotSize = slotSizes[slotName]
    const returnValue =
      typeof widgetSize.width === 'string' &&
      typeof widgetSize.height === 'string'
        ? {
            width:
              slotSize.width *
              percentageToFraction(widgetSize.width as string) *
              scales.width,
            height:
              slotSize.height *
              percentageToFraction(widgetSize.height as string) *
              scales.height,
          }
        : {
            width: slotSize.width * scales.width,
            height: slotSize.height * scales.height,
          }

    return returnValue
  }

  private mapWidgets = ({
    slotName,
    widgets,
    slotSizes,
    selectedLayout,
    scales,
    orientation,
  }: MapWidgetsConfigObject<LayoutWidget[], SlotLayoutScales['footer']>) =>
    widgets.map(({ position, size, isInCollisionWith, ...widget }) => ({
      ...widget,
      ...this.calcScaledWidgetPosition({
        position,
        scales,
        offset: WidgetManager.getSlotOffset(
          slotSizes,
          selectedLayout,
          slotName,
          orientation
        ),
      }),
      ...this.calcScaledWidgetSize({
        widgetSize: size,
        slotSizes,
        slotName,
        scales,
      }),
    }))

  public mapWidgetsToApiWidgets = ({
    widgets,
    slotSizes,
    selectedLayout,
    scales,
    orientation,
  }: MapWidgetsConfigObject<SlotWidgets, SlotLayoutScales>) => {
    const widgetsSections = Object.entries(widgets)
    const mappedWidgets = widgetsSections
      .map(([slotName, widgets]) =>
        this.mapWidgets({
          slotName,
          widgets,
          slotSizes,
          selectedLayout,
          scales: scales[slotName as LayoutSlot],
          orientation,
        } as MapWidgetsConfigObject<LayoutWidget[], SlotLayoutScales['footer']>)
      )
      .flat()

    return mappedWidgets
  }

  static convertWidgetType = (
    widget: Omit<ApiWidget<WidgetType>, 'id'>,
    index: number
  ): LayoutWidget => ({
    ...widget,
    id: `${widget.widgetType}-widget-${index}`,
    size: { width: widget.width, height: widget.height },
    position: { x: widget.positionX, y: widget.positionY },
    isInCollisionWith: [],
    layoutProperties: {},
  })
}

export default WidgetManager
