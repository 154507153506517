import { CircularProgress, CircularProgressProps } from '@material-ui/core'
import { DEFAULT_LOADER_SIZE } from 'shared/config'
import { Container } from './Loader.style'

export interface LoaderProps extends CircularProgressProps {
  size?: number
  isFullHeight?: boolean
}

const Loader = ({
  size = DEFAULT_LOADER_SIZE,
  isFullHeight = false,
  ...props
}: LoaderProps) => (
  <Container isFullHeight={isFullHeight} data-testid="Loader">
    <CircularProgress size={size} {...props} />
  </Container>
)

export default Loader
