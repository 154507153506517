import {
  FloorLabel,
  GetDevicesResponse,
  EditTemplateDetailsAPI,
} from 'shared/types'

export enum GetDevicesParametersNames {
  buildingName = 'buildingName',
  buildingAddress = 'buildingAddress',
  buildingCity = 'buildingCity',
  displayModel = 'displayModel',
  macAddress = 'macAddress',
  connectionStatus = 'connectionStatus',
  tags = 'tags',
  displayId = 'displayId',
  inches = 'inches',
  aspectRatio = 'aspectRatio',
  displayOrientation = 'displayOrientation',
}

export interface GetDevicesParameters {
  [GetDevicesParametersNames.buildingName]?: string
  [GetDevicesParametersNames.displayOrientation]?: string
  [GetDevicesParametersNames.buildingAddress]?: string
  [GetDevicesParametersNames.buildingCity]?: string
  [GetDevicesParametersNames.displayModel]?: string
  [GetDevicesParametersNames.macAddress]?: string
  [GetDevicesParametersNames.connectionStatus]?: boolean | string
  [GetDevicesParametersNames.tags]?: string[]
  [GetDevicesParametersNames.displayId]?: number
  [GetDevicesParametersNames.inches]?: string
  [GetDevicesParametersNames.aspectRatio]?: string
}

export interface UpdateDeviceParameters {
  currentTemplateId: number | null
  lastSentTemplateId: number | null
  navigationBox: boolean
  description: string
  tags: { tag: string }[]
  numberStops: number
  audioLanguage: string
  floorLabels: FloorLabel[]
}

export interface ExtendedUpdateDeviceParameters extends UpdateDeviceParameters {
  lastSentTemplate: EditTemplateDetailsAPI | null
}

export type UpdateFormDeviceParameters = Omit<
  UpdateDeviceParameters,
  'lastSentTemplateId' | 'currentTemplateId'
>

export interface GetEcosystemEventsParameters {
  id: number
  eventFrom?: string // YYYY-MM-DD
  eventTo?: string // YYYY-MM-DD
  skip?: number // default 0
  limit?: number // default 100
}

export interface RequestDeviceScreenshotResponse {
  taskId: string
}

export interface TagsResponse {
  tagId: number
  tag: string
  deviceId: number
}

interface GetDeviceScreenshotResponseItem {
  id: number
  idDevice: number
  filename: string
  uuid: string
  deviceDateposted: string
  dateposted: string
  category: string
}

export type GetDeviceScreenshotResponse = GetDeviceScreenshotResponseItem[]

export interface UpdateOTAParams {
  templateId: number
  deviceId: number
  template?: EditTemplateDetailsAPI
  refreshLastSent?: (
    id: GetDevicesResponse['lastSentTemplateId'],
    thumbnail: GetDevicesResponse['lastSentTemplateThumbnail']
  ) => void
}

export interface DeleteDisplayTagRequest {
  idDevice?: number
  tag: string
}
