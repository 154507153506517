import { LayoutSlot } from '../config'
import {
  ArrowPositionVariant,
  BackgroundWidgetPropertyName,
  BooleanStringType,
  DateTimeWidgetPropertyName,
  DirectionArrowWidgetPropertyName,
  FlippingImagesWidgetPropertyName,
  ImagesByFloorWidgetPropertyName,
  ImageWidgetPropertyName,
  LiftStatusPropertyName,
  LiveVideoWidgetPropertyName,
  MetadataName,
  PictogramEventContentType,
  PictogramWidgetPropertyName,
  PlaylistWidgetPropertyName,
  PositionIndicatorTextPropertyName,
  RssFeedWidgetPropertyName,
  Styles,
  TextAlignment,
  TextWidgetPropertyName,
  VideoWidgetPropertyName,
  WeatherWidgetPropertyName,
  WidgetType,
} from '../enums'

export type MetadataPropertyValues = LayoutSlot | LiveVideoWidgetPropertyName

export type MetaDataProperty =
  | Property<MetadataName, MetadataPropertyValues>
  | undefined

export interface Property<T extends string, K> {
  name: T
  value: K
}

export type FlippingImagesWidgetProperty = Property<
  FlippingImagesWidgetPropertyName,
  number | string | string[]
>

export type ImagesByFloorWidgetProperty = Property<
  ImagesByFloorWidgetPropertyName,
  number | string | (string | false)[]
>

export type ImageWidgetProperty = Property<
  ImageWidgetPropertyName,
  string | false
>

export type BackgroundWidgetProperty = Property<
  BackgroundWidgetPropertyName,
  string
>

export type TextWidgetProperty = Property<
  TextWidgetPropertyName,
  string | BooleanStringType
>

export type DateTimeWidgetProperty = Property<
  DateTimeWidgetPropertyName,
  string | BooleanStringType
>

export type PositionIndicatorTextProperty = Property<
  PositionIndicatorTextPropertyName,
  BooleanStringType | string
>

export type LiftStatusProperty = Property<
  LiftStatusPropertyName,
  BooleanStringType | string
>

export type VideoWidgetProperty = Property<VideoWidgetPropertyName, string>

export type LiveVideoWidgetProperty = Property<
  LiveVideoWidgetPropertyName,
  string
>

export type DirectionArrowWidgetProperty = Property<
  DirectionArrowWidgetPropertyName,
  string | ArrowPositionVariant
>

export type WeatherWidgetProperty = Property<WeatherWidgetPropertyName, string>

export type RssFeedWidgetProperty = Property<RssFeedWidgetPropertyName, string>

export type PictogramWidgetProperty = Property<
  PictogramWidgetPropertyName,
  string | number
>

export type PlaylistWidgetProperty = Property<
  PlaylistWidgetPropertyName,
  string | number
>

export interface TextVisualizerProperties {
  font?: string
  fontSize?: number
  backgroundColor?: string
  color?: string
  alignment?: TextAlignment
  [Styles.Bold]?: BooleanStringType
  [Styles.Italic]?: BooleanStringType
  [Styles.Underline]?: BooleanStringType
  [Styles.Uppercase]?: BooleanStringType
}

export interface PictogramFormElement {
  eventType: PictogramEventContentType | null
  eventContent: string //imageUuid or plain text
  eventIndex: number
  width: number
  height: number
}

export type WidgetProperty =
  | ImageWidgetProperty
  | TextWidgetProperty
  | DateTimeWidgetProperty
  | VideoWidgetProperty
  | BackgroundWidgetProperty
  | DirectionArrowWidgetProperty
  | PositionIndicatorTextProperty
  | LiftStatusProperty
  | LiveVideoWidgetProperty
  | FlippingImagesWidgetProperty
  | ImagesByFloorWidgetProperty
  | WeatherWidgetProperty
  | RssFeedWidgetProperty
  | PictogramWidgetProperty
  | PlaylistWidgetProperty

export type PropertiesMap = {
  [WidgetType.Image]: ImageWidgetProperty
  [WidgetType.Text]: TextWidgetProperty
  [WidgetType.DateTime]: DateTimeWidgetProperty
  [WidgetType.Background]: BackgroundWidgetProperty
  [WidgetType.Video]: VideoWidgetProperty
  [WidgetType.DirectionArrows]: DirectionArrowWidgetProperty
  [WidgetType.LiftStatus]: LiftStatusProperty
  [WidgetType.PositionIndicatorText]: PositionIndicatorTextProperty
  [WidgetType.LiveVideo]: LiveVideoWidgetProperty
  [WidgetType.FlippingImages]: FlippingImagesWidgetProperty
  [WidgetType.ImagesByFloor]: ImagesByFloorWidgetProperty
  [WidgetType.Weather]: WeatherWidgetProperty
  [WidgetType.RssFeed]: RssFeedWidgetProperty
  [WidgetType.Pictogram]: PictogramWidgetProperty
  [WidgetType.Playlist]: PlaylistWidgetProperty
}
