import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'
import {
  LoadingStatus,
  MediaAssetType,
  SelectedMediaLibraryItem,
  WidgetType,
} from 'shared/types'
import { mapAcceptedFileTypes } from 'shared/utils'

interface UseMediaLibraryPickerContentPropsProps {
  onSelect?: (asset: SelectedMediaLibraryItem | null) => void
  mediaType?: MediaAssetType
}
export const useMediaLibraryPickerContent = ({
  onSelect,
  mediaType,
}: UseMediaLibraryPickerContentPropsProps) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()
  const {
    categories,
    items,
    loading,
    totalNumberOfItems,
    widgetType,
  } = useSelector(selectors.mediaPicker.getMediaLibraryPickerState)

  const [selectedCategory, setSelectedCategory] = useState<number | undefined>()

  useEffect(() => {
    if (items.length === 0 && loading === LoadingStatus.Idle) {
      dispatch(
        actions.mediaPicker.getMediaLibraryPickerFiles({
          mediaType,
          mediaCategoryId: selectedCategory,
          willFetchCategories: true,
          fileExtensions: mapAcceptedFileTypes({
            widgetType,
            parseForApi: true,
          }),
        })
      )
    }
  }, [])

  const onChange = (
    e: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    setSelectedCategory(e.target.value as number)
    onSelect?.(null)
    setQuery('')
    dispatch(
      actions.mediaPicker.getMediaLibraryPickerFiles({
        mediaType,
        mediaCategoryId: e.target.value as number,
        loadMore: false,
        willFetchCategories: false,
      })
    )
  }

  const onSearch = (filename: string, extensions?: string[]) => {
    const fileExtensions =
      extensions && extensions.length > 0
        ? extensions?.join(',')
        : widgetType === WidgetType.DirectionArrows
        ? mapAcceptedFileTypes({ widgetType, parseForApi: true })
        : undefined

    return dispatch(
      actions.mediaPicker.getMediaLibraryPickerFiles({
        mediaType,
        mediaCategoryId: selectedCategory,
        loadMore: false,
        willFetchCategories: false,
        filename,
        fileExtensions,
      })
    )
  }

  const onLoadMore = () =>
    dispatch(
      actions.mediaPicker.getMediaLibraryPickerFiles({
        mediaType,
        mediaCategoryId: selectedCategory,
        loadMore: true,
        willFetchCategories: false,
      })
    )

  const categoryOptions = [
    {
      value: undefined,
      label: t('mediaLibraryModal.allFiles'),
    },
    ...categories.map(category => ({
      label: category.mediaCategory,
      value: category.mediaCategoryId,
    })),
  ]

  return {
    query,
    items,
    loading,
    selectedCategory,
    categoryOptions,
    totalNumberOfItems,
    setQuery,
    onSearch,
    onChange,
    onLoadMore,
  }
}
