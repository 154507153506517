import { rest } from 'msw'
import {
  BackgroundWidgetPropertyName,
  DateTimeWidgetPropertyName,
  DirectionArrowWidgetPropertyName,
  ExtendedSlideshowPropertyName,
  ImageWidgetPropertyName,
  MediaTransformationKind,
  MetadataName,
  Orientation,
  PlaylistWidgetPropertyName,
  ProgressStep,
  SlideshowPropertyName,
  EditTemplateDetails,
  EditTemplateDetailsAPI,
  TextWidgetPropertyName,
  WidgetType,
} from 'shared/types'
import { createHandlerURL } from 'shared/utils'
import { GetTemplatesQueryProps } from './Templates.types'
import { templatesEndpoint } from './Templates.utils'

export const MOCKED_DEFAULT_TEMPLATES: {
  [key: string]: {
    [key: string]: {
      landscape: EditTemplateDetailsAPI[]
      portrait: EditTemplateDetailsAPI[]
    }
  }
} = {
  ATOM: {
    '15.0': {
      landscape: [
        {
          id: 1889,
          formatId: 0,
          formatName: 'test',
          name: 'DEFAULT_L1',
          baseTemplateId: 2,
          sections: [
            { name: 'footer', sizeX: 1024.0, sizeY: 192.0 },
            { name: 'main', sizeX: 1024.0, sizeY: 576.0 },
            { name: 'sidebar', sizeX: 0.0, sizeY: 0.0 },
          ],
          editionProgressStep: ProgressStep.LayoutSelection,
          categoryId: 29,
          categoryName: 'Ar',
          tags: [],
          displayModel: 'ATOM',
          resolution: '1024x768',
          displayInches: '15.0',
          displayAspectRatio: '4:3',
          orientation: Orientation.Landscape,
          metric: 'px',
          templateThumbnail: 'a5cd47870b10496abfbbcd3ee9731170',
          widgets: [
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 1024.0,
              height: 576.0,
              name: '',
              thumbnail: '',
              actions: [
                {
                  name: MediaTransformationKind.Crop,
                  value: "{'x':0,'y':97.75,'width':1880,'height':1057.5}",
                },
                { name: MediaTransformationKind.Resize, value: '1024x576' },
              ],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                {
                  name: BackgroundWidgetPropertyName.img,
                  value: '12d9e991088a4b1c922dcdab1866a694',
                },
              ],
              positionX: 0.0,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.DirectionArrows,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 152.0,
              height: 152.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                { name: DirectionArrowWidgetPropertyName.color, value: '' },
                {
                  name: DirectionArrowWidgetPropertyName.downImg,
                  value: 'cd021bdcdafe455b91c15ce680c26442',
                },
                {
                  name: DirectionArrowWidgetPropertyName.upImg,
                  value: 'cd021bdcdafe455b91c15ce680c26442',
                },
              ],
              positionX: 0.0,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 1024.0,
              height: 192.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'footer' }],
              properties: [
                { name: BackgroundWidgetPropertyName.color, value: '#DD6C17' },
              ],
              positionX: 0.0,
              positionY: 576.0,
            },
          ],
          created: '2022-03-23T16:51:22',
          lastUpdate: '2022-03-23T16:52:35',
          projectGenerated: null,
          mediaOnly: false,
        },

        {
          id: 1890,
          formatId: 0,
          formatName: 'test',
          name: 'DEFAULT_LAYOUT_2',
          baseTemplateId: 3,
          sections: [
            { name: 'footer', sizeX: 1024.0, sizeY: 192.0 },
            { name: 'main', sizeX: 406.123167273543, sizeY: 576.0 },
            { name: 'sidebar', sizeX: 617.876832726457, sizeY: 576.0 },
          ],
          editionProgressStep: ProgressStep.LayoutSelection,
          categoryId: 1,
          categoryName: 'BCN Layouts',
          tags: [],
          displayModel: 'ATOM',
          resolution: '1024x768',
          displayInches: '15.0',
          displayAspectRatio: '4:3',
          orientation: Orientation.Landscape,
          metric: 'px',
          templateThumbnail: '916d64797ac64be980039c8b8c73d587',
          widgets: [
            {
              widgetType: WidgetType.Text,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 405.804,
              height: 147.073,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                { name: TextWidgetPropertyName.alignment, value: 'left' },
                { name: TextWidgetPropertyName.color, value: '' },
                { name: TextWidgetPropertyName.bold, value: 'True' },
                { name: TextWidgetPropertyName.color, value: '#000000' },
                { name: TextWidgetPropertyName.content, value: 'DEFAULT' },
                {
                  name: TextWidgetPropertyName.font,
                  value: 'd609435c8d62423d867a94ca9fd0aab2',
                },
                { name: TextWidgetPropertyName.fontSize, value: '64' },
                { name: TextWidgetPropertyName.italic, value: 'False' },
                { name: TextWidgetPropertyName.scrollingText, value: 'fixed' },
                { name: TextWidgetPropertyName.underline, value: 'False' },
                { name: TextWidgetPropertyName.uppercase, value: 'False' },
              ],
              positionX: 617.877,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.Image,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 617.877,
              height: 411.808,
              name: '',
              thumbnail: '',
              actions: [
                { name: MediaTransformationKind.Resize, value: '618x412' },
              ],
              metadata: [{ name: MetadataName.slotName, value: 'sidebar' }],
              properties: [
                {
                  name: ImageWidgetPropertyName.img,
                  value: '22d558bee68f405aaf87ab430c57170f',
                },
              ],
              positionX: 0.0,
              positionY: 112.284,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 617.877,
              height: 576.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'sidebar' }],
              properties: [
                { name: BackgroundWidgetPropertyName.color, value: '#1BB0C4' },
              ],
              positionX: 0.0,
              positionY: 0.0,
            },
          ],
          created: '2022-03-23T18:29:17',
          lastUpdate: '2022-03-23T18:30:54',
          projectGenerated: null,
          mediaOnly: false,
        },

        {
          id: 1891,
          formatId: 0,
          formatName: 'test',
          name: 'DEFAULT_L3',
          baseTemplateId: 5,
          sections: [
            { name: 'footer', sizeX: 0.0, sizeY: 0.0 },
            { name: 'main', sizeX: 768.0, sizeY: 768.0 },
            { name: 'sidebar', sizeX: 256.0, sizeY: 768.0 },
          ],
          editionProgressStep: ProgressStep.LayoutSelection,
          categoryId: 3,
          categoryName: 'Avire',
          tags: [],
          displayModel: 'ATOM',
          resolution: '1024x768',
          displayInches: '15.0',
          displayAspectRatio: '4:3',
          orientation: Orientation.Landscape,
          metric: 'px',
          templateThumbnail: 'a24eda4123654150ac046a73b47790e3',
          widgets: [
            {
              widgetType: WidgetType.Playlist,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 192.0,
              height: 256.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                { name: PlaylistWidgetPropertyName.aspectRatio, value: '3:4' },
              ],
              positionX: 256.0,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.DateTime,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 84.8477,
              height: 28.614,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                { name: DateTimeWidgetPropertyName.color, value: '' },
                { name: DateTimeWidgetPropertyName.color, value: '#000000' },
                {
                  name: DateTimeWidgetPropertyName.content,
                  value: 'Wed Mar 23 2022',
                },
                {
                  name: DateTimeWidgetPropertyName.dateTimeFormat,
                  value: 'dd-MM-YY',
                },
                {
                  name: DateTimeWidgetPropertyName.font,
                  value: 'd609435c8d62423d867a94ca9fd0aab2',
                },
                { name: DateTimeWidgetPropertyName.fontSize, value: '16' },
              ],
              positionX: 431.118,
              positionY: 388.006,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 768.0,
              height: 768.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                { name: BackgroundWidgetPropertyName.color, value: '#EABB19' },
                {
                  name: BackgroundWidgetPropertyName.gradient,
                  value: '#D0640B',
                },
              ],
              positionX: 256.0,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 256.0,
              height: 768.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'sidebar' }],
              properties: [
                { name: BackgroundWidgetPropertyName.color, value: '#0FA66D' },
                {
                  name: BackgroundWidgetPropertyName.gradient,
                  value: '#22A1B3',
                },
              ],
              positionX: 0.0,
              positionY: 0.0,
            },
          ],
          created: '2022-03-23T18:32:07',
          lastUpdate: '2022-03-23T18:33:26',
          projectGenerated: null,
          mediaOnly: false,
        },
      ],
      portrait: [
        {
          id: 1944,
          formatId: 0,
          formatName: 'test',
          name: 'DEFAULT TEST PORTRAIT',
          baseTemplateId: 3,
          sections: [
            { name: 'footer', sizeX: 576.0, sizeY: 256.0 },
            { name: 'main', sizeX: 576.0, sizeY: 768.0 },
            { name: 'sidebar', sizeX: 192.0, sizeY: 1024.0 },
          ],
          editionProgressStep: ProgressStep.LayoutSelection,
          categoryId: 3,
          categoryName: 'Avire',
          tags: [],
          displayModel: 'ATOM',
          resolution: '1024x768',
          displayInches: '15.0',
          displayAspectRatio: '4:3',
          orientation: Orientation.Portrait,
          metric: 'px',
          templateThumbnail: 'fae8b12cfb094eeab4ac32d092e12da4',
          widgets: [
            {
              widgetType: WidgetType.FlippingImages,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 250.0,
              height: 250.0,
              name: '',
              thumbnail: '',
              actions: [
                { name: MediaTransformationKind.Scale, value: '250x250' },
              ],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                {
                  name: SlideshowPropertyName.backgroundColor,
                  value: '#79d282',
                },
                {
                  name: SlideshowPropertyName.backgroundTransparency,
                  value: '1',
                },
                {
                  name: SlideshowPropertyName.imageList,
                  value:
                    '["6390a8243a0f4ce9a142a8e8047291b2", "b761a29993cd454091cd3a5c0c8121a4"]',
                },
                {
                  name: ExtendedSlideshowPropertyName.transitionTime,
                  value: '2',
                },
              ],
              positionX: 397.11,
              positionY: 680.771,
            },
            {
              widgetType: WidgetType.Image,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 576.0,
              height: 384.204,
              name: '',
              thumbnail: '',
              actions: [
                { name: MediaTransformationKind.Resize, value: '576x384' },
              ],
              metadata: [{ name: MetadataName.slotName, value: 'main' }],
              properties: [
                {
                  name: ImageWidgetPropertyName.img,
                  value: '0c068c0dbf9a4d64bc43393ebb58f896',
                },
              ],
              positionX: 192.0,
              positionY: 258.092,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 192.0,
              height: 1024.0,
              name: '',
              thumbnail: '',
              actions: [
                {
                  name: MediaTransformationKind.Crop,
                  value: "{'x':822.53125,'y':0,'width':234.9375,'height':1253}",
                },
                { name: MediaTransformationKind.Resize, value: '192x1024' },
              ],
              metadata: [{ name: MetadataName.slotName, value: 'sidebar' }],
              properties: [
                {
                  name: BackgroundWidgetPropertyName.img,
                  value: '730b78a9e5944a488f18ab088c423881',
                },
              ],
              positionX: 0.0,
              positionY: 0.0,
            },
            {
              widgetType: WidgetType.Background,
              idWidgetCategory: null,
              universal: false,
              created: '2020-12-12T20:20:50',
              width: 576.0,
              height: 256.0,
              name: '',
              thumbnail: '',
              actions: [],
              metadata: [{ name: MetadataName.slotName, value: 'footer' }],
              properties: [
                { name: BackgroundWidgetPropertyName.color, value: '#A2229F' },
              ],
              positionX: 192.0,
              positionY: 0.0,
            },
          ],
          created: '2022-03-28T18:18:21',
          lastUpdate: '2022-03-28T18:29:33',
          projectGenerated: null,
          mediaOnly: false,
        },
      ],
    },
  },
  IS2LX: {},
}

export const templatesHandlers = [
  rest.get<GetTemplatesQueryProps | undefined, EditTemplateDetails[]>(
    createHandlerURL(templatesEndpoint),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            name: 'Test',
            formatId: 0,
            formatName: 'test',
            categoryId: 1,
            displayModel: 'Atom',
            resolution: '3:4',
            displayInches: '',
            displayAspectRatio: 'string',
            orientation: Orientation.Landscape,
            metric: '',
            id: 1,
            widgets: [],
            baseTemplateId: 1,
            templateThumbnail: 'string',
            mediaOnly: false,
            templateStatus: true,
            categoryName: 'General',
            slotSizes: null,
            editionProgressStep: 1,
            created: '20-20-2020T10:10:10',
            projectGenerated: null,
            lastUpdate: null,
          },
        ])
      )
    }
  ),
]
