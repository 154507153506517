import { Button, styled } from '@material-ui/core'

const CollapseButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}))

export default CollapseButton
