import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { reducers, routes } from 'modules'
import { reducer as common } from 'shared/store'
import { reducer as snackbarsReducer } from 'shared/store/snackbars'
import { NavigationItem } from 'shared/types'

export const navItems: NavigationItem[] = routes
  .filter(({ label }) => label)
  .map(({ path, label = '', icon, subItems, exact }) => ({
    path,
    label,
    icon,
    subItems,
    exact,
  }))

export const store = configureStore({
  reducer: combineReducers({
    common,
    ...reducers,
    snackbars: snackbarsReducer,
  }),
})

export type RootState = ReturnType<typeof store.getState>
