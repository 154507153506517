import { SvgIcon, SvgIconProps } from '@material-ui/core'

const LeftAlignment = (props: SvgIconProps) => (
  <SvgIcon width="16" height="13" viewBox="0 0 16 13" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71143 3.58627L5.96438 5.33332H15.5537V6.85713H5.96438L7.71143 8.60418L6.63409 9.68151L3.04781 6.09523L6.63409 2.50894L7.71143 3.58627Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.1905H1.52381V0H0V12.1905Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default LeftAlignment
