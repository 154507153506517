import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Text = (props: SvgIconProps) => (
  <SvgIcon width="21" height="18" viewBox="0 0 21 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2C17.896 2 17 2.896 17 4V13H21V4C21 2.896 20.104 2 19 2Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 18L21 15H17L19 18Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6H3V4H11V6ZM11 10H3V8H11V10ZM8 14H3V12H8V14ZM12 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H12C13.103 18 14 17.103 14 16V2C14 0.897 13.103 0 12 0Z"
    />
  </SvgIcon>
)

export default Text
