import type { RootState } from 'app'
import { createSelector } from 'reselect'
import { getDisplayModels } from 'shared/store/displayModels/selectors'
import { getUploadTemplateThumbnail } from 'shared/store/templates/selectors'
import { selectors } from 'shared/store'
import {
  ApiWidget,
  LoadingStatus,
  Orientation,
  ProgressStep,
  SelectOption,
  WidgetType,
} from 'shared/types'
import {
  mapWidgetsToCategories,
  parseAspectRatio,
  parseScreenResolution,
} from '../utils'
import { getSplitPaneSize } from 'shared/utils'

export const { getSlotSizes } = selectors.utils

export const getActiveStep = (state: RootState) =>
  state.layoutDesigner.editor.currentStep

export const getVariant = (state: RootState) =>
  state.layoutDesigner.editor.selectedLayout

export const getDefaultTemplate = (state: RootState) =>
  state.layoutDesigner.editor.selectedDefaultLayout

export const getSaveTemplate = (state: RootState) =>
  state.layoutDesigner.saveTemplate

export const getTemplate = (state: RootState) => state.layoutDesigner.template

export const getTemplateData = (state: RootState) =>
  state.layoutDesigner.template.data

export const getDeviceSize = createSelector(
  getTemplateData,
  template => template?.resolution.split('x').map(Number) || [-1, -1]
)

export const getBaseTemplateId = (state: RootState) =>
  state.layoutDesigner.editor.selectedLayout

export const getApiTemplateData = createSelector(
  getTemplateData,
  getBaseTemplateId,
  (template, baseTemplateId) =>
    template ? { ...template, baseTemplateId } : null
)

export const getCreateTemplate = (state: RootState) =>
  state.layoutDesigner.createTemplate

export const getCustomBackgroundColors = (state: RootState) =>
  state.layoutDesigner.editor.customBackgroundColors

export const getEditorWidgets = (state: RootState) =>
  state.layoutDesigner.editor.widgets

export const isLayoutEditable = createSelector(getEditorWidgets, widgets =>
  Object.values(widgets).some(slotWidget => slotWidget.length > 0)
)

export const getWidgetCategories = (state: RootState) =>
  state.layoutDesigner.widgetsCategories

export const getArrowsData = (state: RootState) =>
  state.layoutDesigner.arrows.data

export const getTemplateDisplayModel = (state: RootState) =>
  state.layoutDesigner.template.data?.displayModel

export const getTemplateDisplayFormat = (state: RootState) => ({
  formatId: state.layoutDesigner.template.data?.formatId,
  formatName: state.layoutDesigner.template.data?.formatName,
})

export const getDisplayFonts = createSelector(
  getTemplateData,
  getDisplayModels,
  (template, displaysData) =>
    template
      ? displaysData.data.find(
          ({ displayModel }) => displayModel === template.displayModel
        )?.fonts
      : []
)

export const getWidgetsSideEffects = (state: RootState) => ({
  error: state.layoutDesigner.widgets.error,
  loading: state.layoutDesigner.widgets.loading,
})

export const getImageWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[
    WidgetType.Image
  ] as ApiWidget<WidgetType.Image>[]

export const getVideoWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[
    WidgetType.Video
  ] as ApiWidget<WidgetType.Video>[]

export const getPlaylistWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.Playlist]

export const getSlideshowWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.FlippingImages]

export const getRssFeedWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.RssFeed]

export const getWeatherWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.Weather]

export const getImagesByFloorWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.ImagesByFloor]

export const getDisplayPlaylists = (state: RootState) =>
  state.layoutDesigner.getDisplayPlaylists.data

export const getBackgroundWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[
    WidgetType.Background
  ] as ApiWidget<WidgetType.Background>[]

export const getArrowsWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.DirectionArrows]

export const getPictogramWidgets = (state: RootState) =>
  state.layoutDesigner.widgets.data[WidgetType.Pictogram]

export const getImageCategories = createSelector(
  getImageWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getSlideshowCategories = createSelector(
  getSlideshowWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getPictogramCategories = createSelector(
  getPictogramWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getRssFeedCategories = createSelector(
  getRssFeedWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getImagesByFloorCategories = createSelector(
  getImagesByFloorWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getVideoCategories = createSelector(
  getVideoWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getBackgroundCategories = createSelector(
  getBackgroundWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getArrowsCategories = createSelector(
  getArrowsWidgets,
  getWidgetCategories,
  mapWidgetsToCategories
)

export const getOrientation = (state: RootState) =>
  state.layoutDesigner.template.data?.orientation

export const getAspectRatio = createSelector(
  getTemplateData,
  template => template && parseAspectRatio(template.displayAspectRatio)
)

export const getUploadedWidget = (state: RootState) =>
  state.layoutDesigner.uploadWidget

export const getUploadedMediaWidget = (state: RootState) =>
  state.layoutDesigner.uploadMediaWidget

export const getUploadedArrowWidget = (state: RootState) =>
  state.layoutDesigner.uploadArrowWidget

export const getEditMode = (state: RootState) => ({
  ...state.layoutDesigner.editMode,
  slotSize: state.layoutDesigner.editMode.slot
    ? state.layoutDesigner.editor.slotSizes[state.layoutDesigner.editMode.slot]
    : { width: 0, height: 0 },
})

export const getIdCounter = (state: RootState) =>
  state.layoutDesigner.editor.idCounter

export const getScreenSize = createSelector(getTemplateData, template =>
  template
    ? parseScreenResolution({
        resolution: template.resolution,
        orientation: template.orientation,
      })
    : null
)

export const getLayoutPreviewSize = (state: RootState) =>
  state.layoutDesigner.editor.layoutPreviewSize // Careful! Split Panes sizes are not subtracted from this.

export const getLayoutPreviewScale = createSelector(
  // This is the scale for the whole template, keep in mind that for specific slots this value may be wrong.
  // Footer may not have any split panes but the upper two sections can. Calculate it with getSplitPaneSize for arguments other than global.
  getDeviceSize,
  getLayoutPreviewSize,
  getOrientation,
  getVariant,
  getActiveStep,
  (deviceSize, { width }, orientation, variant, activeStep) =>
    orientation === Orientation.Landscape
      ? deviceSize[0] /
        (width -
          getSplitPaneSize(
            variant,
            'global',
            orientation,
            activeStep === ProgressStep.LayoutSelection
          ).x)
      : deviceSize[1] /
        (width -
          getSplitPaneSize(
            variant,
            'global',
            orientation,
            activeStep === ProgressStep.LayoutSelection
          ).x)
)

export const hasAnyCollisions = createSelector(
  getEditorWidgets,
  editorWidgets =>
    Object.entries(editorWidgets).reduce(
      (hasCollisionInSlot, [, widgets]) =>
        hasCollisionInSlot ||
        widgets.reduce(
          (hasCollision, widget) =>
            hasCollision || widget.isInCollisionWith.length > 0,
          false
        ),
      false
    )
)

export const getCalculationDataForSaving = createSelector(
  getSlotSizes,
  getOrientation,
  getDeviceSize,
  getLayoutPreviewSize,
  getVariant,
  getActiveStep,
  getEditorWidgets,
  (
    slotSizes,
    orientation,
    deviceSize,
    layoutPreviewSize,
    variant,
    activeStep,
    widgets
  ) => ({
    slotSizes,
    orientation,
    deviceSize,
    activeStep,
    layoutPreviewSize,
    selectedLayout: variant,
    widgets,
  })
)

export const getWidgetCreationData = (state: RootState) =>
  state.layoutDesigner.widgetCreation

export const getIsSlotDraggedOver = (state: RootState) =>
  state.layoutDesigner.editor.isSlotDraggedOver

export const savingTemplateStatus = createSelector(
  getUploadTemplateThumbnail,
  getSaveTemplate,
  ({ loading: thumbnailUploadProgress }, { loading: saveTemplateProgress }) => {
    const loading = [thumbnailUploadProgress, saveTemplateProgress]
    if (loading.includes(LoadingStatus.Pending)) return LoadingStatus.Pending
    if (saveTemplateProgress === LoadingStatus.Failed)
      return LoadingStatus.Failed
    if (loading[1] === LoadingStatus.Succeeded) return LoadingStatus.Succeeded
  }
)

export const getIsTemplateFullyProcessed = (state: RootState) =>
  state.layoutDesigner.editor.templateFullyProcessed

export const getWidgetEditSection = createSelector(
  getEditorWidgets,
  getEditMode,
  (editedWidgets, { slot, widgetIds }) => ({
    editedWidgets,
    slot,
    widgetIds,
  })
)

export const getDirectionArrowColor = createSelector(
  getEditMode,
  getEditorWidgets,
  (editMode, editorWidgets) => ({
    editSlot: editMode.slot,
    editorWidgets,
  })
)

export const getDirectionArrowPosition = createSelector(
  getEditMode,
  getEditorWidgets,
  ({ slotSize, slot }, editorWidgets) => ({
    slotSize,
    slot,
    layoutWidgets: editorWidgets,
  })
)

export const getUseSlotSize = createSelector(
  getLayoutPreviewSize,
  getSlotSizes,
  getDeviceSize,
  getVariant,
  (layoutPreviewSize, slotSizes, deviceSize, layoutVariant) => ({
    layoutPreviewSize,
    slotSizes,
    deviceSize,
    layoutVariant,
  })
)

export const getUseScaleWithSplitPane = createSelector(
  getOrientation,
  getLayoutPreviewSize,
  getVariant,
  getDeviceSize,
  getActiveStep,
  (orientation, layoutPreviewSize, variant, deviceSize, activeStep) => ({
    orientation,
    layoutPreviewSize,
    variant,
    deviceSize,
    activeStep,
  })
)

export const getTopBar = createSelector(
  getEditMode,
  hasAnyCollisions,
  ({ inProgress }, hasAnyCollisions) => ({
    inProgress,
    hasAnyCollisions,
  })
)

export const getUseGetNavigationDisabledTooltip = createSelector(
  isLayoutEditable,
  getEditMode,
  getTemplateData,
  hasAnyCollisions,
  getDefaultTemplate,
  getActiveStep,
  (
    isLayoutEditable,
    { inProgress: isInEditMode },
    template,
    hasAnyCollisions,
    defaultTemplate,
    activeStep
  ) => ({
    isLayoutEditable,
    isInEditMode,
    template,
    hasAnyCollisions,
    activeStep,
    defaultTemplate,
  })
)

export const getRssFeedOptions = (state: RootState): SelectOption[] =>
  state.layoutDesigner.rssFeeds.data.map(({ source, language, rssFeed }) => ({
    label: `${source} | ${language}`,
    value: rssFeed,
  }))

export const getAddWidgetCategory = (state: RootState) =>
  state.layoutDesigner.addWidgetCategory

export const getDisplayDetails = (state: RootState) =>
  state.layoutDesigner.displayModels

export const getLayoutWidgetsCount = createSelector(
  getEditorWidgets,
  widgets => {
    const allWidgets = [...widgets.footer, ...widgets.sidebar, ...widgets.main]
    return Object.entries(WidgetType).reduce(
      (object, [, widgetType]) => ({
        ...object,
        [widgetType]: allWidgets.filter(
          widget => widget.widgetType === widgetType
        ).length,
      }),
      {}
    ) as { [key in WidgetType]: number }
  }
)

export const getLastGenerated = (state: RootState) =>
  state.layoutDesigner.template.data?.projectGenerated

export const getLastUpdate = (state: RootState) =>
  state.layoutDesigner.template.data?.lastUpdate

export const getDefaultTemplates = (state: RootState) =>
  state.layoutDesigner.defaultTemplates

export const getUpdateWidget = (state: RootState) =>
  state.layoutDesigner.updateWidget

export const isWidgetMenuOpen = (state: RootState) =>
  state.layoutDesigner.isWidgetMenuOpen
