import { paths } from 'config'
import { RouteProps } from 'react-router-dom'
import { Loadable } from './components'

const routes: RouteProps[] = [
  {
    path: paths.login,
    exact: true,
    component: Loadable({
      component: () => import('./containers/Login'),
    }),
  },
]

export default routes
