import { RootState } from 'app/App.utils'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar, VariantType } from 'notistack'
import { ErrorType } from 'shared/types'

interface UseAlert {
  (variant?: VariantType): (message: ErrorType) => void
}

export const useAlert: UseAlert = (variant = 'default') => {
  const { enqueueSnackbar } = useSnackbar()

  return message =>
    enqueueSnackbar(typeof message === 'string' ? message : message?.message, {
      variant,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    })
}

export const useReduxAlert = (
  selector: (state: RootState) => string | null | undefined,
  variant: VariantType
) => {
  const reduxAlertMessage = useSelector(selector)
  const showAlert = useAlert(variant)

  useEffect(() => {
    if (reduxAlertMessage) {
      showAlert(reduxAlertMessage)
    }
  }, [reduxAlertMessage, showAlert])
}
