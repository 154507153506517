import { createAsyncThunkWithErrorHandling } from 'shared/utils/store'
import { GetMeResponse, LoginPayload } from 'shared/services'
import { Api, Users } from 'shared/services'
import { SNACKBARS } from 'shared/strings'

const api = new Api()
const users = new Users()
export const MODULE_NAME = 'auth'

export const login = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/login`,
  payloadCreator: (payload: LoginPayload) => api.login(payload),
  ...SNACKBARS.auth,
})

export const getCurrentUser = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/user`,
  payloadCreator: async () => await users.me(),
  ...SNACKBARS.auth,
})

export const editCurrentUser = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/user`,
  payloadCreator: async (params: Partial<GetMeResponse>) =>
    await users.edit(params),
  ...SNACKBARS.auth,
})
