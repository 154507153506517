import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { DisplayModel, DisplayDataFieldsValues } from 'shared/types'

export const useDisplayModelsAndSizes = (displays: DisplayModel[]) => {
  const {
    values: { displayModel },
    setFieldValue,
  } = useFormikContext<DisplayDataFieldsValues>()

  useEffect(() => {
    setFieldValue('displayInches', '')
  }, [displayModel, setFieldValue])

  const displayModels = displays.map(({ displayModel }) => ({
    value: displayModel,
    label: displayModel,
  }))

  const displaySizes = (
    displays.find(display => display.displayModel === displayModel)?.sizes || []
  ).map(({ inches }) => ({
    value: inches,
    label: inches,
  }))

  return { displayModels, displaySizes }
}
