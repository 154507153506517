import { styled, Theme } from '@material-ui/core'
import { DRAWER_MENU_SCALE, DRAWER_MENU_WIDTH } from 'shared/config'

export const ScrollableContent = styled('div')({
  width: 'max-content',
  whiteSpace: 'nowrap',
})

interface PreviewContainerProps {
  maxWidth: number
  drawer: boolean
}
export const PreviewContainer = styled('div')<Theme, PreviewContainerProps>(
  ({ maxWidth, drawer, theme }) => ({
    pointerEvents: 'none',
    maxWidth: drawer ? DRAWER_MENU_WIDTH - 48 : maxWidth,
    marginTop: drawer ? theme.spacing(1) : '',
    [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
      maxWidth: DRAWER_MENU_WIDTH - 120 * DRAWER_MENU_SCALE,
    },
  })
)
