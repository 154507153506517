import { styled, Theme } from '@material-ui/core'
import { CSSProperties } from 'react'
import { DRAWER_WIDTH } from 'shared/config'

interface CenterBoxProps {
  flexDirection?: CSSProperties['flexDirection']
  position?: CSSProperties['position']
  height?: CSSProperties['height']
}

export const CenterBox = styled('div')<Theme, CenterBoxProps>(
  ({ flexDirection = 'row', position, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection,
    alignItems: 'center',
    height: height ?? '100%',
    width: '100%',
    position,
    ...(position === 'absolute' && { top: 0, left: 0 }),
  })
)

export const ModuleCenterBox = styled('div')({
  position: 'absolute',
  top: '50%',
  left: `calc(50% + ${DRAWER_WIDTH / 2}px)`,
  transform: 'translate(-50%, -50%)',
})
