import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DirectionArrow = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11L10 5L16 11H4ZM16 15H4V12H16V15ZM18 0H2C0.897 0 0 0.897 0 2V18C0 19.103 0.897 20 2 20H18C19.104 20 20 19.103 20 18V2C20 0.897 19.104 0 18 0Z"
    />
  </SvgIcon>
)

export default DirectionArrow
