import { Box, Typography } from '@material-ui/core'
import { actions } from 'modules/mediaLibrary/store'
import { useTranslation } from 'react-i18next'
import { MEDIA_CARD_HEIGHT, ML_MODULE_NAME } from 'shared/config'
import { useDynamicFontLoading, useSetCurrentAssetInDrawer } from 'shared/hooks'
import { ApiFont, MediaAsset } from 'shared/services'
import { StyledCard } from '../CardStyles'
import { Ellipsis } from '../Ellipsis'

interface FontCardProps {
  font: ApiFont
  setSelectedAsset?: (asset: MediaAsset | ApiFont) => void
  selectedAsset: MediaAsset | ApiFont | null
}
const FontCard = ({ font, setSelectedAsset, selectedAsset }: FontCardProps) => {
  const { t } = useTranslation(ML_MODULE_NAME)
  useDynamicFontLoading(font.fileUuid)
  const setCurrentAssetInDrawer = useSetCurrentAssetInDrawer(
    font,
    null,
    actions,
    setSelectedAsset
  )

  return (
    <StyledCard
      isSelected={font.fileUuid === selectedAsset?.fileUuid}
      onClick={setCurrentAssetInDrawer}
      minHeight={MEDIA_CARD_HEIGHT}
    >
      <Box
        fontFamily={font.fileUuid}
        fontSize={42}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={110}
      >
        Aa
      </Box>

      <Box py={2} px={1} display="flex" justifyContent="center">
        <Typography gutterBottom variant="h6" noWrap={false}>
          <Ellipsis variant="horizontal">
            {font.filename || t('assetCard.noFileName')}
          </Ellipsis>
        </Typography>
      </Box>
    </StyledCard>
  )
}

export default FontCard
