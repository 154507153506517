import { Collapse, List } from '@material-ui/core'
import { useLocation } from 'react-router'
import { NavigationItem, SubItem } from 'shared/types'
import LinkItem from './LinkItem'

const getPath = (pathname: string) => pathname.split('/').slice(0, 3).join('/')

interface SubItemsProps {
  items: NavigationItem['subItems']
  open?: boolean
}
const SubItems = ({ items = [], open = false }: SubItemsProps) => {
  const { pathname } = useLocation()
  const filterDiscrete = (item: SubItem) => {
    if (item.discrete && getPath(pathname) === `${item.path}`) return item
    if (!item.discrete) return item
  }
  const filteredItems = items.filter(filterDiscrete)

  return (
    <Collapse in={open}>
      <List disablePadding>
        {filteredItems.map((subItem, index) => (
          <LinkItem
            exact
            {...subItem}
            key={`link-item-${subItem.label}-${index}`}
          />
        ))}
      </List>
    </Collapse>
  )
}

export default SubItems
