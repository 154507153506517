import { PropsWithChildren } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ListItemProps as BaseListItemProps } from '@material-ui/core'
import { NavigationItem } from 'shared/types'
import { Container } from './SidebarItem.style'
import LinkItem from './LinkItem'
import SubItems from './SubItems'

interface SidebarItemProps extends Partial<BaseListItemProps>, NavigationItem {
  exact?: boolean
}

const SidebarItem = ({
  subItems,
  path,
  ...props
}: PropsWithChildren<SidebarItemProps>) => {
  const match = useRouteMatch({ path, exact: !subItems })
  const isOpen = subItems && !!match

  return (
    <Container open={isOpen}>
      <LinkItem
        {...props}
        path={path}
        collapsible={!!subItems}
        subItems={subItems}
      />
      {subItems && <SubItems open={isOpen} items={subItems} />}
    </Container>
  )
}

export default SidebarItem
