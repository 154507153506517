import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Uppercase = (props: SvgIconProps) => (
  <SvgIcon width="23" height="15" viewBox="0 0 23 15" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.40909 0H1.04545C0.468364 0 0 0.468364 0 1.04545V4.18182H2.09091V2.09091H4.18182V12.5455H2.09091V14.6364H8.36364V12.5455H6.27273V2.09091H8.36364V4.18182H10.4545V1.04545C10.4545 0.468364 9.98618 0 9.40909 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9545 0H13.5909C13.0138 0 12.5454 0.468364 12.5454 1.04545V4.18182H14.6363V2.09091H16.7272V12.5455H14.6363V14.6364H20.909V12.5455H18.8181V2.09091H20.909V4.18182H23V1.04545C23 0.468364 22.5316 0 21.9545 0Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Uppercase
