import { createServiceURL } from 'shared/utils'

export interface GetAssetParameters {
  mediaUuid: string
}

export interface TransformVideoParams {
  mediaUuid: string
  resize?: string
  trim?: string // JSON {"start": "20", "end": "30"}
  videoCodec?: string
  keepOriginal?: boolean
}

export interface ApiFont {
  fileUuid: string
  fileExtension: 'ttf' | string
  path: '/fonts/atom' | string
  filename: string
  groupId: number
  dateposted: string
  duration: null
  universal?: 0 | 1 // to match MediaAsset interface
}

export interface UploadAssetResponse {
  fileUuid: string
  fileExtension: string
  groupId: number
  uploadFileTask: string
  alreadyExists: boolean
}

export interface UploadAssetParams {
  file: Blob
  fileName?: string
  path?: string
}
export interface GetAssetInfoResponse
  extends AssetInfoResponseImage,
    AssetInfoResponseVideo {
  type: string
  format: string
  size: number
  width: number
  height: number
}

interface AssetInfoResponseImage {
  imageCodec?: string
  pixelFormat?: string
}

interface AssetInfoResponseVideo {
  videoCodec?: string
  audioCodec?: string
  duration?: number
  channels?: number
}

export interface MediaFileTaskSuccessDescriptionRaw {
  file_extension: string
  file_uuid: string
  groupId: number
  universal: number
}

export interface MediaFileTaskSuccessDescription {
  fileExtension: string
  fileUuid: string
  groupId: number
  universal: number
}

export interface MediaFileTaskResponse {
  status: string
  detail: string | MediaFileTaskSuccessDescription
}

export interface TransformVideoResponse {
  taskId: string
  originalFileUuid: string
  uuidMediaFile?: string
}

export enum MediaTaskStatus {
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Started = 'STARTED',
}

export interface UploadFontParams {
  filename?: string
  file: File
}

const assetsModule = 'assets'
const getMediaFileEndpoint = 'get_media_file'
const getResourceFileEndpoint = 'get_asset_file'
const uploadMediaFileEndpoint = 'upload_media_file'
const getMediaInfoEndpoint = 'get_media_info'
const transformBase64ToImageEndpoint = 'transform_media_file/base64toimage'
const getMediaTaskStatusEndpoint = 'media_task_status'
const transformMediaFileVideoEndpoint = 'transform_media_file/video'
const uploadAssetFileEndpoint = 'upload_asset_file'

export const getMediaFileURL = createServiceURL(
  assetsModule,
  getMediaFileEndpoint
)
export const uploadMediaFileURL = createServiceURL(
  assetsModule,
  uploadMediaFileEndpoint
)
export const uploadFontURL = createServiceURL(
  assetsModule,
  uploadAssetFileEndpoint
)
export const getMediaInfoURL = createServiceURL(
  assetsModule,
  getMediaInfoEndpoint
)
export const getResourceFileURL = createServiceURL(
  assetsModule,
  getResourceFileEndpoint
)
export const transformBase64ToImageURL = createServiceURL(
  assetsModule,
  transformBase64ToImageEndpoint
)
export const getMediaTaskStatusURL = createServiceURL(
  assetsModule,
  getMediaTaskStatusEndpoint
)
export const transformMediaFileVideoURL = createServiceURL(
  assetsModule,
  transformMediaFileVideoEndpoint
)
