import { DataGrid as BaseDataGrid, DataGridProps } from '@material-ui/data-grid'
import { styled, Tooltip } from '@material-ui/core'
import { ExtendedGridColDef } from 'shared/types'
import { renderDatetimeCell } from 'shared/utils'
import NoRowsOverlay from './NoRowsOverlay'

const StyledDataGrid = styled(BaseDataGrid)(({ theme }) => ({
  color: theme.palette.background.table.headerText,
  fontWeight: 600,
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.info.main,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 0,
  },
  '& .MuiDataGrid-columnHeader:focus': { outline: 0 },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 800,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .Mui-even.MuiDataGrid-row': {
    backgroundColor: theme.palette.background.table.metalic,
  },
  '& .Mui-odd.MuiDataGrid-row': {
    backgroundColor: theme.palette.background.table.grey,
  },
  '& .MuiDataGrid-footerContainer': {
    minHeight: 0,
  },
}))

interface DataGridExtendedProps extends DataGridProps {
  columns: ExtendedGridColDef[]
  noRowsText: string
}

const DataGrid = (props: DataGridExtendedProps) => {
  return (
    <StyledDataGrid
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      disableSelectionOnClick
      components={{
        NoRowsOverlay: () => <NoRowsOverlay text={props.noRowsText} />,
      }}
      {...props}
      columns={props.columns.map(column => ({
        ...column,
        renderCell: params => {
          const value = column.withDatetime
            ? renderDatetimeCell(column.field)(params)
            : params.row[column.field]
          return (
            <Tooltip title={value ?? ''}>
              <span>{value ?? '—'}</span>
            </Tooltip>
          )
        },
      }))}
    />
  )
}

export default DataGrid
