import { useEffect, useState } from 'react'
import { TagsListStructure } from 'shared/types'

export const mapObjectsToTagList = <T extends { tags?: string[] }>(
  objects: T[],
  tagsBuilder: TagsListStructure<T> = []
) => {
  objects.forEach(object => {
    if (!object.tags) return

    object.tags.forEach(tag => {
      const foundContainer = tagsBuilder.find(
        tagBuilder => tagBuilder.name === tag
      )
      if (foundContainer) foundContainer.items.push(object)
      else tagsBuilder.push({ name: tag, id: '', items: [object] })
    })
  })

  return tagsBuilder
}

export const useCurrentTag = (tag: string | null) => {
  const [lastTag, setLastTag] = useState(tag)
  useEffect(() => {
    if (tag) setLastTag(tag)
  }, [tag])
  return { lastTag }
}
