import { rest } from 'msw'
import { createHandlerURL } from 'shared/utils'
import { GetMeResponse } from './Users.types'
import { aboutEndpoint } from './Users.utils'

export const usersHandlers = [
  rest.get<{}, GetMeResponse>(
    createHandlerURL(aboutEndpoint),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          active: null,
          avatarImg: null,
          companyId: null,
          email: 'mocked@test.com',
          firstName: 'Jan',
          lastName: 'Kowalski',
          group: null,
          id: 1,
          jobRole: 'Employee',
          username: 'JanKowalski',
          phone: '123123123',
          timezone: 'Europe/London',
          language: 'english',
          created: '2012-06-15T11:59:12',
          lastVisit: '2022-04-08T09:46:47',
          receiveEmails: false,
          companies: [
            {
              company: 'Administrator',
              default: true,
              role: 'Administrator',
              companyId: 2,
              roleId: 2,
              globalGuest: false,
              scopes: [
                'Site',
                'Lift Hawk trigger test',
                'cronjobs',
                'api_post_generate_project',
                'frontend_get_display_details',
                'frontend_get_floor_legends',
                'frontend_get_status_messages',
                'frontend_get_floor_audios',
              ],
            },
          ],
        })
      )
    }
  ),
]
