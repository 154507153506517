import { Position } from 'react-rnd'
import { LayoutSlot, LiveEditHelperValues } from '../config'
import { MediaTransformationKind, WidgetType } from '../enums'
import { LayoutProperties, Size } from './general'
import {
  MetaDataProperty,
  PropertiesMap,
  WidgetProperty,
} from './widgetProperties'

export interface WidgetAction {
  name: MediaTransformationKind
  value: string
}

export type MapToProperties<T extends WidgetType> = PropertiesMap[T]

export interface Widget<T extends WidgetType | null = null> {
  id: number | string
  widgetType: WidgetType
  properties: T extends WidgetType ? MapToProperties<T>[] : WidgetProperty[]
  thumbnail?: string
  metadata?: MetaDataProperty[]
  actions?: WidgetAction[]
}

export interface LayoutWidget<T extends WidgetType | null = null>
  extends Widget<T> {
  position: Position
  size: Size
  isInCollisionWith: (number | string)[]
  layoutProperties: Partial<LayoutProperties>
}

export type SlotWidgets = Record<LayoutSlot, LayoutWidget[]>

export interface ApiWidget<T extends WidgetType | null = null>
  extends Widget<T> {
  id: number
  idWidgetCategory: number | null
  width: number
  height: number
  name: string
  positionX: number
  positionY: number
  created: string
  universal: boolean
}

export type UploadWidget = Omit<
  ApiWidget,
  'id' | 'positionX' | 'positionY' | 'created'
>

export interface WidgetsCategory {
  idWidgetCategory: number
  widgetCategoryName: string
  companyId: number
}

export type LiveEditHelperValuesMap = {
  [key in WidgetType]: LiveEditHelperValues<PropertiesMap[key]>
}

export enum TextDirection {
  RightLeft = 'right:left',
  LeftRight = 'left:right',
  Auto = 'auto',
}

export interface PositionInPercentage {
  x: string
  y: string
}
