import { styled, SvgIcon, SvgIconProps } from '@material-ui/core'

const TextColor = (props: SvgIconProps) => (
  <SvgIcon width="14" height="18" viewBox="0 0 14 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.3333H14V13.4444H0V17.3333Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99913 2.94442L8.25991 6.22198H5.73835L6.99913 2.94442ZM5.44358 10.1109H4.24269L5.14024 7.77753H8.85802L9.75558 10.1109H8.55469V11.6664H12.4436V10.1109H11.4224L7.72558 0.498311C7.60969 0.198089 7.32113 -0.000244141 6.99913 -0.000244141C6.67713 -0.000244141 6.38858 0.198089 6.27269 0.498311L2.57591 10.1109H1.55469V11.6664H5.44358V10.1109Z"
      fill={props.fill}
    />
  </SvgIcon>
)

export default styled(TextColor)({
  fontSize: '1.2rem',
})
