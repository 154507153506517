const DISPLAY_MODULE_PATH = '/display-management'
const PLAYLIST_MODULE_PATH = '/playlist'

const paths = {
  forgetPassword: `${
    process.env.REACT_APP_API_URL?.split('/API')[0]
  }/index.php/auth/forgotten_password`,
  displayDetails: (id: number) => `${DISPLAY_MODULE_PATH}/displays/${id}`,
  schedule: `${PLAYLIST_MODULE_PATH}/playlistSchedule`,
}

export default paths
