import { SvgIcon, SvgIconProps } from '@material-ui/core'

const VerticalAlignment = (props: SvgIconProps) => (
  <SvgIcon width="12" height="18" viewBox="0 0 12 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5V0H0V1.5H5.25V5.6895L4.28025 4.71975L3.21975 5.78025L6 8.5605L8.78025 5.78025L7.71975 4.71975L6.75 5.6895V1.5H12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 16.5V18H12V16.5H6.75V12.3105L7.71975 13.2803L8.78025 12.2198L6 9.43951L3.21975 12.2198L4.28025 13.2803L5.25 12.3105V16.5H0Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default VerticalAlignment
