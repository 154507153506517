import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'displayManagement'

export const SNACKBARS: SnackbarDescriptorTable = {
  assignRssResourceToDevice: {
    successMessage: {
      text: 'widgets.rssSuccess',
      namespace: MODULE_NAME,
      type: 'success',
    },
    errorMessage: true,
  },
  getDeviceScreenshot: {
    errorMessage: true,
  },
  updateDisplay: {
    errorMessage: {
      text: 'errors.updateDetails',
      namespace: MODULE_NAME,
      type: 'error',
    },
    successMessage: {
      text: 'successes.updateDetails',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
  addAudioFile: {
    errorMessage: true,
    successMessage: {
      text: 'successes.addAudioFile',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
  updateFirmware: {
    errorMessage: {
      text: 'errors.firmwareUpdate',
      namespace: MODULE_NAME,
      type: 'error',
    },
    successMessage: {
      text: 'successes.firmwareUpdate',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
  addAudioLanguage: {
    errorMessage: true,
    successMessage: {
      text: 'successes.language',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
}

export const DETAILS_NAMESPACE = 'displayDetails'
