import { SnackbarProvider } from 'notistack'
import { PropsWithChildren } from 'react'
import { useStyles } from './AlertProvider.style'
import { useAuth } from 'shared/hooks'

interface AlertProviderProps {}

const AlertProvider = ({ children }: PropsWithChildren<AlertProviderProps>) => {
  const { isAuthenticated } = useAuth()
  const classes = useStyles({ isAuthenticated })

  return (
    <SnackbarProvider dense maxSnack={3} classes={classes}>
      {children}
    </SnackbarProvider>
  )
}

export default AlertProvider
