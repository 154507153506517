import { SvgIcon, SvgIconProps } from '@material-ui/core'

const HorizontalAlignment = (props: SvgIconProps) => (
  <SvgIcon width="18" height="12" viewBox="0 0 18 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 5.25H12.3105L13.2802 4.28025L12.2197 3.21975L9.43945 6L12.2197 8.78025L13.2802 7.71975L12.3105 6.75H16.5V12H18V0H16.5V5.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 6.75H5.6895L4.71975 7.71975L5.78025 8.78025L8.5605 6L5.78025 3.21975L4.71975 4.28025L5.6895 5.25H1.5V0H0V12H1.5V6.75Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default HorizontalAlignment
