import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Languages } from 'config'
import { IconSelectOption } from 'shared/types'
import { LanguageSelect as StyledLanguageSelect } from './LanguageSelect.style'
import { langIcons } from './LanguageSelect.utils'

interface LanguageMenuProps {
  language?: string
}

const LanguageSelect = ({ language }: LanguageMenuProps) => {
  const { i18n, t } = useTranslation()
  const options: IconSelectOption[] = Object.values(Languages).map(lang => ({
    value: lang,
    label: t(`languages.${lang}`),
    icon: langIcons[lang],
  }))
  const [currentLang, setLang] = useState<string | undefined>(language)
  const onChange = (
    event: ChangeEvent<{
      value: unknown
    }>
  ) => {
    const value = event.target.value as string
    i18n.changeLanguage(value)
    setLang(value)
  }

  useEffect(
    () =>
      setLang(
        options.find(({ label }) => label.toLowerCase() === language)
          ?.value as string
      ),
    [language]
  )

  return (
    <StyledLanguageSelect
      options={options}
      withFormControl={false}
      value={currentLang}
      onChange={onChange}
    />
  )
}

export default LanguageSelect
