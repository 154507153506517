import { createReducer } from '@reduxjs/toolkit'
import { Auth, GetMeResponse } from 'shared/services'
import { SideEffects } from 'shared/types'
import {
  defaultSideEffects,
  setResourceFulfilled,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import { getCurrentUser, login } from './actions'

const auth = new Auth()

interface State extends SideEffects {
  isAuthenticated: boolean
  user: GetMeResponse | null
}

const initialState: State = {
  isAuthenticated: auth.isValid(),
  ...defaultSideEffects,
  user: null,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(login.pending, state => setResourcePending(state))
    .addCase(login.fulfilled, state => {
      state.isAuthenticated = true
      setResourceFulfilled(state)
    })
    .addCase(login.rejected, (state, action) => {
      state.isAuthenticated = false
      setResourceRejected(state, action)
    })
    .addCase(getCurrentUser.pending, state => setResourcePending(state))
    .addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = { ...action.payload }
      setResourceFulfilled(state)
    })
    .addCase(getCurrentUser.rejected, (state, action) =>
      setResourceRejected(state, action)
    )
)
