import { MouseEvent } from 'react'

export const usePlayPause = () => {
  const play = (e: MouseEvent<HTMLVideoElement>) => {
    ;(e.target as HTMLVideoElement).muted = true
    ;(e.target as HTMLVideoElement).play()
  }
  const pause = (e: MouseEvent<HTMLVideoElement>) => {
    ;(e.target as HTMLVideoElement).pause()
    ;(e.target as HTMLVideoElement).currentTime = 0
  }
  return { play, pause }
}
