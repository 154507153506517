import {
  Button as BaseButton,
  ButtonProps,
  styled,
  Theme,
} from '@material-ui/core'
import {
  DENSE_ICON_BUTTON_WIDTH,
  NORMAL_ICON_BUTTON_WIDTH,
} from 'shared/config'

export interface BaseButtonProps extends ButtonProps {
  selected: boolean
  className?: string
  isDense?: boolean
  contrastBackground?: boolean
}

export const CheckboxButton = styled(BaseButton)<Theme, BaseButtonProps>(
  ({
    theme,
    selected,
    fullWidth,
    className,
    isDense = false,
    contrastBackground = false,
  }) => {
    const buttonWidth = isDense
      ? DENSE_ICON_BUTTON_WIDTH
      : NORMAL_ICON_BUTTON_WIDTH
    return {
      backgroundColor: selected
        ? theme.palette.accent.main
        : theme.palette.declined.main,
      borderRadius: 3,
      width: fullWidth ? '100%' : buttonWidth,
      padding: 0,
      minWidth: isDense ? buttonWidth : 'unset',
      cursor: 'pointer',
      '& .MuiButton-label': {
        color: selected
          ? theme.palette.common.white
          : theme.palette.declined.contrastText,
        fontSize: '1rem',
      },
      '&:hover': {
        backgroundColor: selected
          ? theme.palette.accent.main
          : theme.palette.declined.dark,
      },
      [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
        ...(!fullWidth && {
          width: NORMAL_ICON_BUTTON_WIDTH,
          minWidth: NORMAL_ICON_BUTTON_WIDTH,
        }),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
      ...(className === 'arrowItem' && {
        backgroundColor: contrastBackground
          ? theme.palette.background.neutral.main
          : theme.palette.common.white,
        border: `3px solid ${
          theme.palette[selected ? 'primary' : 'declined'].main
        }`,
        borderRadius: 3,
        height: 96,
        padding: theme.spacing(1, 2),
        '&:hover': {
          backgroundColor: contrastBackground
            ? theme.palette.background.neutral.light
            : theme.palette.common.white,
          border: `3px solid ${theme.palette.primary.main}`,
        },
        '& .MuiButton-label': {
          color: theme.palette.accent.main,
          display: 'block',
          height: '100%',
        },
      }),
    }
  }
)
