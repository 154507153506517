import {
  Box,
  Container,
  Drawer,
  IconButton,
  styled,
  Theme,
} from '@material-ui/core'
import Scrollbars from 'react-custom-scrollbars'
import {
  DRAWER_MENU_SCALE,
  DRAWER_MENU_WIDTH,
  TOOLBAR_HEIGHT,
} from 'shared/config'

const DRAWER_MENU_CLOSED_WIDTH = 50
interface DrawerMenuContentProps {
  isOpen: boolean
  isActionRequiredInDrawer?: boolean
}

export const DrawerMenuContent = styled(Drawer)<Theme, DrawerMenuContentProps>(
  ({ theme, isOpen, isActionRequiredInDrawer }) => ({
    '& .MuiDrawer-paper': {
      zIndex: isActionRequiredInDrawer
        ? theme.zIndex.appOverlay.uploadOverlay
        : theme.zIndex.drawer,
      backgroundColor: theme.palette.info.light,
      padding: theme.spacing(0),
      height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
      width: DRAWER_MENU_WIDTH,
      overflow: 'hidden',
      border: 'none',
      position: 'relative',
      top: 0,
      [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
        width: DRAWER_MENU_WIDTH / DRAWER_MENU_SCALE,
      },
    },

    ...(!isOpen && {
      '& .MuiDrawer-paper': {
        width: DRAWER_MENU_CLOSED_WIDTH,
      },
    }),
  })
)

export const DrawerMenuContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  height: 'inherit',
  padding: theme.spacing(0, 2.2, 0, 4),
}))

interface ContentWrapperProps {
  show: boolean
}
export const ContentWrapper = styled('div')<Theme, ContentWrapperProps>(
  ({ show }) => ({
    height: '100%',
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'all' : 'none',
  })
)

export const ContentScrollableContainer = styled(Scrollbars)<Theme>(
  ({ theme }) => ({
    maxHeight: `calc(100% - ${theme.spacing(19)}px)`,
    overflowX: 'hidden',
    '& > div': {
      paddingBottom: theme.spacing(6),
    },
  })
)

export const DrawerFooter = styled('footer')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 0,
  zIndex: 999,
  backgroundColor: theme.palette.info.light,
}))

export const FooterChildrenBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 4.5, 0),
}))

interface CloseButtonProps {
  isDrawerOpen: boolean
}

export const CloseButton = styled(IconButton)<Theme, CloseButtonProps>(
  ({ theme, isDrawerOpen }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: isDrawerOpen ? 13 : 24,
      transform: isDrawerOpen ? undefined : 'rotate(180deg)',
      color: isDrawerOpen ? undefined : theme.palette.primary.main,
    },
  })
)
