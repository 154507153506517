import { styled, Theme } from '@material-ui/core'

export const Container = styled('div')<Theme, { isFullHeight: boolean }>(
  ({ isFullHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
    height: isFullHeight ? '100%' : 'unset',
  })
)
