import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'shared/store'
import { useToggle, useAssetUrl, useAuth } from 'shared/hooks'

export const useUserProfile = (avatarImg: string | null | undefined) => {
  const dispatch = useDispatch()
  const [isOpen, toggleOpen] = useToggle()
  const { assetUrl } = useAssetUrl(avatarImg)
  const { logout } = useAuth()

  useEffect(() => {
    dispatch(actions.auth.getCurrentUser())
  }, [dispatch])

  return { isOpen, toggleOpen, assetUrl, logout }
}
