import { useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useEffect } from 'react'
import { useAssetUrl } from 'shared/hooks'
import { ErrorIcon } from 'shared/icons'
import { LoadingStatus } from 'shared/types'
import { isImageAsset } from 'shared/utils'
import ImageThumbnail from './ImageThumbnail'
import ImageThumbnailWithDuration, {
  ImageThumbnailWithDurationProps,
} from './ImageThumbnailWithDuration'
import VideoThumbnail, { VideoThumbnailProps } from './VideoThumbnail'

export interface MultimediaThumbnailProps {
  thumbnailUuid: string | null
  backupUuid?: string
  videoProps?: Omit<
    VideoThumbnailProps,
    'isBlocked' | 'withDurationLabel' | 'thumbnail'
  >
  imageProps?: Omit<ImageThumbnailWithDurationProps, 'isBlocked' | 'thumbnail'>
  onLoad?: () => void
  errorImage?: string
  isBlocked?: boolean
  withDurationLabel?: boolean
}

/**
 * MultimediaThumbnail will take a UUID and display an associated thumbnail (for video/image). If it fails, it resolves back to backupUuid, which loads the original, bigger media.
 * @param onLoad will be called when the asset is loaded
 * @errorImage will be displayed instead of the default error
 * for the rest of params, lookup Image/Video Thumbnail
 */
const MultimediaThumbnail = ({
  thumbnailUuid,
  backupUuid,
  videoProps,
  imageProps,
  onLoad,
  errorImage,
  isBlocked,
  withDurationLabel,
}: MultimediaThumbnailProps) => {
  const theme = useTheme()
  const { assetUrl, loading, assetType } = useAssetUrl(thumbnailUuid)
  const {
    assetUrl: backupAssetUrl,
    loading: backupLoading,
    assetType: backupAssetType,
  } = useAssetUrl(backupUuid, {
    isAllowedToLoad: loading === LoadingStatus.Failed,
  })

  const loadingWithBackup =
    loading === LoadingStatus.Failed && backupUuid ? backupLoading : loading

  const assetUrlWithBackup =
    loading === LoadingStatus.Failed && backupUuid ? backupAssetUrl : assetUrl

  const isImage = isImageAsset(
    backupLoading === LoadingStatus.Succeeded && backupUuid
      ? backupAssetType
      : assetType
  )

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) onLoad?.()
  }, [loading, onLoad])

  const preview = assetUrlWithBackup ? (
    isImage ? (
      withDurationLabel && imageProps?.duration ? (
        <ImageThumbnailWithDuration
          {...imageProps}
          thumbnail={assetUrlWithBackup}
          isBlocked={isBlocked}
        />
      ) : (
        <ImageThumbnail
          {...imageProps}
          thumbnail={assetUrlWithBackup}
          isBlocked={isBlocked}
        />
      )
    ) : (
      <VideoThumbnail
        {...videoProps}
        thumbnail={assetUrlWithBackup}
        isBlocked={isBlocked}
        withDurationLabel={withDurationLabel}
      />
    )
  ) : null

  return (
    <>
      {loadingWithBackup === LoadingStatus.Pending ||
      (loadingWithBackup === LoadingStatus.Idle && thumbnailUuid) ? (
        <Skeleton width="100%" height="100%" />
      ) : loadingWithBackup === LoadingStatus.Succeeded &&
        assetUrlWithBackup ? (
        preview
      ) : (
        <ImageThumbnail
          thumbnail={errorImage}
          backgroundSize="150%"
          backgroundColor={theme.palette.background.overlay.contrast}
        >
          {!errorImage && (
            <ErrorIcon
              color="disabled"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </ImageThumbnail>
      )}
    </>
  )
}

export default MultimediaThumbnail
