import { createAction } from '@reduxjs/toolkit'
import {
  Assets,
  Devices,
  LayoutDesignerUtils,
  MediaLibrary,
  MediaTaskStatus,
  TransformVideoParams,
  UpdateOTAParams,
} from 'shared/services'
import { SNACKBARS } from 'shared/strings'
import {
  PreventFromRedirectionOptions,
  RedirectionModalProps,
} from 'shared/types'
import { createAsyncThunkWithErrorHandling, delay } from 'shared/utils'

const layoutDesignerUtils = new LayoutDesignerUtils()
const devices = new Devices()
const assets = new Assets()

const MODULE_NAME = 'utils'
const TRANSFORM_POOLING_COUNT = 20
const POOLING_DELAY = 5000

export const setPreventFromRedirection = createAction<PreventFromRedirectionOptions>(
  `${MODULE_NAME}/setPreventFromRedirection`
)

export const setOpenPreventRedirectionModal = createAction<RedirectionModalProps>(
  `${MODULE_NAME}/setOpenPreventRedirectionModal`
)

export const setPreventFromRedirectionOptions = createAction<any>(
  `${MODULE_NAME}/setPreventFromRedirectionOptions`
)

export const getRssFeedFromUrl = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getRssFeedFromUrl`,
  payloadCreator: async (url: string) =>
    layoutDesignerUtils.getRssFeedFromUrl(url),
})

export const updateOTA = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updateOTA`,
  payloadCreator: async (params: UpdateOTAParams) => devices.updateOTA(params),
  ...SNACKBARS.updateOTA,
})

export const resetUpdateOTA = createAction(`${MODULE_NAME}/resetUpdateOTA`)

export const resetTransformVideo = createAction(
  `${MODULE_NAME}/resetTransformVideo`
)

export const transformVideo = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/transformVideo`,
  payloadCreator: async (params: TransformVideoParams, thunkApi) => {
    const { taskId } = await assets.transformVideo(params)
    let poolingCount = 0
    while (poolingCount < TRANSFORM_POOLING_COUNT) {
      const { status, detail } = await assets.getMediaTaskStatus(taskId)
      await delay(POOLING_DELAY)
      if (status === MediaTaskStatus.Failure) throw new Error(detail.toString())
      if (status === MediaTaskStatus.Success) break // transformation started and ended
      poolingCount++
    }

    // TODO: remove later if backend provides solution
    await new MediaLibrary().editMediaFile({
      mediaUuid: params.mediaUuid,
      mediaCategoryId: 902,
    })
    return true
  },
  ...SNACKBARS.transformVideo,
})
