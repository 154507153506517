import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  styled,
  Typography,
} from '@material-ui/core'
import { Field } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFileButton } from 'shared/components'
import { MEDIA_LIBRARY_FOLDER } from 'shared/config'
import { mapAcceptedFileTypes } from 'shared/utils'
import { actions, selectors } from '../../store'
import { MediaLibraryPicker } from './MediaLibraryPicker'

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: theme.breakpoints.values.sm,
  },
  '& .MuiButton-label': {
    whiteSpace: 'nowrap',
  },
}))

const MediaLibraryModal = () => {
  const { t } = useTranslation()
  const { isOpen, assetsType, numberOfItems, widgetType } = useSelector(
    selectors.mediaPicker.getMediaLibraryPickerState
  )
  const dispatch = useDispatch()
  const [isPickerOpen, setPickerOpen] = useState(false)
  const fetchImages = async (files: FileList) =>
    dispatch(
      actions.mediaPicker.uploadMediaLibraryPickerFiles({
        files,
        path: MEDIA_LIBRARY_FOLDER,
      })
    )
  const onClose = () => {
    setPickerOpen(false)
    dispatch(actions.mediaPicker.closeMediaLibraryPicker())
  }

  return (
    <>
      <ModalDialog
        maxWidth="md"
        open={isOpen && !isPickerOpen}
        onClose={onClose}
      >
        <DialogTitle>
          <Typography variant="h2" align="center" gutterBottom>
            {t('mediaLibraryModal.selectionTitle', {
              count: numberOfItems,
            })}
          </Typography>
          <Typography variant="body2" align="center" color="inherit">
            {t('mediaLibraryModal.selectionSubtitle', {
              count: numberOfItems,
            })}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <Field
                fullWidth
                color="secondary"
                component={UploadFileButton}
                name="media-library-modal-file-upload"
                label={t('mediaLibraryModal.uploadNewAsset', {
                  count: numberOfItems,
                })}
                accept={mapAcceptedFileTypes({
                  widgetType,
                  assetType: assetsType,
                })}
                size="medium"
                withSameUploadsInRow
                multiple={numberOfItems > 1}
                setUploaded={(files: FileList) => fetchImages(files)}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => setPickerOpen(true)}
              >
                {t(`mediaLibraryModal.pickFromMediaLibrary`)}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </ModalDialog>
      <MediaLibraryPicker
        isOpen={isOpen && isPickerOpen}
        onGoBack={() => setPickerOpen(false)}
        onClose={onClose}
        numberOfItems={numberOfItems}
        mediaType={assetsType}
        widgetType={widgetType}
      />
    </>
  )
}

export default MediaLibraryModal
