import { styled, Theme } from '@material-ui/core'
import { SharpArrow, Empty, Error, Close, Magnifier } from 'shared/icons'
import ArrowUp from './ArrowUp'
import Resize from './Resize'

const DEFAULT_NORESULT_ICON_SIZE = 65
const BIG_NORESULT_ICON_SIZE = 150

export const SharpArrowSmall = styled(SharpArrow)({
  width: 12,
  height: 12,
})

export const CollapsibleSharpArrowSmall = styled(SharpArrowSmall)(
  ({ collapsed }: { collapsed: Boolean }) => ({
    transform: collapsed ? 'rotate(-90deg)' : 'rotate(90deg)',
  })
)

interface SharpArrowRightProps {
  withSpacing?: boolean
}
export const SharpArrowRight = styled(SharpArrowSmall)<
  Theme,
  SharpArrowRightProps
>(({ theme, withSpacing = true }) => ({
  marginLeft: withSpacing ? theme.spacing(1) : 0,
  transform: 'rotate(180deg)',
}))

interface NoResultProps {
  isCentered?: boolean
  isBig?: boolean
}

export const NoResult = styled(Empty)<Theme, NoResultProps>(
  ({ theme, isCentered, isBig = false }) => ({
    width: isBig ? BIG_NORESULT_ICON_SIZE : DEFAULT_NORESULT_ICON_SIZE,
    height: isBig ? BIG_NORESULT_ICON_SIZE : DEFAULT_NORESULT_ICON_SIZE,
    margin: theme.spacing(isCentered ? 'auto' : 3, 'auto'),
    color: theme.palette.background.overlay.primary,
    display: 'block',
  })
)

export const ErrorIcon = styled(Error)({
  fontSize: '3.5rem',
})

const INPUT_ICON_SIZE = '0.85rem'

export const ClearInputIcon = styled(Close)<Theme>(({ theme }) => ({
  color: theme.palette.declined.contrastText,
  fontSize: INPUT_ICON_SIZE,
}))

interface TextFieldMagnifierProps {
  customColor?: string
}

export const TextFieldMagnifier = styled(Magnifier)<
  Theme,
  TextFieldMagnifierProps
>(({ theme, customColor }) => ({
  marginRight: theme.spacing(2),
  fontSize: INPUT_ICON_SIZE,
  color: customColor ? customColor : theme.palette.declined.contrastText,
}))

export const CenteredResize = styled(Resize)({
  transform: 'translateY(-10%)',
})

export const Tile = styled('div')(({ theme }) => ({
  height: 127,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ArrowDown = styled(ArrowUp)({ transform: 'rotate(180deg)' })
