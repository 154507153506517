import { useState, useEffect } from 'react'
import { debounce } from 'ts-debounce'
import { Size } from 'shared/types'

const DEBOUNCE_TIME = 500

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Partial<Size<number, number>>>({
    width: undefined,
    height: undefined,
  })

  const handleResize = debounce(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, DEBOUNCE_TIME)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
