import { WidgetType } from 'shared/types'
import { ERRORS } from 'shared/utils'

const throwError = (message: string) => {
  throw new Error(message)
}

const config = {
  TOKEN_KEY: 'token',
  API_URL: process.env.REACT_APP_API_URL || throwError(ERRORS.missingApiUrl),
  SUPPORTED_WIDGETS: [
    WidgetType.Background,
    WidgetType.Image,
    WidgetType.PositionIndicatorText,
    WidgetType.DirectionArrows,
    WidgetType.Text,
    WidgetType.Video,
    WidgetType.LiftStatus,
    WidgetType.DateTime,
    WidgetType.LiveVideo,
    WidgetType.FlippingImages,
    WidgetType.ImagesByFloor,
    WidgetType.Weather,
    WidgetType.RssFeed,
    WidgetType.Pictogram,
    WidgetType.Playlist,
  ],
  REORDER_LISTS: {
    FlippingImages: 'FlippingImages',
    MediaLibrary: 'MediaLibrary',
    PlaylistCreator: 'PlaylistCreator',
  },
  SUPPORTED_FILE_TYPES: {
    image: '.jpg, .png, .jpeg, .bmp, .svg',
    video: '.mp4, .avi, .mov, .mkv, .mpg',
    audio: '.mp3, .wav',
    default: '',
  },
  SUPPORTED_MEDIA_ASSET_TYPES: [
    '.jpg',
    '.png',
    '.jpeg',
    '.bmp',
    '.mp4',
    '.mov',
    '.svg',
    '.ttf',
  ],
  DISPLAYS_WITHOUT_LIVE_PREVIEW: ['IS2LX'],
  SUPPORTED_ARROW_WIDGET_FORMATS: '.png, .svg',
  DEFAULT_METRIC_VALUE: 'px',
  DEFAULT_DIRECTIONAL_ARROW_NAME: '',
  DEFAULT_POSITION_INDICATOR_TEXT: 'G01',
}

export default config

export { DEFAULT_NAMESPACE, default as i18n } from './i18n'
export { default as theme } from './theme'
export { default as paths } from './paths'
export { default as consoleFilter } from './consoleFilter'
export { default as backgroundColors } from './backgroundColors'
export { default as scheduleColors } from './scheduleColors'
export { default as constants } from './constants'
export { default as Languages } from './languages'
