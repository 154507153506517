import { GridCellParams } from '@material-ui/data-grid'
import { AxiosError } from 'axios'
import config from 'config'
import { format, parseISO } from 'date-fns'
import { DefaultRequestBody, RequestParams, RestRequest } from 'msw'
import { DATETIME_FORMAT } from 'shared/config'
import {
  ApiComplexError,
  AppEnv,
  LoadingStatus,
  MediaAssetType,
  SelectOption,
  Size,
  WidgetProperty,
  WidgetType,
} from 'shared/types'

export const percentageToFraction = (percentage: string) =>
  parseFloat(percentage) / 100

export const addIfNotContained = <T>(value: T, array: T[]) =>
  array.includes(value) ? array : [...array, value]

export const toPercentage = (absoluteValue: number, parentValue: number) =>
  `${(absoluteValue * 100) / parentValue}%`

export const limitPercentageValue = (value: string, limit: number) =>
  `${Math.min(Number(value.split('%')[0]), limit)}%`

export const complementPercentageSize = (
  value: Size<string, string>
): Size<string, string> => ({
  width: `${(1 / (parseFloat(value.width) / 100)) * 100}%`,
  height: `${(1 / (parseFloat(value.height) / 100)) * 100}%`,
})

type ToScale<K extends string> = { [key in K]: number }
type ScalingValue<K extends string> = { [key in K]: string }
interface ScalePyPercentageProps<K extends string> {
  values: ToScale<K>
  by: ScalingValue<K>
}

export const scaleByPercentage = <K extends string>({
  values,
  by,
}: ScalePyPercentageProps<K>) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        percentageToFraction(typeof by === 'string' ? by : by[key as K]) *
        (value as number),
    }),
    {}
  ) as ToScale<K>

interface ManipulatePercentagesProps<K extends string> {
  values: ScalingValue<K>
  by: ScalingValue<K>
}

export const dividePercentages = <K extends string>({
  values,
  by,
}: ManipulatePercentagesProps<K>) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${
        (percentageToFraction(value as string) /
          percentageToFraction(by[key as K])) *
        100
      }%`,
    }),
    {}
  ) as ScalingValue<K>

export const multiplyPercentages = <K extends string>({
  values,
  by,
}: ManipulatePercentagesProps<K>) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${
        percentageToFraction(value as string) *
        percentageToFraction(by[key as K]) *
        100
      }%`,
    }),
    {}
  ) as ScalingValue<K>

export const toPercentageOf = <K extends string>(
  values: ToScale<K>,
  of: ToScale<K>
) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${((value as number) * 100) / of[key as K]}%`,
    }),
    {}
  ) as ScalingValue<K>

export const ratioToPercentage = (ratio: number) => `${ratio * 100}%`

export const sleep = (time: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })

export const addLeadingZero = (value: number) => `${value}`.padStart(2, '0')

export const convertMinutesToSeconds = (minutes: number) => minutes * 60 * 1000

export const convertSecondsToFullTime = (seconds: number) => {
  const h = Math.floor(seconds / 60 / 60)
  const m = Math.floor(seconds / 60) - h * 60
  const s = Math.floor(seconds % 60)

  return `${addLeadingZero(h)}:${addLeadingZero(m)}:${addLeadingZero(s)}`
}

export const convertFullTimetoSeconds = (fullTime: string) => {
  const [hours, minutes, seconds] = fullTime
    .split(':')
    .map(part => parseInt(part))

  return hours * 60 * 60 + minutes * 60 + seconds
}

export const mapFormValuesToProps = <
  T extends WidgetProperty,
  U extends {} = {}
>(
  values: U
) =>
  Object.entries(values).map(([name, value]) => ({
    name,
    value,
  })) as T[]

type ValueOf<T> = T[keyof T]

export const mapObject = <T extends {}, U = any>(
  obj: T,
  mapFn: (value: ValueOf<T>) => U
) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, mapFn(value as ValueOf<T>)])
  ) as Record<keyof T, U>

export const isImageAsset = (assetType: string | null) =>
  assetType?.includes(MediaAssetType.Image)

export const isVideoAsset = (assetType?: string | null) =>
  assetType?.includes(MediaAssetType.Video)

export const getPageTypePaginationParams = (page: number, limit: number) => ({
  skip: page * limit,
  limit,
})

export const renderDatetimeCell = (field: string) => (params: GridCellParams) =>
  format(parseISO(`${params.row[field]}+0`), DATETIME_FORMAT)

export const downloadFileFromBlob = (file: Blob, name: string) => {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(file)
  a.download = name
  a.click()
  URL.revokeObjectURL(a.href)
  a.remove()
}

export const safelyParseJSON = <ExpectedType>(json: string) => {
  try {
    return JSON.parse(json) as ExpectedType
  } catch (e) {
    return null
  }
}

export const convertStringArrayToJSON = (array: (string | false)[]) => {
  const output = (array.reduce(
    (builtString, nextImage) =>
      `${builtString}, ${
        typeof nextImage === 'string' ? `"${nextImage}"` : false
      }`,
    ''
  ) as string).substring(1)

  return `[${output}]`
}

export const extractFileName = (filename?: string) => {
  if (!filename) return
  const splitedFileName = filename.split('.')
  const fileExtension = splitedFileName.pop()
  let fileName = ''
  splitedFileName.forEach(name => {
    fileName = fileName.concat(name)
  })
  return { fileName, fileExtension }
}

export const createServiceURL = (module: string, endpoint: string) =>
  `/${module}/${endpoint}`

export const createHandlerURL = (serviceURL: string) =>
  `${process.env.REACT_APP_API_URL}${serviceURL}`

export const trimNumber = (value: number, precision: number = 2) =>
  Number(value.toFixed(precision))

export const getRandomString = () => Math.random().toString(36).substring(2, 15)

export const getMockParam = <T extends DefaultRequestBody>(
  paramName: string,
  req: RestRequest<T | undefined, RequestParams>
) =>
  req.url.search
    .split('&')
    .find(item => item.includes(paramName))
    ?.split('=')[1]

export const hideOnEnv = (env: AppEnv | AppEnv[] | 'all') => {
  if (env === 'all') return false
  if (Array.isArray(env)) {
    const envExist = env.find(item => item === process.env.REACT_APP_ENV)
    return !envExist
  } else return process.env.REACT_APP_ENV !== env
}

export const setLogoutError = (error: string) => {
  localStorage.setItem('logoutError', error)
}

export const resetLogoutError = () => {
  localStorage.removeItem('logoutError')
}

export const getLogoutError = () => localStorage.getItem('logoutError')

export const removeDuplicates = <T>(
  array: T[],
  mapping: (item: T) => string
) => {
  let seen = new Set()
  return array.filter(item => {
    let k = mapping(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const convertAspectRatioStringToFraction = (aspectRatio: string) => {
  const [width, height] = aspectRatio.split(':').map(Number)

  return width / height
}

export const decodeHoursString = (hoursString: string) => {
  const parts = hoursString.split(':')
  return {
    hours: Number(parts[0]),
    minutes: parts[1] && parts[1].length > 0 ? Number(parts[1]) : undefined,
    seconds: parts[2] && parts[2].length > 0 ? Number(parts[2]) : undefined,
  }
}

interface AcceptedFileProps {
  assetType?: MediaAssetType
  widgetType?: WidgetType
  parseForApi?: boolean
}

export const mapAcceptedFileTypes = (props?: AcceptedFileProps): string => {
  const { parseForApi, assetType, widgetType } = props || {}

  if (parseForApi)
    return mapAcceptedFileTypes({ assetType, widgetType })
      .replaceAll(' ', '')
      .replaceAll('.', '')
  if (widgetType === WidgetType.DirectionArrows)
    return config.SUPPORTED_ARROW_WIDGET_FORMATS
  else {
    switch (assetType) {
      case MediaAssetType.Image:
        return config.SUPPORTED_FILE_TYPES.image
      case MediaAssetType.Video:
        return config.SUPPORTED_FILE_TYPES.video
      default:
        return `${config.SUPPORTED_FILE_TYPES.image}, ${config.SUPPORTED_FILE_TYPES.video}`
    }
  }
}

export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor(duration % 60)
  const minutesOutput = minutes < 10 ? `0${minutes}` : minutes
  const secondsOutput = seconds < 10 ? `0${seconds}` : seconds

  return `${minutesOutput}:${secondsOutput}`
}

export const formatTimezoneDatetime = (datetime: string) => {
  const dt = new Date(datetime)
  const dtDateOnly = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, DATETIME_FORMAT)
}

export const getCurrentDatetimeWithoutTimezone = () => {
  const dt = new Date()
  return new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  ).toISOString()
}

interface ConvertToSelectOptionsProps<T> {
  options?: T[] | null
  value?: keyof T
  label?: keyof T
}

export const convertToSelectOptions = <T>({
  options,
  value,
  label,
}: ConvertToSelectOptionsProps<T>) =>
  options?.reduce<SelectOption[]>(
    (acc, item) => [
      ...acc,
      {
        value: value
          ? ((item[value] as unknown) as SelectOption['value'])
          : ((item as unknown) as SelectOption['value']),
        label: label
          ? ((item[label] as unknown) as SelectOption['label'])
          : `${item}`,
      },
    ],
    []
  ) || []

export const delay = (delay: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, delay))

export const convertComplexErrorToString = (errors: ApiComplexError) => {
  return errors
    .reduce((currentErrorString, error) => {
      const localization = error.loc
        .reduce((loc, locElem) => `${loc}.${locElem}`, '')
        .slice(1)

      return `${currentErrorString}, Field ${localization} has error: ${error.msg}`
    }, '')
    .slice(2)
}

export const convertAxiosErrorToSnackbarData = (error: unknown) => {
  const err = error as AxiosError
  let message = (err?.response?.data.detail || err.message) as
    | string
    | ApiComplexError
  if (typeof message !== 'string')
    message = convertComplexErrorToString(message)

  return {
    message,
    code: err.response?.status,
  }
}

export const isAnyLoadingPending = (...loadings: LoadingStatus[]) =>
  loadings.some(loading => loading === LoadingStatus.Pending)
