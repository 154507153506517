import { styled, Theme, Input as BaseInput, fade } from '@material-ui/core'
import { ReadOnly } from 'shared/types'

export const BORDER_SIZE = 3

type TagsBackgroundBoxProps = ReadOnly
export const TagsBackgroundBox = styled('div')<Theme, TagsBackgroundBoxProps>(
  ({ theme, readOnly }) => ({
    backgroundColor: theme.palette.background.neutral.light,
    padding: theme.spacing(2, 3),
    border: `${BORDER_SIZE}px solid ${theme.palette.declined.main}`,
    borderRadius: 3,
    color: theme.palette.accent.main,
    ...(readOnly && {
      backgroundColor: `${fade(theme.palette.accent.main, 0.08)}`,
      color: theme.palette.info.contrastText,
      fontWeight: 700,
      whiteSpace: 'pre',
      pointerEvents: 'none',
    }),

    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 0),
    },
    '& .MuiInput-underline::before': {
      borderBottom: 'unset',
    },
  })
)

export const Input = styled(BaseInput)({ width: '100%', minWidth: 160 })
