import { SvgIcon, SvgIconProps } from '@material-ui/core'

const LayoutDesigner = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 19 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.622 13.0352L4.552 14.4492L5.966 7.37821L11.344 2.00021H2C0.897 2.00021 0 2.89821 0 4.00021V18.0002C0 19.1022 0.897 20.0002 2 20.0002H16C17.103 20.0002 18 19.1022 18 18.0002V6.65621L11.622 13.0352Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.80856 8.36469L10.6366 11.1927L7.10156 11.8997L7.80856 8.36469Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4147 3.41409C19.1957 2.63309 19.1957 1.36709 18.4147 0.586086C17.6337 -0.194914 16.3677 -0.194914 15.5867 0.586086L9.22266 6.95009L12.0507 9.77809L18.4147 3.41409Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default LayoutDesigner
