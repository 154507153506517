import { Box, Button, Grid, styled, Theme } from '@material-ui/core'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ExpressiveDialogScrollbars,
  Loader,
  MaterialSelect,
  SimpleSearch,
} from 'shared/components'
import { NoResult } from 'shared/icons'
import {
  LoadingStatus,
  MediaAssetType,
  SelectedMediaLibraryItem,
  WidgetType,
} from 'shared/types'
import MediaLibraryItem from './MediaLibraryItem'
import { useMediaLibraryPickerContent } from './MediaLibraryPickerContent.utils'
import UploadFile from './UploadFileModal/UploadFileModal'

interface FilterBoxProps {
  isInDrawer: boolean
}
const FilterBox = styled(Box)<Theme, FilterBoxProps>(
  ({ theme, isInDrawer }) => ({
    maxWidth: isInDrawer ? 'auto' : 400,
    margin: 'auto',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  })
)

interface MediaLibraryPickerContentProps {
  isInDrawer?: boolean
  selected?: SelectedMediaLibraryItem[]
  mediaType?: MediaAssetType
  numberOfItems?: number
  widgetType?: WidgetType
  onSelect?: (asset: SelectedMediaLibraryItem | null) => void
}

const MediaLibraryPickerContent = ({
  selected,
  mediaType,
  numberOfItems,
  onSelect,
  widgetType,
  isInDrawer = false,
}: MediaLibraryPickerContentProps) => {
  const { t } = useTranslation()
  const {
    query,
    items,
    loading,
    selectedCategory,
    categoryOptions,
    totalNumberOfItems,
    setQuery,
    onSearch,
    onChange,
    onLoadMore,
  } = useMediaLibraryPickerContent({ onSelect, mediaType })

  return (
    <ExpressiveDialogScrollbars
      perRow={isInDrawer ? null : 6}
      length={isInDrawer ? null : items.length}
      fullHeight={isInDrawer}
    >
      <FilterBox isInDrawer={isInDrawer}>
        <SimpleSearch
          paddingTop={0}
          isBold={false}
          query={query}
          loading={loading === LoadingStatus.Pending}
          setQuery={setQuery}
          onSearch={onSearch}
          mediaType={mediaType}
          widgetType={widgetType}
        />
        <MaterialSelect
          options={categoryOptions}
          onChange={onChange}
          value={selectedCategory}
          isDefaultCase
        />
        {isInDrawer && <UploadFile options={categoryOptions.slice(1)} />}
      </FilterBox>
      {items.length === 0 && loading !== LoadingStatus.Pending ? (
        <NoResult isCentered isBig />
      ) : (
        <Grid container spacing={2}>
          {items.map(({ fileUuid, ...params }) => {
            const isBlocked = Boolean(
              numberOfItems &&
                selected &&
                selected.length >= numberOfItems &&
                !selected?.find(item => item.uuid === fileUuid)
            )
            return (
              <MediaLibraryItem
                fileUuid={fileUuid}
                isInDrawer={isInDrawer}
                key={fileUuid}
                onSelect={onSelect}
                isSelected={Boolean(
                  selected?.find(item => item.uuid === fileUuid)
                )}
                isBlocked={isBlocked}
                {...params}
              />
            )
          })}
        </Grid>
      )}
      <Grid container justifyContent="center">
        <Grid item>
          <Box my={5}>
            {loading === LoadingStatus.Pending ? (
              <Loader isFullHeight />
            ) : (
              items.length > 0 &&
              items.length !== totalNumberOfItems && (
                <Button color="secondary" onClick={onLoadMore}>
                  {t('mediaLibraryModal.loadMore')}
                </Button>
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </ExpressiveDialogScrollbars>
  )
}

export default memo(MediaLibraryPickerContent)
