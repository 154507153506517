import { CenterBox } from '../CenterBox'
import Loader from '../Loader'

const LOADER_BOX_SIZE = 420
const LOADER_SIZE = 50

const VideoTrimLoader = () => {
  return (
    <CenterBox height={LOADER_BOX_SIZE}>
      <Loader size={LOADER_SIZE} isFullHeight />
    </CenterBox>
  )
}

export default VideoTrimLoader
