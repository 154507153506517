import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid } from '@material-ui/core'
import {
  UserProfileAvatar,
  UserProfileCardGrid,
  UserProfileContainer,
  DropdownIcon,
  Collapse,
  StyledTypography,
} from './UserProfile.style'
import { useUserProfile } from './UserProfile.utils'
import { GetMeResponse } from 'shared/services'

type UserProfileProps = Partial<
  Pick<GetMeResponse, 'firstName' | 'lastName' | 'avatarImg'>
> | null
const UserProfile = (props: UserProfileProps) => {
  const { firstName, lastName, avatarImg } = props || {}
  const { t } = useTranslation()
  const { isOpen, toggleOpen, assetUrl, logout } = useUserProfile(avatarImg)

  return (
    <UserProfileContainer open={isOpen}>
      <Grid container>
        <UserProfileCardGrid container onClick={toggleOpen}>
          <Grid item xs={4}>
            <UserProfileAvatar
              variant="square"
              alt={t('userProfile.avatarAltText')}
              src={assetUrl || ''}
            />
          </Grid>
          <Grid
            item
            xs={7}
            container
            direction="column"
            justifyContent="center"
          >
            <Grid item>
              <StyledTypography color="inherit">{firstName}</StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography color="inherit">
                <b>{lastName}</b>
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            xs={1}
          >
            <DropdownIcon flipped={isOpen} />
          </Grid>
        </UserProfileCardGrid>
        <Grid item xs={12}>
          <Collapse in={isOpen}>
            <Box py={3} px={4}>
              <Button fullWidth color="secondary" size="small" onClick={logout}>
                {t('userProfile.menuItems.logout')}
              </Button>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </UserProfileContainer>
  )
}

export default memo(UserProfile)
