import { SvgIcon, SvgIconProps } from '@material-ui/core'

const SharpArrow = (props: SvgIconProps) => (
  <SvgIcon width="7" height="11" viewBox="0 0 7 11" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.121 8.56705L2.7833 5.22935L6.121 1.89165L5.22935 1L1 5.22935L5.22935 9.4587L6.121 8.56705Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </SvgIcon>
)

export default SharpArrow
