import { makeStyles } from '@material-ui/core'
import { DRAWER_MENU_WIDTH, DRAWER_WIDTH, TOOLBAR_HEIGHT } from 'shared/config'
export interface StylesProps {
  isAuthenticated: boolean
}

export const useStyles = makeStyles({
  containerRoot: {
    zIndex: 50,
    transform: 'none',
    width: (props: StylesProps) =>
      props.isAuthenticated ? `calc(100vw - ${DRAWER_WIDTH}px)` : '100%',
    left: (props: StylesProps) => (props.isAuthenticated ? DRAWER_WIDTH : 0),
    top: (props: StylesProps) => (props.isAuthenticated ? TOOLBAR_HEIGHT : 0),
  },
  message: {
    paddingRight: (props: StylesProps) =>
      props.isAuthenticated ? DRAWER_MENU_WIDTH : 0,
  },
  variantSuccess: { borderRadius: 0 },
  variantError: { borderRadius: 0 },
  variantInfo: { borderRadius: 0 },
  variantWarning: { borderRadius: 0 },
})
