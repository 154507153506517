import { Box, Theme, Tooltip, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Info as BaseInfo } from 'shared/icons'

interface HelperTextProps {
  text?: string
  iconSize?: number
  explanation: string
}

type IconProps = Pick<HelperTextProps, 'iconSize'>
const Info = styled(BaseInfo)<Theme, IconProps>(({ iconSize }) => ({
  fontSize: iconSize,
}))

const StyledTooltip = styled(Tooltip)({
  cursor: 'help',
})

const HelperText = ({ text, explanation, iconSize = 12 }: HelperTextProps) => (
  <StyledTooltip title={explanation} placement="bottom">
    <Box display="flex" alignItems="center" gridGap={5}>
      <Info iconSize={iconSize} />
      {text && <Typography color="primary">{text}</Typography>}
    </Box>
  </StyledTooltip>
)

export default HelperText
