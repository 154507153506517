import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { debounce } from 'ts-debounce'
import config from 'config'
import {
  ArrowPositionVariant,
  DirectionArrowWidgetProperty,
  Size,
  PositionIndicatorTextBehaviour,
  PositionIndicatorTextProperty,
  PositionIndicatorTransitionType,
  Styles,
  TextAlignment,
  TextBehaviour,
  TextWidgetProperty,
  DateTimeWidgetProperty,
  WidgetProperty,
  WidgetType,
  AbsoluteSize,
  LayoutWidget,
  LiftStatusProperty,
  MapToProperties,
  BooleanStringType,
  MetaDataProperty,
  RssFeedWidgetProperty,
  MetadataName,
  Property,
  ApiWidget,
  SlideshowPropertyName,
  PictogramEventContentType,
  PictogramWidgetProperty,
  ProgressStep,
  TextWidgetPropertyName,
} from 'shared/types'
import { WidgetManager } from 'shared/services'
import { mapFormValuesToProps } from 'shared/utils'
import {
  DirectionArrowProperties,
  LiftStatusProperties,
  PositionIndicatorTextProperties,
  TextWidgetProperties,
  DateTimeWidgetProperties,
  LiveVideoProperties,
} from '../types'
import { MODULE_NAME } from '../strings'
import { actions, selectors } from '../store'
import {
  DateTimeFormatOptions,
  DEFAULT_TEXT_SIZE,
  DEFAULT_TEXT_WIDGET_COLOR,
  MIN_SIZE,
} from '../config'
import {
  MappedProperties,
  UpdateWidgetEditorOptions,
  useOnSubmitWidgetEditorProps,
} from './widgets.types'

export const createInitialWidgetValues = (
  properties: WidgetProperty[],
  initialValues:
    | DirectionArrowProperties
    | TextWidgetProperties
    | DateTimeWidgetProperties
    | PositionIndicatorTextProperties
    | LiftStatusProperties
    | LiveVideoProperties
) =>
  properties.reduce(
    (obj, item) => ({ ...obj, [item.name]: item.value }),
    initialValues
  )

export const useFontFamilyAndSizeInitialValues = (
  widget: ApiWidget<any> | null
) => {
  const properties = widget?.properties || []
  const fonts = useSelector(selectors.getDisplayFonts)
  const defaultFont = fonts && fonts?.length !== 0 ? fonts[0].ttfUuidFile : ''
  var closestFontSize = fonts?.[0]?.sizes.reduce((prev, curr) =>
    Math.abs(curr - DEFAULT_TEXT_SIZE) < Math.abs(prev - DEFAULT_TEXT_SIZE)
      ? curr
      : prev
  )
  const defaultFontSize =
    closestFontSize || fonts?.[0]?.sizes[0] || DEFAULT_TEXT_SIZE

  const font =
    WidgetManager.getProperty<TextWidgetProperty>(
      TextWidgetPropertyName.font,
      properties
    ) || defaultFont
  const fontSize =
    WidgetManager.getProperty<TextWidgetProperty>(
      TextWidgetPropertyName.fontSize,
      properties
    ) || defaultFontSize.toString()
  const color =
    WidgetManager.getProperty<TextWidgetProperty>(
      TextWidgetPropertyName.color,
      properties
    ) || DEFAULT_TEXT_WIDGET_COLOR
  const backgroundColor =
    WidgetManager.getProperty<TextWidgetProperty>(
      TextWidgetPropertyName.backgroundColor,
      properties
    ) || ''

  return { font, fontSize, color, backgroundColor }
}

export const useCommonTextInitialValues = (widget: ApiWidget<any> | null) => {
  const properties = widget?.properties || []
  const bold =
    (WidgetManager.getProperty(
      TextWidgetPropertyName.bold,
      properties
    ) as BooleanStringType) || BooleanStringType.False
  const italic =
    (WidgetManager.getProperty(
      TextWidgetPropertyName.italic,
      properties
    ) as BooleanStringType) || BooleanStringType.False
  const underline =
    (WidgetManager.getProperty(
      TextWidgetPropertyName.underline,
      properties
    ) as BooleanStringType) || BooleanStringType.False
  const uppercase =
    (WidgetManager.getProperty(
      TextWidgetPropertyName.uppercase,
      properties
    ) as BooleanStringType) || BooleanStringType.False

  return {
    ...useFontFamilyAndSizeInitialValues(widget),
    [Styles.Bold]: bold,
    [Styles.Italic]: italic,
    [Styles.Underline]: underline,
    [Styles.Uppercase]: uppercase,
  }
}

export const usePositionIndicatorInitialTextValues = (
  properties: PositionIndicatorTextProperty[] = [],
  layoutProperties: LayoutWidget['layoutProperties'] = {}
) => {
  const initialValues = useCommonTextInitialValues(null)
  const values = createInitialWidgetValues(properties, {
    ...initialValues,
    transition: PositionIndicatorTextBehaviour.StaticText,
    transitionType: PositionIndicatorTransitionType.FadeInOut,
    content: config.DEFAULT_POSITION_INDICATOR_TEXT,
    ...layoutProperties,
  }) as PositionIndicatorTextProperties

  return values
}

export const useLiftStatusInitialValues = (
  properties: LiftStatusProperty[] = [],
  layoutProperties: LayoutWidget['layoutProperties'] = {}
) => {
  const initialValues = useCommonTextInitialValues(null)
  const { t } = useTranslation(MODULE_NAME)
  const values = createInitialWidgetValues(properties, {
    ...initialValues,
    content: t('widgets.liftStatusPicker.defaultMessage'),
    ...layoutProperties,
  }) as LiftStatusProperties

  return values
}

export const useTextWidgetInitialValues = (
  properties:
    | TextWidgetProperty[]
    | RssFeedWidgetProperty[]
    | PictogramWidgetProperty[] = []
): TextWidgetProperties => {
  const { t } = useTranslation(MODULE_NAME)
  const baseInitialValues = useCommonTextInitialValues(null)
  const initialValues = {
    ...baseInitialValues,
    content: t('widgets.textEditor.contentDefaultValue'),
    scrollingText: TextBehaviour.Fixed,
    alignment: TextAlignment.Left,
    scrollingTextDirection: '',
  }
  const values = createInitialWidgetValues(
    properties,
    initialValues
  ) as TextWidgetProperties

  return values
}

export const useDateTimeWidgetInitialValues = (
  properties: DateTimeWidgetProperty[] = []
): DateTimeWidgetProperties => {
  const initialValues = {
    ...useFontFamilyAndSizeInitialValues(null),
    dateTimeFormat: DateTimeFormatOptions[0].value,
    content: new Date().toDateString(),
  }
  const values = createInitialWidgetValues(
    properties,
    initialValues
  ) as DateTimeWidgetProperties

  return values
}

export const useDirectionArrowWidgetValues = (
  properties: DirectionArrowWidgetProperty[] = []
): DirectionArrowProperties => {
  const initialValues = {
    color: '',
    position: ArrowPositionVariant.TopLeft,
  }
  const values = createInitialWidgetValues(
    properties,
    initialValues
  ) as DirectionArrowProperties

  return values
}

const DEBOUNCE_TIMEOUT = 2000

const mapFormValuesToPropsAndLayoutProps = <
  T extends {},
  U extends WidgetProperty
>(
  values: T,
  layoutPropertyNames?: (keyof LayoutWidget['layoutProperties'])[]
): MappedProperties<U> => {
  if (layoutPropertyNames) {
    const properties = mapFormValuesToProps<U>(values).filter(
      property =>
        !layoutPropertyNames.includes(
          property.name as keyof LayoutWidget['layoutProperties']
        )
    )
    const layoutProperties = Object.entries(values).reduce(
      (props, [name, value]) =>
        layoutPropertyNames.includes(
          name as keyof LayoutWidget['layoutProperties']
        )
          ? { ...props, [name]: value }
          : props,
      {}
    )
    return { properties, layoutProperties }
  } else return { properties: mapFormValuesToProps<U>(values) }
}

export const useUpdateWidgetEditor = <T extends {}, U extends WidgetType>({
  isEditingWidget,
  updateFunction,
  continuousFieldNames,
  discreteFieldNames,
  layoutPropertyNames,
  widgetType,
}: UpdateWidgetEditorOptions<T, U>) => {
  const { submitForm, values, isValid } = useFormikContext<T>()
  const updateWidget = useCallback(debounce(submitForm, DEBOUNCE_TIMEOUT), [])
  const dispatch = useDispatch()
  const continuousDependencies = continuousFieldNames.map(name => values[name])
  const discreteDependencies = discreteFieldNames.map(name => values[name])

  useEffect(() => {
    dispatch(
      updateFunction({
        isValid,
        widgetType,
      })
    )
  }, [isValid, isEditingWidget, dispatch])

  const updateAddWidgetDependencies = useCallback(() => {
    const { properties, layoutProperties } = mapFormValuesToPropsAndLayoutProps<
      T,
      MapToProperties<U>
    >(values, layoutPropertyNames)

    dispatch(
      updateFunction({
        isValid,
        properties,
        layoutProperties,
        widgetType,
      })
    )
  }, [...continuousDependencies, isValid, dispatch])

  useEffect(() => {
    if (isEditingWidget) updateWidget()
  }, continuousDependencies)

  useEffect(() => {
    if (isEditingWidget) submitForm()
    else {
      const {
        properties,
        layoutProperties,
      } = mapFormValuesToPropsAndLayoutProps<T, MapToProperties<U>>(
        values,
        layoutPropertyNames
      )
      dispatch(
        updateFunction({
          isValid,
          properties,
          layoutProperties,
          widgetType,
        })
      )
    }
  }, [...discreteDependencies, isEditingWidget, dispatch])

  return updateAddWidgetDependencies
}

export const useOnSubmitWidgetEditor = <
  T extends {},
  U extends WidgetProperty
>({
  layoutPropertyNames = [],
  widgetId,
}: useOnSubmitWidgetEditorProps) => {
  const dispatch = useDispatch()
  const layoutWidgets = useSelector(selectors.getEditorWidgets)
  const { slot: editedSlot } = useSelector(selectors.getEditMode)

  return async (values: T) => {
    const { properties, layoutProperties } = mapFormValuesToPropsAndLayoutProps<
      T,
      U
    >(values, layoutPropertyNames)

    if (!editedSlot) return
    const index = layoutWidgets[editedSlot].findIndex(
      item => item.id === widgetId
    )

    if (widgetId && index !== -1) {
      return dispatch(
        actions.setWidgetProperties({
          slot: editedSlot,
          index,
          newProperties: properties,
          newLayoutProperties: layoutProperties,
        })
      )
    }
  }
}

export const getWidgetMetadataProperty = <T>(
  propertyName: MetadataName,
  metadata?: MetaDataProperty[]
) =>
  metadata?.find(item => item?.name === propertyName) as
    | Property<MetadataName, T>
    | undefined

export const parseWidgetSizeToNumber = (
  slotSize: AbsoluteSize,
  widgetSize?: Size
) => {
  if (!widgetSize) return { widgetWidth: 0, widgetHeight: 0 }

  const { height: widgetHeight, width: widgetWidth } = widgetSize
  const widgetHeightNumber =
    typeof widgetHeight === 'string' ? parseFloat(widgetHeight) : widgetHeight
  const widgetWidthNumber =
    typeof widgetWidth === 'string' ? parseFloat(widgetWidth) : widgetWidth
  return {
    widgetWidth: Math.round((widgetWidthNumber * slotSize.width) / 100),
    widgetHeight: Math.round((widgetHeightNumber * slotSize.height) / 100),
  }
}

export const calculateWidgetSizeFromImages = <T extends AbsoluteSize>(
  eventsList: T[]
) => {
  const size = eventsList.reduce(
    (previous, { width, height }) => {
      return {
        width: width ? Math.max(previous.width, width) : previous.width,
        height: height ? Math.max(previous.height, height) : previous.height,
      }
    },
    { width: 0, height: 0 }
  )

  const isWidthOkay = size.width >= MIN_SIZE
  const isHeightOkay = size.height >= MIN_SIZE

  if (isHeightOkay && isWidthOkay) return size
  if (isHeightOkay && !isWidthOkay) return { ...size, width: MIN_SIZE }
  if (!isHeightOkay && isWidthOkay) return { ...size, height: MIN_SIZE }
  if (!isHeightOkay && !isWidthOkay)
    return { width: MIN_SIZE, height: MIN_SIZE }
  return size
}

export const hasEventListText = (widget: ApiWidget<WidgetType.Pictogram>) => {
  return !!widget.properties.find(
    ({ name, value }) =>
      name === SlideshowPropertyName.eventsList &&
      (value as string).includes(
        `"eventType":"${PictogramEventContentType.Text}"`
      )
  )
}

export const mapSavedActiveStep = (
  baseStep: ProgressStep,
  defaultLayout: number | null
) =>
  defaultLayout && baseStep === ProgressStep.LayoutSelection
    ? ProgressStep.ContentAddition
    : baseStep
