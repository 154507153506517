import { IMaskInput } from 'react-imask'
import { forwardRef } from 'react'
import { IMaskInputProps } from 'react-imask/dist/mixin'
import { TextField, TextFieldProps } from 'formik-material-ui'
import IMask from 'imask'

type TimeInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  withoutSeconds?: boolean
  isClockTime?: boolean
} & IMaskInputProps

export const TimeInput = forwardRef<HTMLElement, TimeInputProps>(
  (
    { onChange, name, withoutSeconds = false, isClockTime = false, ...other },
    ref
  ) => {
    const mask =
      isClockTime && withoutSeconds
        ? '%%:$#'
        : isClockTime
        ? '%%:$#:$#'
        : withoutSeconds
        ? '##:$#'
        : '##:$#:$#'
    return (
      <IMaskInput
        {...other}
        //@ts-expect-error issue with TS https://github.com/uNmAnNeR/imaskjs/issues/554
        mask={mask}
        definitions={{
          '#': /[0-9]/,
          $: /[0-5]/,
        }}
        placeholderChar="0"
        blocks={{
          '%%': {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
        }}
        lazy={false}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name, value } })}
        overwrite
      />
    )
  }
)

interface TimeTextFieldProps extends TextFieldProps {
  maskProps: TimeInputProps
  withoutSeconds?: boolean
  isClockTime?: boolean
}

export const TimeTextField = (props: TimeTextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={
        {
          inputComponent: TimeInput,
          ...props.maskProps,
          inputProps: {
            withoutSeconds: props.withoutSeconds,
            isClockTime: props.isClockTime,
          },
        } as any
      }
    />
  )
}
