import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { routes } from 'modules'
import { Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { paths as globalPaths, theme } from 'config'
import {
  AlertProvider,
  DefaultSEO,
  Layout,
  LeaveModal,
  Loader,
  PrivateRoute,
} from 'shared/components'
import { useAuth } from 'shared/hooks'
import sharedRoutes from 'shared/routes'
import { AppEnv } from 'shared/types'
import { hideOnEnv } from 'shared/utils'
import paths from '../modules/layoutDesigner/paths'
import { navItems, store } from './App.utils'

const RedirectUser = () => {
  const { isAuthenticated } = useAuth()

  return (
    <>
      {hideOnEnv([AppEnv.prod, AppEnv.stage]) && (
        <Route component={() => <Redirect to={globalPaths.dashboard} />} />
      )}
      {isAuthenticated ? (
        <Redirect to={paths.editList} />
      ) : (
        <Redirect to={globalPaths.login} />
      )}
    </>
  )
}

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Suspense fallback={<Loader />}>
      <DefaultSEO />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <AlertProvider>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <LeaveModal />
                <Layout navItems={navItems}>
                  <Switch>
                    {sharedRoutes.map(route => (
                      <Route key={`sharedRoute-${route.path}`} {...route} />
                    ))}
                    {routes.map(route => (
                      <PrivateRoute key={`route-${route.path}`} {...route} />
                    ))}
                    <RedirectUser />
                  </Switch>
                </Layout>
              </BrowserRouter>
            </DndProvider>
          </AlertProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </Suspense>
  </ThemeProvider>
)

export default App
