import { Chip, styled, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ConnectionStatus } from 'shared/types'

interface StatusProps {
  active: boolean
  scale: number
}

const StyledChip = styled(Chip)<Theme, StatusProps>(
  ({ theme, active, scale }) => ({
    backgroundColor: active
      ? theme.palette.success.light
      : theme.palette.error.light,
    color: active ? theme.palette.success.main : theme.palette.error.main,
    borderRadius: 19,
    fontSize: '1rem',
    transform: `scale(${scale})`,
    pointerEvents: 'none',
  })
)

const ActiveDot = styled('div')<Theme, StatusProps>(({ theme, active }) => ({
  borderRadius: '50%',
  width: 15,
  height: 15,
  backgroundColor: active
    ? theme.palette.success.main
    : theme.palette.error.main,
  marginLeft: theme.spacing(1.5),
}))

interface ActiveChipProps {
  connectionStatus: boolean
  scale?: number
}

const ActiveChip = ({ connectionStatus, scale = 1 }: ActiveChipProps) => {
  const { t } = useTranslation()
  return (
    <StyledChip
      active={connectionStatus}
      scale={scale}
      label={t(
        `status.${
          connectionStatus ? ConnectionStatus.Active : ConnectionStatus.Inactive
        }`
      )}
      icon={<ActiveDot active={connectionStatus} scale={scale} />}
    />
  )
}

export default ActiveChip
