type Audios = string[]

export interface LiftStopAudio {
  stop: number
  audios: Audios
}

export type UpdateAudioFloorsResponse = LiftStopAudio[]

export enum AudioEventsType {
  doorsOpening = 'doorsOpening',
  doorsClosing = 'doorsClosing',
  liftGoingUp = 'liftGoingUp',
  liftGoingDown = 'liftGoingDown',
  liftOutOfService = 'liftOutOfService',
  liftOnMaintenance = 'liftOnMaintenance',
  liftOnFireService = 'liftOnFireService',
  stayCalm = 'stayCalm',
}

export type AudioEvent = {
  eventIndex: number
  audios: string[]
}
