import {
  CreateTemplateDetailsDTO,
  LayoutSlot,
  Orientation,
  ProgressStep,
  EditTemplateDetailsDTO,
  SlotLayoutScales,
  SlotSizes,
  Tag,
  EditTemplateDetails,
  EditTemplateDetailsAPI,
} from 'shared/types'
import { getSplitPaneSize } from '../../utils'
import { Api } from '../api'
import { WidgetManager } from '../widgetManager'
import {
  AddCategoryBody,
  AddCategoryResponse,
  AddTagRequest,
  AddTemplateThumbnailResponse,
  CalculationProperties,
  DeleteTemplateResponse,
  GetCategoryListResponse,
  GetTemplatesParams,
  GetTemplatesQueryProps,
  TemplateStatus,
  UploadTemplateThumbnailParams,
} from './Templates.types'

const SUBPATH = '/layout_designer_templates'

class Templates extends Api {
  widgetManager = new WidgetManager()

  static mapSlotSizesToSections = (
    slotSizes: SlotSizes,
    scales: SlotLayoutScales
  ) =>
    Object.entries(slotSizes).map(([slotName, { width, height }]) => ({
      name: slotName,
      sizeX: width * scales[slotName as LayoutSlot].width || 0,
      sizeY: height * scales[slotName as LayoutSlot].height || 0,
    }))

  static mapSectionSizesToSlots = (
    templateDetails: EditTemplateDetailsAPI
  ): EditTemplateDetails => {
    const { sections, ...template } = templateDetails
    const slotSizes = sections
      ? sections.reduce(
          (acc, { name, sizeX, sizeY }) => ({
            ...acc,
            [name]: { width: sizeX, height: sizeY },
          }),
          {} as SlotSizes
        )
      : null

    const mappedTemplate = { ...template, slotSizes }

    return mappedTemplate
  }

  transformTemplateForSaving = (
    payload: CreateTemplateDetailsDTO,
    calculationProperties: CalculationProperties,
    slotSizes: SlotSizes
  ) => {
    const {
      orientation,
      deviceSize,
      activeStep,
      layoutPreviewSize,
      selectedLayout,
    } = calculationProperties

    const scales: SlotLayoutScales = Object.entries(
      calculationProperties.slotSizes
    ).reduce((obj, [slotName]) => {
      const splitPaneSize = getSplitPaneSize(
        selectedLayout,
        slotName as LayoutSlot,
        orientation,
        activeStep === ProgressStep.LayoutSelection
      )
      return {
        ...obj,
        [slotName]: {
          width:
            (orientation === Orientation.Landscape
              ? deviceSize[0]
              : deviceSize[1]) /
            (layoutPreviewSize.width - splitPaneSize.x),
          height:
            (orientation === Orientation.Landscape
              ? deviceSize[1]
              : deviceSize[0]) /
            (layoutPreviewSize.height - splitPaneSize.y),
        },
      }
    }, {}) as SlotLayoutScales

    const widgets = this.widgetManager.mapWidgetsToApiWidgets({
      ...calculationProperties,
      scales,
    })
    const { slotSizes: payloadSlotSizes, ...templatePayload } = payload

    return {
      ...templatePayload,
      widgets,
      sections: Templates.mapSlotSizesToSections(slotSizes, scales),
    }
  }

  saveTemplate = async (
    payload: EditTemplateDetailsDTO,
    calculationProperties: CalculationProperties,
    slotSizes: SlotSizes
  ) => {
    const { data } = await this.api.put<EditTemplateDetails>(
      `${SUBPATH}/templates`,
      this.transformTemplateForSaving(
        payload,
        calculationProperties,
        slotSizes
      ),
      { params: { templateId: payload.id } }
    )

    return data
  }

  createTemplate = async (
    templateDetails: CreateTemplateDetailsDTO,
    calculationProperties: CalculationProperties,
    slotSizes: SlotSizes
  ) => {
    const isBeingCreatedFromScratch =
      !templateDetails.widgets && !templateDetails.slotSizes
    const { data } = await this.api.post<EditTemplateDetails>(
      `${SUBPATH}/templates`,
      isBeingCreatedFromScratch
        ? templateDetails
        : this.transformTemplateForSaving(
            templateDetails,
            calculationProperties,
            slotSizes
          )
    )
    return data
  }

  getTemplate = async (
    params: GetTemplatesQueryProps
  ): Promise<EditTemplateDetails> => {
    const {
      data: [template],
    } = await this.api.get<EditTemplateDetailsAPI[]>(`${SUBPATH}/templates`, {
      params,
    })

    const mappedTemplate = Templates.mapSectionSizesToSlots(template)

    return {
      ...mappedTemplate,
      editionProgressStep: template.editionProgressStep,
    }
  }

  getTemplates = async (
    params?: GetTemplatesQueryProps
  ): Promise<EditTemplateDetailsAPI[]> => {
    const { data } = await this.api.get<EditTemplateDetailsAPI[]>(
      `${SUBPATH}/templates`,
      { params }
    )
    return data
  }

  getDefaultTemplatesMOCKED = async (
    // TODO: Change to real implementation in the future
    params: Omit<
      GetTemplatesParams,
      'templateId' | 'resolution' | 'templateName'
    >
  ): Promise<EditTemplateDetailsAPI[]> => {
    return (await this.getTemplates(params))
      .filter(template => template.widgets.length > 3)
      .reverse()
      .slice(0, 6)
  }

  deleteTemplate = async (templateId: number) => {
    const { data } = await this.api.delete<DeleteTemplateResponse>(
      `${SUBPATH}/templates`,
      { params: { templateId } }
    )
    return data
  }

  getCategoryList = async () => {
    const { data } = await this.api.get<GetCategoryListResponse>(
      `${SUBPATH}/categories`
    )
    return data
  }

  addCategory = async (body: AddCategoryBody) => {
    const { data } = await this.api.post<AddCategoryResponse>(
      `${SUBPATH}/categories`,
      body
    )

    return data
  }

  getTemplateStatuses = async () => {
    const { data } = await this.api.get<TemplateStatus[]>(
      `${SUBPATH}/template_status`
    )
    return data
  }

  addTemplateThumbnail = async (params: UploadTemplateThumbnailParams) => {
    const { data } = await this.api.post<AddTemplateThumbnailResponse>(
      `${SUBPATH}/template_thumbnail`,
      null,
      {
        params,
      }
    )

    return data
  }

  getTags = async () => {
    const { data } = await this.api.get<Tag[]>(`${SUBPATH}/tags`)
    return data
  }

  addTag = async (body: AddTagRequest) => {
    const { data } = await this.api.post<AddTagRequest>(`${SUBPATH}/tags`, body)
    return data
  }

  deleteTag = async (idTag: number | string) => {
    const { data } = await this.api.delete(`${SUBPATH}/tags`, {
      params: { idTag },
    })
    return data
  }
}

export default Templates
