import { styled, Box, Theme } from '@material-ui/core'
import AssetError from './AssetError'

interface BaseTileProps {
  height?: number
}

export const BaseTile = styled(Box)<Theme, BaseTileProps>(({ height }) => ({
  height: height || '100%',
  borderRadius: 4,
  position: 'relative',
  boxShadow:
    '0px 1px 20px 0px rgba(0,0,0,0.05), 0px 2px 3px 0px rgba(0,0,0,0.05)',
  overflow: 'hidden',
}))

export const ErrorTile = ({ height }: BaseTileProps) => (
  <BaseTile height={height}>
    <AssetError />
  </BaseTile>
)
