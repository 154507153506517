import { ReactNode } from 'react'
import { ButtonProps } from '@material-ui/core'
import { useField } from 'formik'
import { CheckboxButton } from './CheckboxButton'

interface RadioButtonProps extends ButtonProps {
  value: any
  name: string
  label: string | ReactNode
  fullWidth?: boolean
  isDense?: boolean
  className?: string
  getValue?: (data: string) => void
  defaultValue?: string
  isActive?: boolean
  contrastBackground?: boolean
}
const RadioButton = ({
  value,
  name,
  label,
  fullWidth,
  isDense = false,
  defaultValue = '',
  className,
  getValue,
  isActive,
  contrastBackground = false,
  ...props
}: RadioButtonProps) => {
  const [field, , { setValue }] = useField(name)

  const handleClick = () => {
    if (getValue) getValue(value)
    setValue(value)
  }

  const selected =
    isActive ||
    (field.value !== null || field.value !== undefined
      ? field.value === value
      : defaultValue === value)

  return (
    <CheckboxButton
      isDense={isDense}
      fullWidth={fullWidth}
      selected={selected}
      onClick={handleClick}
      className={className}
      contrastBackground={contrastBackground}
      {...props}
    >
      {label}
    </CheckboxButton>
  )
}
export default RadioButton
