import {
  InputBaseComponentProps,
  InputProps,
  TextField,
} from '@material-ui/core'
import { FastField, Field, FieldAttributes, useField } from 'formik'
import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react'
import { centerPlaceholderStyle } from './ClearableField.style'
import { useShouldClearField } from './ClearableField.utils'
import EndAdornment from './EndAdornment'

const getClasses = (disabled: boolean, height?: number) =>
  centerPlaceholderStyle({
    disabled,
    height,
  })

interface ClearableFieldFormikProps extends FieldAttributes<any> {
  formik?: true
  onClear?: () => void | Promise<any>
  isFast?: boolean
  shouldClear?: boolean
}
const ClearableFieldFormik = ({
  onClear,
  isFast,
  disabled,
  shouldClear = false,
  height,
  ...props
}: ClearableFieldFormikProps) => {
  const [{ value }, , { setValue, setTouched }] = useField(props.name)
  useShouldClearField(props.name, shouldClear)
  const isClearButtonVisible = Boolean(
    (value && value.length > 0) || props.value?.length > 0
  )
  const { root } = getClasses(disabled, height)

  const clear = () => {
    setValue('')
    setTouched(true, true)
    if (onClear) onClear()
  }
  const FieldComponent = isFast ? FastField : Field

  return (
    <FieldComponent
      {...props}
      classes={{ root }}
      InputProps={{
        endAdornment: (
          <EndAdornment
            clear={clear}
            isClearButtonVisible={isClearButtonVisible}
            disabled={disabled}
          />
        ),
        ...props.InputProps,
        inputProps: { disabled, ...props.inputProps },
      }}
    />
  )
}

interface ClearableFieldRawProps {
  isFormik: false
  value: string
  setValue: (value: string) => void
  disabled: boolean
  placeholder?: string
  onEnter?: () => void
  autoFocus?: boolean
  onClear?: () => void
  height?: number
  InputProps?: InputProps
  inputProps?: InputBaseComponentProps
}
const ClearableFieldRaw = ({
  setValue,
  value,
  disabled = false,
  placeholder,
  onEnter,
  autoFocus,
  onClear,
  height,
  inputProps,
  InputProps,
}: ClearableFieldRawProps) => {
  const { root } = getClasses(disabled, height)
  const clear = () => {
    setValue('')
    onClear?.()
  }
  const isClearButtonVisible = value.length > 0
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value)
  const handleOnKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') onEnter?.()
  }
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    autoFocus && inputRef.current?.focus()
  }, [])

  return (
    <TextField
      classes={{ root }}
      value={value}
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <EndAdornment
            clear={clear}
            isClearButtonVisible={isClearButtonVisible}
            disabled={!!disabled}
          />
        ),
      }}
      inputProps={{ placeholder, ...inputProps }}
      inputRef={inputRef}
    />
  )
}

type ClearableFieldProps = ClearableFieldFormikProps | ClearableFieldRawProps
const ClearableField = ({
  isFormik = true,
  onEnter,
  setValue,
  disabled = false,
  height,
  ...props
}: ClearableFieldProps) =>
  isFormik
    ? ClearableFieldFormik({ isFormik, disabled, ...props })
    : ClearableFieldRaw({
        isFormik,
        setValue,
        onEnter,
        height,
        disabled: disabled,
        value: props.value,
        placeholder: props.placeholder,
        autoFocus: props.autoFocus,
        onClear: props.onClear,
        inputProps: props.inputProps,
        InputProps: props.InputProps,
      })

export default ClearableField
