import { MODULE_NAME } from 'modules/displayManagement/strings'
import { SnackbarDescriptorTable } from './types'

export const SNACKBARS: SnackbarDescriptorTable = {
  createTemplate: {
    errorMessage: true,
    successMessage: {
      text: 'alerts.success.saveLayout',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
  getZipProject: {
    errorMessage: {
      text: 'downloadProject.failed',
      type: 'error',
      namespace: MODULE_NAME,
    },
    successMessage: {
      type: 'success',
      text: 'downloadProject.success',
      namespace: MODULE_NAME,
    },
  },
  deleteTemplate: {
    successMessage: {
      text: 'deleteTemplateModal.success',
      type: 'success',
    },
    errorMessage: true,
  },
  updateOTA: {
    successMessage: {
      text: 'displaysModal.updateOTACompleted',
      type: 'success',
    },
    errorMessage: {
      text: [
        'displaysModal.secondGenerationFailed',
        'displaysModal.updateOTAFailed',
      ],
      type: 'error',
    },
  },
  uploadTemplateThumbnail: {
    errorMessage: true,
  },
  generateProject: {
    successMessage: {
      text: 'generateProject.success',
      type: 'success',
    },
    errorMessage: true,
  },
  addTemplateCategory: {
    errorMessage: true,
    successMessage: {
      type: 'success',
      text: 'snackbars.categoryAddedSuccessfully',
    },
  },
  auth: {
    errorMessage: true,
  },
  uploadDrawerMediaLibraryFile: {
    successMessage: {
      text: 'mediaLibraryModal.uploadedSuccessfully',
      type: 'success',
    },
    errorMessage: true,
  },
  transformVideo: {
    errorMessage: true,
  },
}
