import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MediaLibrary } from 'shared/services'
import { SNACKBARS } from 'shared/strings'
import { SelectedMediaLibraryItem } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { State } from './reducer'
import {
  GetMediaLibraryPickerFilesProps,
  OpenMediaLibraryPickerProps,
  UploadMediaAssetsProps,
  UploadMediaLibraryFileParams,
} from './types'

export const MODULE_NAME = 'shared'
const mediaLibrary = new MediaLibrary()

export const openMediaLibraryPicker = createAction<OpenMediaLibraryPickerProps>(
  `${MODULE_NAME}/openMediaLibraryPicker`
)

export const saveMediaLibraryPicker = createAction<SelectedMediaLibraryItem[]>(
  `${MODULE_NAME}/saveMediaLibraryPicker`
)

export const closeMediaLibraryPicker = createAction(
  `${MODULE_NAME}/closeMediaLibraryPicker`
)

export const uploadMediaLibraryPickerFiles = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadMediaLibraryPickerFile`,
  payloadCreator: async (
    { files, path }: UploadMediaAssetsProps,
    { getState }
  ) => {
    const { common } = getState() as {
      common: {
        mediaPicker: State
      }
    }
    const { mediaPicker } = common

    return mediaLibrary.uploadMultipleWidgetAssets(
      files,
      mediaPicker.numberOfItems,
      path
    )
  },
})

export const getMediaLibraryPickerFiles = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getMediaLibraryPickerFiles`,
  payloadCreator: async (
    params: GetMediaLibraryPickerFilesProps,
    { getState }
  ) => {
    const { common } = getState() as {
      common: {
        mediaPicker: State
      }
    }
    const { mediaPicker } = common

    const mediaFiles = await mediaLibrary.getMediaFiles({
      ...params,
      mediaType: mediaPicker.assetsType,
      limit: mediaPicker.assets.limit,
      skip: params.loadMore ? mediaPicker.assets.skip : 0,
    })

    let categories = undefined

    if (params.willFetchCategories) {
      try {
        categories = await mediaLibrary.getAllMediaCategories()
      } catch (e) {
        // If there are no categories, "All Files" should be available nonetheless
        const axiosError = e as AxiosError
        if (axiosError.response?.status !== 404) {
          throw e
        }
      }
    }

    return {
      loadMore: params.loadMore,
      ...mediaFiles,
      categories,
    }
  },
})

export const clearMediaLibraryPicker = createAction(
  `${MODULE_NAME}/clearMediaLibraryPicker`
)

export const uploadDrawerMediaLibraryFile = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadMediaLibraryFile`,
  payloadCreator: (params: UploadMediaLibraryFileParams) =>
    mediaLibrary.createMediaFile(params),
  ...SNACKBARS.uploadDrawerMediaLibraryFile,
})

export const resetUploadDrawerMediaLibraryFile = createAction(
  `${MODULE_NAME}/resetUploadMediaLibraryFile`
)
