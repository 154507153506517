import { SvgIcon, SvgIconProps } from '@material-ui/core'

const AddPhoto = (props: SvgIconProps) => (
  <SvgIcon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-64.000000, -64.000000)"
        fill="currentColor"
      >
        <g id="Add_Image" transform="translate(64.000000, 64.000000)">
          <path
            d="M9.52380952,8.57142857 L11.2942857,5.73142857 L12.3857143,7.48190476 L14.9009524,3.44761905 L18.0952381,8.57142857 L9.52380952,8.57142857 Z M18.0952381,0 L9.52380952,0 C8.47238095,0 7.61904762,0.853333333 7.61904762,1.9047619 L7.61904762,9.52380952 C7.61904762,10.5752381 8.47238095,11.4285714 9.52380952,11.4285714 L18.0952381,11.4285714 C19.1466667,11.4285714 20,10.5752381 20,9.52380952 L20,1.9047619 C20,0.853333333 19.1466667,0 18.0952381,0 L18.0952381,0 Z"
            id="Fill-2337"
          />
          <polygon
            id="Fill-2316"
            points="14.2857143 17.1428571 16.1904762 17.1428571 16.1904762 19.047619 18.0952381 19.047619 18.0952381 17.1428571 20 17.1428571 20 15.2380952 18.0952381 15.2380952 18.0952381 13.3333333 16.1904762 13.3333333 16.1904762 15.2380952 14.2857143 15.2380952"
          />
          <path
            d="M5.71428571,2.85714286 L3.80952381,2.85714286 L3.80952381,13.3333333 C3.80952381,14.3838095 4.66380952,15.2380952 5.71428571,15.2380952 L13.3482975,15.2380952 L13.3482975,13.3333333 L5.71428571,13.3333333 L5.71428571,2.85714286 Z"
            id="Fill-2338"
          />
          <path
            d="M1.9047619,6.66666667 L0,6.66666667 L0,17.1428571 C0,18.1933333 0.854285714,19.047619 1.9047619,19.047619 L11.4285714,19.047619 L11.4285714,17.1428571 L1.9047619,17.1428571 L1.9047619,6.66666667 Z"
            id="Fill-2339"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default AddPhoto
