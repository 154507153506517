import { styled, Theme } from '@material-ui/core'

interface BoxProps {
  isBlocked: boolean
}

export const StyledBox = styled('div')<Theme, BoxProps>(
  ({ theme, isBlocked }) => ({
    display: isBlocked ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: isBlocked ? 'not-allowed' : 'pointer',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    padding: theme.spacing(4),
    opacity: 0.75,
    background: theme.palette.primary.dark,
    zIndex: theme.zIndex.editOverlay,
    '& svg': {
      fontSize: '4rem',
      color: theme.palette.background.overlay.dark,
    },
  })
)
