import { Box } from '@material-ui/core'
import { ArrowLeft, ArrowRight } from '@material-ui/icons'
import { RefObject } from 'react'
import { StyledSlider } from './VideoTrimPreview.style'
import { useOnChangeFunctions } from './VideoTrimPreview.utils'

interface VideoSliderProps {
  marks:
    | {
        label: string
        value: any
      }[]
    | false
  sliderTimes: [number, number]
  setSliderTimes: (times: [number, number]) => void
  disabled: boolean
  duration: number
  videoRef: RefObject<HTMLVideoElement>
  extractedFrames: string[]
}

const VideoSlider = ({
  marks,
  sliderTimes,
  setSliderTimes,
  disabled,
  duration,
  videoRef,
  extractedFrames,
}: VideoSliderProps) => {
  const { temporaryTimes, ...sliderChangeProps } = useOnChangeFunctions(
    setSliderTimes,
    sliderTimes,
    duration,
    videoRef
  )
  return (
    <StyledSlider
      min={0}
      max={duration}
      value={temporaryTimes}
      disabled={disabled}
      valueLabelDisplay="on"
      valueLabelFormat={(num, index) => (
        <Box display="flex" alignItems="center">
          {index === 1 && <ArrowLeft />}
          <span>{num.toFixed(1)}s</span>
          {index === 0 && <ArrowRight />}
        </Box>
      )}
      urls={extractedFrames}
      {...sliderChangeProps}
      marks={marks}
    />
  )
}

export default VideoSlider
