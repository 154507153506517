import { SvgIcon, SvgIconProps } from '@material-ui/core'

const TopLeft = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <g transform="translate(-1213.000000, -700.000000)" fill="currentColor">
      <g transform="translate(1191.000000, 690.000000)">
        <g transform="translate(31.000000, 19.000000) scale(1, -1) translate(-31.000000, -19.000000) translate(22.000000, 10.000000)">
          <path d="M8,8 L2,8 C0.897,8 0,8.897 0,10 L0,16 C0,17.103 0.897,18 2,18 L8,18 C9.103,18 10,17.103 10,16 L10,10 C10,8.897 9.103,8 8,8"></path>
          <path d="M2,2 L7,2 L7,0 L2,0 C0.897,0 0,0.897 0,2 L0,6 L2,6 L2,2 Z"></path>
          <path d="M16,0 L11,0 L11,2 L16,2 L16,7 L18,7 L18,2 C18,0.897 17.103,0 16,0"></path>
          <path d="M16,16 L12,16 L12,18 L16,18 C17.103,18 18,17.103 18,16 L18,11 L16,11 L16,16 Z"></path>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default TopLeft
