import { Redirect, Route, RouteProps } from 'react-router-dom'
import { paths } from 'config'
import { useAuth } from 'shared/hooks'

const PrivateRoute = (props: RouteProps) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Route {...props} /> : <Redirect to={paths.login} />
}

export default PrivateRoute
