import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import config from 'config'
import { LOGOUT_TIMEOUT } from 'shared/config'
import { resetLogoutError, setLogoutError } from 'shared/utils'
import { Auth } from '../auth'
import { LoginPayload, LoginResponse, loginURL } from './Api.utils'

class Api {
  private auth = new Auth()
  protected api: AxiosInstance = applyCaseMiddleware(
    axios.create({ baseURL: config.API_URL })
  )

  constructor() {
    this.api.interceptors.request.use(this.authorizeRequest)
    this.api.interceptors.response.use(undefined, this.handleApiResponseError)
  }

  private handleApiResponseError = (error: AxiosError) => {
    const status = Number(error?.response?.status)
    const token = this.auth.getToken()
    const { url } = error?.config //TODO: temporary solution for protected endpoints

    if (
      (status === 403 || status === 401) &&
      (!token || token.tokenType !== 'Mocked')
    ) {
      //TODO: temporary solution for protected endpoints
      if (url?.indexOf('/set_params')) return

      switch (url) {
        case 'floors/audios':
          return
        case 'floors/audios/events':
          return
        case 'audio_library/device_audio_file':
          return
      }
      //

      const details = error.response?.data.detail
      setLogoutError(details)
      setTimeout(() => this.auth.logout(), LOGOUT_TIMEOUT)
    }

    return Promise.reject(error)
  }

  authorizeRequest = async (config: AxiosRequestConfig) => {
    const token = this.auth.getToken()
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `${token.tokenType} ${token.accessToken}` : null,
      },
    }
  }

  login = async (payload: LoginPayload) => {
    const params = new URLSearchParams({ ...payload })
    const { data } = await this.api.post<LoginResponse>(loginURL, params)
    this.auth.setToken(data)
    resetLogoutError()

    return data
  }
}

export default Api
