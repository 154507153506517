import { ComponentsProps } from '@material-ui/core/styles/props'

const MAX_LIST_HEIGHT = 200

const props: ComponentsProps = {
  MuiTextField: {
    variant: 'filled',
    fullWidth: true,
  },
  MuiFilledInput: {
    disableUnderline: true,
  },
  MuiButton: {
    variant: 'contained',
    disableElevation: true,
  },
  MuiCircularProgress: {
    color: 'secondary',
  },
  MuiSelect: {
    MenuProps: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      PaperProps: {
        style: {
          maxHeight: MAX_LIST_HEIGHT,
        },
      },
    },
  },
}

export default props
