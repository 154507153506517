import { Grid, styled, Theme } from '@material-ui/core'
import { ErrorIcon } from 'shared/icons'

const ErrorGrid = styled(Grid)<Theme, AssetErrorProps>(
  ({ theme, rounded }) => ({
    height: '100%',
    minHeight: 50,
    backgroundColor: theme.palette.background.overlay.contrast,
    borderRadius: rounded ? 4 : 0,
  })
)

interface AssetErrorProps {
  rounded?: boolean
}
const AssetError = ({ rounded = false }: AssetErrorProps) => (
  <ErrorGrid
    container
    alignItems="center"
    justifyContent="center"
    rounded={rounded}
  >
    <ErrorIcon color="disabled" />
  </ErrorGrid>
)

export default AssetError
