import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ApiFont, MediaAsset } from 'shared/services'

export const useSetCurrentAssetInDrawer = (
  file: MediaAsset | ApiFont,
  assetUrl: string | null,
  actions: any, //Media Library actions
  setSelectedAsset?: (asset: MediaAsset | ApiFont) => void,
  setPreviewUrl?: (url: string | null) => void
) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    setPreviewUrl?.(assetUrl)
    setSelectedAsset?.(file)
    dispatch(actions.resetSaveMediaAsset())
    dispatch(actions.resetDeleteMediaFile())
    dispatch(actions.resetDeleteMediaStatus())
  }, [dispatch, assetUrl, file])
}
