import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as Title,
  Grid,
  IconButton,
  styled,
  Theme,
  Typography,
} from '@material-ui/core'
import { Children, PropsWithChildren } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { CheckIcon, Close, Refresh } from 'shared/icons'
import { ApiFont } from 'shared/services'
import { StyledCard } from './CardStyles'
import { Ellipsis } from './Ellipsis'
import { EnhancedTypography } from './EnhancedTypography'
import FontCard from './FontCard'
import { MultimediaThumbnail, MultimediaThumbnailProps } from './Thumbnails'

const DIALOG_CONTENT_MAX_HEIGHT = 520
const DIALOG_CONTENT_MIN_HEIGHT = 280

const calcContentSize = (length?: number | null, perRow?: number | null) =>
  !length || !perRow
    ? DIALOG_CONTENT_MAX_HEIGHT
    : length <= perRow
    ? DIALOG_CONTENT_MIN_HEIGHT
    : length > perRow && length <= perRow * 2
    ? DIALOG_CONTENT_MIN_HEIGHT * 2
    : DIALOG_CONTENT_MAX_HEIGHT

interface ExpressiveDialogScrollbarsProps {
  length?: number | null
  perRow?: number | null
  fullHeight?: boolean
}

const FullHeightDialog = styled(DialogContent)({
  height: '100%',
  padding: 0,
})

export const ExpressiveDialogScrollbars = ({
  children,
  length,
  perRow,
  fullHeight,
}: PropsWithChildren<ExpressiveDialogScrollbarsProps>) => {
  const contentSize = calcContentSize(length, perRow)
  const DialogComponent = fullHeight ? FullHeightDialog : DialogContent

  return (
    <DialogComponent>
      <Scrollbars
        autoHide
        {...(fullHeight
          ? {
              height: '100%',
            }
          : {
              autoHeight: true,
              autoHeightMin: contentSize,
              autoHeightMax: DIALOG_CONTENT_MAX_HEIGHT,
            })}
      >
        <Box p={3} height={contentSize}>
          {children}
        </Box>
      </Scrollbars>
    </DialogComponent>
  )
}

interface ExpressiveDialogCardProps extends MultimediaThumbnailProps {
  title?: string
  subtitle?: string
  isSelected: boolean
  onClick?: () => void
  thumbnailHeight?: number
  minHeight?: number
  font?: ApiFont
}

const DIALOG_ITEM_DEFAULT_HEIGHT = 186
const THUMBNAIL_DEFAULT_HEIGHT = 136

export const ExpressiveDialogCard = ({
  title,
  subtitle,
  isSelected,
  onClick,
  thumbnailHeight = THUMBNAIL_DEFAULT_HEIGHT,
  minHeight = DIALOG_ITEM_DEFAULT_HEIGHT,
  font,
  ...props
}: ExpressiveDialogCardProps) => (
  <Box position="relative">
    <StyledCard isSelected={isSelected} onClick={onClick} minHeight={minHeight}>
      {font ? (
        <FontCard font={font} selectedAsset={null} />
      ) : (
        <Box height={thumbnailHeight}>
          <MultimediaThumbnail
            {...props}
            videoProps={{ withPlayOnHover: true }}
          />
        </Box>
      )}

      <Box
        position="absolute"
        top="15px"
        left="15px"
        display={isSelected ? 'block' : 'none'}
      >
        <CheckIcon />
      </Box>
      {title && (
        <Box
          marginY={1}
          textAlign={subtitle ? 'left' : 'center'}
          marginX={subtitle ? 4 : 1}
        >
          {subtitle && (
            <EnhancedTypography variant="caption2">
              <Ellipsis variant="horizontal"> {subtitle}</Ellipsis>
            </EnhancedTypography>
          )}
          <Typography variant="h6">
            <Ellipsis variant="horizontal">{title}</Ellipsis>
          </Typography>
        </Box>
      )}
    </StyledCard>
  </Box>
)

interface ExpressiveDialogActionsProps {
  selectedItem?: string
}

export const ExpressiveDialogActions = ({
  selectedItem,
  children,
}: PropsWithChildren<ExpressiveDialogActionsProps>) => {
  const { t } = useTranslation()

  return (
    <DialogActions>
      <Grid container justifyContent="space-around" direction="row">
        {selectedItem && (
          <Grid item xs={6}>
            <EnhancedTypography variant="caption2">
              {t('expressiveDialog.selectedItem')}:
            </EnhancedTypography>
            <Typography variant="subtitle2">{selectedItem || ''}</Typography>
          </Grid>
        )}
        <Grid
          item
          xs={selectedItem ? 6 : 12}
          container
          justifyContent={selectedItem ? 'flex-end' : 'center'}
          spacing={4}
        >
          {Children.toArray(children).map(child => (
            <Grid item>{child}</Grid>
          ))}
        </Grid>
      </Grid>
    </DialogActions>
  )
}

const StyledBox = styled('div')({
  cursor: 'pointer',
  position: 'absolute',
  top: '10px',
  right: 0,
})

interface ExpressiveDialogTitleProps {
  refresh?: () => void
  subtitle?: string
  title: string
}

export const ExpressiveDialogTitle = ({
  refresh,
  subtitle,
  title,
}: ExpressiveDialogTitleProps) => {
  return (
    <Title>
      <Box position="relative">
        <Typography variant="h2" align="center" gutterBottom>
          <Ellipsis variant="horizontal" tooltipText={title}>
            {title}
          </Ellipsis>
        </Typography>
        {refresh && (
          <StyledBox>
            <Refresh onClick={refresh} />
          </StyledBox>
        )}
      </Box>
      <Typography variant="body2" align="center" color="inherit">
        {subtitle}
      </Typography>
    </Title>
  )
}

interface ExpressiveDialogProps {
  maxHeight?: number | string
  minHeight?: number | string
  width?: number
}

const EXPRESSIVE_DIALOG_MAX_HEIGHT = 775

export const ExpressiveDialog = styled(Dialog)<Theme, ExpressiveDialogProps>(
  ({ theme, maxHeight, width, minHeight }) => ({
    '& .MuiDialog-container > .MuiPaper-root': {
      width: width ?? 880,
      maxHeight: maxHeight ? maxHeight : EXPRESSIVE_DIALOG_MAX_HEIGHT,
      padding: 0,
      minHeight,
    },
    '& .MuiDialogTitle-root': {
      padding: theme.spacing(10, 10, 3, 10),
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0, 7, 0),
    },
    '& .MuiDialogActions-root': {
      marginTop: 0,
      padding: theme.spacing(3, 10),
      boxShadow: '0px -3px 16px 2px rgba(0, 0, 0, 0.5)',
      zIndex: theme.zIndex.dialogFooter,
    },
  })
)

const CloseButton = styled(IconButton)<Theme>(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  color: theme.palette.accent.main,
}))
interface CloseDialogButtonProps {
  onClick: () => void
}
export const CloseDialogButton = ({ onClick }: CloseDialogButtonProps) => {
  return (
    <CloseButton onClick={onClick}>
      <Close />
    </CloseButton>
  )
}
