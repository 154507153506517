import { useThrottleCallback } from '@react-hook/throttle'
import { actions, selectors } from 'modules/layoutDesigner/store'
import { useDispatch, useSelector } from 'react-redux'

export const useWidgetDragOverStatus = () => {
  const dispatch = useDispatch()
  const prevValue = useSelector(selectors.getIsSlotDraggedOver)

  const setDragOverStatus = useThrottleCallback((dragOverStatus: boolean) => {
    if (dragOverStatus === prevValue) return
    dispatch(actions.setIsSlotDraggedOver(dragOverStatus))
  }, 10)

  return setDragOverStatus
}
