import { SvgIcon, SvgIconProps } from '@material-ui/core'

const RightAlignment = (props: SvgIconProps) => (
  <SvgIcon width="16" height="13" viewBox="0 0 16 13" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.28853 8.60418L10.0356 6.85713H0.446243V5.33332H10.0356L8.28853 3.58627L9.36586 2.50894L12.9521 6.09523L9.36586 9.68151L8.28853 8.60418Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4761 12.1905H15.9999V0H14.4761V12.1905Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default RightAlignment
