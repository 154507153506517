import { Redirect } from 'react-router'
import { Loadable } from 'shared/components'
import { Playlist } from 'shared/icons'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/playlist'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    path: '/',
    label: 'navigation.playlist.label',
    icon: Playlist,
    exact: true,
    component: () => <Redirect to={`${BASE_PATH}/list`} />,
    subItems: addBasePath(BASE_PATH, [
      {
        label: 'navigation.playlist.new',
        path: '/new',
      },
      {
        label: 'navigation.playlist.myPlaylists',
        path: '/list',
      },
      {
        label: 'navigation.playlist.playlistSchedule',
        path: '/playlistSchedule',
        discrete: true,
      },
    ]),
  },
  {
    path: '/new',
    exact: true,
    component: Loadable({
      component: () => import('./containers/PlaylistCreation'),
    }),
  },
  {
    path: '/list',
    exact: true,
    component: Loadable({
      component: () => import('./containers/MyPlaylists'),
    }),
  },
  {
    path: '/edit/:id',
    exact: true,
    component: Loadable({
      component: () => import('./containers/PlaylistCreation'),
    }),
  },
  {
    path: '/playlistSchedule',
    exact: true,
    component: Loadable({
      component: () => import('./containers/PlaylistSchedule'),
    }),
  },
])

export default routes
