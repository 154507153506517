import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Italic = (props: SvgIconProps) => (
  <SvgIcon width="15" height="18" viewBox="0 0 15 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.55556V1H7.22222V2.55556H9.91411L4.38411 15H1V16.5556H8.77778V15H6.08589L11.6159 2.55556H15Z"
      fill="currentColor"
      stroke="#022C3C"
      strokeWidth="0.2"
    />
  </SvgIcon>
)

export default Italic
