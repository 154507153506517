import { Grid, useTheme } from '@material-ui/core'
import { memo, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { useAuth, useWidgetDragOverStatus } from 'shared/hooks'
import { selectors } from 'shared/store'
import { NavigationItem } from 'shared/types'
import LanguageSelect from './LanguageSelect'
import { Container, Drawer, GridContainer } from './Layout.style'
import MenuItems from './MenuItems'
import UserProfile from './UserProfile'
import BrandLogo from './BrandLogo'

interface LayoutProps {
  navItems: NavigationItem[]
}

const Layout = ({ navItems, children }: PropsWithChildren<LayoutProps>) => {
  const { isAuthenticated } = useAuth()
  const setIsDragOverStatus = useWidgetDragOverStatus()
  const { firstName, lastName, avatarImg, language } =
    useSelector(selectors.auth.getCurrentUser) || {}
  const theme = useTheme()
  const zIndex = theme.zIndex.appMenuDrawer.mediaLibrary

  return (
    <Container>
      {isAuthenticated && (
        <Drawer
          anchor="left"
          variant="permanent"
          onDragEnter={() => setIsDragOverStatus(false)}
          zIndex={zIndex}
        >
          <GridContainer container>
            <Grid item>
              <BrandLogo />
              <UserProfile
                firstName={firstName}
                lastName={lastName}
                avatarImg={avatarImg}
              />
              <MenuItems items={navItems} />
            </Grid>
            <LanguageSelect language={language} />
          </GridContainer>
        </Drawer>
      )}
      {children}
    </Container>
  )
}

export default memo(Layout)
