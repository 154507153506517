import axios from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Assets,
  GetAssetInfoResponse,
  GetAssetParameters,
  MediaAsset,
} from 'shared/services'
import {
  LoadingStatus,
  PictogramEventContentType,
  PictogramFormElement,
} from 'shared/types'
import { ERRORS } from 'shared/utils'

const assets = new Assets()

interface UseAssetUrlOptions {
  isAllowedToLoad?: boolean
  isResource?: boolean
  autoReload?: boolean
  cancelOnCleanup?: boolean
  customFetchFunction?: (
    params: GetAssetParameters,
    isResource?: boolean
  ) => Promise<{
    url: string
    type: any
  }>
}

export interface MediaFileDescriptors
  extends Partial<GetAssetInfoResponse>,
    Partial<MediaAsset> {}

export const useAssetUrl = (
  mediaUuid?: string | false | null,
  options?: UseAssetUrlOptions
) => {
  const {
    isAllowedToLoad = true,
    isResource = false,
    autoReload = false,
    customFetchFunction,
  } = useMemo(() => options, [options]) || {}

  const [assetUrl, setAssetUrl] = useState<string | null>(null)
  const [assetType, setAssetType] = useState<string | null>(null)
  const [descriptors, setDescriptors] = useState<MediaFileDescriptors | null>(
    null
  )

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle)
  const lastUuid = useRef<string | false | null | undefined>(null)

  useEffect(() => {
    const getAsset = async (mediaUuid: string | false) => {
      try {
        setLoading(LoadingStatus.Pending)
        lastUuid.current = mediaUuid

        if (mediaUuid === false) {
          setAssetUrl('false')
          setAssetType('blank')
          setDescriptors(null)
        } else {
          const { url, type, ...assetInfo } = await (
            customFetchFunction ?? assets.getAssetUrl
          )({ mediaUuid }, isResource)
          setAssetUrl(url)
          setAssetType(type)
          if (assetInfo) {
            setDescriptors(
              Object.keys(assetInfo).length === 0
                ? null
                : (assetInfo as MediaFileDescriptors)
            )
          }
        }
        setLoading(LoadingStatus.Succeeded)
      } catch {
        setLoading(LoadingStatus.Failed)
      }
    }

    const shouldFetchNextMedia =
      isAllowedToLoad &&
      mediaUuid !== undefined &&
      mediaUuid !== null &&
      mediaUuid !== '' &&
      (loading === LoadingStatus.Idle ||
        (autoReload && lastUuid.current !== mediaUuid))

    if (shouldFetchNextMedia) getAsset(mediaUuid)
  }, [mediaUuid, isAllowedToLoad, isResource, loading, autoReload, descriptors])

  useEffect(() => {
    if (mediaUuid !== false) setLoading(LoadingStatus.Idle)
  }, [mediaUuid])

  const resetLoadedAsset = useCallback(() => {
    setLoading(LoadingStatus.Idle)
    setAssetUrl(null)
    setAssetType(null)
  }, [])

  useEffect(() => {
    return () => {
      if (options?.cancelOnCleanup) assets.cancelFetching()
    }
  }, [options?.cancelOnCleanup])

  return { assetUrl, assetType, loading, resetLoadedAsset, descriptors }
}

interface MediaPromise {
  url: string | false
  type: any
}
export const useMultipleAssetUrl = (
  mediaUuids?: (string | false)[] | null,
  isAllowedToLoad: boolean = true
) => {
  const [assetUrls, setAssetUrls] = useState<(string | false)[] | null>(null)
  const [assetTypes, setAssetTypes] = useState<string[] | null>(null)
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle)

  useEffect(() => {
    const getItems = async () => {
      try {
        setLoading(LoadingStatus.Pending)
        let loadedAssets = null

        if (mediaUuids && mediaUuids.length > 0) {
          loadedAssets = await Promise.all(
            mediaUuids.reduce(
              (otherPromises: Promise<MediaPromise>[], currentUuid) => {
                if (currentUuid === false)
                  return [
                    ...otherPromises,
                    new Promise<MediaPromise>(resolve => {
                      resolve({
                        url: false,
                        type: 'image/jpg',
                      })
                    }),
                  ]

                return [
                  ...otherPromises,
                  assets.getAssetUrl({ mediaUuid: currentUuid }),
                ]
              },
              []
            )
          )
        }

        setLoading(LoadingStatus.Succeeded)
        loadedAssets && setAssetUrls(loadedAssets.map(data => data.url))
        loadedAssets && setAssetTypes(loadedAssets.map(data => data.type))
      } catch (err) {
        setLoading(LoadingStatus.Failed)
      }
    }

    isAllowedToLoad && getItems()
  }, [isAllowedToLoad, mediaUuids])

  return { assetUrls, assetTypes, loading }
}

type Events = {
  eventType: PictogramEventContentType | null
  eventContent: string | null
}
export const usePictogramWidgetContent = (
  eventList?: Pick<PictogramFormElement, 'eventType' | 'eventContent'>[],
  isAllowedToLoad: boolean = true
) => {
  const [events, setEvents] = useState<Events[] | null>(null)
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle)

  const getImageUrl = async (assetId: string) => {
    const { url } = await assets.getAssetUrl({
      mediaUuid: assetId,
    })
    return url
  }

  useEffect(() => {
    const getEventsData = async () => {
      try {
        setLoading(LoadingStatus.Pending)

        if (eventList && eventList.length > 0) {
          const loadedEvents = await axios.all(
            eventList.map(async ({ eventContent, eventType }) => ({
              eventType,
              eventContent:
                eventType === PictogramEventContentType.Text
                  ? eventContent
                  : eventType === null
                  ? null
                  : await getImageUrl(eventContent),
            }))
          )

          setLoading(LoadingStatus.Succeeded)
          loadedEvents && setEvents(loadedEvents)
        }
      } catch (err) {
        setLoading(LoadingStatus.Failed)
      }
    }

    isAllowedToLoad && getEventsData()
  }, [isAllowedToLoad, eventList])

  return { loading, events }
}

export const useAssetInfo = (
  mediaUuid?: string | null,
  isAllowedToLoad: boolean = true
) => {
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle)
  const [info, setInfo] = useState<null | GetAssetInfoResponse>(null)

  useEffect(() => {
    const getAssetInfo = async () => {
      try {
        setLoading(LoadingStatus.Pending)
        if (!mediaUuid) throw new Error(ERRORS.noMediaAssetWithId(mediaUuid))
        const data = await assets.getAssetInfo(mediaUuid)
        setInfo(data)
        setLoading(LoadingStatus.Succeeded)
      } catch {
        setLoading(LoadingStatus.Failed)
      }
    }
    isAllowedToLoad && getAssetInfo()
  }, [mediaUuid, isAllowedToLoad])

  return { loading, info }
}
