import axios from 'axios'
import {
  convertMinutesToSeconds,
  ERRORS,
  safelyParseJSON,
  sleep,
} from 'shared/utils'
import { Api } from '../api'
import {
  GetAssetInfoResponse,
  GetAssetParameters,
  getMediaFileURL,
  getMediaInfoURL,
  getMediaTaskStatusURL,
  getResourceFileURL,
  MediaFileTaskResponse,
  MediaTaskStatus,
  transformBase64ToImageURL,
  transformMediaFileVideoURL,
  TransformVideoParams,
  UploadAssetParams,
  UploadAssetResponse,
  uploadMediaFileURL,
  UploadFontParams,
  uploadFontURL,
  ApiFont,
  TransformVideoResponse,
  MediaFileTaskSuccessDescription,
  MediaFileTaskSuccessDescriptionRaw,
} from './Assets.utils'

class Assets extends Api {
  isPollingBase64Image = true
  readonly sleepTime = 2000
  readonly pollingLimit = convertMinutesToSeconds(1) / this.sleepTime

  private cancelSource = axios.CancelToken.source()
  cancelFetching = () => {
    this.cancelSource.cancel(ERRORS.requestCancelled)
    this.cancelSource = axios.CancelToken.source()
  }

  getAssetUrl = async (params: GetAssetParameters, isResource = false) => {
    const { data, headers } = await this.api.get<BlobPart>(
      isResource ? getResourceFileURL : getMediaFileURL,
      {
        cancelToken: this.cancelSource.token,
        params,
        responseType: 'arraybuffer',
      }
    )
    const blobData = new Blob([data], { type: headers['contentType'] })

    return { url: URL.createObjectURL(blobData), type: headers['contentType'] }
  }

  getAssetUrlAndInfo = async (
    { mediaUuid }: GetAssetParameters,
    isResource = false
  ) => {
    const [assetInfo, asset] = await Promise.all([
      this.getAssetInfo(mediaUuid),
      this.getAssetUrl({ mediaUuid }, isResource),
    ])

    return { ...assetInfo, ...asset }
  }

  uploadAsset = async ({ file, fileName, path }: UploadAssetParams) => {
    const formData = new FormData()
    formData.append('file', file, fileName)

    const response = await this.api.post<UploadAssetResponse>(
      uploadMediaFileURL,
      formData,
      { params: { path } }
    )

    return response
  }

  getAssetInfo = async (mediaUuid: string) => {
    const { data } = await this.api.get<GetAssetInfoResponse>(getMediaInfoURL, {
      params: { mediaUuid },
    })

    return data
  }

  transformBase64ImageToPng = async (base64: string | null) => {
    const { data } = await this.api.post<string>(transformBase64ToImageURL, {
      base64,
    })

    return data
  }

  getMediaTaskStatus = async (taskId: string) => {
    const { data } = await this.api.get<MediaFileTaskResponse>(
      getMediaTaskStatusURL,
      {
        params: { taskId },
      }
    )

    return data
  }

  stopPollingUploadingBase64Image = () => {
    this.isPollingBase64Image = false
  }
  startPollingUploadingBase64Image = () => {
    this.isPollingBase64Image = true
  }

  uploadBase64Image = async (base64: string) => {
    let pollingCount = 0
    const taskId = await this.transformBase64ImageToPng(base64)
    this.startPollingUploadingBase64Image()

    while (this.isPollingBase64Image) {
      const { status, detail } = await this.getMediaTaskStatus(taskId)
      if (pollingCount >= this.pollingLimit)
        throw new Error(ERRORS.transformBase65Timeout)
      if (status === MediaTaskStatus.Success) {
        // 08.03.2023 for some reason, dev server sends stringified JSON while production sends JSON
        const succeededId =
          typeof detail === 'string'
            ? safelyParseJSON<MediaFileTaskSuccessDescriptionRaw>(
                detail.replace(/'/g, '"')
              )?.file_uuid
            : detail.fileUuid
        if (succeededId) return succeededId
      }
      if (status === MediaTaskStatus.Failure)
        throw new Error(ERRORS.transformBase65Failed)

      await sleep(2000)
      pollingCount++
    }
  }

  transformVideo = async (params: TransformVideoParams) => {
    const { data } = await this.api.post<TransformVideoResponse>(
      transformMediaFileVideoURL,
      null,
      { params }
    )

    return data
  }

  getFonts = async () => {
    const { data } = await this.api.get<ApiFont[]>(
      `assets/get_my_asset_files`,
      {
        params: { path: '/fonts/atom' },
      }
    )

    return data
  }

  uploadFont = async ({ file, filename }: UploadFontParams) => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    const response = await this.api.post<ApiFont>(uploadFontURL, formData, {
      params: { filename, path: '/fonts/atom' },
    })

    return response
  }
}

export default Assets
