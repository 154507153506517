import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Empty = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 55" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Empty-category"
        transform="translate(-1383.000000, -739.000000)"
        fill="currentColor"
      >
        <g id="Empty-icon" transform="translate(1383.000000, 739.000000)">
          <path
            d="M42.6666667,12.3333333 L37.3333333,12.3333333 L37.3333333,17.6666667 L42.6666667,17.6666667 L42.6666667,33.6666667 L5.33333333,33.6666667 L5.33333333,17.6666667 L10.6666667,17.6666667 L10.6666667,12.3333333 L5.33333333,12.3333333 C2.392,12.3333333 0,14.7253333 0,17.6666667 L0,41.6666667 C0,44.608 2.392,47 5.33333333,47 L21.3333333,47 L21.3333333,49.6666667 L13.3333333,49.6666667 L13.3333333,55 L34.6666667,55 L34.6666667,49.6666667 L26.6666667,49.6666667 L26.6666667,47 L42.6666667,47 C45.608,47 48,44.608 48,41.6666667 L48,17.6666667 C48,14.7253333 45.608,12.3333333 42.6666667,12.3333333"
            id="Fill-724"
          />
          <g id="Group-19" transform="translate(14.000000, 0.000000)">
            <path
              d="M9.5,0 C4.26075,0 0,4.26075 0,9.5 L4.75,9.5 C4.75,6.88275 6.880375,4.75 9.5,4.75 C12.119625,4.75 14.25,6.88275 14.25,9.5 C14.25,12.119625 12.119625,14.25 9.5,14.25 L7.125,14.25 L7.125,21.375 L11.875,21.375 L11.875,18.698375 C15.9695,17.643875 19,13.9175 19,9.5 C19,4.26075 14.73925,0 9.5,0"
              id="Fill-2260"
            />
            <path
              d="M11.875,26.125 C11.875,27.438375 10.813375,28.5 9.5,28.5 C8.186625,28.5 7.125,27.438375 7.125,26.125 C7.125,24.811625 8.186625,23.75 9.5,23.75 C10.813375,23.75 11.875,24.811625 11.875,26.125"
              id="Fill-2261"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Empty
