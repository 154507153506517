import { Chip, ChipProps, styled, Theme } from '@material-ui/core'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import { TAG_FONT_SIZE, TAG_FONT_WEIGHT } from 'shared/config'

export interface TagProps extends ChipProps {
  maxWidth?: number
  bgVariant?: keyof PaletteColor
  canWrap?: boolean
}

const Tag = styled(Chip)<Theme, TagProps>(
  ({ theme, canWrap, clickable, maxWidth, bgVariant }) => ({
    borderRadius: 4,
    padding: clickable ? theme.spacing(1, 1) : theme.spacing(1, 2),
    backgroundColor: theme.palette.info[bgVariant ?? 'light'],
    color: theme.palette.text.primary,
    fontSize: TAG_FONT_SIZE,
    fontWeight: TAG_FONT_WEIGHT,
    height: 45,
    whiteSpace: canWrap ? 'unset' : 'nowrap',
    '& .MuiChip-label': {
      whiteSpace: canWrap ? 'unset' : 'nowrap',
    },
    maxWidth,
    [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
      padding: theme.spacing(0),
    },
  })
)

export default Tag
