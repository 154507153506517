import { styled, Theme, Tooltip } from '@material-ui/core'
import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react'

interface EllipsisPropsHorizontal {
  variant: 'horizontal'
  rows?: number
  tooltipText?: string
  helpCursor?: boolean
}
interface EllipsisPropsVertical {
  variant: 'vertical'
  rows: number
  tooltipText?: string
  helpCursor?: boolean
}
type EllipsisProps = PropsWithChildren<
  EllipsisPropsHorizontal | EllipsisPropsVertical
>

const EllipsisBase = styled('span')<Theme, EllipsisProps>(
  ({ variant, rows, tooltipText, helpCursor }) => ({
    textOverflow: 'ellipsis',
    '-webkit-box-orient': variant,
    width: 'inherit',
    cursor: tooltipText && helpCursor ? 'help' : 'default',
    ...(variant === 'vertical' && {
      overflowY: 'hidden',
      lineClamp: rows,
      display: '-webkit-box',
    }),
    ...(variant === 'horizontal' && {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    }),
  })
)

export const Ellipsis = ({
  tooltipText,
  children,
  ...props
}: EllipsisProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [tooltip, setTooltip] = useState('')

  const getTooltip = () => {
    if (!tooltipText) return ''
    const { offsetWidth } = ref.current || {}
    const { scrollWidth } = ref.current || {}
    if (!scrollWidth || !offsetWidth) return ''
    const isEllipsis = scrollWidth > offsetWidth

    return isEllipsis ? tooltipText : ''
  }

  useLayoutEffect(() => {
    setTooltip(getTooltip())
  }, [])

  return (
    <Tooltip title={tooltip}>
      <EllipsisBase {...props} tooltipText={tooltip} ref={ref}>
        {children}
      </EllipsisBase>
    </Tooltip>
  )
}
