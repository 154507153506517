import { Theme, createTheme } from '@material-ui/core'
import props from './props'
import palette from './palette'
import overrides from './overrides'
import typography from './typography'
import spacing from './spacing'
import transitions from './transitions'
import zIndex from './zIndex'
import breakpoints from './breakpoints'

const theme: Theme = createTheme({
  props,
  palette,
  overrides,
  typography,
  spacing,
  transitions,
  zIndex,
  breakpoints,
})

export default theme
