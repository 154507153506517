import createBreakpoints, {
  BreakpointsOptions,
} from '@material-ui/core/styles/createBreakpoints'

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    mdDrawer: 1460,
    templateListXl: 1620,
    templateListMd: 1470,
    templateListSm: 1310,
    tagsMd: 1080,
    mediaCategoryName: 1525,
    myPlaylistsItemMd: 1510,
    myPlaylistsItemSm: 1388,
    displayDetailsAppBar: 1310,
  },
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    mdDrawer: true
    templateListXl: true
    templateListMd: true
    templateListSm: true
    tagsMd: true
    mediaCategoryName: true
    myPlaylistsItemMd: true
    myPlaylistsItemSm: true
    displayDetailsAppBar: true
  }
}

export default createBreakpoints(breakpoints)
