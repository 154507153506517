import { Box } from '@material-ui/core'
import { DewhurstLogoWhite } from 'shared/images'

const BrandLogo = () => (
  <Box width="100%" px={4.5} pt={6}>
    <img src={DewhurstLogoWhite} width="100%" alt="Dewhurst logo" />
  </Box>
)

export default BrandLogo
