import config from 'config'
import jwtDecode from 'jwt-decode'
import { DecodedToken, Token } from './Auth.types'

type NullableToken = Token | null

class Auth {
  setToken = (token: NullableToken) => {
    localStorage.setItem(config.TOKEN_KEY, JSON.stringify(token))
  }

  getToken = () => {
    try {
      const tokenData = localStorage.getItem(config.TOKEN_KEY)
      const token: NullableToken = JSON.parse(tokenData as string)
      return token
    } catch (error) {
      console.error(error)
      return null
    }
  }

  removeToken = () => {
    localStorage.removeItem(config.TOKEN_KEY)
  }

  logout = () => {
    this.removeToken()
    window.location.reload()
  }

  isValid = () => {
    const token = this.getToken()
    if (!token) return false
    if (token.tokenType === 'Mocked') return true

    try {
      const { exp } = jwtDecode<DecodedToken>(token.accessToken)
      return exp >= new Date().getTime() / 1000
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export default Auth
