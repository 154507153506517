import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Tags = (props: SvgIconProps) => (
  <SvgIcon width="17px" height="19px" viewBox="0 0 17 19" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-230.000000, -66.000000)"
        fill="currentColor"
      >
        <g id="Tags" transform="translate(230.000000, 66.000000)">
          <path
            d="M3.86363636,8.5 C3.009,8.5 2.31818182,7.80686364 2.31818182,6.95454545 C2.31818182,6.10068182 3.009,5.40909091 3.86363636,5.40909091 C4.71672727,5.40909091 5.40909091,6.10068182 5.40909091,6.95454545 C5.40909091,7.80686364 4.71672727,8.5 3.86363636,8.5 M15.2281364,11.8173182 L6.72813636,3.31731818 C6.58286364,3.17204545 6.38659091,3.09090909 6.18181818,3.09090909 L0.772727273,3.09090909 C0.346181818,3.09090909 0,3.43709091 0,3.86363636 L0,9.27272727 C0,9.4775 0.0819090909,9.67454545 0.226409091,9.81904545 L8.72640909,18.3190455 C8.87709091,18.4697273 9.07490909,18.5454545 9.27272727,18.5454545 C9.47054545,18.5454545 9.66836364,18.4697273 9.81904545,18.3190455 L15.2281364,12.9099545 C15.5302727,12.6078182 15.5302727,12.1194545 15.2281364,11.8173182"
            id="Fill-1224"
          />
          <path
            d="M15.6809545,9.81904545 L7.40736364,1.54545455 L2.31818182,1.54545455 L2.31818182,0 L7.72727273,0 C7.93204545,0 8.12831818,0.0811363636 8.27359091,0.226409091 L16.7735909,8.72640909 L15.6809545,9.81904545 Z"
            id="Fill-1225"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Tags
