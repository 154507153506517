import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'layoutDesigner'

export const SNACKBARS: SnackbarDescriptorTable = {
  uploadArrowWidget: {
    errorMessage: true,
  },
  saveTemplate: {
    errorMessage: true,
    successMessage: {
      text: 'alerts.success.saveLayout',
      namespace: MODULE_NAME,
      type: 'success',
    },
  },
  uploadWidget: {
    errorMessage: {
      type: 'error',
      text: 'uploadWidget.error',
      namespace: MODULE_NAME,
    },
    successMessage: {
      type: 'success',
      text: 'updateWidget.success',
      namespace: MODULE_NAME,
    },
  },
  getTemplate: {
    errorMessage: true,
  },
  uploadMediaWidget: {
    errorMessage: true,
  },
  getDisplayDetails: {
    errorMessage: {
      type: 'error',
      text: 'supportedWidgets.notLoaded',
      namespace: MODULE_NAME,
    },
  },
  addWidgetCategory: {
    errorMessage: true,
    successMessage: {
      type: 'success',
      text: 'snackbars.categoryAddedSuccessfully',
    },
  },
  updateWidget: {
    errorMessage: true,
    successMessage: {
      type: 'success',
      text: 'updateWidget.success',
      namespace: MODULE_NAME,
    },
  },
}
