import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'

export const useDisplayModels = () => {
  const dispatch = useDispatch()
  const displays = useSelector(selectors.displayModels.getDisplayModels)

  useEffect(() => {
    dispatch(actions.displayModels.getDisplayModels())
  }, [dispatch])
  return displays
}
