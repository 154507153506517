import { Grid, useTheme } from '@material-ui/core'
import { useField } from 'formik'
import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'shared/hooks'
import { ReadOnly } from 'shared/types'
import Tag from './Tag'
import { BORDER_SIZE, Input, TagsBackgroundBox } from './TagsField.style'
import { useOnDeleteTag, useOnKeyDown } from './TagsField.utils'

interface TagsFieldProps extends ReadOnly {
  name: string
}

const TagsField = ({ name, readOnly }: TagsFieldProps) => {
  const [{ value: tags }, , { setValue }] = useField<string[]>(name)
  const [newTag, setNewTag] = useState('')
  const { t } = useTranslation()
  const onKeyDown = useOnKeyDown(newTag, tags, setNewTag, setValue)
  const onDeleteTag = useOnDeleteTag(tags, setValue)
  const ref = useRef<HTMLDivElement>(null)
  const [tagMaxWidth, setTagMaxWidth] = useState(0)
  const theme = useTheme()
  const windowSize = useWindowSize()

  useLayoutEffect(() => {
    const padding = theme.spacing(3)
    const width = ref.current?.offsetWidth

    if (padding && width) setTagMaxWidth(width - padding * 2 - BORDER_SIZE)
  }, [windowSize.width, theme])

  return (
    <TagsBackgroundBox ref={ref} readOnly={readOnly}>
      <Grid container spacing={2}>
        {tags.map(tag => (
          <Grid key={`tag-${name}-${tag}`} item>
            <Tag
              label={tag}
              onDelete={() => onDeleteTag(tag)}
              maxWidth={tagMaxWidth}
              clickable
            />
          </Grid>
        ))}
        <Grid item xs>
          <Input
            value={newTag}
            onChange={e => setNewTag(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder={t('tags.addNewTag')}
          />
        </Grid>
      </Grid>
    </TagsBackgroundBox>
  )
}

export default TagsField
