import { AppBarProps as BaseAppBarProps, Typography } from '@material-ui/core'
import React, { memo, PropsWithChildren } from 'react'
import { Ellipsis } from '../index'
import {
  Section,
  StyledAppBar,
  StyledToolbar,
  ToolbarFix,
} from './AppBar.style'

interface AppBarProps extends BaseAppBarProps {
  label: string
  flexValues?: (string | null)[]
  fixNameSizeSection?: boolean
  tooltipTitle?: string
}

const AppBar = ({
  label,
  flexValues,
  fixNameSizeSection,
  children,
  tooltipTitle,
  ...props
}: PropsWithChildren<AppBarProps>) => (
  <>
    <StyledAppBar elevation={1} position="fixed" {...props}>
      <StyledToolbar disableGutters>
        <Section fixNameSizeSection>
          <Typography variant="h4" color="primary">
            <Ellipsis variant="horizontal" tooltipText={tooltipTitle}>
              {label}
            </Ellipsis>
          </Typography>
        </Section>
        {React.Children.map(
          children,
          (child, i) =>
            child && (
              <Section flexValue={flexValues?.[i]} key={`section${i}`}>
                {child}
              </Section>
            )
        )}
      </StyledToolbar>
    </StyledAppBar>
    <ToolbarFix />
  </>
)

export default memo(AppBar)
