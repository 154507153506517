import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { object, string, SchemaOf } from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'

interface LoginFormValues {
  username: string
  password: string
}

const initialValues: LoginFormValues = {
  username: '',
  password: '',
}

const useValidationSchema = (): SchemaOf<LoginFormValues> => {
  const { t } = useTranslation()
  return object().shape({
    username: string().required(t('validation.required')),
    password: string().required(t('validation.required')),
  })
}

const useOnSubmit = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectors.auth.getIsAuthenticated)

  return async (
    { username, password }: LoginFormValues,
    helpers: FormikHelpers<LoginFormValues>
  ) => {
    await dispatch(actions.auth.login({ username, password }))
    if (isAuthenticated) await dispatch(actions.auth.getCurrentUser())
  }
}

export const useLoginForm = () => {
  const onSubmit = useOnSubmit()
  const validationSchema = useValidationSchema()

  return { onSubmit, validationSchema, initialValues }
}
