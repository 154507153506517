import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  LayoutVariant,
  ProgressStep,
  BackgroundColor,
  ApiWidget,
  Resource,
  WidgetsCategory,
  SideEffects,
  WidgetType,
  EditTemplateDetails,
  MediaTransformationKind,
  AbsoluteSize,
  Arrow,
  LayoutSlot,
  LayoutWidget,
  SlotWidgets,
  LiveEditHelperValuesMap,
  MetadataName,
  LoadingStatus,
  DisplayModel,
  EditTemplateDetailsAPI,
  ScheduleComplexId,
} from 'shared/types'
import {
  AddWidgetCategoryResponse,
  AddWidgetResponse,
  GetDefaultRssFeedResponse,
  WidgetManager,
} from 'shared/services'
import {
  setResourcePending,
  setResourceRejected,
  defaultSideEffects,
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
  defaultLiveEditValuesMap,
  defaultWidgetLists,
  getCurrentDatetimeWithoutTimezone,
} from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  saveTemplate,
  setCurrentStepValue,
  setSelectedLayout,
  addCustomBackgroundColor,
  addWidgetToSlot,
  getWidgetsByType,
  getWidgets,
  getWidgetsCategories,
  uploadMediaWidget,
  setWidgetPosition,
  setWidgetSize,
  clearSaveCreate,
  setEditMode,
  toggleEditItem,
  removeWidget,
  cancelEditMode,
  setMediaTransformation,
  setWidgetProperties,
  setLayoutPreviewSize,
  setSlotSize,
  resetLiveEditHelperValues,
  setIsSlotDraggedOver,
  getArrows,
  uploadArrowWidget,
  resetDirectionArrowUpload,
  setEditorWidgets,
  resetSlotSizes,
  getTemplate,
  setTemplateFullyProcessed,
  setActionValues,
  clearWidgetTemplate,
  uploadWidget,
  clearUploadWidget,
  getDefaultRssFeed,
  resetLoadingUploadWidget,
  updateTemplateThumbnail,
  addWidgetCategory,
  getDisplayDetails,
  resetDisplayDetails,
  addWidgetCategoryClearSideEffects,
  updateGeneratedProjectTime,
  deleteWidget,
  getDefaultTemplates,
  clearDefaultTemplate,
  clearGetTemplateError,
  updateWidget,
  getDisplayPlaylists,
  setIsWidgetMenuOpen,
  createTemplate,
} from './actions'
import { SetLiveEditHelperValuesPayload } from './actions.types'
import { mapSavedActiveStep } from '../utils'

interface State {
  editor: {
    slotSizes: {
      main: AbsoluteSize
      sidebar: AbsoluteSize
      footer: AbsoluteSize
    }
    isSlotDraggedOver: boolean
    idCounter: number
    currentStep: ProgressStep
    selectedLayout: LayoutVariant
    selectedDefaultLayout: number | null
    layoutPreviewSize: AbsoluteSize
    layoutPreviewWidth: number
    widgets: SlotWidgets
    customBackgroundColors: BackgroundColor[]
    templateFullyProcessed: boolean
  }
  widgetCreation: LiveEditHelperValuesMap
  widgets: Resource<Record<WidgetType, ApiWidget<WidgetType>[]>>
  widgetsCategories: Resource<WidgetsCategory[]>
  createTemplate: Resource<number | null>
  addWidgetCategory: Resource<AddWidgetCategoryResponse | null>
  uploadWidget: SideEffects
  uploadMediaWidget: Resource<AddWidgetResponse | null>
  saveTemplate: SideEffects
  uploadArrowWidget: SideEffects
  defaultTemplates: Resource<EditTemplateDetailsAPI[]>
  arrows: Resource<Arrow[]>
  rssFeeds: Resource<GetDefaultRssFeedResponse[]>
  template: Resource<EditTemplateDetails | null>
  editMode: {
    transformation: {
      widgetId: string | number
      kind: MediaTransformationKind
    } | null
    inProgress: boolean
    slot: LayoutSlot | null
    widgetIds: (string | number)[]
    initialWidgets: {
      main: LayoutWidget[]
      sidebar: LayoutWidget[]
      footer: LayoutWidget[]
    }
  }
  displayModels: Resource<DisplayModel[]>
  deleteWidget: SideEffects
  updateWidget: SideEffects
  getDisplayPlaylists: Resource<ScheduleComplexId[]>
  isWidgetMenuOpen: boolean
}

const DEFAULT_SLOT_SIZE = { width: 0, height: 0 }
const DEFAULT_PREVIEW_SIZE = { width: 0, height: 0 }

const initialState: State = {
  editor: {
    slotSizes: {
      footer: DEFAULT_SLOT_SIZE,
      main: DEFAULT_SLOT_SIZE,
      sidebar: DEFAULT_SLOT_SIZE,
    },
    isSlotDraggedOver: false,
    idCounter: 1,
    currentStep: ProgressStep.LayoutSelection,
    selectedLayout: LayoutVariant.DividedVertically,
    customBackgroundColors: [],
    layoutPreviewSize: DEFAULT_PREVIEW_SIZE,
    layoutPreviewWidth: 0,
    selectedDefaultLayout: null,
    widgets: {
      main: [],
      sidebar: [],
      footer: [],
    },
    templateFullyProcessed: false,
  },
  widgetCreation: defaultLiveEditValuesMap,
  widgets: getDefaultResourceState(defaultWidgetLists),
  widgetsCategories: getDefaultResourceState([]),
  defaultTemplates: getDefaultResourceState([]),
  addWidgetCategory: getDefaultResourceState(null),
  uploadWidget: defaultSideEffects,
  uploadMediaWidget: getDefaultResourceState(null),
  saveTemplate: defaultSideEffects,
  arrows: getDefaultResourceState([]),
  rssFeeds: getDefaultResourceState([]),
  createTemplate: getDefaultResourceState(null),
  template: getDefaultResourceState(null),
  uploadArrowWidget: defaultSideEffects,
  editMode: {
    transformation: null,
    inProgress: false,
    slot: null,
    widgetIds: [],
    initialWidgets: {
      main: [],
      sidebar: [],
      footer: [],
    },
  },
  displayModels: getDefaultResourceState([]),
  deleteWidget: defaultSideEffects,
  updateWidget: defaultSideEffects,
  getDisplayPlaylists: getDefaultResourceState([]),
  isWidgetMenuOpen: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(setIsWidgetMenuOpen, (state, { payload }) => {
      state.isWidgetMenuOpen = payload
    })
    .addCase(setCurrentStepValue, (state, action) => {
      state.editor.currentStep = action.payload
    })
    .addCase(setSelectedLayout, (state, { payload }) => {
      const { variant, defaultTemplate } = payload

      if (defaultTemplate !== undefined && variant) {
        state.editor.selectedDefaultLayout = defaultTemplate
        state.editor.selectedLayout = variant
      } else if (variant) {
        state.editor.selectedLayout = variant
      }
    })
    .addCase(saveTemplate.pending, state =>
      setResourcePending(state.saveTemplate)
    )
    .addCase(saveTemplate.fulfilled, (state, { meta }) => {
      setResourceFulfilled(state.saveTemplate)
      if (state.template.data) {
        state.template.data.lastUpdate = getCurrentDatetimeWithoutTimezone()
        state.editor.currentStep = mapSavedActiveStep(
          state.editor.currentStep,
          state.editor.selectedDefaultLayout
        )
      }
      if (state.template?.data && meta.arg.name && meta.arg.categoryId) {
        state.template.data.name = meta.arg.name
        state.template.data.categoryId = meta.arg.categoryId
      }
    })
    .addCase(saveTemplate.rejected, (state, action) =>
      setResourceRejected(state.saveTemplate, action)
    )
    .addCase(clearSaveCreate, state => {
      state.saveTemplate = initialState.saveTemplate
      state.createTemplate = initialState.createTemplate
    })
    .addCase(addCustomBackgroundColor, (state, action) => {
      state.editor.customBackgroundColors.push({ baseColor: action.payload })
    })
    .addCase(addWidgetToSlot, ({ editor }, action) => {
      const { slot, widget } = action.payload
      editor.idCounter++
      editor.widgets[slot] = WidgetManager.addWidget(
        editor.widgets[slot],
        widget,
        slot
      )
      editor.widgets[slot] = WidgetManager.handleWidgetsCollisions(
        editor.widgets[slot].length - 1,
        editor.widgets[slot],
        {
          width: editor.slotSizes[slot].width,
          height: editor.slotSizes[slot].height,
        }
      )
    })
    .addCase(getWidgetsByType.pending, state =>
      setResourcePending(state.widgets)
    )
    .addCase(getWidgetsByType.fulfilled, (state, action) => {
      setResourceFulfilled(state.widgets)
      state.widgets.data[action.meta.arg] = action.payload
    })
    .addCase(getWidgetsByType.rejected, (state, action) =>
      setResourceRejected(state.widgets, action)
    )
    .addCase(getDisplayPlaylists.pending, state =>
      setResourcePending(state.getDisplayPlaylists)
    )
    .addCase(getDisplayPlaylists.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDisplayPlaylists)
      state.getDisplayPlaylists.data = payload
    })
    .addCase(getDisplayPlaylists.rejected, (state, action) =>
      setResourceRejected(state.getDisplayPlaylists, action)
    )
    .addCase(resetLoadingUploadWidget, state => {
      state.uploadWidget.loading = LoadingStatus.Idle
    })
    .addCase(getWidgets.pending, state => setResourcePending(state.widgets))
    .addCase(getWidgets.fulfilled, (state, action) => {
      setResourceFulfilled(state.widgets)
      state.widgets.data[action.meta.arg.widgetType] = action.payload
    })
    .addCase(getWidgets.rejected, (state, action) => {
      setResourceRejected(state.widgets, action)
      state.widgets.data[action.meta.arg.widgetType] = []
    })
    .addCase(getWidgetsCategories.pending, state =>
      setResourcePending(state.widgetsCategories)
    )
    .addCase(getWidgetsCategories.rejected, (state, action) =>
      setResourceRejected(state.widgetsCategories, action)
    )
    .addCase(getWidgetsCategories.fulfilled, (state, action) => {
      setResourceFulfilled(state.widgetsCategories)
      state.widgetsCategories.data = action.payload
    })

    .addCase(getArrows.pending, state => {
      setResourcePending(state.arrows)
    })
    .addCase(getArrows.fulfilled, (state, action) => {
      setResourceFulfilled(state.arrows)
      state.arrows.data = action.payload
    })
    .addCase(getArrows.rejected, (state, action) => {
      setResourceRejected(state.arrows, action)
    })
    .addCase(uploadWidget.pending, state =>
      setResourcePending(state.uploadWidget)
    )
    .addCase(uploadWidget.fulfilled, ({ uploadWidget }) => {
      setResourceFulfilled(uploadWidget)
    })
    .addCase(uploadWidget.rejected, (state, action) =>
      setResourceRejected(state.uploadWidget, action)
    )
    .addCase(clearUploadWidget, state => {
      setResourceIdle(state.uploadWidget)
    })
    .addCase(uploadMediaWidget.pending, state => {
      state.uploadMediaWidget.data = initialState.uploadMediaWidget.data
      setResourcePending(state.uploadMediaWidget)
    })
    .addCase(
      uploadMediaWidget.fulfilled,
      ({ widgets, uploadMediaWidget }, { payload }) => {
        setResourceFulfilled(uploadMediaWidget)
        widgets.data[payload.widgetType].push(payload)
        uploadMediaWidget.data = payload
      }
    )
    .addCase(uploadMediaWidget.rejected, (state, action) =>
      setResourceRejected(state.uploadMediaWidget, action)
    )
    .addCase(uploadArrowWidget.pending, state =>
      setResourcePending(state.uploadArrowWidget)
    )
    .addCase(
      uploadArrowWidget.fulfilled,
      ({ widgets, uploadArrowWidget }, { payload }) => {
        setResourceFulfilled(uploadArrowWidget)
        widgets.data[payload.widgetType].push(payload)
      }
    )
    .addCase(uploadArrowWidget.rejected, (state, action) =>
      setResourceRejected(state.uploadArrowWidget, action)
    )
    .addCase(resetDirectionArrowUpload, state =>
      setResourceIdle(state.uploadArrowWidget)
    )
    .addCase(getTemplate.pending, state => setResourcePending(state.template))
    .addCase(getTemplate.rejected, (state, action) =>
      setResourceRejected(state.template, action)
    )
    .addCase(getTemplate.fulfilled, (state, action) => {
      setResourceFulfilled(state.template)

      action.payload.widgets.forEach((widget, index) =>
        widget.metadata?.forEach(data => {
          if (data?.name !== MetadataName.slotName) return
          const layoutWidget = WidgetManager.convertWidgetType(widget, index)
          const slot = data.value as LayoutSlot
          state.editor.widgets[slot].push(layoutWidget)
        })
      )

      state.editor.currentStep =
        Number(action.payload.editionProgressStep) ??
        ProgressStep.LayoutSelection
      state.editor.selectedLayout =
        action.payload.baseTemplateId || initialState.editor.selectedLayout

      if (action.meta.arg.prefetchedTemplate && state.template.data) {
        state.template.data = {
          ...state.template.data,
          editionProgressStep: Number(action.payload.editionProgressStep),
          widgets: action.payload.widgets,
          templateThumbnail: action.payload.templateThumbnail,
          baseTemplateId: action.payload.baseTemplateId,
          slotSizes: action.payload.slotSizes,
          templateStatus: action.payload.templateStatus,
        }
      } else state.template.data = action.payload
    })
    .addCase(setWidgetPosition, ({ editor }, { payload }) => {
      const { slot, index, newPosition, checkCollisions = true } = payload
      if (!editor.widgets[slot][index]) return
      editor.widgets[slot][index].position = newPosition
      if (checkCollisions)
        editor.widgets[slot] = WidgetManager.handleWidgetsCollisions(
          index,
          editor.widgets[slot],
          editor.slotSizes[payload.slot]
        )
    })
    .addCase(setWidgetSize, ({ editor }, { payload }) => {
      const { slot, index, newSize } = payload
      if (editor.widgets[slot][index].size)
        editor.widgets[slot][index].size = newSize
      editor.widgets[slot] = WidgetManager.handleWidgetsCollisions(
        index,
        editor.widgets[slot],
        editor.slotSizes[payload.slot]
      )
    })
    .addCase(setEditMode, ({ editMode, editor }, { payload }) => {
      if (!editMode.inProgress && payload.inProgress)
        editMode.initialWidgets = editor.widgets

      editMode.inProgress = payload.inProgress
      editMode.slot = payload.slot || null
      editMode.widgetIds = payload.id ? [payload.id] : []
      if (payload.id)
        editMode.transformation = {
          kind: MediaTransformationKind.Resize,
          widgetId: payload.id,
        }
    })
    .addCase(cancelEditMode, ({ editMode, editor }) => {
      editor.widgets = editMode.initialWidgets
      editMode.slot = null
      editMode.inProgress = false
      editMode.widgetIds = []
    })
    .addCase(toggleEditItem, (state, { payload }) => {
      const index = state.editMode.widgetIds.findIndex(id => id === payload)
      if (index !== -1) state.editMode.widgetIds.splice(index, 1)
      else state.editMode.widgetIds.push(payload)
    })
    .addCase(removeWidget, ({ editor, editMode }, { payload }) => {
      const index = editor.widgets[payload.slot].findIndex(
        ({ id }) => id === payload.id
      )
      const removedId = editor.widgets[payload.slot][index].id
      editor.widgets[payload.slot].splice(index, 1)
      editMode.widgetIds = editMode.widgetIds.filter(id => id !== payload.id)

      editor.widgets[payload.slot].forEach(widget => {
        widget.isInCollisionWith = widget.isInCollisionWith.filter(
          id => id !== removedId
        )
      })
    })
    .addCase(setMediaTransformation, (state, { payload }) => {
      state.editMode.transformation = payload
    })
    .addCase(setWidgetProperties, (state, { payload }) => {
      const { slot, index, newProperties, newLayoutProperties } = payload
      state.editor.widgets[slot][index].properties = newProperties
      state.editor.widgets[slot][index].layoutProperties =
        newLayoutProperties ||
        state.editor.widgets[slot][index].layoutProperties
    })
    .addCase(setActionValues, (state, { payload }) => {
      const { data, index, slotName } = payload
      state.editor.widgets[slotName][index].actions = [
        {
          name: MediaTransformationKind.Crop,
          value: data,
        },
      ]
    })
    .addCase(setLayoutPreviewSize, (state, { payload }) => {
      state.editor.layoutPreviewSize = payload
    })
    .addCase(setSlotSize, (state, { payload }) => {
      state.editor.slotSizes[payload.slot] = payload.size
    })
    .addCase(setIsSlotDraggedOver, (state, { payload }) => {
      state.editor.isSlotDraggedOver = payload
    })
    .addCase(setEditorWidgets, (state, action) => {
      state.editor.widgets = action.payload
    })
    .addCase(resetSlotSizes, state => {
      state.editor.slotSizes = initialState.editor.slotSizes
    })
    .addCase(resetLiveEditHelperValues, (state, action) => {
      state.widgetCreation = defaultLiveEditValuesMap
    })
    .addCase(setTemplateFullyProcessed, (state, { payload }) => {
      state.editor.templateFullyProcessed = payload
    })
    .addCase(clearWidgetTemplate, state => {
      state.editor = initialState.editor
      state.editMode = initialState.editMode
    })
    .addCase(clearGetTemplateError, state => {
      state.template = initialState.template
    })
    .addCase(clearDefaultTemplate, state => {
      state.editor = {
        ...initialState.editor,
        slotSizes: state.editor.slotSizes,
        layoutPreviewSize: state.editor.layoutPreviewSize,
        layoutPreviewWidth: state.editor.layoutPreviewWidth,
        selectedDefaultLayout: state.editor.selectedDefaultLayout,
      }
      state.editMode = initialState.editMode
      if (state.template.data) state.template.data.slotSizes = null
    })
    .addCase(getDefaultRssFeed.pending, state =>
      setResourcePending(state.rssFeeds)
    )
    .addCase(getDefaultRssFeed.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.rssFeeds)
      state.rssFeeds.data = payload
    })
    .addCase(getDefaultRssFeed.rejected, (state, action) =>
      setResourceRejected(state.rssFeeds, action)
    )
    .addCase(getDisplayDetails.pending, state => {
      setResourcePending(state.displayModels)
    })
    .addCase(getDisplayDetails.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.displayModels)
      state.displayModels.data = payload
    })
    .addCase(getDisplayDetails.rejected, (state, action) => {
      setResourceRejected(state.displayModels, action)
    })
    .addCase(resetDisplayDetails, state => {
      setResourceIdle(state.displayModels)
    })
    .addCase(updateTemplateThumbnail, (state, { payload }) => {
      if (state.template.data)
        state.template.data.templateThumbnail = payload.thumbnailUuid
    })
    .addCase(addWidgetCategory.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.addWidgetCategory)
      state.addWidgetCategory.data = payload
    })
    .addCase(addWidgetCategory.pending, state =>
      setResourcePending(state.addWidgetCategory)
    )
    .addCase(addWidgetCategory.rejected, (state, action) =>
      setResourceRejected(state.addWidgetCategory, action)
    )
    .addCase(getDefaultTemplates.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.defaultTemplates)
      state.defaultTemplates.data = payload
    })
    .addCase(getDefaultTemplates.pending, state =>
      setResourcePending(state.defaultTemplates)
    )
    .addCase(getDefaultTemplates.rejected, (state, action) =>
      setResourceRejected(state.defaultTemplates, action)
    )
    .addCase(addWidgetCategoryClearSideEffects, state => {
      state.addWidgetCategory = initialState.addWidgetCategory
    })
    .addCase(updateGeneratedProjectTime, state => {
      if (state.template.data)
        state.template.data.projectGenerated = getCurrentDatetimeWithoutTimezone()
    })
    .addCase(deleteWidget.pending, state =>
      setResourcePending(state.deleteWidget)
    )
    .addCase(deleteWidget.fulfilled, (state, { meta }) => {
      const { widgetType, widgetId } = meta.arg
      state.widgets.data[widgetType] = state.widgets.data[widgetType].filter(
        ({ id }) => id !== widgetId
      )
      setResourceFulfilled(state.deleteWidget)
    })
    .addCase(deleteWidget.rejected, (state, action) =>
      setResourceRejected(state.deleteWidget, action)
    )
    .addCase(updateWidget.pending, state =>
      setResourcePending(state.updateWidget)
    )
    .addCase(updateWidget.fulfilled, state =>
      setResourceFulfilled(state.updateWidget)
    )
    .addCase(updateWidget.rejected, (state, action) =>
      setResourceRejected(state.updateWidget, action)
    )
    .addCase(createTemplate.pending, state =>
      setResourcePending(state.createTemplate)
    )
    .addCase(createTemplate.fulfilled, (state, action) => {
      setResourceFulfilled(state.createTemplate)
      state.createTemplate.data = action.payload
    })
    .addCase(createTemplate.rejected, (state, action) => {
      setResourceRejected(state.createTemplate, action)
    })
    .addMatcher(
      action =>
        typeof action.type === 'string' &&
        action.type.startsWith(`${MODULE_NAME}/setLiveEditHelperValues`),
      (
        state,
        { payload }: PayloadAction<SetLiveEditHelperValuesPayload<any>>
      ) => {
        const widgetCreation =
          state.widgetCreation[payload.widgetType as WidgetType]
        widgetCreation.isValid = payload.isValid
        widgetCreation.properties =
          payload.properties || widgetCreation.properties
        widgetCreation.layoutProperties =
          payload.layoutProperties || widgetCreation.layoutProperties
      }
    )
)
