import { styled } from '@material-ui/core'
import { MaterialSelect } from 'shared/components/Select'

export const LanguageSelect = styled(MaterialSelect)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: `5px 35px 5px 10px`,
  alignSelf: 'flex-end',
  width: '100%',
}))
