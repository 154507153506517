import React, { forwardRef } from 'react'
import { ToggleButtonProps } from '@material-ui/lab'
import { Tooltip, TooltipProps } from '@material-ui/core'
import { ToggleButton } from './ToggleButton'

interface TooltipToggleButtonProps extends ToggleButtonProps {
  TooltipProps: Omit<TooltipProps, 'children'>
}

export const TooltipToggleButton = forwardRef<
  HTMLButtonElement | null,
  TooltipToggleButtonProps
>(({ TooltipProps, ...props }, ref) => (
  <Tooltip {...TooltipProps}>
    <div>
      <ToggleButton ref={ref} {...props} />
    </div>
  </Tooltip>
))
