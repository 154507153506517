import { fade, styled, Theme } from '@material-ui/core'
import { TextField, TextFieldProps } from 'formik-material-ui'
import { isSafari } from 'shared/utils'

export const StyledTextField = styled(TextField)<Theme, TextFieldProps>(
  ({ theme, disabled, inputProps }) => {
    const isSafariBrowser = isSafari()

    return {
      '& .MuiFilledInput-root': {
        fontWeight: 600,
        pointerEvents: inputProps?.readOnly ? 'none' : 'all',
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(1.2, 0, 1.2, 2),
        fontWeight: 700,
        cursor: disabled ? 'not-allowed' : 'auto',
        ...(inputProps?.readOnly && {
          background: fade(theme.palette.accent.main, 0.08),
          color: theme.palette.info.contrastText,
          fontWeight: 700,
        }),
        '&::-webkit-input-placeholder': {
          ...(isSafariBrowser && {
            position: 'absolute',
            top: '50%',
            transform: 'translate(57px, 4px)',
          }),
        },
      },
      '& .MuiFilledInput-multiline': {
        paddingTop: theme.spacing(2),
      },
    }
  }
)

export const StyledTextFieldColor = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '8px 0 6px 15px ',
  },
})
