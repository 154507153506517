import { createReducer } from '@reduxjs/toolkit'
import { PAGINATION_LIMITS } from 'shared/config'
import {
  AssignRssResourceToDeviceResponse,
  AudioFile,
  AudioType,
  DeviceRssSource,
  LiftStopAudio,
  GetDeviceScreenshotResponse,
  AudioLanguage,
  AudioEvent,
} from 'shared/services'
import {
  DisplayDetailsProperties,
  DisplayProperties,
  EcosystemEvent,
  PaginationResource,
  Resource,
  SideEffects,
  EditTemplateDetails,
} from 'shared/types'
import {
  defaultSideEffects,
  getDefaultPaginationResourceState,
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import {
  assignRssResourceToDevice,
  changeEcosystemEventsPage,
  clearDisplaySideEffects,
  clearUpdateFirmwareSideEffect,
  getAudioEvents,
  getDeviceAudioFiles,
  getDeviceAudioFloors,
  getDeviceScreenshot,
  getDisplayDetails,
  getDisplays,
  getEcosystemEvents,
  getRssSourcesFromDevice,
  refreshStatuses,
  resetAssignRssResourceToDeviceSideEffects,
  resetGetDisplays,
  setAudioEvents,
  updateDisplay,
  updateFirmware,
  getAudioLanguages,
  addAudioFile,
  addAudioLanguage,
  resetAddAudioLanguage,
  updateLastSent,
} from './actions'

interface State {
  displays: Resource<DisplayProperties[]>
  displayDetails: Resource<DisplayDetailsProperties | null>
  ecosystemEvents: PaginationResource<EcosystemEvent[]>
  refreshStatusesSideEffects: SideEffects
  updateDisplaySideEffects: SideEffects
  updateFirmwareSideEffects: SideEffects
  getDeviceAudioFilesFloors: PaginationResource<AudioFile[]>
  getDeviceAudioFilesStatusMessages: PaginationResource<AudioFile[]>
  getDeviceAudioFloors: Resource<LiftStopAudio[]>
  setAudioEvents: SideEffects
  getAudioEvents: Resource<AudioEvent[]>
  getDeviceRssFeeds: Resource<DeviceRssSource[]>
  assignRssResourceToDevice: Resource<AssignRssResourceToDeviceResponse | null>
  getDeviceScreenshot: Resource<GetDeviceScreenshotResponse | null>
  getTemplateAssignedToTheDevice: Resource<EditTemplateDetails | null>
  getAudioLanguages: Resource<AudioLanguage[] | null>
  addAudioFile: SideEffects
  addAudioLanguage: SideEffects
}

const initialState: State = {
  displays: getDefaultResourceState([]),
  displayDetails: getDefaultResourceState(null),
  ecosystemEvents: getDefaultPaginationResourceState(
    [],
    PAGINATION_LIMITS.ecosystemEvents
  ),
  refreshStatusesSideEffects: defaultSideEffects,
  updateDisplaySideEffects: defaultSideEffects,
  updateFirmwareSideEffects: defaultSideEffects,
  getDeviceAudioFilesFloors: getDefaultPaginationResourceState(
    [],
    PAGINATION_LIMITS.audioFiles
  ),
  getDeviceAudioFilesStatusMessages: getDefaultPaginationResourceState(
    [],
    PAGINATION_LIMITS.audioFiles
  ),
  getDeviceAudioFloors: getDefaultResourceState([]),
  setAudioEvents: defaultSideEffects,
  getAudioEvents: getDefaultResourceState([]),
  getDeviceRssFeeds: getDefaultResourceState([]),
  assignRssResourceToDevice: getDefaultResourceState(null),
  getDeviceScreenshot: getDefaultResourceState(null),
  getTemplateAssignedToTheDevice: getDefaultResourceState(null),
  getAudioLanguages: getDefaultResourceState(null),
  addAudioFile: defaultSideEffects,
  addAudioLanguage: defaultSideEffects,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getDisplays.pending, state => setResourcePending(state.displays))
    .addCase(getDisplays.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.displays)
      state.displays.data = payload
    })
    .addCase(getDisplays.rejected, (state, action) => {
      setResourceRejected(state.displays, action)
      state.displays.data = []
    })
    .addCase(addAudioLanguage.pending, state =>
      setResourcePending(state.addAudioLanguage)
    )
    .addCase(addAudioLanguage.fulfilled, state =>
      setResourceFulfilled(state.addAudioLanguage)
    )
    .addCase(addAudioLanguage.rejected, (state, action) =>
      setResourceRejected(state.addAudioLanguage, action)
    )
    .addCase(resetAddAudioLanguage, state => {
      state.addAudioLanguage = defaultSideEffects
    })
    .addCase(addAudioFile.pending, state =>
      setResourcePending(state.addAudioFile)
    )
    .addCase(addAudioFile.fulfilled, state =>
      setResourceFulfilled(state.addAudioFile)
    )
    .addCase(addAudioFile.rejected, (state, action) =>
      setResourceRejected(state.addAudioFile, action)
    )
    .addCase(getAudioLanguages.pending, state =>
      setResourcePending(state.getAudioLanguages)
    )
    .addCase(getAudioLanguages.rejected, (state, action) => {
      setResourceRejected(state.getAudioLanguages, action)
      state.getAudioLanguages.data = []
    })
    .addCase(getAudioLanguages.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getAudioLanguages)
      state.getAudioLanguages.data = payload
    })
    .addCase(getDisplayDetails.pending, state =>
      setResourcePending(state.displayDetails)
    )
    .addCase(getDisplayDetails.fulfilled, (state, { payload: display }) => {
      setResourceFulfilled(state.displayDetails)
      state.displayDetails.data = display
    })
    .addCase(getDisplayDetails.rejected, (state, action) =>
      setResourceRejected(state.displayDetails, action)
    )
    .addCase(refreshStatuses.pending, state =>
      setResourcePending(state.refreshStatusesSideEffects)
    )
    .addCase(refreshStatuses.fulfilled, (state, { payload: statuses }) => {
      setResourceFulfilled(state.refreshStatusesSideEffects)
      statuses.forEach(({ deviceId, connectionStatus }) => {
        const matchingFromList = state.displays.data.find(
          ({ id }) => id === deviceId
        )
        if (matchingFromList)
          matchingFromList.connectionStatus = connectionStatus
        if (
          state.displayDetails.data &&
          state.displayDetails.data.id === deviceId
        )
          state.displayDetails.data.connectionStatus = connectionStatus
      })
    })
    .addCase(refreshStatuses.rejected, (state, action) =>
      setResourceRejected(state.refreshStatusesSideEffects, action)
    )
    .addCase(updateDisplay.pending, state =>
      setResourcePending(state.updateDisplaySideEffects)
    )
    .addCase(updateDisplay.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.updateDisplaySideEffects)
    })
    .addCase(updateLastSent, (state, { payload }) => {
      if (!state.displayDetails.data) return
      state.displayDetails.data.lastSentTemplateId = payload.lastSentTemplateId
      state.displayDetails.data.lastSentTemplateThumbnail =
        payload.lastSentTemplateThumbnail
    })
    .addCase(updateDisplay.rejected, (state, action) =>
      setResourceRejected(state.updateDisplaySideEffects, action)
    )
    .addCase(resetGetDisplays, state => setResourceIdle(state.displays))
    .addCase(clearDisplaySideEffects, state =>
      setResourceIdle(state.updateDisplaySideEffects)
    )
    .addCase(getEcosystemEvents.pending, state =>
      setResourcePending(state.ecosystemEvents)
    )
    .addCase(getEcosystemEvents.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.ecosystemEvents)
      state.ecosystemEvents.data = payload
    })
    .addCase(getEcosystemEvents.rejected, (state, action) =>
      setResourceRejected(state.ecosystemEvents, action)
    )
    .addCase(changeEcosystemEventsPage, (state, { payload }) => {
      state.ecosystemEvents.page = payload
    })
    .addCase(getDeviceAudioFiles.pending, (state, { meta }) => {
      meta.arg.audioType === AudioType.floor
        ? setResourcePending(state.getDeviceAudioFilesFloors)
        : setResourcePending(state.getDeviceAudioFilesStatusMessages)
    })
    .addCase(getDeviceAudioFiles.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.audioType === AudioType.floor) {
        state.getDeviceAudioFilesFloors.data = payload
        setResourceFulfilled(state.getDeviceAudioFilesFloors)
      } else {
        state.getDeviceAudioFilesStatusMessages.data = payload
        setResourceFulfilled(state.getDeviceAudioFilesStatusMessages)
      }
    })
    .addCase(getDeviceAudioFiles.rejected, (state, action) => {
      action.meta.arg.audioType === AudioType.floor
        ? setResourceRejected(state.getDeviceAudioFilesFloors, action)
        : setResourceRejected(state.getDeviceAudioFilesStatusMessages, action)
    })
    .addCase(getDeviceAudioFloors.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDeviceAudioFloors)
      state.getDeviceAudioFloors.data = payload
    })
    .addCase(getDeviceAudioFloors.pending, state => {
      setResourcePending(state.getDeviceAudioFloors)
    })
    .addCase(getDeviceAudioFloors.rejected, (state, action) => {
      setResourceRejected(state.getDeviceAudioFloors, action)
    })
    .addCase(setAudioEvents.pending, state =>
      setResourcePending(state.setAudioEvents)
    )
    .addCase(setAudioEvents.fulfilled, state =>
      setResourceFulfilled(state.setAudioEvents)
    )
    .addCase(setAudioEvents.rejected, (state, action) =>
      setResourceRejected(state.setAudioEvents, action)
    )
    .addCase(getAudioEvents.pending, state =>
      setResourcePending(state.getAudioEvents)
    )
    .addCase(getAudioEvents.rejected, (state, action) =>
      setResourceRejected(state.getAudioEvents, action)
    )
    .addCase(getAudioEvents.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getAudioEvents)
      state.getAudioEvents.data = payload
    })
    .addCase(updateFirmware.pending, state =>
      setResourcePending(state.updateFirmwareSideEffects)
    )
    .addCase(updateFirmware.fulfilled, (state, { payload }) =>
      setResourceFulfilled(state.updateFirmwareSideEffects)
    )
    .addCase(updateFirmware.rejected, (state, action) =>
      setResourceRejected(state.updateFirmwareSideEffects, action)
    )
    .addCase(clearUpdateFirmwareSideEffect, state =>
      setResourceIdle(state.updateFirmwareSideEffects)
    )
    .addCase(getRssSourcesFromDevice.pending, state =>
      setResourcePending(state.getDeviceRssFeeds)
    )
    .addCase(getRssSourcesFromDevice.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDeviceRssFeeds)
      state.getDeviceRssFeeds.data = payload
    })
    .addCase(getRssSourcesFromDevice.rejected, (state, action) => {
      setResourceRejected(state.getDeviceRssFeeds, action)
      state.getDeviceRssFeeds.data = initialState.getDeviceRssFeeds.data
    })
    .addCase(assignRssResourceToDevice.pending, state =>
      setResourcePending(state.assignRssResourceToDevice)
    )
    .addCase(assignRssResourceToDevice.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.assignRssResourceToDevice)
      state.assignRssResourceToDevice.data = payload
    })
    .addCase(assignRssResourceToDevice.rejected, (state, action) =>
      setResourceRejected(state.assignRssResourceToDevice, action)
    )
    .addCase(resetAssignRssResourceToDeviceSideEffects, state => {
      state.assignRssResourceToDevice = initialState.assignRssResourceToDevice
    })
    .addCase(getDeviceScreenshot.fulfilled, (state, { payload }) => {
      setResourceFulfilled(state.getDeviceScreenshot)
      state.getDeviceScreenshot.data = payload
    })
    .addCase(getDeviceScreenshot.pending, state =>
      setResourcePending(state.getDeviceScreenshot)
    )
    .addCase(getDeviceScreenshot.rejected, (state, action) =>
      setResourceRejected(state.getDeviceScreenshot, action)
    )
)
