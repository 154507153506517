import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'
import { PreventFromRedirectionOptions } from 'shared/types'

export const useRedirectionGuard = () => {
  const dispatch = useDispatch()
  const { prevent } = useSelector(selectors.utils.getRedirectionOptions)

  const setRedirectionGuard = useCallback(
    (options: PreventFromRedirectionOptions) => {
      dispatch(actions.utils.setPreventFromRedirection(options))
    },
    [dispatch]
  )

  return {
    setRedirectionGuard,
    isRedirectionAllowed: !prevent,
  }
}
