import { createReducer } from '@reduxjs/toolkit'
import { QueuedSnackbarDescriptor } from '../../types'
import { add, dropAll } from './actions'

interface State {
  queue: QueuedSnackbarDescriptor[]
}

const initialState: State = {
  queue: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(add, (state, { payload }) => {
      state.queue.push(payload)
    })
    .addCase(dropAll, state => {
      state.queue = []
    })
)
