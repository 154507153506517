import { SvgIcon, SvgIconProps } from '@material-ui/core'

const PlaylistWidget = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 18" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icons"
        transform="translate(-746.000000, -2499.000000)"
        fill="currentColor"
      >
        <g
          id="Fill-45-+-Fill-46"
          transform="translate(746.000000, 2499.000000)"
        >
          <path
            d="M6,3 C5.448,3 5,2.552 5,2 C5,1.448 5.448,1 6,1 C6.552,1 7,1.448 7,2 C7,2.552 6.552,3 6,3 M3,3 C2.448,3 2,2.552 2,2 C2,1.448 2.448,1 3,1 C3.552,1 4,1.448 4,2 C4,2.552 3.552,3 3,3 M18,0 L2,0 C0.897,0 0,0.896 0,2 L0,4.002 L20,4.002 L20,2 C20,0.896 19.103,0 18,0"
            id="Fill-45"
          ></path>
          <path
            d="M4,12 C4.552,12 5,12.448 5,13 C5,13.552 4.552,14 4,14 C3.448,14 3,13.552 3,13 C3,12.448 3.448,12 4,12 L4,12 Z M4,8.001 C4.552,8.001 5,8.449 5,9.001 C5,9.554 4.552,10.001 4,10.001 C3.448,10.001 3,9.554 3,9.001 C3,8.449 3.448,8.001 4,8.001 L4,8.001 Z M7,12.003 L17,12.003 L17,14.003 L7,14.003 L7,12.003 Z M7,8.004 L17,8.004 L17,10.004 L7,10.004 L7,8.004 Z M0,16 C0,17.103 0.897,18 2,18 L18,18 C19.103,18 20,17.103 20,16 L20,5.003 L0,5.003 L0,16 Z"
            id="Fill-46"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default PlaylistWidget
