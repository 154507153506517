import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DropdownArrow = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 13 10" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9059 0.576C11.7409 0.225 11.3879 0 10.9999 0H0.999851C0.611851 0 0.258851 0.225 0.0938508 0.576C-0.0701492 0.928 -0.0161492 1.343 0.231851 1.64L5.23185 7.64C5.42185 7.868 5.70285 8 5.99985 8C6.29685 8 6.57785 7.868 6.76785 7.64L11.7679 1.64C12.0169 1.343 12.0699 0.928 11.9059 0.576Z"
      fill="white"
    />
  </SvgIcon>
)

export default DropdownArrow
