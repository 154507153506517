import { styled, Theme, Typography, TypographyProps } from '@material-ui/core'

const FormLabel = styled(Typography)<Theme, TypographyProps>(
  ({ theme, ...props }) => ({
    ...theme.typography.subtitle2,
    letterSpacing: 0.19,
    color: props.color || theme.palette.label.main,
  })
)

export default FormLabel
