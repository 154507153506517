import { useCallback, useEffect } from 'react'
import { LoadingStatus } from 'shared/types'
import { useAssetUrl } from './useAssetUrl'

const loadedFonts = new Map()

export const useDynamicFontLoading = (fontUuid?: string | null) => {
  const isFontAvailable = fontUuid && loadedFonts.has(fontUuid)

  const { assetUrl, loading, resetLoadedAsset } = useAssetUrl(fontUuid, {
    isAllowedToLoad: !isFontAvailable,
    isResource: true,
  })

  const loadFont = useCallback(
    async (font: FontFace) => {
      try {
        const face = await font.load()
        document.fonts.add(face)
        loadedFonts.set(fontUuid, true)
        resetLoadedAsset()
      } catch (err) {
        throw err
      }
    },
    [fontUuid, resetLoadedAsset]
  )

  useEffect(() => {
    if (!assetUrl || !fontUuid || isFontAvailable) return
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    try {
      if (isFirefox) {
        const font = new FontFace(`"${fontUuid}"`, `url(${assetUrl})`)
        loadFont(font)
      } else {
        const font = new FontFace(fontUuid, `url(${assetUrl})`)
        loadFont(font)
      }
    } catch (err) {
      throw err
    }
  }, [assetUrl, fontUuid, isFontAvailable, loadFont])

  return { loading: isFontAvailable ? LoadingStatus.Succeeded : loading }
}
