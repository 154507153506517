import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DateTime = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-381.000000, -1082.000000)">
        <g id="Icons" transform="translate(197.000000, 314.000000)">
          <g transform="translate(184.000000, 768.000000)">
            <path d="M2,5 L18,5 L18,8 L2,8 L2,5 Z M10,18 C10,13.582 13.582,10 18,10 C18.692,10 19.359,10.097 20,10.263 L20,5 C20,3.898 19.103,3 18,3 L16,3 L16,0 L14,0 L14,3 L6,3 L6,0 L4,0 L4,3 L2,3 C0.897,3 0,3.898 0,5 L0,18 C0,19.103 0.897,20 2,20 L10.262,20 C10.097,19.359 10,18.692 10,18 L10,18 Z"></path>
            <path d="M21,18.9849 L17,18.9849 L17,13.9849 L19,13.9849 L19,16.9849 L21,16.9849 L21,18.9849 Z M18,11.9849 C14.691,11.9849 12,14.6769 12,17.9849 C12,21.2919 14.691,23.9849 18,23.9849 C21.309,23.9849 24,21.2919 24,17.9849 C24,14.6769 21.309,11.9849 18,11.9849 L18,11.9849 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default DateTime
