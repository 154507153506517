import { Loadable } from 'shared/components'
import { Category } from 'shared/icons'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/categories'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    path: '',
    label: 'Categories',
    icon: Category,
    exact: true,
    component: Loadable({
      component: () => import('./containers/Categories'),
    }),
  },
])

export default routes
