import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AddCategoryBody,
  Assets,
  GetTemplatesQueryProps,
  LayoutDesignerUtils,
  Templates,
} from 'shared/services'
import { SNACKBARS } from 'shared/strings'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { generateBase64Image } from '../../utils'

const templates = new Templates()
const assets = new Assets()
const layoutDesignerUtils = new LayoutDesignerUtils()

const MODULE_NAME = 'templates'

export const getCategoryList = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getCategoryList`,
  payloadCreator: () => templates.getCategoryList(),
})

export const getTemplates = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getTemplates`,
  payloadCreator: (params?: GetTemplatesQueryProps) =>
    templates.getTemplates(params),
})

export const getTemplateStatuses = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getTemplateStatuses`,
  payloadCreator: () => templates.getTemplateStatuses(),
})

export const deleteTemplate = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteTemplate`,
  payloadCreator: ({ templateId }: { templateId: number }) =>
    templates.deleteTemplate(templateId),
  ...SNACKBARS.deleteTemplate,
})

interface uploadTemplateThumbnailPayload {
  target: Node | null
  templateId: number
}
export const uploadTemplateThumbnail = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadTemplateThumbnail`,
  payloadCreator: async ({
    target,
    templateId,
  }: uploadTemplateThumbnailPayload) => {
    const base64 = await generateBase64Image(target)
    const thumbnailUuid = await assets.uploadBase64Image(base64)
    if (!thumbnailUuid) return
    return await templates.addTemplateThumbnail({
      templateId,
      thumbnailUuid,
    })
  },
  ...SNACKBARS.uploadTemplateThumbnail,
})

export const stopPollingUploadingTemplateThumbnail = createAsyncThunk(
  `${MODULE_NAME}/stopPollingUploadingBase64Image`,
  () => {
    assets.stopPollingUploadingBase64Image()
  }
)

export const clearTemplate = createAction(`${MODULE_NAME}/clearTemplate`)

export const clearSaveTemplateError = createAction(
  `${MODULE_NAME}/clearSaveTemplateError`
)

interface GenerateProjectPayload {
  templateId: number
  deviceId?: number
}

interface DownloadUSBPayload {
  templateId: number
  deviceId: number
}

export const generateProject = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/generateProject`,
  payloadCreator: ({ templateId, deviceId }: GenerateProjectPayload) =>
    layoutDesignerUtils.generateProject(templateId, deviceId),
  ...SNACKBARS.generateProject,
})

export const getZipProject = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getZipProject`,
  payloadCreator: ({ templateId, deviceId }: DownloadUSBPayload) =>
    layoutDesignerUtils.getZipProject(templateId, deviceId),
  ...SNACKBARS.getZipProject,
})

export const clearGenerateProject = createAction(
  `${MODULE_NAME}/clearGenerateProject`
)

export const clearGetZipProject = createAction(
  `${MODULE_NAME}/clearGetZipProject`
)

export const addTemplateCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/addTemplateCategory`,
  payloadCreator: async (payload: AddCategoryBody) =>
    templates.addCategory(payload),
  ...SNACKBARS.addTemplateCategory,
})

export const resetAddCategory = createAction(`${MODULE_NAME}/resetAddCategory`)
