import { RootState } from 'app'

export const getPlaylist = (state: RootState) => state.playlist.playlists

export const getDeletePlaylist = (state: RootState) =>
  state.playlist.deletePlaylist

export const getUpdatePlaylist = (state: RootState) =>
  state.playlist.updatePlaylist

export const getPlaylistCategories = (state: RootState) => ({
  categories: state.playlist.getPlaylistCategories.data.map(
    ({ name, idPlaylistCategory }) => ({
      label: name,
      value: idPlaylistCategory,
    })
  ),
  loading: state.playlist.getPlaylistCategories.loading,
  error: state.playlist.getPlaylistCategories.error,
})

export const getPlaylistCounter = (state: RootState) =>
  state.playlist.playlistCounter

export const getDeviceSchedules = (state: RootState) =>
  state.playlist.getDeviceSchedules

export const getUpdateOrCreateSchedule = (state: RootState) =>
  state.playlist.updateOrCreateSchedule

export const getDeleteSchedule = (state: RootState) =>
  state.playlist.deleteSchedule

export const getSchedulesBeingGenerated = (state: RootState) =>
  state.playlist.schedulesBeingGenerated

export const getPlaylistsToSchedule = (state: RootState) =>
  state.playlist.playlistsToSchedule.playlists

export const getPlaylistsToScheduleAspectRatio = (state: RootState) =>
  state.playlist.playlistsToSchedule.setupForAspectRatio
