import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Refresh = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.351 4.3506C5.824 2.8716 7.828 1.9996 10 1.9996C14.412 1.9996 18 5.5886 18 9.9996H20C20 4.4856 15.515 -0.000396729 10 -0.000396729C7.285 -0.000396729 4.78 1.0886 2.938 2.9376L1 0.999603V6.9996H7L4.351 4.3506Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.649 15.6494C14.176 17.1284 12.172 18.0004 10 18.0004C5.588 18.0004 2 14.4114 2 10.0004H0C0 15.5144 4.485 20.0004 10 20.0004C12.715 20.0004 15.22 18.9114 17.062 17.0614L19 19.0004V13.0004H13L15.649 15.6494Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Refresh
