import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Center = (props: SvgIconProps) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
    <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <polygon id="Fill-413" points="16 2 0 2 0 0 16 0"></polygon>
      <polygon
        id="Fill-414"
        points="13 6.999 3 6.999 3 4.999 13 4.999"
      ></polygon>
      <polygon id="Fill-415" points="16 12 0 12 0 10 16 10"></polygon>
      <polygon id="Fill-416" points="15 17 1 17 1 15 15 15"></polygon>
    </g>
  </SvgIcon>
)

export default Center
