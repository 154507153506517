import { styled, Theme } from '@material-ui/core'
import { PropsWithChildren } from 'react'
import { useDynamicFontLoading } from 'shared/hooks'
import {
  BooleanStringType,
  LoadingStatus,
  TextVisualizerProperties,
} from 'shared/types'
import Loader from './Loader'

const TextPropertiesVisualizerContent = styled('div')<
  Theme,
  TextVisualizerProperties
>(
  ({
    color,
    font,
    fontSize,
    bold,
    backgroundColor,
    uppercase,
    underline,
    italic,
    alignment: textAlign,
  }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    textAlign,
    color,
    fontFamily: font,
    fontSize,
    backgroundColor,
    fontWeight: bold === BooleanStringType.True ? 700 : 'normal',
    textTransform: uppercase === BooleanStringType.True ? 'uppercase' : 'none',
    textDecoration: underline === BooleanStringType.True ? 'underline' : 'none',
    fontStyle: italic === BooleanStringType.True ? 'italic' : 'normal',
  })
)

const DEFAULT_LOADER_SIZE = 15

const TextPropertiesVisualizer = (
  props: PropsWithChildren<TextVisualizerProperties>
) => {
  const { loading } = useDynamicFontLoading(props.font)
  return loading === LoadingStatus.Pending || loading === LoadingStatus.Idle ? (
    <Loader isFullHeight size={props.fontSize || DEFAULT_LOADER_SIZE} />
  ) : (
    <TextPropertiesVisualizerContent {...props}>
      {props.children}
    </TextPropertiesVisualizerContent>
  )
}

export default TextPropertiesVisualizer
