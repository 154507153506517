import { createReducer } from '@reduxjs/toolkit'
import {
  GetDevicesResponse,
  Resource,
  SideEffects,
  TagsListStructure,
  TagType,
  EditTemplateDetailsAPI,
} from 'shared/types'
import {
  defaultSideEffects,
  getDefaultResourceState,
  setResourceFulfilled,
  setResourceIdle,
  setResourcePending,
  setResourceRejected,
} from 'shared/utils'
import {
  addTag,
  clearTags,
  deleteDisplayTag,
  deleteTemplateTag,
  loadDisplayTags,
  loadTemplateTags,
  resetAddTag,
  resetDeleteDisplayTag,
  resetDeleteTemplateTag,
} from './actions'

type TemplateDetailsTags = Omit<EditTemplateDetailsAPI, 'tags'> & {
  tags?: string[]
}

type DeviceDetailsTags = Omit<GetDevicesResponse, 'tags'> & {
  tags?: string[]
}

type TagList = {
  [TagType.Template]: Resource<TagsListStructure<TemplateDetailsTags>>
  [TagType.Display]: Resource<TagsListStructure<DeviceDetailsTags>>
}

interface State {
  tags: TagList
  addTag: SideEffects
  deleteDisplayTag: SideEffects
  deleteTemplateTag: SideEffects
}

const initialState: State = {
  tags: {
    [TagType.Template]: getDefaultResourceState([]),
    [TagType.Display]: getDefaultResourceState([]),
  },
  addTag: defaultSideEffects,
  deleteDisplayTag: defaultSideEffects,
  deleteTemplateTag: defaultSideEffects,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(resetAddTag, state => {
      state.addTag = initialState.addTag
    })
    .addCase(loadTemplateTags.pending, state => {
      setResourcePending(state.tags[TagType.Template])
    })
    .addCase(loadTemplateTags.fulfilled, (state, action) => {
      setResourceFulfilled(state.tags[TagType.Template])
      state.tags[TagType.Template].data = action.payload
    })
    .addCase(loadTemplateTags.rejected, (state, action) => {
      setResourceRejected(state.tags[TagType.Template], action)
    })
    .addCase(clearTags, (state, { payload }) => {
      setResourceIdle(state.tags[payload])
      state.tags[payload].data = initialState.tags[payload].data
    })
    .addCase(loadDisplayTags.pending, state => {
      setResourcePending(state.tags[TagType.Display])
    })
    .addCase(loadDisplayTags.fulfilled, (state, action) => {
      setResourceFulfilled(state.tags[TagType.Display])
      state.tags[TagType.Display].data = action.payload
    })
    .addCase(loadDisplayTags.rejected, (state, action) => {
      setResourceRejected(state.tags[TagType.Display], action)
    })
    .addCase(addTag.fulfilled, state => {
      setResourceFulfilled(state.addTag)
    })
    .addCase(addTag.rejected, (state, action) => {
      setResourceRejected(state.addTag, action)
    })
    .addCase(addTag.pending, state => {
      setResourcePending(state.addTag)
    })
    .addCase(deleteDisplayTag.fulfilled, state => {
      setResourceFulfilled(state.deleteDisplayTag)
    })
    .addCase(deleteDisplayTag.rejected, (state, action) => {
      setResourceRejected(state.deleteDisplayTag, action)
    })
    .addCase(deleteDisplayTag.pending, state => {
      setResourcePending(state.deleteDisplayTag)
    })
    .addCase(deleteTemplateTag.fulfilled, state => {
      setResourceFulfilled(state.deleteTemplateTag)
    })
    .addCase(deleteTemplateTag.rejected, (state, action) => {
      setResourceRejected(state.deleteTemplateTag, action)
    })
    .addCase(deleteTemplateTag.pending, state => {
      setResourcePending(state.deleteTemplateTag)
    })
    .addCase(resetDeleteDisplayTag, state => {
      state.deleteDisplayTag = initialState.deleteDisplayTag
    })
    .addCase(resetDeleteTemplateTag, state => {
      state.deleteTemplateTag = initialState.deleteTemplateTag
    })
)
