import { Slide } from '@material-ui/core'
import { DEFAULT_NAMESPACE } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store/snackbars'

export const useSnackbars = (loadedNamespaces: string[] = []) => {
  const queue = useSelector(selectors.getQueue)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { t } = useTranslation([DEFAULT_NAMESPACE, ...loadedNamespaces])

  useEffect(() => {
    queue.forEach(({ text, type, namespace, error, code }) => {
      enqueueSnackbar(
        t(text, { ns: namespace ?? DEFAULT_NAMESPACE, error, code }),
        {
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: type,
          TransitionComponent: Slide as any,
        }
      )
    })
    if (queue.length > 0) dispatch(actions.dropAll())
  }, [dispatch, enqueueSnackbar, queue, t])
}
