import { styled, TextField } from '@material-ui/core'

export const FilterTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    '&::placeholder': {
      ...theme.typography.button,
      lineHeight: '1.125rem',
      opacity: 0.55,
    },
  },
}))
