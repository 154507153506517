import { styled } from '@material-ui/core'

interface DisabledElementProps {
  disabled: boolean
  fitContent?: boolean
}

const DisabledElement = styled('div')(
  ({ disabled, fitContent = false }: DisabledElementProps) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    width: fitContent ? 'fit-content' : 'auto',
  })
)

export default DisabledElement
