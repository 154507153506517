import layoutDesigner from './layoutDesigner'
import mediaLibrary from './mediaLibrary'
import displayManagement from './displayManagement'
import tags from './tags'
import playlist from './playlist'
import categories from './categories'

export const routes = [
  ...layoutDesigner.routes,
  ...mediaLibrary.routes,
  ...displayManagement.routes,
  ...tags.routes,
  ...playlist.routes,
  ...categories.routes,
].filter(({ path }) => path)

export const reducers = {
  [layoutDesigner.name]: layoutDesigner.reducer,
  [displayManagement.name]: displayManagement.reducer,
  [mediaLibrary.name]: mediaLibrary.reducer,
  [tags.name]: tags.reducer,
  [playlist.name]: playlist.reducer,
  [categories.name]: categories.reducer,
}
