import { setupWorker } from 'msw'
import {
  apiHandlers,
  assetsHandlers,
  mediaLibraryHandlers,
  usersHandlers,
  devicesHandlers,
  templatesHandlers,
  layoutDesignerUtilsHandlers,
  AudioLibraryHandlers,
} from 'shared/services'

const handlers = [
  ...apiHandlers,
  ...assetsHandlers,
  ...usersHandlers,
  ...mediaLibraryHandlers,
  ...devicesHandlers,
  ...templatesHandlers,
  ...layoutDesignerUtilsHandlers,
  ...AudioLibraryHandlers,
]

export const worker = setupWorker(...handlers)
