import { useSelector } from 'react-redux'
import { DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION } from 'shared/config'
import { selectors } from 'shared/store'
import { LayoutSlot, TextBehaviour } from 'shared/types'

const DEFAULT_MIN_DURATION = 7

interface UseScrollingTextDurationProps {
  textBehavior: TextBehaviour
  textContainerWidth?: number
  slotName?: LayoutSlot
  maxTextContainerWidth?: number
}
export const useScrollingTextDuration = ({
  textBehavior,
  textContainerWidth,
  slotName,
  maxTextContainerWidth,
}: UseScrollingTextDurationProps) => {
  const slotSizes = useSelector(selectors.utils.getSlotSizes)

  if (textContainerWidth && maxTextContainerWidth) {
    const duration =
      (textContainerWidth / maxTextContainerWidth) *
      DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION
    return duration < DEFAULT_MIN_DURATION ? DEFAULT_MIN_DURATION : duration
  }

  if (textBehavior !== TextBehaviour.Scrolling || !slotName)
    return DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION

  const slotSize = slotSizes[slotName]
  const sizeRelation = textContainerWidth && textContainerWidth / slotSize.width

  if (!textContainerWidth || !sizeRelation)
    return DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION

  const duration =
    (textContainerWidth / slotSize.width) *
      DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION +
    sizeRelation

  return duration < DEFAULT_MIN_DURATION ? DEFAULT_MIN_DURATION : duration
}
