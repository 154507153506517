import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'categories'

export const SNACKBARS: SnackbarDescriptorTable = {
  addCategory: {
    errorMessage: true,
    successMessage: {
      type: 'success',
      text: 'snackbars.categoryAddedSuccessfully',
    },
  },
}
