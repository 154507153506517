import { Box, styled, Theme } from '@material-ui/core'
import { CSSProperties } from 'react'
import { DRAWER_MENU_SCALE, VIDEO_THUMBNAIL_SIZE } from 'shared/config'

export const thumbnailShadow: CSSProperties = {
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.05), 0px 1px 20px rgba(0, 0, 0, 0.05)',
}

export const responsiveThumbnailSize = (theme: Theme) => ({
  [theme.breakpoints.down(theme.breakpoints.values.templateListMd)]: {
    width: 85,
  },
})

export const responsiveVideoThumbnailHeight = (theme: Theme) => ({
  [theme.breakpoints.down(theme.breakpoints.values.mdDrawer)]: {
    height: VIDEO_THUMBNAIL_SIZE / DRAWER_MENU_SCALE,
  },
})

export const THUMBNAIL_BORDER_RADIUS = 3

export const DurationContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 10,
  transform: 'translateX(-50%)',
}))
