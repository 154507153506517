import {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NUM_FRAMES } from './VideoTrimPreview.style'

type JumpFun = null | (() => void)

const SLIDER_SUBDIVISIONS = 1000
const REFRESH_PLAY_SLIDER_PERIOD = 50

const calcFrames = async (
  ref: HTMLVideoElement,
  context: CanvasRenderingContext2D,
  setObserverWhenSeeked: (f: JumpFun) => void,
  setExtractedFrames: Dispatch<SetStateAction<string[]>>
) => {
  context.canvas.width = ref.videoWidth
  context.canvas.height = ref.videoHeight
  let urls: string[] = []
  for (let i = 0; i < NUM_FRAMES; i++) {
    ref.currentTime = (ref.duration * i) / NUM_FRAMES
    // eslint-disable-next-line no-loop-func
    await new Promise<void>(r => setObserverWhenSeeked(r))
    context?.drawImage(ref, 0, 0, ref.videoWidth, ref.videoHeight)
    urls.push(context.canvas.toDataURL())
  }
  setExtractedFrames(urls)
  setObserverWhenSeeked(null)
  ref.currentTime = 0
}

export const useFrameExtractor = (
  videoRef: RefObject<HTMLVideoElement>,
  areDefaultsLoaded: boolean,
  setTimes: (times: [number, number]) => void,
  assetUrl: string
) => {
  const [extractedFrames, setExtractedFrames] = useState<string[]>([])
  const [duration, setDuration] = useState(0)
  useEffect(() => {
    if (!videoRef.current) return

    const ref = videoRef.current
    const canvas = globalThis.document.createElement('canvas')
    const context = canvas.getContext('2d')

    let nextFrameJumpDone: null | (() => void) = null
    const onSeek = () => {
      nextFrameJumpDone?.()
    }
    const durationChangeListener = async () => {
      if (!areDefaultsLoaded) setTimes([0, ref.duration]) // if sliderTimes[0] !== 0, it means, default values were loaded
      setDuration(ref.duration)
      context &&
        (await calcFrames(
          ref,
          context,
          f => (nextFrameJumpDone = f),
          setExtractedFrames
        ))
      ref.removeEventListener('durationchange', durationChangeListener)
      ref.removeEventListener('seeked', onSeek)
    }

    ref.addEventListener('seeked', onSeek)
    if (!ref.duration)
      ref.addEventListener('durationchange', durationChangeListener)
    else durationChangeListener()

    return () => {
      ref.removeEventListener('durationchange', durationChangeListener)
      ref.removeEventListener('seeked', onSeek)
      canvas.remove()
      setExtractedFrames([])
    }
  }, [assetUrl, setTimes, videoRef, areDefaultsLoaded])

  return { duration, extractedFrames }
}

export const useOnChangeFunctions = (
  setTimes: (times: [number, number]) => void,
  times: [number, number],
  duration: number,
  videoRef: RefObject<HTMLVideoElement>
) => {
  const cutEpsilon = duration / SLIDER_SUBDIVISIONS
  const [temporaryTimes, setTemporaryTimes] = useState(times)

  return {
    onChange: useCallback((event: ChangeEvent<{}>, val: number | number[]) => {
      if (typeof val === 'number') return
      setTemporaryTimes([val[0], val[1]])
    }, []),
    onChangeCommitted: useCallback(
      (event: ChangeEvent<{}>, val: number | number[]) => {
        if (typeof val === 'number') return
        const newVideoFrameIndex =
          val[0] < times[0] + cutEpsilon && val[0] > times[0] - cutEpsilon
            ? 1
            : 0
        const newVideoFrame = val[newVideoFrameIndex]
        setTimes([val[0], val[1]])
        if (videoRef.current) videoRef.current.currentTime = newVideoFrame
      },
      [cutEpsilon, setTimes, times, videoRef]
    ),
    step: cutEpsilon,
    temporaryTimes,
  }
}

export const usePlaySection = (
  videoRef: RefObject<HTMLVideoElement>,
  startTime: number,
  endTime: number
) => {
  const [isSectionRunning, setSectionRunning] = useState(false)
  const [currentlyAt, setCurrentlyAt] = useState<null | number>(null)
  const counter = useRef(0)

  useEffect(() => {
    const video = videoRef.current
    const onUpdate = () => {
      if (!isSectionRunning || !video) return
      if (video.currentTime >= endTime) {
        video.pause()
        video.controls = true
        setSectionRunning(false)
      }
      if (counter.current > 5) {
        setCurrentlyAt(video.currentTime)
        counter.current = 0
      }
      counter.current++
    }

    const interval = setInterval(onUpdate, REFRESH_PLAY_SLIDER_PERIOD)

    return () => {
      clearInterval(interval)
      setCurrentlyAt(null)
    }
  }, [endTime, isSectionRunning, videoRef])

  const onStart = useCallback(() => {
    if (!videoRef.current) return
    setSectionRunning(true)
    videoRef.current.controls = false
    videoRef.current.currentTime = startTime
    videoRef.current.play()
  }, [startTime, videoRef])

  const onStop = useCallback(() => {
    if (!videoRef.current) return
    setSectionRunning(false)
    videoRef.current.controls = true
    videoRef.current.pause()
    setCurrentlyAt(null)
  }, [videoRef])

  return { isSectionRunning, onStart, onStop, currentlyAt }
}
