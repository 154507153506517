import { SnackbarDescriptorTable } from 'shared/types'

export const MODULE_NAME = 'playlist'

export const SNACKBARS: SnackbarDescriptorTable = {
  deletePlaylist: {
    successMessage: {
      text: 'myPlaylists.alerts.playlistDeletedSuccessfully',
      namespace: MODULE_NAME,
      type: 'success',
    },
    errorMessage: true,
  },
  updateOrCreateSchedule: {
    successMessage: {
      text: 'scheduler.playlistScheduleSuccess',
      namespace: MODULE_NAME,
      type: 'success',
    },
    errorMessage: true,
  },
  createPlaylist: {
    successMessage: {
      text: 'creator.createdSuccess',
      namespace: MODULE_NAME,
      type: 'success',
    },
    errorMessage: {
      text: 'creator.error',
      namespace: MODULE_NAME,
      type: 'error',
    },
  },
  updatePlaylist: {
    successMessage: {
      text: 'creator.updatedSuccess',
      namespace: MODULE_NAME,
      type: 'success',
    },
    errorMessage: {
      text: 'creator.error',
      namespace: MODULE_NAME,
      type: 'error',
    },
  },
}
