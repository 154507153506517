import {
  Button,
  DialogContent,
  Grid,
  styled,
  TextField,
} from '@material-ui/core'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ExpressiveDialog,
  ExpressiveDialogActions,
  ExpressiveDialogTitle,
  LoadingSpinner,
  MaterialSelect,
  UploadFileButton,
} from 'shared/components'
import { SelectOption } from 'shared/types'
import { mapAcceptedFileTypes } from 'shared/utils'
import {
  useOnUploadHandler,
  useUploadFileModal,
  useUploadFileSideEffects,
} from './UploadFileModal.utils'

const DIALOG_WIDTH = 450

const ExpressiveDialogContent = styled(DialogContent)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}))

interface UploadFileProps {
  options: SelectOption[]
}
const UploadFile = ({ options }: UploadFileProps) => {
  const { t } = useTranslation()
  const {
    loading,
    isLoading,
    error,
    file,
    filename,
    selectedCategory,
    isOpen,
    setFile,
    setFilename,
    setSelectedCategory,
    open,
    close,
  } = useUploadFileModal()
  const onUploadHandle = useOnUploadHandler(filename, file, selectedCategory)

  useUploadFileSideEffects({
    loading,
    isOpen,
    error,
    close,
    setFilename,
    setSelectedCategory,
  })

  return (
    <>
      <Button fullWidth onClick={open}>
        {t('mediaLibraryModal.uploadNewAsset')}
      </Button>
      <ExpressiveDialog open={isOpen} onClose={close} width={DIALOG_WIDTH}>
        <ExpressiveDialogTitle title={t('mediaLibraryModal.uploadNewAsset')} />
        <ExpressiveDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UploadFileButton
                name="ml-file"
                accept={mapAcceptedFileTypes().split(',')}
                label={t('mediaLibraryModal.chooseFile')}
                setUploaded={files => {
                  setFile(files[0])
                  setFilename(files[0].name)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="filename"
                placeholder={t('mediaLibraryModal.fileName')}
                value={filename}
                onChange={(e: ChangeEvent<{ value: string }>) =>
                  setFilename(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialSelect
                options={options}
                value={selectedCategory}
                placeholder={t('mediaLibraryModal.selectCategory')}
                isDefaultCase
                onChange={e => setSelectedCategory(e.target.value as number)}
              />
            </Grid>
          </Grid>
        </ExpressiveDialogContent>

        <ExpressiveDialogActions>
          <Button
            color="secondary"
            disabled={!file || !selectedCategory || isLoading}
            onClick={onUploadHandle}
          >
            <LoadingSpinner isVisible={isLoading}>
              {t('mediaLibraryModal.upload')}
            </LoadingSpinner>
          </Button>
        </ExpressiveDialogActions>
      </ExpressiveDialog>
    </>
  )
}

export default UploadFile
