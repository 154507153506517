import { Fade } from '@material-ui/core'
import { ClearInputIcon } from 'shared/icons'
import { Adornment, ClearButton } from './ClearableField.style'

interface EndAdornmentProps {
  isClearButtonVisible: boolean
  clear: () => void
  disabled: boolean
}
const EndAdornment = ({
  clear,
  disabled,
  isClearButtonVisible,
}: EndAdornmentProps) => {
  return (
    <Fade in={isClearButtonVisible} mountOnEnter unmountOnExit>
      <Adornment position="end">
        <ClearButton
          disableRipple
          disableFocusRipple
          onClick={clear}
          disabled={disabled}
        >
          <ClearInputIcon />
        </ClearButton>
      </Adornment>
    </Fade>
  )
}

export default EndAdornment
