import { Button, DialogContent } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssetUrl } from 'shared/hooks'
import { LoadingStatus } from 'shared/types'
import {
  ExpressiveDialog,
  ExpressiveDialogActions,
  ExpressiveDialogTitle,
} from '../ExpressiveDialogComponents'
import LoadingSpinner from '../LoadingSpinner'
import { useSaveVideoTrim } from './VideoTrimDialog.utils'
import VideoTrimLoader from './VideoTrimLoader'
import VideoTrimPreview from './VideoTrimPreview'

interface VideoTrimDialogProps {
  trimmedAssetURL?: string | null
  trimmedAssetUuid: string | null
  defaultTrimTimes?: [number, number] | null
  onClose: () => void
  onSave?: (times: [number, number], keepOriginal: boolean) => void
}

/**
 * @param onClose You should close the dialog with this. If will be called no matter whether the operation was cancelled or successful
 * @param trimmedAssetURL If you already know the URL, it will speed up the loading, if not, use trimmedAssetUuid
 * @param trimmedAssetUuid VideoTrimDialog will load the video from this UUID
 * @param onSave - if not passed, VideoTrimDialog will transform the video using media transform endpoints. If you want to perform your custom operations, you can do it in this callback
 */
const VideoTrimDialog = ({
  trimmedAssetURL,
  trimmedAssetUuid,
  onClose,
  defaultTrimTimes,
  onSave: onSaveOutsideComponent,
}: VideoTrimDialogProps) => {
  const { t } = useTranslation()
  const { assetUrl, loading } = useAssetUrl(trimmedAssetUuid, {
    autoReload: true,
    isAllowedToLoad: !trimmedAssetURL,
  })

  const uniformAssetUrl = trimmedAssetURL ?? assetUrl
  const uniformLoading = trimmedAssetURL ? LoadingStatus.Succeeded : loading
  const [sliderTimes, setSliderTimes] = useState<[number, number]>(
    defaultTrimTimes ?? [0, 0]
  )
  useEffect(() => {
    defaultTrimTimes && setSliderTimes(defaultTrimTimes)
  }, [defaultTrimTimes])

  const { loading: saveLoading, onSave: defaultOnSave } = useSaveVideoTrim({
    start: sliderTimes[0],
    end: sliderTimes[1],
    mediaUuid: trimmedAssetUuid,
    onClose,
  })

  const onSave = useCallback(
    (keepOriginal: boolean) => {
      if (onSaveOutsideComponent) {
        onSaveOutsideComponent(sliderTimes, keepOriginal)
        return onClose()
      }
      defaultOnSave(keepOriginal)
    },
    [defaultOnSave, onClose, onSaveOutsideComponent, sliderTimes]
  )

  return (
    <ExpressiveDialog
      maxHeight="100vh"
      open={!!trimmedAssetUuid}
      onClose={onClose}
    >
      <ExpressiveDialogTitle
        title={t('videoTrimModal.title')}
        subtitle={t('videoTrimModal.subtitle')}
      />
      <DialogContent>
        {uniformAssetUrl && uniformLoading === LoadingStatus.Succeeded ? (
          <VideoTrimPreview
            areDefaultsLoaded={!!defaultTrimTimes}
            assetUrl={uniformAssetUrl}
            sliderTimes={sliderTimes}
            setSliderTimes={setSliderTimes}
            disabled={saveLoading}
          />
        ) : (
          <VideoTrimLoader />
        )}
      </DialogContent>
      <ExpressiveDialogActions>
        {!onSaveOutsideComponent && (
          <Button
            color="secondary"
            size="small"
            disabled={saveLoading}
            onClick={() => onSave(true)}
          >
            <LoadingSpinner isVisible={saveLoading} color="primary">
              {t('buttons.saveAsNew')}
            </LoadingSpinner>
          </Button>
        )}
        <Button
          color="secondary"
          size="small"
          disabled={saveLoading}
          onClick={() => onSave(false)}
        >
          <LoadingSpinner isVisible={saveLoading} color="primary">
            {t('buttons.trim')}
          </LoadingSpinner>
        </Button>
        <Button color="default" onClick={onClose} size="small">
          {t('buttons.cancel')}
        </Button>
      </ExpressiveDialogActions>
    </ExpressiveDialog>
  )
}

export default VideoTrimDialog
