import { SvgIcon, SvgIconProps } from '@material-ui/core'

const DraggingIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 2.08333H9.375V0H6.25V2.08333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 20.8333H9.375V18.75H6.25V20.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5833 0H11.4583V2.08333H14.5833V0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08333 6.25H0V9.375H2.08333V6.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 9.375H20.8333V6.25H18.75V9.375Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.311 12.1907C20.0067 12.3664 19.9023 12.756 20.078 13.0603L20.3963 13.6116L19.845 13.9299L18.8901 12.276C18.7144 11.9717 18.3248 11.8673 18.0205 12.043L17.4692 12.3613C17.1649 12.537 17.0605 12.9265 17.2362 13.2308L17.5545 13.7821L17.0032 14.1004L16.3667 12.9978C16.191 12.6935 15.8014 12.5891 15.4971 12.7648L14.9458 13.0831C14.6415 13.2588 14.5371 13.6484 14.7128 13.9527L15.3494 15.0553L14.7981 15.3736L14.4798 14.8223C14.3041 14.518 13.9145 14.4136 13.6102 14.5893L13.0589 14.9076C12.7546 15.0833 12.6502 15.4728 12.8259 15.7771L13.4625 16.8797L12.3599 17.5163C12.1069 17.6624 11.9857 17.9617 12.0664 18.2429L12.7883 20.7664C12.8371 20.9388 12.9572 21.0819 13.118 21.1618L17.2329 23.1964C17.424 23.2905 17.6491 23.2832 17.8332 23.1769L22.7948 20.3123C22.9685 20.2121 23.0846 20.0363 23.1086 19.8372L23.4893 16.6772C23.5059 16.5404 23.4773 16.4018 23.4086 16.2827L21.1806 12.4237C21.0049 12.1194 20.6153 12.015 20.311 12.1907Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08333 11.4585H0V14.5835H2.08333V11.4585Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.04167V4.16667H2.08333V2.08333H4.16667V0H1.04167C0.466667 0 0 0.466667 0 1.04167Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08333 16.6665H0V19.7915C0 20.3665 0.466667 20.8332 1.04167 20.8332H4.16667V18.7498H2.08333V16.6665Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7501 4.16667H20.8334V1.04167C20.8334 0.466667 20.3667 0 19.7917 0H16.6667V2.08333H18.7501V4.16667Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default DraggingIcon
