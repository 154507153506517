import { MODULE_NAME } from 'modules/mediaLibrary/strings'
import { useTranslation } from 'react-i18next'
import {
  HeaderText,
  SubtitleText,
  RequiredActionBox,
} from './RequiredActionInfo.style'

const RequiredActionInfo = () => {
  const { t } = useTranslation(MODULE_NAME)
  return (
    <RequiredActionBox>
      <HeaderText variant="h1">{t('overlay.header')}</HeaderText>
      <SubtitleText variant="h5">{t('overlay.subtitle')}</SubtitleText>
    </RequiredActionBox>
  )
}

export default RequiredActionInfo
