import { Button, styled, Theme } from '@material-ui/core'

interface BaseTabButtonProps {
  active: boolean
}
export const BaseTabButton = styled(Button)<Theme, BaseTabButtonProps>(
  ({ theme, active }) => ({
    width: '100%',
    backgroundColor: active
      ? theme.palette.accent.main
      : theme.palette.background.overlay.primary,
    color: active ? theme.palette.common.white : theme.palette.accent.main,
    minWidth: 50,
    '&:hover': {
      backgroundColor: active
        ? theme.palette.primary.dark
        : theme.palette.declined.dark,
    },
    '& .MuiSvgIcon-root': {
      verticalAlign: 'middle',
      fontSize: '1.2rem',
    },
  })
)
