import { Module } from 'shared/types'
import routes from './routes'
import { MODULE_NAME } from './strings'
import { reducer } from './store'

const moduleConfig: Module<typeof MODULE_NAME, typeof reducer> = {
  name: MODULE_NAME,
  reducer,
  routes,
}

export default moduleConfig
