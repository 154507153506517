import { DisplayModel, DisplayModelFormat } from 'shared/types'
import { downloadFileFromBlob } from 'shared/utils'
import { Api } from '../api'
import {
  GetDisplayFormatsResponse,
  GetDisplayModelsResponse,
  GetArrowsResponse,
  GetDefaultRssFeedsParams,
  GetDefaultRssFeedResponse,
  GetRssFeedFromUrlResponse,
  FetchedFormatData,
} from './LayoutDesignerUtils.types'

const SUBPATH = '/layout_designer'

class LayoutDesignerUtils extends Api {
  getDisplayFormats = async (displayModel: string) => {
    const { data } = await this.api.get<GetDisplayFormatsResponse>(
      `${SUBPATH}/display_formats`,
      { params: { displayModel } }
    )
    return data
  }

  getDisplayModels = async () => {
    const { data } = await this.api.get<GetDisplayModelsResponse>(
      `${SUBPATH}/display_models`
    )
    const formats = (await Promise.all(
      data.map(
        model =>
          new Promise((resolve, reject) =>
            this.getDisplayFormats(model.displayModel)
              .then(data => resolve({ data, name: model.displayModel }))
              .catch(reject)
          )
      )
    )) as FetchedFormatData[]

    const displayModels: DisplayModel[] = data.map(model => ({
      ...model,
      formats: model.formats.reduce<DisplayModelFormat[]>(
        (previous, format) => {
          const formatId = formats
            .find(f => f.name === model.displayModel)
            ?.data.find(d => d.formatType === format.name)?.formatId
          return formatId
            ? [
                ...previous,
                {
                  ...format,
                  id: formatId,
                },
              ]
            : previous
        },
        []
      ),
    }))

    return displayModels
  }

  getArrows = async () => {
    const { data } = await this.api.get<GetArrowsResponse>(
      `${SUBPATH}/display_arrows`
    )
    return data
  }

  generateProject = async (templateId: number, deviceId?: number) => {
    const { data } = await this.api.post<{}>(
      `${SUBPATH}/generate_project`,
      {},
      {
        params: {
          templateId,
          deviceId,
        },
      }
    )
    return data
  }

  getZipProject = async (templateId: number, deviceId: number) => {
    await this.generateProject(templateId, deviceId)
    const { data } = await this.api.post<BlobPart>(
      `${SUBPATH}/get_zip_project`,
      {},
      {
        params: {
          templateId,
        },
        responseType: 'arraybuffer',
      }
    )
    downloadFileFromBlob(
      new Blob([data], { type: 'application/x-zip' }),
      `project-${templateId}.zip`
    )
  }

  getDefaultRssFeeds = async (params: GetDefaultRssFeedsParams) => {
    const { data } = await this.api.get<GetDefaultRssFeedResponse[]>(
      `${SUBPATH}/rss_feeds`,
      { params }
    )

    return data
  }

  getRssFeedFromUrl = async (urlAddress: string) => {
    const { data } = await this.api.get<GetRssFeedFromUrlResponse>(
      `${SUBPATH}/rss/{url}`,
      {
        params: { urlAddress: decodeURI(urlAddress) },
        paramsSerializer: params => `url_address=${params.url_address}`,
      }
    )

    return data
  }
}

export default LayoutDesignerUtils
