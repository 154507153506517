import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { i18n, consoleFilter } from 'config'
import { worker } from 'mocks/worker'
import App from './app'
import reportWebVitals from './reportWebVitals'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENABLE_MOCKING === 'true'
) {
  worker.start({ onUnhandledRequest: 'bypass' })
}

i18n.init()
consoleFilter.init()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
