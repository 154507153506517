import { Box } from '@material-ui/core'
import { PlayArrowRounded, StopRounded } from '@material-ui/icons'
import { memo, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import VideoTrimLoader from '../VideoTrimLoader'
import VideoSlider from './VideoSlider'
import {
  IconButton,
  NUM_FRAMES,
  useVideoStyles,
} from './VideoTrimPreview.style'
import { useFrameExtractor, usePlaySection } from './VideoTrimPreview.utils'

interface VideoTrimPreviewProps {
  assetUrl: string
  sliderTimes: [number, number]
  areDefaultsLoaded: boolean
  setSliderTimes: (times: [number, number]) => void
  disabled: boolean
}

const VideoTrimPreview = ({
  assetUrl,
  sliderTimes,
  areDefaultsLoaded,
  setSliderTimes,
  disabled,
}: VideoTrimPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { t } = useTranslation()
  const { duration, extractedFrames } = useFrameExtractor(
    videoRef,
    areDefaultsLoaded,
    setSliderTimes,
    assetUrl
  )
  const loading = extractedFrames.length !== NUM_FRAMES
  const { root } = useVideoStyles({ loading })

  const { isSectionRunning, onStart, onStop, currentlyAt } = usePlaySection(
    videoRef,
    sliderTimes[0],
    sliderTimes[1]
  )

  const marks = useMemo(
    () => (currentlyAt ? [{ label: '', value: currentlyAt }] : false),
    [currentlyAt]
  )
  return (
    <>
      <video className={root} ref={videoRef} controls>
        <source src={assetUrl} />
      </video>
      {loading ? (
        <VideoTrimLoader />
      ) : (
        <Box my={8}>
          <VideoSlider
            marks={marks}
            sliderTimes={sliderTimes}
            setSliderTimes={setSliderTimes}
            disabled={disabled || isSectionRunning}
            extractedFrames={extractedFrames}
            videoRef={videoRef}
            duration={duration}
          />
          <Box mt={6} textAlign="center">
            {isSectionRunning ? (
              <IconButton onClick={onStop} color="secondary">
                <StopRounded />
                {t('videoTrimModal.stop')}
              </IconButton>
            ) : (
              <IconButton onClick={onStart} color="secondary">
                <PlayArrowRounded />
                {t('videoTrimModal.play')}
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default memo(VideoTrimPreview)
