import { Typography } from '@material-ui/core'
import { Ellipsis } from 'shared/components'

interface DisplayLongValueProps {
  value: string
  testId: string
}

const DisplayLongValue = ({ value, testId }: DisplayLongValueProps) => {
  return (
    <Typography variant="button">
      <Ellipsis variant="horizontal" tooltipText={value} data-testid={testId}>
        {value}
      </Ellipsis>
    </Typography>
  )
}

export default DisplayLongValue
