import { memo } from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../LoadingSpinner'
import LabeledButton from '../LabeledButton'

interface ConfirmModalProps {
  open: boolean
  question?: string
  title: string
  onSubmit: () => void
  onClose: () => void
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  loading?: boolean
}

const ConfirmModal = ({
  open,
  question,
  title,
  onSubmit,
  onClose,
  primaryButtonLabel,
  secondaryButtonLabel,
  loading,
}: ConfirmModalProps) => {
  const { t } = useTranslation()
  const handleSubmit = () => {
    onSubmit()
    typeof loading !== 'boolean' && onClose()
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="h2" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {question}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={4}>
            <LabeledButton
              fullWidth
              disabledLabel={t('errors.disabledButton')}
              disabled={loading}
              onClick={handleSubmit}
              color="secondary"
            >
              <LoadingSpinner color="primary" isVisible={loading}>
                {primaryButtonLabel || t('buttons.accept')}
              </LoadingSpinner>
            </LabeledButton>
          </Grid>
          <Grid item xs={4}>
            <LabeledButton
              fullWidth
              disabledLabel={t('errors.disabledButton')}
              disabled={loading}
              onClick={onClose}
              color="default"
            >
              {secondaryButtonLabel || t('buttons.decline')}
            </LabeledButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default memo(ConfirmModal)
