import { RootState } from 'app'

export const getRssFeedFromUrl = (state: RootState) =>
  state.common.utils.getRssFeedFromUrl

export const getSlotSizes = (state: RootState) =>
  state.layoutDesigner.editor.slotSizes

export const getUpdateOTA = (state: RootState) => state.common.utils.updateOTA

export const getTransformVideo = (state: RootState) =>
  state.common.utils.transformVideo

export const getRedirectionOptions = (state: RootState) =>
  state.common.utils.redirectionGuard
