import { Box, styled, Theme } from '@material-ui/core'
import { CSSProperties, PropsWithChildren } from 'react'
import ItemOverlay from '../ItemOverlay'

export interface ImageThumbnailProps {
  thumbnail?: string
  isBlocked?: boolean
  backgroundSize?: CSSProperties['backgroundSize']
  backgroundColor?: CSSProperties['backgroundColor']
}

interface ThumbnailImgProps {
  backgroundUrl?: string
  backgroundSize?: CSSProperties['backgroundSize']
  backgroundColor?: CSSProperties['backgroundColor']
}

export const ThumbnailImg = styled(Box)<Theme, ThumbnailImgProps>(
  ({ backgroundUrl, backgroundSize = 'contain', backgroundColor }) => ({
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'unset',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor,
    backgroundSize,
  })
)

/** Thumbnail component is dedicated to display an IMAGE, which URL is known.
 * Image is assumed to be compressed. If you have two UUIDs
 * (video and thumbnail or high-res image and thumbnail), you should use MultimediaThumbnail.
 * Width and height are determined by the size of thumbnail's container.
 * @param isBlocked will show a locked sign over the image (indication that this item is unavailable)
 * */
const ImageThumbnail = ({
  thumbnail,
  isBlocked = false,
  backgroundSize,
  backgroundColor,
  children,
}: PropsWithChildren<ImageThumbnailProps>) => (
  <>
    <ThumbnailImg
      backgroundUrl={thumbnail}
      backgroundColor={backgroundColor}
      backgroundSize={backgroundSize}
    >
      {children}
    </ThumbnailImg>
    <ItemOverlay isBlocked={isBlocked} />
  </>
)

export default ImageThumbnail
