import {
  AssignRssResourceToDeviceParams,
  AssignRssResourceToDeviceResponse,
} from '.'
import { Api } from '../api'
import {
  GetRssSourcesFromDeviceParams,
  GetRssSourcesFromDeviceResponse,
} from './CMS.types'

const SUBPATH = 'cms'

class CMS extends Api {
  assignRssResourceToDevice = async (
    params: AssignRssResourceToDeviceParams
  ) => {
    const { data } = await this.api.post<AssignRssResourceToDeviceResponse>(
      `${SUBPATH}/rss`,
      null,
      { params }
    )

    return data
  }
  getRssSourcesFromDevice = async (params: GetRssSourcesFromDeviceParams) => {
    const { data } = await this.api.get<GetRssSourcesFromDeviceResponse>(
      `${SUBPATH}/rss`,
      { params }
    )

    return data
  }
}

export default CMS
