import {
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core'
import { isSafari } from 'shared/utils'

export const ClearButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
})

export const Adornment = styled(InputAdornment)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}))

interface CenterPlaceholderStyleProps {
  disabled: boolean
  height?: number
}
export const centerPlaceholderStyle = makeStyles<
  Theme,
  CenterPlaceholderStyleProps
>(theme => {
  const isSafariBrowser = isSafari()

  return createStyles({
    root: {
      '& ::placeholder': {
        position: isSafariBrowser ? 'static' : 'absolute',
        top: isSafariBrowser ? 0 : '50%',
        transform: isSafariBrowser ? 'translateY(-1px)' : 'translateY(-50%)',
      },

      '& .MuiFilledInput-root': {
        height: ({ height }) => height || 'auto',
      },
      '& .MuiInputBase-root, .MuiInputBase-input': {
        cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'auto'),
      },
    },
  })
})
