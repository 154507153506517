import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Clock = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.293 12.707L8.293 9.707C8.105 9.52 8 9.266 8 9V4H10V8.586L12.707 11.293L11.293 12.707ZM9 0C4.038 0 0 4.038 0 9C0 13.962 4.038 18 9 18C13.962 18 18 13.962 18 9C18 4.038 13.962 0 9 0Z"
      fill="#022C3C"
    />
  </SvgIcon>
)

export default Clock
