import { RouteProps } from 'react-router-dom'
import { RESIZER_SIZE } from 'shared/config'
import { LayoutSlot, LayoutVariant, Orientation } from 'shared/types'

export const addBasePath = <T extends RouteProps>(
  basePath: string,
  routes: T[]
) =>
  routes.map(({ path, ...route }) => ({
    ...route,
    path: `${basePath}${path}`.replaceAll('//', '/'),
  }))

export const ERRORS = {
  missingApiUrl: 'Missing API URL env variable.',
  couldNotUploadFiles: 'Could not upload any of selected files',
  transformBase65Timeout:
    'Transform base64 image to png failed due to timeout limit.',
  transformBase65Failed: 'Transform base64 image to png failed.',
  noMediaAssetWithId: (mediaUuid: string | null | undefined) =>
    `No media asset with id: ${mediaUuid}`,
  noMediaAssets: `No media assets listed`,
  widgetNeedsAtListOneImage: 'This widget needs at least one image',
  widgetTypeCannotBeUploaded: 'This widget type cannot be uploaded',
  failedToGenerateImg: 'Failed to generate image',
  wrongAspectRatioFormat: 'Wrong aspect ratio format',
  wrongResolutionFormat: 'Wrong resolution format',
  requestCancelled: 'Request cancelled',
}

export const getSplitPaneSize = (
  selectedLayout: LayoutVariant,
  slot: LayoutSlot | 'global',
  orientation?: Orientation,
  allowResize: boolean = true
) => {
  if (!allowResize) return { x: 0, y: 0 }
  if (orientation === Orientation.Landscape) {
    switch (selectedLayout) {
      case LayoutVariant.DoubleLeft:
      case LayoutVariant.DoubleRight:
        return { x: RESIZER_SIZE, y: 0 }
      case LayoutVariant.DividedVertically:
        return { x: 0, y: RESIZER_SIZE }
      case LayoutVariant.TripleLeft:
      case LayoutVariant.TripleRight:
        if (slot === 'footer') return { x: 0, y: RESIZER_SIZE }
        else return { x: RESIZER_SIZE, y: RESIZER_SIZE }
      case LayoutVariant.Fullscreen:
      default:
        return { x: 0, y: 0 }
    }
  } else if (orientation === Orientation.Portrait) {
    switch (selectedLayout) {
      case LayoutVariant.DoubleLeft:
      case LayoutVariant.DoubleRight:
        return { x: 0, y: RESIZER_SIZE }
      case LayoutVariant.DividedVertically:
        return { x: RESIZER_SIZE, y: 0 }
      case LayoutVariant.TripleLeft:
      case LayoutVariant.TripleRight:
        if (slot === 'sidebar') return { x: RESIZER_SIZE, y: 0 }
        else return { x: RESIZER_SIZE, y: RESIZER_SIZE }
      case LayoutVariant.Fullscreen:
      default:
        return { x: 0, y: 0 }
    }
  } else return { x: 0, y: 0 }
}
