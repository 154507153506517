import { Tooltip } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import BaseTag, { TagProps as BaseTagProps } from '../Tag'

interface TagProps extends BaseTagProps {
  maxWidth: number
}
const Tag = (props: TagProps) => {
  const [isEllipsis, setEllipsis] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const isTextLongerThanWrapper =
      ref.current &&
      (ref.current?.children[0] as HTMLDivElement).offsetWidth >
        ref.current?.children[0].scrollWidth

    if (isTextLongerThanWrapper) setEllipsis(false)
    else setEllipsis(true)
  }, [props.label, props.maxWidth])

  return (
    <Tooltip title={isEllipsis ? (props.label as string) : ''}>
      <BaseTag {...props} ref={ref} />
    </Tooltip>
  )
}

export default Tag
