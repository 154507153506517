import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Error = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-1127.000000, -1996.000000)"
        fill="currentColor"
      >
        <g id="Icons" transform="translate(197.000000, 314.000000)">
          <g id="Fill-1903" transform="translate(930.000000, 1682.000000)">
            <path d="M10,15.25 C9.31,15.25 8.75,14.69 8.75,14 C8.75,13.31 9.31,12.75 10,12.75 C10.69,12.75 11.25,13.31 11.25,14 C11.25,14.69 10.69,15.25 10,15.25 L10,15.25 Z M9,4 L11,4 L11,11 L9,11 L9,4 Z M10,0 C4.486,0 0,4.486 0,10 C0,15.515 4.486,20 10,20 C15.514,20 20,15.515 20,10 C20,4.486 15.514,0 10,0 L10,0 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Error
