import { PaginationParams } from 'shared/types'

export enum AudioType {
  floor = 'floor',
  event = 'event',
}

export interface GetDeviceAudioFilesParams extends PaginationParams {
  filename?: string
  languageLabel?: string
  displayFamily?: string
  audioType?: AudioType
}

export interface AudioFile {
  dateposted: string
  fileExtension: string
  fileUuid: string
  filename: string
  folder: string
}

export interface AudioLanguage {
  audioLanguageId?: number
  languageLabel?: string
}

export interface AddAudioFile {
  file: File
  filename?: string
  languageLabel: string
  displayFamily: string
  audioType: AudioType
}

export type GetDeviceAudioFilesResponse = AudioFile[]
