import { createAction } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { PAGINATION_LIMITS } from 'shared/config'
import {
  AudioLibrary,
  CMS,
  Devices,
  FloorAndEventsContent,
  GetDeviceAudioFilesParams,
  GetDevicesParameters,
  AssignRssResourceToDeviceParams,
  Templates,
  AudioLanguage,
  AddAudioFile,
  Playlist,
} from 'shared/services'
import { GetDevicesResponse, ScheduleComplexId } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import {
  GetEcosystemEvents,
  SetAudioEventsPayload,
  SetAudioFloorsPayload,
  UpdateDisplayPayload,
  UpdateFirmwareParams,
} from './actions.types'

const devices = new Devices()
const audioLibrary = new AudioLibrary()
const floorAndEventsContent = new FloorAndEventsContent()
const cms = new CMS()
const templates = new Templates()
const playlists = new Playlist()

export const getDisplays = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDisplays`,
  payloadCreator: (params?: GetDevicesParameters) =>
    devices.getDisplays(params),
  withCode: true,
})

export const getDisplayDetails = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDisplayDetails`,
  payloadCreator: async (id: number) => {
    const displayInfo = await devices.getDisplays({ displayId: id })
    const lastSentTemplateId = displayInfo[0].lastSentTemplateId
    const lastSentTemplate = lastSentTemplateId
      ? await templates.getTemplate({ templateId: lastSentTemplateId })
      : null

    return {
      ...displayInfo[0],
      lastSentTemplate,
    }
  },
})

export const getAudioLanguages = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getAudioLanguages`,
  payloadCreator: async ({ audioLanguageId, languageLabel }: AudioLanguage) =>
    audioLibrary.getAudioLanguages({ audioLanguageId, languageLabel }),
})

export const addAudioFile = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}`,
  payloadCreator: (params: AddAudioFile) => audioLibrary.addAudioFile(params),
  ...SNACKBARS.addAudioFile,
})

export const resetAddAudioLanguage = createAction(
  `${MODULE_NAME}/resetAddAudioLanguage`
)

export const resetGetDisplays = createAction(`${MODULE_NAME}/resetGetDisplays`)

export const refreshStatuses = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/refreshStatuses`,
  payloadCreator: (ids: number[]) => devices.getConnectionStatus(ids),
})

export const updateDisplay = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updateDisplay`,
  payloadCreator: ({ updateData, id }: UpdateDisplayPayload) =>
    devices.updateDisplay(updateData, id),
})

export const clearDisplaySideEffects = createAction(
  `${MODULE_NAME}/clearDisplaySideEffects`
)

export const changeEcosystemEventsPage = createAction<number>(
  `${MODULE_NAME}/changeEcosystemEventsPage`
)

export const getEcosystemEvents = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getEcosystemEvents`,
  payloadCreator: ({ id, page }: GetEcosystemEvents, thunkApi) => {
    const {
      displayManagement: { ecosystemEvents },
    } = thunkApi.getState() as RootState

    if (page !== undefined) thunkApi.dispatch(changeEcosystemEventsPage(page))

    const eventsPromise = devices.getEcosystemEvents({
      id,
      // TODO: implement pagination when backend is ready
      // ...getPageTypePaginationParams(
      //   page ?? ecosystemEvents.page,
      //   ecosystemEvents.limit
      // ),
    })

    return eventsPromise
  },
})

export const addAudioLanguage = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/addAudioLanguage`,
  payloadCreator: (languageLabel: string) =>
    audioLibrary.addAudioLanguage(languageLabel),
  ...SNACKBARS.addAudioLanguage,
})

export const updateFirmware = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updateFirmware`,
  payloadCreator: ({ deviceId, firmwareUrl }: UpdateFirmwareParams) =>
    devices.updateFirmware(deviceId, firmwareUrl),
  ...SNACKBARS.updateFirmware,
})

export const clearUpdateFirmwareSideEffect = createAction(
  `${MODULE_NAME}/clearUpdateFirmwareSideEffect`
)

export const getDeviceAudioFiles = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDeviceAudioFiles`,
  payloadCreator: (params: Omit<GetDeviceAudioFilesParams, 'skip' | 'limit'>) =>
    audioLibrary.getDeviceAudioFiles({
      ...params,
      skip: 0,
      limit: PAGINATION_LIMITS.audioFiles,
    }),
})

export const setAudioFloors = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/setAudioFloors`,
  payloadCreator: (params: SetAudioFloorsPayload) => {
    floorAndEventsContent.setAudioFloors(params.deviceId, params.stops)
  },
})

export const getDeviceAudioFloors = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDeviceAudioFloors`,
  payloadCreator: (deviceId: number) =>
    floorAndEventsContent.getDeviceAudioFloors(deviceId),
})

export const setAudioEvents = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/setAudioEvents`,
  payloadCreator: ({ deviceId, AudioEvents }: SetAudioEventsPayload) =>
    floorAndEventsContent.setAudioEvents(deviceId, AudioEvents),
})

export const getAudioEvents = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getAudioEvents`,
  payloadCreator: async (deviceId: number) =>
    floorAndEventsContent.getAudioEvents(deviceId),
})

export const getRssSourcesFromDevice = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getRssSourcesFromDevice`,
  payloadCreator: async (deviceId: number) =>
    cms.getRssSourcesFromDevice({ deviceId }),
})

export const assignRssResourceToDevice = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/assignRssResourceToDevice`,
  payloadCreator: async (params: AssignRssResourceToDeviceParams) =>
    cms.assignRssResourceToDevice(params),
  ...SNACKBARS.assignRssResourceToDevice,
})

export const resetAssignRssResourceToDeviceSideEffects = createAction(
  `${MODULE_NAME}/resetAssignRssResourceToDeviceSideEffects`
)

export const getDeviceScreenshot = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDeviceScreenshot`,
  payloadCreator: async (deviceId: number) => {
    await devices.requestDeviceScreenshot(deviceId)
    const deviceScreenshot = await devices.getDeviceScreenshot(deviceId)

    return deviceScreenshot
  },
  ...SNACKBARS.getDeviceScreenshot,
})

export const removeScheduleFromDevice = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/removeScheduleFromDevice`,
  payloadCreator: (
    complexId: Pick<ScheduleComplexId, 'playlistId' | 'displayId'>
  ) => playlists.removePlaylistScheduleFromDevice(complexId),
})

export const updateLastSent = createAction<
  Pick<GetDevicesResponse, 'lastSentTemplateId' | 'lastSentTemplateThumbnail'>
>(`${MODULE_NAME}/removeScheduleFromDevice`)
