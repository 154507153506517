import { createAction } from '@reduxjs/toolkit'
import {
  CreateMediaFileParameters,
  EditMediaCategoryParams,
  EditMediaFileParams,
  MediaAsset,
  GetMediaFilesParams,
  MediaLibrary,
  Assets,
  UploadFontParams,
} from 'shared/services'
import { MediaAssetFilterType, MediaAssetType } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import { State } from './reducer'

const mediaLibrary = new MediaLibrary()
const assets = new Assets()

export const setGetAllMediaFilesPage = createAction<number>(
  `${MODULE_NAME}/setGetAllMediaFilesPage`
)

export const setSelectedMediaType = createAction<
  MediaAssetFilterType | undefined
>(`${MODULE_NAME}/setSelectedMediaType`)

interface GetAllMediaFilesParams {
  loadMore?: boolean
  mediaType?: MediaAssetType
}

export const getAllMediaFiles = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getAllMediaFiles`,
  payloadCreator: async (
    { loadMore, mediaType }: GetAllMediaFilesParams,
    { getState }
  ) => {
    const { skip, limit } = (getState() as {
      [MODULE_NAME]: State
    }).mediaLibrary.getAllMediaFiles
    return mediaLibrary.getMediaFiles({
      skip,
      limit,
      loadMore,
      mediaType,
    })
  },
  ...SNACKBARS.getMediaFiles,
})

export const getMediaFiles = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getMediaFiles`,
  payloadCreator: async (
    params: Partial<GetMediaFilesParams>,
    { getState }
  ) => {
    const { skip, limit } = (getState() as {
      [MODULE_NAME]: State
    }).mediaLibrary.getMediaFiles
    const { mediaCategoryId, loadMore } = params
    return mediaLibrary.getMediaFiles({
      mediaCategoryId,
      skip,
      limit,
      loadMore,
    })
  },
  ...SNACKBARS.getMediaFiles,
})

export const setAffectedAssets = createAction<number>(
  `${MODULE_NAME}/setAffectedAssets`
)

export const resetGetMediaFiles = createAction(
  `${MODULE_NAME}/resetGetMediaFiles`
)

export const resetGetAllMediaFiles = createAction(
  `${MODULE_NAME}/resetGetAllMediaFiles`
)

export const createMediaCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/createMediaCategory`,
  payloadCreator: async (mediaCategoryName: string) =>
    mediaLibrary.createMediaCategory(mediaCategoryName),
  ...SNACKBARS.createCategory,
})

export const createMediaFile = createAsyncThunkWithErrorHandling<
  MediaAsset | undefined,
  CreateMediaFileParameters
>({
  typePrefix: `${MODULE_NAME}/createMediaFile`,
  payloadCreator: async (params: CreateMediaFileParameters) =>
    mediaLibrary.createMediaFile(params),
})

export const editMediaCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/editMediaCategory`,
  payloadCreator: async (params: EditMediaCategoryParams) =>
    mediaLibrary.editMediaCategory(params),
  ...SNACKBARS.editCategory,
})

export const deleteMediaCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteMediaCategory`,
  payloadCreator: async (id: number) => mediaLibrary.deleteMediaCategory(id),
  ...SNACKBARS.deleteCategory,
})

export const getAllMediaCategories = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getAllMediaCategories`,
  payloadCreator: () => mediaLibrary.getAllMediaCategories(),
  ...SNACKBARS.getCategories,
})

export const resetGetAllMediaCategories = createAction(
  `${MODULE_NAME}/resetGetAllMediaCategories`
)

export const deleteMediaFile = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteMediaFile`,
  payloadCreator: async (mediaUuid: string[]) =>
    mediaLibrary.deleteMediaFile(mediaUuid),
})

export const saveMediaAsset = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/saveMediaAsset`,
  payloadCreator: async (
    { selectedAsset, ...params }: EditMediaFileParams,
    { dispatch }
  ) => {
    const response = await mediaLibrary.editMediaFile(params)
    if (selectedAsset && 'path' in selectedAsset) dispatch(getFonts())
    return response
  },
  ...SNACKBARS.saveMediaFile,
})

export const resetAddNewCategory = createAction(
  `${MODULE_NAME}/resetAddNewCategory`
)

export const resetSaveMediaAsset = createAction(
  `${MODULE_NAME}/resetSaveMediaAsset`
)
export const resetAddNewMediaFile = createAction(
  `${MODULE_NAME}/resetAddNewMediaFile`
)

export const resetEditCategory = createAction(
  `${MODULE_NAME}/resetEditCategory`
)

export const resetDeleteMediaCategory = createAction(
  `${MODULE_NAME}/resetDeleteMediaCategory`
)

export const resetDeleteMediaFile = createAction(
  `${MODULE_NAME}/resetDeleteMediaFile`
)

export const resetDeleteMediaStatus = createAction(
  `${MODULE_NAME}/resetDeleteMediaStatus`
)

export const getMediaCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getMediaCategory`,
  payloadCreator: (categoryId: number) =>
    mediaLibrary.getMediaCategory(categoryId),
})

export const getFonts = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getFonts`,
  payloadCreator: async () => assets.getFonts(),
  ...SNACKBARS.getFonts,
})

export const uploadFont = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadFont`,
  payloadCreator: async (params: UploadFontParams, { dispatch }) => {
    const response = await assets.uploadFont(params)
    dispatch(getFonts())
    return response
  },
  ...SNACKBARS.uploadFont,
})
