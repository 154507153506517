import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Category = (props: SvgIconProps) => (
  <SvgIcon width="50px" height="50px" viewBox="0 0 50 50" {...props}>
    <g
      id="CALENDAR"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="My-displays"
        transform="translate(-584.000000, -392.000000)"
        fill="#FFF"
      >
        <g id="Fill-1265" transform="translate(584.000000, 392.000000)">
          <path d="M47.5,32.5 L42.5,32.5 L42.5,30 C42.5,25.865 39.135,22.5 35,22.5 L27.5,22.5 L27.5,17.5 L32.5,17.5 C33.88,17.5 35,16.38 35,15 L35,2.5 C35,1.12 33.88,0 32.5,0 L17.5,0 C16.12,0 15,1.12 15,2.5 L15,15 C15,16.38 16.12,17.5 17.5,17.5 L22.5,17.5 L22.5,22.5 L15,22.5 C10.865,22.5 7.5,25.865 7.5,30 L7.5,32.5 L2.5,32.5 C1.12,32.5 0,33.62 0,35 L0,47.5 C0,48.88 1.12,50 2.5,50 L17.5,50 C18.88,50 20,48.88 20,47.5 L20,35 C20,33.62 18.88,32.5 17.5,32.5 L12.5,32.5 L12.5,30 C12.5,28.6225 13.6225,27.5 15,27.5 L35,27.5 C36.3775,27.5 37.5,28.6225 37.5,30 L37.5,32.5 L32.5,32.5 C31.12,32.5 30,33.62 30,35 L30,47.5 C30,48.88 31.12,50 32.5,50 L47.5,50 C48.88,50 50,48.88 50,47.5 L50,35 C50,33.62 48.88,32.5 47.5,32.5"></path>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Category
