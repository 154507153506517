import { rest } from 'msw'
import { createHandlerURL } from 'shared/utils'
import { GetDisplayModelsResponse } from './LayoutDesignerUtils.types'
import {
  displayModelsEndpoint,
  projectEndpoint,
} from './LayoutDesignerUtils.utils'

export const layoutDesignerUtilsHandlers = [
  rest.get<{}, GetDisplayModelsResponse>(
    createHandlerURL(displayModelsEndpoint),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            displayModel: 'Atom',
            sizes: [],
            fonts: [],
            formats: [
              {
                name: 'primary',
                widgets: [],
              },
            ],
          },
        ])
      )
    }
  ),
  rest.post(createHandlerURL(projectEndpoint), (req, res, ctx) => {
    return res(ctx.status(200))
  }),
]
