import { useThrottleCallback } from '@react-hook/throttle'
import { ChangeEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'
import { SelectedFeed, SelectOption } from 'shared/types'

type Event = ChangeEvent<{ value: number | string }>

const getRssUrlFromOptions = (
  options: SelectOption[],
  selectedRssFeed: SelectedFeed
) =>
  typeof selectedRssFeed === 'number'
    ? options.find(({ value }) => value === selectedRssFeed)?.label
    : selectedRssFeed

export const useRssFeedPreview = (options: SelectOption[]) => {
  const dispatch = useDispatch()
  const [selectedRssFeed, setSelectedRssFeed] = useState<SelectedFeed>(
    undefined
  )
  const { data, loading } = useSelector(selectors.utils.getRssFeedFromUrl)
  const clearSelectedRssFeed = () => setSelectedRssFeed(undefined)
  const onChange = useCallback(
    (e: Event) => {
      const value = e.target.value
      setSelectedRssFeed(value)
      const rssUrl =
        typeof value === 'number' ? getRssUrlFromOptions(options, value) : value

      if (rssUrl) dispatch(actions.utils.getRssFeedFromUrl(rssUrl))
    },
    [selectedRssFeed, dispatch, options]
  )

  const getRssFeedFromUrl = useThrottleCallback((url: string) => {
    dispatch(actions.utils.getRssFeedFromUrl(url))
  }, 1)

  const getRssFeed = (url: string) => {
    setSelectedRssFeed(url)
    getRssFeedFromUrl(url)
  }

  return {
    selectedRssFeed,
    data,
    loading,
    clearSelectedRssFeed,
    onChange,
    getRssFeed,
  }
}
