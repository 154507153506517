import { useSelector } from 'react-redux'
import { Auth } from 'shared/services'
import { selectors } from './../store/index'

const auth = new Auth()

export const useAuth = () => {
  const isAuthenticated: boolean = useSelector(
    selectors.auth.getIsAuthenticated
  )

  return { isAuthenticated, logout: auth.logout }
}
