import {
  PlaylistItem,
  Playlist,
  Schedule,
  ScheduleComplexId,
} from 'shared/types'

export type GetPlaylistResponse = Playlist[]

export enum GetPlaylistParamsNames {
  playlistId = 'playlistId',
  playlistName = 'playlistName',
  playlistCategory = 'playlistCategory',
  aspectRatio = 'aspectRatio',
}

export type GetPlaylistParams = Partial<{
  playlistId: number
  playlistName: string
  playlistCategory: string
  aspectRatio: string
}>

export type UpdatePlaylistDto = {
  name: string
  aspectRatio: string
  description?: string | null
  categoryId?: number | null
  items: PlaylistItem[]
}

export interface PlaylistCategory {
  idPlaylistCategory: number
  name: string
}

export type PlaylistCategories = PlaylistCategory[]

export interface GetScheduledPlaylistsInDeviceParams {
  playlistId?: number
  displayId?: number
  templateId?: number
  scheduleId?: number
}

export type GetScheduledPlaylistsInDeviceResponse = ScheduleComplexId[]

export interface RemovePlaylistScheduleFromDeviceParams {
  playlistId: number
  displayId: number
  templateId?: number
}
export interface GetScheduleParams {
  scheduleId?: number
  name?: string
  tag?: string
  priority?: number
}

export type GetScheduleResponse = Schedule[]

export type addPlaylistCategoryBody = { name: string }

export type ChangeScheduleDto = Omit<
  Schedule,
  'idPlaylists' | 'idSchedule' | 'weeklySchedule'
> &
  Partial<Pick<Schedule, 'weeklySchedule'>>
