import createPalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'

const palette: PaletteOptions = {
  chipNeutral: {
    main: '#63b2d4',
  },
  primary: {
    light: '#173d52',
    main: '#033E54',
    dark: '#01202B',
    contrastText: '#fff',
  },
  secondary: {
    light: '#63ADAD',
    main: '#05A19F',
    dark: '#167e7d',
    contrastText: '#fff',
  },
  declined: {
    light: '#CCCCCC',
    main: '#B6C6CB',
    dark: '#91A2A8',
    contrastText: '#022C3C',
  },
  accent: {
    light: '#002B5F',
    main: '#022C3C',
    dark: '#8a9091',
    contrastText: '#fff',
  },
  background: {
    default: '#f9f8f8',
    paper: '#fff',
    neutral: {
      light: '#F6F9FB',
      main: '#EEF0F0',
      divider: '#CFDBDF',
    },
    overlay: {
      light: 'rgba(234,239,241,0.45)',
      loading: 'rgba(2, 44, 60, 0.6)',
      dark: '#075876',
      primary: '#CBD6D9',
      contrast: '#A6B3B6',
      default: '#DDE7EE',
      activeItem: '#1AB079',
      inactiveItem: '#C83744',
      lightGray: '#D8D8D8',
    },
    table: {
      metalic: '#F8F8FA',
      grey: '#EFEFF1',
      headerText: '#373E4B',
    },
  },
  info: {
    light: '#E7ECED',
    main: '#D6DDE0',
    dark: '#C4CFD3',
    contrastText: '#0B202C',
  },
  label: {
    main: '#808181',
    dark: '#757575',
  },
  error: {
    light: '#F5C0D9',
    main: '#BE1663',
  },
  success: {
    light: '#C4F6DF',
    main: '#0DBE6E',
  },
  text: {
    primary: '#022C3C',
  },
}

export default createPalette(palette)
declare module '@material-ui/core/styles/createPalette' {
  export interface Palette {
    accent: PaletteColor
    label: Partial<PaletteColor>
    declined: Partial<PaletteColor>
    chipNeutral: PaletteColor
  }

  export interface PaletteOptions {
    accent: PaletteColorOptions
    label: Partial<PaletteColorOptions>
    declined: Partial<PaletteColorOptions>
    chipNeutral: PaletteColorOptions
  }

  export interface TypeBackground {
    neutral: {
      light: string
      main: string
      divider: string
    }
    overlay: {
      light: string
      loading: string
      dark: string
      primary: string
      contrast: string
      default: string
      activeItem: string
      inactiveItem: string
      lightGray: string
    }
    table: {
      metalic: string
      grey: string
      headerText: string
    }
  }
}
