import { BackgroundColor, BackgroundGradient } from 'shared/types'

const backgroundColors: {
  colors: BackgroundColor[]
  gradients: BackgroundGradient[]
} = {
  colors: [
    { baseColor: '#DD6C17' },
    { baseColor: '#2CC97A' },
    { baseColor: '#1BB0C4' },
    { baseColor: '#A2229F' },
  ],
  gradients: [
    { startColor: '#39648E', endColor: '#94BAE0' },
    { startColor: '#E5620B', endColor: '#D01C1C' },
    { startColor: '#0FA66D', endColor: '#22A1B3' },
    { startColor: '#C921A9', endColor: '#9B33BC' },
    { startColor: '#D00413', endColor: '#780B0F' },
    { startColor: '#EABB19', endColor: '#D0640B' },
  ],
}

export default backgroundColors
