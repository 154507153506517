import { Position } from 'react-rnd'

export const DEFAULT_WIDGET_OFFSET: Position = { x: 0, y: 0 }
export const VIDEO_THUMBNAIL_SIZE = 120
export const DENSE_ICON_BUTTON_WIDTH = 56
export const NORMAL_ICON_BUTTON_WIDTH = 62
export const DEFAULT_LOADER_SIZE = 100
export const TOOLBAR_HEIGHT = 80
export const DRAWER_WIDTH = 240
export const DRAWER_MENU_WIDTH = 475
export const DRAWER_MENU_SCALE = 1.2
export const MIN_STATUS_BAR_ITEM_WIDTH = 45
export const PAGINATION_LIMITS = {
  ecosystemEvents: 100,
  mediaAssets: 24,
  audioFiles: 100,
}
export const DATETIME_FORMAT = 'dd-MM-yyyy HH:mm:ss'
export const TIME_FORMAT = 'HH:mm'
export const EXTENDED_TIME_FORMAT = 'HH:mm:ss'
export const DATE_FORMAT = 'dd-MM-yyyy'
export const DEFAULT_SCROLLING_TEXT_ANIMATION_DURATION = 10
export const PICKER_DIALOG_CONTENT_MIN_HEIGHT = 180
export const PICKER_DIALOG_CONTENT_MAX_HEIGHT = 640
export const PICKER_ITEM_HEIGHT = 147
export const PICKER_DIALOG_MAX_SIZE = 1060
export const LOGOUT_TIMEOUT = 3000
export const TAG_FONT_SIZE = '1rem'
export const TAG_FONT_WEIGHT = 700
export const ASPECT_RATIO_OPTIONS = [
  { label: '1:1', value: '1:1' },
  { label: '4:3', value: '4:3' },
  { label: '3:4', value: '3:4' },
  { label: '16:9', value: '16:9' },
  { label: '9:16', value: '9:16' },
  { label: '5:4', value: '5:4' },
  { label: '4:5', value: '4:5' },
]
export const STRING_ASPECT_RATIO_OPTIONS = ASPECT_RATIO_OPTIONS.map(option => ({
  label: option.label,
  value: option.label,
}))
export const DEFAULT_WIDGET_ASPECT_RATIO = '9:16'
export const DEFAULT_PLAYLIST_ITEM_DURATION = 10
export const MEDIA_LIBRARY_FOLDER = '/media_library/'
export const CARD_HEIGHT_WITH_SPACING = 351
export const DISPLAY_CARD_INNER_MARGIN = 20
export const RESIZER_SIZE = 10
export const NO_FILTER_NAME = 'noFilter'
export const MEDIA_CARD_HEIGHT = 148
export const ML_MODULE_NAME = 'mediaLibrary'
export const searchParams = (
  deviceId?: number,
  templateId?: number | null,
  aspectRatio?: string | null
) => `?deviceId=${deviceId}&templateId=${templateId}&aspectRatio=${aspectRatio}`
export const DURATION_LABEL_SIZE = { height: 22, width: 55 }
