import {
  styled,
  FormControl as BaseFormControl,
  Theme,
  makeStyles,
  MenuItem as BaseMenuItem,
  fade,
  ListItemIcon as BaseListItemIcon,
  ListItemText as BaseListItemText,
  ListItem as BaseListItem,
} from '@material-ui/core'

interface FormControlProps {
  open: boolean
  isGray?: boolean
  isDefaultCase?: boolean
  isHigher?: boolean
  height?: string | number
  width?: string | number
  showError?: boolean
  readonly?: boolean
}

export const ListItem = styled(BaseListItem)({
  padding: 0,
  margin: 0,

  '&:hover': {
    background: 'none',
  },
})

export const ListItemIcon = styled(BaseListItemIcon)({
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
})

export const ListItemText = styled(BaseListItemText)({
  padding: 0,
  margin: 0,
})

export const MenuItem = styled(BaseMenuItem)({
  textTransform: 'none',
})

export const FormControl = styled(BaseFormControl)<Theme, FormControlProps>(
  ({
    theme,
    open,
    isGray,
    isDefaultCase,
    isHigher,
    height,
    width,
    showError,
    readonly,
  }) => ({
    border: `3px solid ${
      showError
        ? theme.palette.error.main
        : open
        ? theme.palette.primary.main
        : theme.palette.declined.main
    }`,
    ...(isGray && {
      border: `3px solid ${theme.palette.declined.main}`,
    }),
    height,
    width,
    borderRadius: 3,

    '& .MuiInputBase-root': {
      backgroundColor: isGray
        ? theme.palette.declined.main
        : theme.palette.background.neutral.light,
      color: theme.palette.accent.main,
      fontWeight: 600,
      zIndex: 1,
      height: isHigher ? 34 : height,
      ...(readonly && {
        background: `${fade(theme.palette.accent.main, 0.08)} !important`,
        color: theme.palette.info.contrastText,
        fontWeight: 700,
        whiteSpace: 'pre',
        pointerEvents: 'none',
      }),
    },

    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: 10,
      zIndex: -1,
    },

    '& .MuiListItem-root': {
      textTransform: isDefaultCase ? 'none' : 'capitalize',
    },
  })
)

export const useSelectStyles = makeStyles<
  Theme,
  {
    disabled?: boolean
    isDefaultCase?: boolean
    isGray?: boolean
  }
>(theme => ({
  select: {
    '& .MuiSelect-selectMenu': {
      paddingLeft: theme.spacing(2),
      textTransform: props => (props.isDefaultCase ? 'none' : 'capitalize'),
      cursor: props => (props.disabled ? 'not-allowed' : 'pointer'),

      '&:focus': {
        backgroundColor: props =>
          props.isGray ? theme.palette.declined.main : 'transparent',
      },
    },
  },
}))

export const ErrorWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  opacity: 0.55,
  fontSize: '0.8125rem',
  fontWeight: 400,
  padding: theme.spacing(0, 3),
  color: theme.palette.error.main,
}))
