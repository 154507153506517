import { SvgIcon, SvgIconProps } from '@material-ui/core'

const BottomAlignment = (props: SvgIconProps) => (
  <SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74998 0.4395V9.879L8.46973 8.15925L9.53023 9.21975L5.99998 12.75L2.46973 9.21975L3.53023 8.15925L5.24998 9.879V0.4395H6.74998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15.75H12V14.25H0V15.75Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default BottomAlignment
