import { SvgIcon, SvgIconProps } from '@material-ui/core'

const FlippingImages = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 21 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.00342L11.859 6.02142L13.005 7.85942L15.646 3.62342L19 9.00342H10ZM19 0.00341797H10C8.896 0.00341797 8 0.899418 8 2.00342V10.0034C8 11.1074 8.896 12.0034 10 12.0034H19C20.104 12.0034 21 11.1074 21 10.0034V2.00342C21 0.899418 20.104 0.00341797 19 0.00341797Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.00342H4V14.0034C4 15.1064 4.897 16.0034 6 16.0034H16V14.0034H6V3.00342Z"
      fill="#033E54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.00342H0V18.0034C0 19.1064 0.897 20.0034 2 20.0034H12V18.0034H2V7.00342Z"
      fill="#033E54"
    />
  </SvgIcon>
)

export default FlippingImages
