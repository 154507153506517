import {
  ApiWidget,
  ImageWidgetPropertyName,
  VideoWidgetPropertyName,
  WidgetType,
  UploadWidget,
} from 'shared/types'
import { ERRORS } from 'shared/utils'
import { Api } from '../api'
import { Assets } from '../assets'
import {
  GetWidgetsResponse,
  GetWidgetsParameters,
  AddMediaWidgetRequest,
  AddWidgetResponse,
  GetWidgetCategoriesResponse,
  AddArrowWidgetRequest,
  AddWidgetCategoryBody,
  AddWidgetCategoryResponse,
} from './Widgets.types'

const SUBPATH = '/layout_designer_widgets'

class Widgets extends Api {
  private assets = new Assets()

  private getFilePropertyName = (widgetType: WidgetType) => {
    switch (widgetType) {
      case WidgetType.Background:
      case WidgetType.Image:
        return ImageWidgetPropertyName.img
      case WidgetType.Video:
        return VideoWidgetPropertyName.video
      default:
        return null
    }
  }

  deleteWidget = async (widgetId: number) => {
    const { data } = await this.api.delete<GetWidgetsResponse>(
      `${SUBPATH}/widgets`,
      { params: { widgetId } }
    )

    return data
  }

  getWidgets = async (params: GetWidgetsParameters) => {
    const { data } = await this.api.get<GetWidgetsResponse>(
      `${SUBPATH}/widgets`,
      { params }
    )

    return data
  }

  getWidgetsByType = async (widgetType: WidgetType) =>
    this.getWidgets({ widgetType })

  uploadArrowWidget = async ({
    fileProperties,
    idWidgetCategory,
    displayModel,
    name,
  }: AddArrowWidgetRequest) => {
    if (!fileProperties[0]) throw new Error(ERRORS.widgetNeedsAtListOneImage)
    const assetData = await Promise.all(
      fileProperties.map(fileProperty =>
        this.assets.getAssetInfo(fileProperty.content)
      )
    )

    const savedProperties = fileProperties.map(({ name, content }) => ({
      name,
      value: content,
    }))

    const payload = {
      idWidgetCategory,
      widgetType: WidgetType.DirectionArrows,
      width: assetData[0].width,
      height: assetData[0].height,
      properties: savedProperties,
      universal: false, // this flag is used to create widget for all companies
      name,
    }

    const { data } = await this.api.post<AddWidgetResponse>(
      `${SUBPATH}/widgets`,
      payload,
      { params: { displayModel } }
    )

    return data
  }

  uploadWidget = async (widget: UploadWidget, displayModel?: string) => {
    const { data } = await this.api.post<AddWidgetResponse>(
      `${SUBPATH}/widgets`,
      widget,
      { params: { displayModel } }
    )

    return data
  }

  updateWidget = async (widget: ApiWidget) => {
    const { data } = await this.api.put(`${SUBPATH}/widgets`, widget, {
      params: { widgetId: widget.id },
    })
    return data
  }

  uploadMediaWidget = async ({
    fileUuid,
    fileName,
    displayModel,
    widgetType,
    thumbnail,
    idWidgetCategory,
  }: AddMediaWidgetRequest) => {
    const { width, height } = await this.assets.getAssetInfo(fileUuid)

    const filePropertyName = this.getFilePropertyName(widgetType)
    if (!filePropertyName) throw new Error(ERRORS.widgetTypeCannotBeUploaded)

    const payload = {
      widgetType,
      idWidgetCategory,
      width,
      height,
      universal: false, // this flag is used to create widget for all companies
      name: fileName,
      thumbnail: thumbnail ?? fileUuid,
      properties: [
        {
          name: filePropertyName,
          value: fileUuid,
        },
      ],
    }

    return await this.uploadWidget(payload, displayModel)
  }

  getWidgetsCategories = async () => {
    const { data } = await this.api.get<GetWidgetCategoriesResponse[]>(
      `${SUBPATH}/widgets_categories`
    )

    return data
  }

  addWidgetCategory = async (body: AddWidgetCategoryBody) => {
    const { data } = await this.api.post<AddWidgetCategoryResponse>(
      `${SUBPATH}/widgets_categories`,
      body
    )

    return data
  }
}

export default Widgets
