import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app'
import {
  AddArrowWidgetRequest,
  AddMediaWidgetRequest,
  AddWidgetCategoryBody,
  CalculationProperties,
  GetDefaultRssFeedsParams,
  GetTemplatesParams,
  LayoutDesignerUtils,
  MediaLibrary,
  Templates,
  Widgets,
  Playlist,
} from 'shared/services'
import {
  AbsoluteSize,
  ApiWidget,
  GetDisplayPlaylistsParams,
  ProgressStep,
  EditTemplateDetailsDTO,
  SlotWidgets,
  WidgetType,
  CreateTemplateDetailsDTO,
} from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import { mapSavedActiveStep } from '../utils'
import {
  AddWidgetToSlotPayload,
  DeleteWidgetPayload,
  GetTemplateParams,
  GetWidgetsPayload,
  RemoveWidgetPayload,
  SaveDisplaySizeDetailsPayload,
  SetActionValuesPayload,
  SetEditModePayload,
  SetLiveEditHelperValuesPayload,
  SetMediaTransformationPayload,
  SetSelectedLayoutPayload,
  SetSlotSizePayload,
  SetWidgetPositionPayload,
  SetWidgetPropertiesPayload,
  SetWidgetSizePayload,
  UploadWidgetPayload,
} from './actions.types'
import {
  getActiveStep,
  getCalculationDataForSaving,
  getSlotSizes,
} from './selectors'

const layoutDesignerUtils = new LayoutDesignerUtils()
const widgets = new Widgets()
const templates = new Templates()
const mediaLibrary = new MediaLibrary()
const playlist = new Playlist()

export const setIsWidgetMenuOpen = createAction<boolean>(
  `${MODULE_NAME}/setIsWidgetMenuOpen`
)

export const setCurrentStepValue = createAction<ProgressStep>(
  `${MODULE_NAME}/setCurrentStepValue`
)

export const setSelectedLayout = createAction<SetSelectedLayoutPayload>(
  `${MODULE_NAME}/setSelectedLayout`
)

export const addCustomBackgroundColor = createAction<string>(
  `${MODULE_NAME}/addCustomBackgroundColor`
)

export const addWidgetToSlot = createAction<AddWidgetToSlotPayload>(
  `${MODULE_NAME}/addWidgetToSlot`
)

export const getWidgetsByType = createAsyncThunk(
  `${MODULE_NAME}/getWidgetsByType`,
  (widgetType: WidgetType) => widgets.getWidgetsByType(widgetType)
)

export const resetLoadingUploadWidget = createAction(
  `${MODULE_NAME}/resetLoadingUploadWidget`
)

export const getWidgets = createAsyncThunk(
  `${MODULE_NAME}/getWidgetsByTypeAndName`,
  (params: GetWidgetsPayload) => widgets.getWidgets(params)
)

export const getArrows = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getArrows`,
  payloadCreator: () => layoutDesignerUtils.getArrows(),
})

export const getWidgetsCategories = createAsyncThunk(
  `${MODULE_NAME}/getWidgetsCategories`,
  () => widgets.getWidgetsCategories()
)

export const uploadWidget = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadWidget`,
  payloadCreator: async ({ widget, displayModel }: UploadWidgetPayload) =>
    widgets.uploadWidget(widget, displayModel),
  ...SNACKBARS.uploadWidget,
})

export const clearUploadWidget = createAction(
  `${MODULE_NAME}/clearUploadWidget`
)

export const uploadMediaWidget = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadMediaWidget`,
  payloadCreator: async (payload: AddMediaWidgetRequest) =>
    widgets.uploadMediaWidget(payload),
  ...SNACKBARS.uploadMediaWidget,
})

export const uploadArrowWidget = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/uploadArrowWidget`,
  payloadCreator: async (payload: AddArrowWidgetRequest) =>
    widgets.uploadArrowWidget(payload),
  ...SNACKBARS.uploadArrowWidget,
})

export const getDisplayPlaylists = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDisplayPlaylists`,
  payloadCreator: async (payload: GetDisplayPlaylistsParams) =>
    playlist.getDisplayPlaylists(payload),
})

export const setWidgetSize = createAction<SetWidgetSizePayload>(
  `${MODULE_NAME}/setWidgetSize`
)

export const setWidgetPosition = createAction<SetWidgetPositionPayload>(
  `${MODULE_NAME}/setWidgetPosition`
)

export const setEditMode = createAction<SetEditModePayload>(
  `${MODULE_NAME}/setEditMode`
)

export const toggleEditItem = createAction<string | number>(
  `${MODULE_NAME}/toggleEditItem`
)

export const removeWidget = createAction<RemoveWidgetPayload>(
  `${MODULE_NAME}/removeWidget`
)

export const cancelEditMode = createAction(`${MODULE_NAME}/cancelEditMode`)

export const saveDisplaySizeDetails = createAction<SaveDisplaySizeDetailsPayload>(
  `${MODULE_NAME}/saveDisplaySizeDetails`
)

export const setMediaTransformation = createAction<SetMediaTransformationPayload | null>(
  `${MODULE_NAME}/setMediaTransformation`
)

export const setWidgetProperties = createAction<SetWidgetPropertiesPayload>(
  `${MODULE_NAME}/setWidgetProperties`
)

export const setLayoutPreviewSize = createAction<AbsoluteSize>(
  `${MODULE_NAME}/setLayoutPreviewSize`
)

export const setSlotSize = createAction<SetSlotSizePayload>(
  `${MODULE_NAME}/setSlotSize`
)

export const createSetLiveEditHelperValues = <T extends WidgetType>(type: T) =>
  createAction<SetLiveEditHelperValuesPayload<T>>(
    `${MODULE_NAME}/setLiveEditHelperValues/${type}`
  )

export const resetLiveEditHelperValues = createAction(
  `${MODULE_NAME}/resetLiveEditHelperValues`
)

export const resetDirectionArrowUpload = createAction(
  `${MODULE_NAME}/resetDirectionArrowUpload`
)

export const setIsSlotDraggedOver = createAction<boolean>(
  `${MODULE_NAME}/setIsSlotDraggedOver`
)

export const setEditorWidgets = createAction<SlotWidgets>(
  `${MODULE_NAME}/setEditorWidgets`
)

export const resetSlotSizes = createAction(`${MODULE_NAME}/resetSlotSizes`)

export const createTemplate = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/createTemplate`,
  payloadCreator: async (
    templateDetails: CreateTemplateDetailsDTO,
    thunkApi
  ): Promise<number> => {
    const state = thunkApi.getState() as RootState
    const calculationProperties = getCalculationDataForSaving(state)
    const slotSizes = getSlotSizes(state)
    const baseEditionProgressStep = getActiveStep(state)
    const editionProgressStep = mapSavedActiveStep(
      baseEditionProgressStep,
      state.layoutDesigner.editor.selectedDefaultLayout
    )

    const data = await templates.createTemplate(
      { ...templateDetails, editionProgressStep },
      calculationProperties as CalculationProperties,
      slotSizes
    )
    return data.id
  },
  ...SNACKBARS.createTemplate,
})

export const saveTemplate = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/saveTemplate`,
  payloadCreator: async (templateDetails: EditTemplateDetailsDTO, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const calculationProperties = getCalculationDataForSaving(state)
    const slotSizes = getSlotSizes(state)
    const baseEditionProgressStep = getActiveStep(state)
    const editionProgressStep = mapSavedActiveStep(
      baseEditionProgressStep,
      state.layoutDesigner.editor.selectedDefaultLayout
    )

    await templates.saveTemplate(
      { ...templateDetails, editionProgressStep },
      calculationProperties as CalculationProperties,
      slotSizes
    )
  },
  ...SNACKBARS.saveTemplate,
})

export const clearSaveCreate = createAction(`${MODULE_NAME}/clearSaveCreate`)

export const setTemplateFullyProcessed = createAction<boolean>(
  `${MODULE_NAME}/setTemplateFullyProcessed`
)

export const setActionValues = createAction<SetActionValuesPayload>(
  `${MODULE_NAME}/setActionValues`
)

export const getTemplate = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getTemplate`,
  payloadCreator: async (params: GetTemplateParams) =>
    params.prefetchedTemplate
      ? {
          ...Templates.mapSectionSizesToSlots(params.prefetchedTemplate),
          editionProgressStep: ProgressStep.LayoutSelection,
        }
      : templates.getTemplate(params),
  ...SNACKBARS.getTemplate,
})

export const getDefaultTemplates = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDefaultTemplates`,
  payloadCreator: (
    params: Omit<
      GetTemplatesParams,
      'templateId' | 'resolution' | 'templateName'
    >
  ) => templates.getDefaultTemplatesMOCKED(params),
})

export const clearWidgetTemplate = createAction(
  `${MODULE_NAME}/clearWidgetTemplate`
)

export const clearGetTemplateError = createAction(
  `${MODULE_NAME}/clearGetTemplateError`
)

export const clearDefaultTemplate = createAction(
  `${MODULE_NAME}/clearDefaultTemplate`
)

export const updateGeneratedProjectTime = createAction(
  `${MODULE_NAME}/updateGeneratedProjectTime`
)

export const getDefaultRssFeed = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/getDefaultRssFeed`,
  payloadCreator: async (params: GetDefaultRssFeedsParams) =>
    layoutDesignerUtils.getDefaultRssFeeds(params),
})

export const deleteTemplateThumbnail = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteTemplateThumbnail`,
  payloadCreator: (thumbnailId: string) =>
    mediaLibrary.deleteMediaFile([thumbnailId]),
})

export const updateTemplateThumbnail = createAction<{
  thumbnailUuid: string
}>(`${MODULE_NAME}/updateTemplateThumbnail`)

export const addWidgetCategory = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/addWidgetCategory`,
  payloadCreator: async (payload: AddWidgetCategoryBody) =>
    widgets.addWidgetCategory(payload),
  ...SNACKBARS.addWidgetCategory,
})

export const addWidgetCategoryClearSideEffects = createAction(
  `${MODULE_NAME}/addWidgetCategoryClearSideEffects`
)

export const getDisplayDetails = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}`,
  payloadCreator: async () => layoutDesignerUtils.getDisplayModels(),
  ...SNACKBARS.getDisplayDetails,
})

export const resetDisplayDetails = createAction(
  `${MODULE_NAME}/resetDisplayDetails`
)

export const deleteWidget = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteWidget`,
  payloadCreator: async (payload: DeleteWidgetPayload) =>
    widgets.deleteWidget(payload.widgetId),
})

export const updateWidget = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/updateWidget`,
  payloadCreator: async (payload: ApiWidget) => widgets.updateWidget(payload),
  ...SNACKBARS.updateWidget,
})
