import { Chip, styled } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { DURATION_LABEL_SIZE } from 'shared/config'

const DURATION_LABEL_POSITION = { bottom: 10, right: 10 }

export const Duration = styled(Chip)(({ theme }) => ({
  ...DURATION_LABEL_SIZE,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  pointerEvents: 'none',

  '& .MuiChip-label': { fontWeight: 700, fontSize: '0.65rem' },
}))

export const DurationSkeleton = styled(Skeleton)({
  position: 'absolute',
  ...DURATION_LABEL_POSITION,
  height: 30,
  width: 55,
})
