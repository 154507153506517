import { combineReducers } from 'redux'
import auth from './auth'
import displayModels from './displayModels'
import templates from './templates'
import utils from './utils'
import mediaPicker from './mediaPicker'

export const actions = {
  auth: auth.actions,
  displayModels: displayModels.actions,
  templates: templates.actions,
  utils: utils.actions,
  mediaPicker: mediaPicker.actions,
}

export const selectors = {
  auth: auth.selectors,
  displayModels: displayModels.selectors,
  templates: templates.selectors,
  utils: utils.selectors,
  mediaPicker: mediaPicker.selectors,
}

export const reducer = combineReducers({
  auth: auth.reducer,
  displayModels: displayModels.reducer,
  templates: templates.reducer,
  utils: utils.reducer,
  mediaPicker: mediaPicker.reducer,
})
