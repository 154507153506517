import filterConsole from 'filter-console'

const wellKnownLogs: string[] = [
  'for a non-boolean attribute',
  'findDOMNode',
  'If you accidentally passed it from a parent component',
]

const consoleFilter = {
  init: () => {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        '%c Some of the errors could be removed due to the development mode. List of them you can find in consoleFilter.ts',
        'background: #006403; font-size: 13px'
      )
      filterConsole(wellKnownLogs)
    }
  },
}

export default consoleFilter
