import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Underline = (props: SvgIconProps) => (
  <SvgIcon width="15" height="18" viewBox="0 0 15 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 17.25H14V15.625H1V17.25Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.125 1V2.625H10.75V9.125C10.75 10.9174 9.29237 12.375 7.5 12.375C5.70762 12.375 4.25 10.9174 4.25 9.125V2.625H5.875V1H1V2.625H2.625V9.125C2.625 11.8136 4.81144 14 7.5 14C10.1886 14 12.375 11.8136 12.375 9.125V2.625H14V1H9.125Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </SvgIcon>
)

export default Underline
