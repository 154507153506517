import { rest } from 'msw'
import { createHandlerURL, getMockParam } from 'shared/utils'
import {
  GetDeviceAudioFilesParams,
  GetDeviceAudioFilesResponse,
} from './AudioLibrary.types'
import { EVENT_AUDIOS, FLOOR_AUDIOS, getAudioFiles } from './AudioLibrary.utils'

export const AudioLibraryHandlers = [
  rest.get<GetDeviceAudioFilesParams | undefined, GetDeviceAudioFilesResponse>(
    createHandlerURL(getAudioFiles),
    (req, res, ctx) => {
      const audioType = getMockParam('audio_type', req)
      const response = audioType === 'event' ? EVENT_AUDIOS : FLOOR_AUDIOS

      return res(ctx.status(200), ctx.json(response))
    }
  ),
]
